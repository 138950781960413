import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { Transition } from 'components/';
import {
  Create,
  Reset,
  Success
} from './containers'
import { updateSimplifiedInvoice, generateUrl, resetUrl } from '../../actions/simplified-invoice';
import { updateUserInfo } from '../../actions/user';

const Request = () => {
  return (
    <div className="payout">
      <div className="content center">
        <Transition
          root={'/request'}
          defaultPath={`/request/add`}
        >
          <Transition.Item path={root => `${root}/done`}>
            <Success />
          </Transition.Item>
          <Transition.Item path={root => `${root}/add`}>
            <Create />
          </Transition.Item>
          <Transition.Item path={root => `${root}/new`}>
            <Reset />
          </Transition.Item>
        </Transition>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  loading: state.data.loading,
  price: state.simplifiedInvoice.price,
  to: state.simplifiedInvoice.to,
  description: state.simplifiedInvoice.description,
  url: state.simplifiedInvoice.url,
  status: state.simplifiedInvoice.status,
  latest_cellphone_number: state.user.latest_cellphone_number,

});
const mapDispatchToProps = dispatch => ({
  generateUrl: () => {
    dispatch(generateUrl());
  },
  resetUrl: () => {
    dispatch(resetUrl());
  },
  updateSimplifiedInvoice: (data) => {
    dispatch(updateSimplifiedInvoice(data));
  },
  updateUserInfo: (user) => {
    dispatch(updateUserInfo(user))
  }

});


export default connect(mapStateToProps, mapDispatchToProps)(Request);