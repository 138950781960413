import {
  types,
} from '../constants/action-types';
import { get, patch } from 'utils/fetchV3';

const url = (parms = '') => config =>
  `${config.invoicingUrl}/${parms}`;


export const fetchOpenInvoice = (id, token) =>
  get(types.FETCH_OPEN_INVOICE, url(`open_invoices/${id}/?token=${token}`));


export const connectOpenInvoice = (id, token) =>
  patch(types.CONNECT_OPEN_INVOICE, url(`open_invoices/${id}/?token=${token}`), {});
