import {
  ADD_RECIPIENT,
  REMOVE_RECIPIENT,
  UPDATE_RECIPIENT,
  VALID_RECIPIENT,
  INVALID_RECIPIENT,
  PAYOUT_RESET,
  PAYOUT_CLEAR,
  types,
} from '../constants/action-types';
import { post, get as get2 } from '../utils/fetchV3';


const privateUrl = (parms = '') => config =>
  `${config.privateUrl}/${parms}`;


export const setRecipients = data => 
  ({ type: types.SET_RECIPIENTS, payload: data })

export const resetRecipients = () => 
  ({ type: types.RESET_RECIPIENTS, payload: [] })

export const resetPayoutsById = () => 
  ({ type: types.RESET_PAYOUTS_BY_ID });

export const setEmployeesById = ids =>  (dispatch, getState) => {
  const employees = getState().employee.list.results || [];
  dispatch({
    type: types.SET_EMPLOYEES_BY_ID,
    payload: {
      employees,
      ids
    },
  });
}



const makeid = () => {
  let text = "";
  const possible = "abcdefghijklmnopqrstuvwxyz0123456789";
  for (var i = 0; i < 10; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
}


const apiUrl = (parms = '') => config =>
  `${config.baseUrl}/${parms}`;

export const addRecipientsNotice = (data) =>
  post(types.ADD_RECIPIENT_NOTICE, apiUrl(`payouts/?expand=employee`), data);

export const updateNoticeData = (data) => ({
  type: types.UPDATE_NOTICE_DATA,
  payload: data,
});

export const fetchPayoutsById = ids => 
  get2(types.PAYOUTS_BY_ID, ids.map(i => privateUrl(`payouts/${i}/`)));



export const reset = () => ({
  type: PAYOUT_RESET,
  payload: makeid(),
});
export const clear = () => ({
  type: PAYOUT_CLEAR,
});
export const addRecipient = (data = {}) => ({
  type: ADD_RECIPIENT,
  payload: {
    ...data,
    tempId: makeid()
  },
});

export const removeRecipient = (receiver) => ({
  type: REMOVE_RECIPIENT,
  payload: receiver,
});

export const updateRecipient = (user) => ({
  type: UPDATE_RECIPIENT,
  payload: {
    ...user,
    amount: (user.amount === '') ? 0 : parseInt(user.amount)
  },
});

export const recipientsError = (msg) => ({
  type: INVALID_RECIPIENT,
  payload: msg
});
export const recipientsValid = () => ({
  type: VALID_RECIPIENT
});

