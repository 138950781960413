import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation, Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom'
import {
  Icon,
  Link,
  Subtitle,
  Title,
  Button,
  Alert,
  ToolTip
} from 'gigapay-ui';
import _ from 'lodash';
import { status } from 'constants/action-types';
import css from '../../file.module.css';

const errorToString = serverError => {
  let errorString = '';
  if (serverError !== null) {
    for (var key in serverError) {
      errorString = `${errorString} ${serverError[key]}`;
    }
  }
  return errorString;
};

const List = ({ onClear, onSubmit, onRemove, employees, error: createError, status: createStatus }) => {
  const { t } = useTranslation(['employees', 'common']);
  const history = useHistory();
  useEffect(() => {
    if (createStatus === status.SUCCESS) {
      history.push(`/employees/?numberOfImported=${employees.length}`)
    }
  }, [createStatus]);

  const list = employees.map((e, index) => ({
    ...e,
    error: (createError && createError[index] && !_.isEmpty(createError[index])) ?
      errorToString(createError[index]) :
      null,
  }));

  const numberOfError = list.filter(x => x.error !== null).length;
  return (
    <>
      <Title.Line>{t('Import recipients')}</Title.Line>
      {numberOfError === 0 && (
        <Alert.Success style={{ marginBottom: 10 }}>
          <p>{t('{{x}} recipients were found', { x: employees.length })}</p>
        </Alert.Success>
      )}
      {numberOfError !== 0 && (
        <Alert.Error style={{ marginBottom: 10 }}>
          <p>{t('{{x}} errors were found', { x: numberOfError })}</p>
        </Alert.Error>
      )}
      <Link primary bold onClick={onClear} >
        <Icon name={'arrow-back'} size={15} style={{ marginRight: 5 }} />
        {t('payout:Change file')}
      </Link>
      <Subtitle small>{t('Team')}</Subtitle>
      <ul className={css.fileList}>
        <li className={css.header}>
          <div className={css.col}>{t('common:Phone number')}</div>
          <div className={css.col}>{t('common:Email')}</div>
          <div className={css.col}>{t('common:Name')}</div>
          <div className={css.editRow} />
        </li>
        {list.map((i, index) => (
          <li key={i.id} className={`${i.error ? css.error : ''}`}>
            <div className={css.symbolCol}>
              {i.error && (
                <ToolTip
                  width={180}
                  label={(<Icon name={'warning'} color={'#650000'} />)}
                  text={t(i.error)}
                />)}
            </div>
            <div className={css.col}>
              {i.cellphone_number && <b>{i.cellphone_number}</b>}
              {!i.cellphone_number && <b>-</b>}
            </div>
            <div className={css.col}>
              <b>{i.email}</b>
            </div>
            <div className={css.col}>
              <b>{i.name}</b>
            </div>
            <div className={css.editRow}>
              <Link onClick={() => onRemove(i.id)}>
                <Icon name={'trash'} size={15} style={{ marignRight: 4 }} />
              </Link>
            </div>
          </li>
        ))}
      </ul>
      <div className={'spacing'} />
      <Button.Primary
        loading={createStatus === status.BEGIN}
        onClick={() => onSubmit(employees)}
      >
        {t('Add')}
      </Button.Primary>
    </>
  )
}

List.propTypes = {
  employees: PropTypes.array,
  onClear: PropTypes.func,
  onSubmit: PropTypes.func,
  onRemove: PropTypes.func,
}
List.defaultProps = {
  employees: [],
  onClear: () => { },
  onSubmit: () => { },
  onRemove: () => { },
}

export default List
