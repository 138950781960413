import React, { useState } from 'react';
import PropTypes from 'prop-types'
import { useHistory } from "react-router-dom";
import { Title, Icon, Table, Button, Subtitle } from 'gigapay-ui';
import { useTranslation } from 'react-i18next';
import { numberWithSpaces } from 'utils/price';
import { TableFooter, Avatar } from 'components/';
import { format } from 'utils/dateTime';
import { configFile } from 'config';


const ReceivedPayouts = ({ title, subTitle, canApprove, data: propData, loadMore }) => {
  const { t } = useTranslation(['dashboard', 'common']);
  const [selected, setSelected] = useState([]);
  const history = useHistory();
  const { data, loading, hasNext } = propData;

  if (data.length === 0) return null;

  return (
    <div className="flex-row-md">
      <div className="flex-col-md-12">

        <Title small style={{ marginTop: '20px', marginBottom: '5px' }}>{title}</Title>
        {subTitle && <div style={{display: "flex", flexDirection: "row"}}><Subtitle small style={{maxWidth: "600px", fontWeight: "normal", marginBottom: "20px"}}>{subTitle}</Subtitle></div>}
        <Table
          selectable={canApprove}
          keyExtractor={(item) => item.id}
          data={data}
          onClick={(props) => history.push("/ok/payout/?id=" + props.id)}
          columns={[{
            Header: t('common:Description'),
            Cell: props => (
              <div className="cell-container">
                <span>
                  <span className="bold-mobile">{props.description}</span>
                  <span className="mobileOnly table-subtitle">{props.employer}</span>
                </span>

              </div>),
            id: 'desciption',
            className: 'main-cell',
            headerClassName: 'main-cell',
            headerStyle: canApprove ? {}: {paddingLeft: '30px'},
            style: canApprove ? {}: {paddingLeft: '30px'}
          }, {
            Header: t('common:Date'),
            Cell: props => <span>{format(props.created_at)}</span>,
            id: 'date',
            className: 'tableCell-desktopOnly',
            headerClassName: 'tableCell-desktopOnly'
          }, {
            Header: t('common:Company'),
            Cell: props => <span>{props.employer}</span>,
            id: 'employer',
            className: 'tableCell-desktopOnly',
            headerClassName: 'tableCell-desktopOnly'
          },
          ...((configFile === 'payhour') ? [
              {
                Header: <span className="text-align-right">{t('common:GrossSalary')}</span>,
                Cell: props => <span className="text-align-right">{numberWithSpaces(parseFloat(props.amount) + parseFloat(props.fee))} kr</span>,
                id: 'amount',
              }] : []
            ),
          ...((configFile !== 'payhour') ? [
              {
                Header: <span className="text-align-right">{t('common:GrossSalary')}</span>,
                Cell: props => <span className="text-align-right">{numberWithSpaces(props.amount)} kr</span>,
                id: 'amount',
              }] : []
          ), {
            Header: null,
            Cell: props => <Icon style={{ textAlign: 'right', marginRight: 16 }} name={'arrow-forward'} />,
            id: 'arrow',
            headerClassName: 'arrow-cell',
            className: 'arrow-cell',
          }]}
          onChange={(rows) => {
            setSelected(rows.map((r) => r.id))
          }}
        />
        <TableFooter
          loading={loading}
          hasNext={hasNext}
          onClick={() => {
            loadMore();
          }}
        />

        {selected.length !== 0 && (
          <Button
            onClick={() => {
              history.push(`/ok?id=${selected.join(',')}`)
            }}
            primary
            style={{ marginTop: '15px' }}>{t('Accept')}</Button>)}
      </div>
    </div>
  )
};


ReceivedPayouts.propTypes = {
  data: PropTypes.object,
  canApprove: PropTypes.bool,
};


ReceivedPayouts.defaultProps = {
  data: {
    data: [],
    loading: false,
    hasNext: false
  },
  canApprove: false,
  subTitle: '',
};


export default ReceivedPayouts;