import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom'
import * as qs from 'query-string';
import { Button, Title, Paragraph } from 'gigapay-ui';
import { SuccessAnimation } from 'components/'

const ClaimedComplete = () => {
  const { t } = useTranslation(['accept', 'common']);
  const history = useHistory();
  const location = useLocation();
  const query = qs.parse(location.search);
  return (
    <div className="content center">
      <div style={{ textAlign: 'center' }}>
        <SuccessAnimation />
        <Title margins={false}>{t('Done!')}</Title>
        <Paragraph>{t('You have been added to the team and can start receiving your salary.')}</Paragraph>
        {query.auto === 'True' ?
          <Paragraph>{t('Your salary will be paid out automatically.')}</Paragraph> :
          <Paragraph>{t('We will send you a text when you receive a payout.')}</Paragraph>
        }
        <div className="spacing" />
        <Button
          onClick={() => {
            history.push('/dashboard')
          }}
          responsive
        >
          {t('Go to dashboard')}
        </Button>
      </div>
    </div>
  )
};

export default ClaimedComplete;
