import {
  types,
} from '../constants/action-types';
import { get} from '../utils/fetchV3';

const urlWithQuery = (parms = '', args = {}) => config => {
  let url = `${config.baseUrl}/${parms}?`;
  const pairs = [];
  for (let [k, v] of Object.entries(args)) {
    pairs.push(`${k}=${v}`);
  }
  url = `${url}${pairs.join('&')}`
  return url;
}
const privateWithQuery = (parms = '', args = {}) => config => {
  let url = `${config.privateUrl}/${parms}?`;
  const pairs = [];
  for (let [k, v] of Object.entries(args)) {
    pairs.push(`${k}=${v}`);
  }
  url = `${url}${pairs.join('&')}`
  return url;
}

export const fetchDispatchPayouts = (args, reset = true) =>
  get(types.FETCH_DISPATCED, urlWithQuery(`payouts/`, args), { reset });

export const fetchReceivedPayout = (args, reset = true) =>
  get(types.FETCH_RECEIVED, privateWithQuery(`payouts/`, args), { reset });
