import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next';
import { ToolTip } from 'gigapay-ui';
import greenShield from './green-shield.png';
import grayShield from './gray-shield.png';
import css from './shield.module.css';

const Shield = ({ verified_at, size }) => {
  const cls = `${css.shield} ${size === 'medium' ? css.medium : ''}`;
  const w = size === 'medium' ? 160 : 'auto';
  const { t } = useTranslation('employees');
  if (verified_at) {
    return (
      <ToolTip
        width={w}
        label={(
          <div className={cls}>
            <img src={greenShield} alt="Verified user" />
          </div>
        )}
        text={t('The user is verified')}
      />
    )
  }
  return (
    <ToolTip
      width={w}
      label={(
        <div className={cls}>
          <img src={grayShield} alt="Not verified" />
        </div>
      )}
      text={t('The user is not yet verified as a worker')}
    />
  )

}

Shield.propTypes = {
  verified_at: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium','large'])
}
Shield.defaultProps = {
  verified_at: null,
  size: 'small'
}

export default Shield
