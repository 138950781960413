import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import {
  Button,
  Title,
  BoxSelector,
  Paragraph,
  Icon
} from 'gigapay-ui'

import { withTranslation } from 'react-i18next';
import { updateSignup } from '../../../../actions/signup'

class AccountType extends Component {

  constructor(props) {
    super(props);
    this.state = {
      accountType: 1
    }
  }

  next = () => {
    this.props.onComplete(this.state.accountType);
  };

  componentDidMount(){
    this.props.updateSignup({ accountType: 1, email: null })
  }


  render() {
    const { t } = this.props;
    const { accountType } = this.state;
    return (
      <div className="step">
        <Title.Line small>{t('common:Sign up')}</Title.Line>
        <Paragraph>{t('Choose the option that best suits your needs. Are you a Freelancer who wants to receive money or are you a Company that wants to send payouts? It’s free to sign up.')}</Paragraph>
        <BoxSelector
          value={accountType}
          large
          onChange={(accountType) => {
            this.setState({ accountType })
            this.props.updateSignup({ accountType })
          }}
          options={[
            {
              Icon: <Icon size={40} color={accountType === 1 ? '#2D2FA6' : '#333'} name={'wallet-arrow-down'} />,
              label: t('Freelancer'),
              value: 1,
            },
            {
              Icon: <Icon size={40} color={accountType === 2 ? '#2D2FA6' : '#333'} name={'wallet-arrow-up'} />,
              label: t('Company'),
              value: 2
            }
          ]}
        />
        <div style={{ marginTop: 20 }} />
        <Button onClick={this.next} responsive primary>{t('common:Next')}</Button>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  updateSignup: data => dispatch(updateSignup(data))
});
const mapStateToProps = (state) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('signup')(AccountType))
