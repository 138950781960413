export const types = {
  LOGIN: 'LOGIN',
  FETCH_ME: 'FETCH_ME',
  
  FETCH_DISPATCED: 'FETCH_DISPATCED',
  FETCH_STATISTIC: 'FETCH_STATISTIC',
  FETCH_COMPANY_INVOICES: 'FETCH_COMPANY_INVOICES',
  FETCH_RECEIVED: 'FETCH_RECEIVED',
  FETCH_TO_APPROVE: 'FETCH_TO_APPROVE',

  // GIGGER INVOICES
  FETCH_INVOICES: 'FETCH_INVOICES',
  FETCH_INVOICE: 'FETCH_INVOICE',
  CREATE_INVOICING_PAYOUT: 'CREATE_INVOICING_PAYOUT',
  SET_INVOICING_DATA: 'SET_INVOICING_DATA',

  // OPEN INVOCIE
  FETCH_OPEN_INVOICE: 'FETCH_OPEN_INVOICE',
  CONNECT_OPEN_INVOICE: 'CONNECT_OPEN_INVOICE',

  
  UPDATE_SIGNUP: 'UPDATE_SIGNUP',

  UPLOAD_LOGO: 'UPLOAD_LOGO',
  SAVE_COMPANY: 'SAVE_COMPANY',
  FETCH_USER_COMPANIES: 'FETCH_USER_COMPANIES',

  FETCH_COMPANY_LEADS: 'FETCH_COMPANY_LEADS',
  VERIFY_COMPANY: 'VERIFY_COMPANY',
  CREATE_COMPANY: 'CREATE_COMPANY',
  FETCH_COMPANY_ENGAGEMENTS: 'FETCH_COMPANY_ENGAGEMENTS',

  REGISTER_EMAIL: 'REGISTER_EMAIL',

  ADD_RECIPIENT_NOTICE: 'ADD_RECIPIENT_NOTICE',
  UPDATE_NOTICE_DATA: 'UPDATE_NOTICE_DATA',

  PAYOUTS_BY_ID: 'PAYOUTS_BY_ID',
  RESET_PAYOUTS_BY_ID: 'RESET_PAYOUTS_BY_ID',

  SET_LANG: 'SET_LANG',
  SHOW_FOOTER: 'SHOW_FOOTER',
  SHOW_NAVBAR: 'SHOW_NAVBAR',

  FETCH_COMPANY_INVOICE: 'FETCH_COMPANY_INVOICE',
  FETCH_COMPANY_INVOICE_PAYOUTS: 'FETCH_COMPANY_INVOICE_PAYOUTS',

  CREATE_PAYOUT: 'CREATE_PAYOUT',
  CREATE_PAYOUT_BEGIN: 'CREATE_PAYOUT_BEGIN',
  CREATE_PAYOUT_SUCCESS: 'CREATE_PAYOUT_SUCCESS',
  CREATE_PAYOUT_FAILURE: 'CREATE_PAYOUT_FAILURE',
  CREATE_PAYMENT_INTENT: 'CREATE_PAYMENT_INTENT',
  SET_PAYMENT_RESPONSE: 'SET_PAYMENT_RESPONSE',
  PAYMENT_BEGIN: 'PAYMENT_BEGIN',
  PAYMENT_SUCCESS: 'PAYMENT_SUCCESS',
  PAYMENT_FAILURE: 'PAYMENT_FAILURE',
  PAYMENT_RESET: 'PAYMENT_RESET',

  // email signup
  REQUEST_TOKEN: 'REQUEST_TOKEN',

  SAVE_USER: 'SAVE_USER',
  VERIFY_EMAIL: 'VERIFY_EMAIL',

  // accept
  CHECK_CLAIM: 'CHECK_CLAIM',
  CHECK_CLAIM_RESET: 'CHECK_CLAIM_RESET',
  CLAIM_EMPLOYEE: 'CLAIM_EMPLOYEE',
  ACCEPT_PAYOUT: 'ACCEPT_PAYOUT',
  RESET_ACCEPT: 'RESET_ACCEPT',

  FETCH_EMPLOYEE: 'FETCH_EMPLOYEE',
  FETCH_EMPLOYEE_PAYOUTS: 'FETCH_EMPLOYEE_PAYOUTS',
  FETCH_EMPLOYEES: 'FETCH_EMPLOYEES',
  CREATE_EMPLOYEE: 'CREATE_EMPLOYEE',
  UPDATE_EMPLOYEE: 'UPDATE_EMPLOYEE',
  UPDATE_EMPLOYEE_RESET: 'UPDATE_EMPLOYEE_RESET',
  BATCH_CREATE_EMPLOYEE: 'BATCH_CREATE_EMPLOYEE',
  RESET_BATCH_CREATE_EMPLOYEE: 'RESET_BATCH_CREATE_EMPLOYEE',
  CREATE_EMPLOYEE_RESET: 'CREATE_EMPLOYEE_RESET',
  SET_EMPLOYEES_BY_ID: 'SET_EMPLOYEES_BY_ID',

  // payout 2.0
  SET_RECIPIENTS: 'SET_RECIPIENTS',
  RESET_RECIPIENTS: 'RESET_RECIPIENTS',

  // Request
  RESET_REQUEST_PAYOUT: 'RESET_REQUEST_PAYOUT',

  // Verify
  CREATE_USER_LEAD: 'CREATE_USER_LEAD',
  UPDATE_USER_LEAD: 'UPDATE_USER_LEAD',
  GET_ONBOARDING_STATUS: "GET_ONBOARDING_STATUS",
  UPLOAD_VERIFY_DOCUMENT: 'UPLOAD_VERIFY_DOCUMENT',

  // Payout backends
  LIST_PAYOUT_BACKENDS: 'LIST_PAYOUT_BACKENDS',
  UPDATE_PREFERRED_PAYOUT_BACKEND: 'UPDATE_PREFERRED_PAYOUT_BACKEND',
  RESET_PAYOUT_BACKENDS: 'RESET_PAYOUT_BACKENDS',

  CREATE_TRUSTLY_BACKEND: 'CREATE_TRUSTLY_BACKEND',
  UPDATE_TRUSTLY_BACKEND: 'UPDATE_TRUSTLY_BACKEND',
  RESET_TRUSTLY_BACKEND: 'RESET_TRUSTLY_BACKEND',

  CREATE_BANKGIRO_BACKEND: 'CREATE_BANKGIRO_BACKEND',
  UPDATE_BANKGIRO_BACKEND: 'UPDATE_BANKGIRO_BACKEND',
  RESET_BANKGIRO_BACKEND: 'RESET_BANKGIRO_BACKEND',

  CREATE_BRITE_BACKEND: 'CREATE_BRITE_BACKEND',
  UPDATE_BRITE_BACKEND: 'UPDATE_BRITE_BACKEND',
  RESET_BRITE_BACKEND: 'RESET_BRITE_BACKEND',

  UPDATE_SWISH_BACKEND: 'UPDATE_SWISH_BACKEND',
  RESET_SWISH_BACKEND: 'RESET_SWISH_BACKEND',
};

export const ADD_RECIPIENT = 'ADD_RECIPIENT';
export const REMOVE_RECIPIENT = 'REMOVE_RECIPIENT';
export const UPDATE_RECIPIENT = 'UPDATE_RECIPIENT';
export const PAYOUT_CLEAR = 'PAYOUT_CLEAR';

export const FILE_PARSING_BEGIN = 'FILE_PARSING_BEGIN';
export const FILE_PARSING_FAILURE = 'FILE_PARSING_FAILURE';
export const FILE_PARSING_SUCCESS = 'FILE_PARSING_SUCCESS';

export const INVALID_RECIPIENT = 'INVALID_RECIPIENT';
export const VALID_RECIPIENT = 'VALID_RECIPIENT';

export const PAYOUT_RESET = 'PAYOUT_RESET';



export const SET_USER_INFO = 'SET_USER_INFO';
export const LOGOUT = 'LOGOUT';

export const NOT_LOGIN = 'NOT_LOGIN';
export const LOGIN_BEGIN = 'LOGIN_BEGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_CANCEL = 'LOGIN_CANCEL';



export const ACCEPT_PAYOUT_BEGIN = 'ACCEPT_PATOUT_BEGIN';
export const ACCEPT_PAYOUT_SUCCESS = 'ACCEPT_PATOUT_SUCCESS';
export const ACCEPT_PAYOUT_FAILURE = 'ACCEPT_PATOUT_FAILURE';

export const RESET_SIGN_STATUS = 'RESET_SIGN_STATUS';
export const SIGN_CANCEL = 'SIGN_CANCEL';


export const FETCH_USER_BEGIN = 'FETCH_USER_BEGIN';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';

export const UPDATE_SIMPLIFIED_INVOICE = 'UPDATE_SIMPLIFIED_INVOICE';
export const UPDATE_URL = 'UPDATE_URL';
export const URL_FAILED = 'URL_FAILED';
export const RESET_URL = 'RESET_URL';

export const RESET_AND_ADD = 'RESET_AND_ADD';

export const RESET_ERROR = 'RESET_ERROR';

export const status = {
    DEFAULT: 'DEFAULT',
    BEGIN: 'BEGIN',
    ERROR: 'ERROR',
    SUCCESS: 'SUCCESS',
    TIMEOUT: 'TIMEOUT',
};