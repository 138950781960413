import React, { useEffect, useState } from 'react';
import {
  Loader
} from 'gigapay-ui';
import { Header, Container, Section } from 'components/Invoice'

import { Row, Text } from 'components/Placeholder/'
import moment from 'moment';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import css from './invoiceoverview.module.css';
import { fetchInvoice } from 'actions/invoicing';
import { numberWithSpaces } from 'utils/price';

const InvoiceOverview = ({
  fetchInvoice,
  invoices,
  loading,
}) => {
  const { t } = useTranslation('invoices');
  const { id } = useParams();
  const [invoice, setInvoice] = useState(null)
  useEffect(() => {
    if (invoices[id]) {
      setInvoice(invoices[id])
    } else if (!loading) {
      fetchInvoice(id)
    }
  }, [invoices])
  const {
    company = { name: '' },
    price = '',
    ocr_number = '',
    currency = 'SEK',
    created_at = null,
    viewed_at = null,
    paid_at = null,
    description = ''
  } = invoice || {};
  const subtitle = !paid_at ? 
    <span className={`${css.subtitle} ${css.orange}`}>{t('Unpaid, expires in {{x}} days', { x: moment(created_at).add(30, 'days').diff(moment(), 'days') })}</span> :
    <span className={`${css.subtitle} ${css.green}`}>{t('Paid')}</span>;
  const amount = invoice === null ?
    <Loader small inline className={css.amountLoading} /> :
    (<><b>{numberWithSpaces(price)} {currency}</b><small>(ink VAT)</small></>)
  return (
    <Container>
      <>
        <Header>
          <Header.Title
            title={company.name}
            subtitle={subtitle}
            recipient={(
              <>
                <span>{company.name}</span><br />
                {company.address_line_1}<br />
                {company.zip_code} {company.city}<br />
              </>
            )}
          />
          <Header.Summary
            amountTitle={t('common:Total amount')}
            amountComponent={(<>{amount}</>)}
            list={[
              { key: 'bankgiro', label: 'Bankgiro', value: '5129-2423' },
              { key: 'ocr', label: t('Invoice-/OCR-n'), value: ocr_number },
              { key: 'duedate', label: t('invoices:Due date'), value: moment(created_at).add(30, 'days').format('YYYY-MM-DD') },
            ]}

          />
        </Header>
        <Section title={t('Events')}>
          <ul className={css.timeline}>
           
            <li className={`${created_at ? css.active : ''}`}>
              <span className={css.date}>{moment(created_at).format('YYYY-MM-DD HH:mm')}</span>
              <b>{t('The invoice has been sent to')} {company.email}</b>
            </li>
            <li className={`${viewed_at ? css.active : ''}`}>
              <span className={css.date}>{viewed_at ? moment(viewed_at).format('YYYY-MM-DD HH:mm') : '-'}</span>
              <b>{t('The invoice has been opened')}</b>
            </li>
            <li className={`${paid_at ? css.active : ''}`}>
              <span className={css.date}>{ paid_at ? moment(paid_at).format('YYYY-MM-DD HH:mm') : '-'}</span>
              <b>{t('The invoice has been paid')}</b>
            </li>
          </ul>
        </Section>
      </>
    </Container>
  )
  
}


const mapStateToProps = (state) => ({
  invoices: state.invoices.invoices,
  loading: state.invoices.loading,
});
const mapDispatchToProps = {
  fetchInvoice
}
export default connect(mapStateToProps, mapDispatchToProps)(InvoiceOverview);
