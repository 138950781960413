import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { Link, NavLink, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Icon } from 'gigapay-ui'
import DropDownMenu from './components/DropDownMenu'
import { persistor } from '../../store/'
import { logout, updateUserInfo } from '../../actions/user';
import authentication from '../authentication';
import { logo, configFile } from 'config'
import css from './Navbar.module.css'


class NavBar extends Component {

  componentDidMount() {
    window.addEventListener("resize", this.checkIntercom);
    this.checkIntercom();
  }

  checkIntercom = () => {
    if (configFile === 'payhour') return;

    var width = window.innerWidth
      || document.documentElement.clientWidth
      || document.body.clientWidth;
    if (width > 620) {
      window.Intercom('update', {
        "hide_default_launcher": false
      });
    } else {
      window.Intercom('update', {
        "hide_default_launcher": true
      });
    }
  };

  openIntercom = (e) => {
    e.preventDefault();
    window.Intercom('show');
  };

  render() {
    const headerClass = this.props.showNavbar ? '' : 'hidden';
    const { t, mode, isVerified } = this.props;
    const extraHeaderClass = isVerified ? 'user-verified' : 'user-not-verified';
    return (
      <header className={`navbar ${headerClass} ${extraHeaderClass}`}>
        <nav className={css.navBar}>
          {this.props.isAuthenticated && <ul className={css.mainMenu}>
            <li>
              <NavLink activeClassName='active' to="/dashboard">
                {/*<Icon name={'card'} size={18} /> */}
                <span className={css.icon}><Icon name={'card'} size={18} /></span>
                {t('Dashboard')}
              </NavLink>
            </li>
             {((mode !== 'company') && (configFile !== 'payhour')) && <li>
              <NavLink activeClassName='active' to="/invoices/">
                {/*<Icon name={'card'} size={18} /> */}
                <span className={css.icon}><Icon name={'document'} size={18} /></span>
                {t('invoices:Invoices')}
              </NavLink>
            </li>}
            {mode === 'company' && <li>
              <NavLink activeClassName='active' to="/payouts">
                {/*<Icon name={'card'} size={18} /> */}
                <span className={css.icon}><Icon name={'wallet'} size={18} /></span>
                {t('Payouts')}
              </NavLink>
            </li>}
            {mode === 'company' && <li>
              <NavLink activeClassName='active' to="/employees">
                {/*<Icon name={'people'} size={18} />*/}
                <span className={css.icon}><Icon name={'person'} size={18} /></span>
                {t('employees:Team')}
              </NavLink>
            </li>}
            <li style={{ paddingTop: '5px' }}>  {/* Alignment because the cog icon is different */}
              <NavLink activeClassName='active' to="/settings">
                <span className={css.icon}><Icon name={'cog'} size={18}/></span>
                {t('Settings')}
              </NavLink>
            </li>
            {(mode !== 'company' && configFile !== 'payhour')&&<li className="help-menu">
              <a href="#" onClick={this.openIntercom}>
                <Icon name={'help-buoy'} size={18} />
                {t('Help')}
              </a>
            </li>}
          </ul>}
        </nav>

        <Link className={css.logo} to="/dashboard"><img src={logo} alt="" className="logo" /></Link>

        <div>
          <DropDownMenu
            logout={this.props.logout}
            isAuthenticated={this.props.isAuthenticated}
            first_name={this.props.first_name}
            switchMode={this.props.updateUserInfo}
            companies={this.props.companies}
            companyLeads={this.props.companyLeads}
            currentCompany={this.props.currentCompany}
            mode={this.props.mode}
            email={this.props.email}
          />
        </div>
      </header>
    )
  }
}


NavBar.contextTypes = {
  router: PropTypes.object
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,

  companies: state.user.companies,
  companyLeads: state.user.companyLeads,
  hasCompany: state.user.hasCompany,
  currentCompany: state.user.currentCompany,
  first_name: state.user.first_name,
  mode: state.user.mode,
  email: state.user.email,
  isVerified: state.auth.isVerified,
  showNavbar: state.user.showNavbar,
});

const mapDispatchToProps = dispatch => ({
  logout: () => {
    authentication.logout();
    persistor.flush();
    dispatch(logout());
  },
  updateUserInfo: (info) => {
    dispatch(updateUserInfo(info));
  }
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation(['common','invoices'])(NavBar)));
