import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import { Button, Paragraph, Title, Fieldset, Input, Icon, Alert, Link } from 'gigapay-ui'
import { requestToken, reset } from '../../actions';
import { validEmail } from '../../../../../../utils/validate'
import css from '../../../../login.module.css';

const InputEmail = ({ requestTokenStatus, switchMode, requestToken, navigate, reset, redirectUrl }) => {

  const { t } = useTranslation('signup');
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const asValue = (v) => (v === null) ? '' : v;


  useEffect(() => {
    if (requestTokenStatus === 'SUCCESS' && email !== '') {
      navigate(`/verify?email=${encodeURIComponent(email)}`);
      reset()
    }
  },[requestTokenStatus])

  const sendLink = () => {
    if (!validEmail(email)) {
      setError('Invalid email');
      return
    }
    requestToken({ email, redirect_url: redirectUrl })
  };

  return (
    <div>
      <Title.Line small>{t('common:Log in')}</Title.Line>
      <Paragraph small>{t('Enter your email and click the button to request a login link. This link will only work once.')}</Paragraph>
      <Fieldset>
        <Input
          leftIcon={<Icon name={'mail'} />}
          label={t('common:Email')}
          value={asValue(email)}
          onChange={(email) => setEmail(email)}
          validate={validEmail}
          type="email"
        />
      </Fieldset>
      {error && (
        <Alert.Error style={{ marginBottom: 10 }}>
          {error}
        </Alert.Error>
      )}
      <Button
        onClick={sendLink}
        primary
        responsive
        loading={requestTokenStatus === 'BEGIN'}
      >
        {t('common:Send link')}
      </Button>
       <span className={css.termsLogin}>
        <Trans i18nKey="common:terms-login">
          {[
            <a key={'terms-link'} rel="noopener noreferrer" target="_blank" href={t('common:termsLink')}></a>,
            <a key={'data-link'} rel="noopener noreferrer" target="_blank" href={t('common:dataLink')}></a>
          ]}
        </Trans>
      </span>
      {
        true && <span className={`${css.termsLogin} ${css.switchLogin}`}>{t('Do you have BankID?')} <Link primary onClick={() => switchMode('bankid')}>{t('Login with BankID')}</Link></span>
      }
    </div>
  )
};


const mapStateToProps = (state) => ({
  requestTokenStatus: state.mailLogin.requestTokenStatus,
});

const mapDispatchToProps = dispatch => ({
  requestToken: data => dispatch(requestToken(data)),
  reset: () => dispatch(reset())
});


export default connect(mapStateToProps, mapDispatchToProps)(InputEmail)
