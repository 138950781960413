import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom'
import { resetPayoutRequest } from 'actions/request-payout';

export const Reset = () => {
  const history = useHistory();
  resetPayoutRequest();
  useEffect(() => {
    history.replace('/request/add')
  }, []);
  return null;
}
const mapStateToProps = (state) => ({

});
const mapDispatchToProps = {
  resetPayoutRequest
};


export default connect(mapStateToProps, mapDispatchToProps)(Reset);

