import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Loader } from 'gigapay-ui'
import i18n from '../../I18n';
import { logo } from 'config';
import { notLoggedIn } from '../../actions/user-login'
import { fetchMe, fetchUserCompanies, fetchCompanyLeads } from '../../actions/user';
import { fetchEmployees } from '../../actions/employee';
import { listPayoutBackends } from '../../actions/payout-backends';

const Placeholder = () => {
  return (
    <div className="placeholder">
      <header className="navbar">
        <a href="/dashboard"><img src={logo} alt="" className="logo" /></a>
      </header>
      <div className="content center">
        <div>
          <div className="loading-container">
            <Loader.Fill large inline />
          </div>
        </div>
      </div>
    </div>
  )
};

const AuthenticationGate = ({
  children,
  isAuthenticated,
  language,
  fetchMe,
  fetchUserCompanies,
  fetchCompanyLeads,
  notLoggedIn,
  currentCompany,
  fetchEmployees,
  listPayoutBackends
}) => {
  const [hasLoaded, setHasLoaded] = useState(false);
  useEffect(() => {
    i18n.changeLanguage(language);
    if (isAuthenticated) {
      fetchMe();
      fetchUserCompanies();
      fetchCompanyLeads();
      listPayoutBackends();
      setHasLoaded(true);
    } else {
      notLoggedIn();
      setHasLoaded(true)
    }
  },[isAuthenticated]);

  useEffect(() => {
    if (currentCompany && isAuthenticated) {
      fetchEmployees({ page_size: 400 });
    }
  }, [currentCompany, isAuthenticated]);

  if (!hasLoaded) {
    return <Placeholder />
  }

  return (
    <Fragment>
      {children}
    </Fragment>
  )
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  language: state.auth.language,
  currentCompany: state.user.currentCompany,
});
const mapDispatchToProps = {
  fetchMe,
  notLoggedIn,
  fetchUserCompanies,
  fetchCompanyLeads,
  fetchEmployees,
  listPayoutBackends,
};


export default connect(mapStateToProps, mapDispatchToProps)(AuthenticationGate);
