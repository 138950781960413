import React from 'react'
import { connect } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next'
import { Button, Paragraph, Title, Icon, Link } from 'gigapay-ui'
import { login } from '../../actions'
import css from '../../../../login.module.css'
import { useSetAtom } from 'jotai'
import { qrCodeAtom } from 'store/bankIdAtom'
import useDevice from 'hooks/useDevice'

const InputBankid = ({ loginStatus, login, switchMode }) => {
  const showOption = true // queryStrings.debug;

  const { t } = useTranslation('signup')

  const setQr = useSetAtom(qrCodeAtom)
  const { isMobileOrTablet } = useDevice()

  const startLogin = () => {
    login(setQr, isMobileOrTablet)
  }

  return (
    <div>
      <Title.Line small>{t('common:Log in with BankID')}</Title.Line>
      <Paragraph small>{t('Verify your identity through BankID.')}</Paragraph>
      <Button.Primary
        onClick={startLogin}
        primary
        responsive
        Icon={<Icon name={'bankid'} color={'#fff'} size={21} />}
        loading={loginStatus === 'BEGIN'}
      >
        {t('common:Sign in')}
      </Button.Primary>
      <span className={css.termsLogin}>
        <Trans i18nKey="common:terms-login">
          {[
            <a
              key={'terms-link'}
              rel="noopener noreferrer"
              target="_blank"
              href={t('common:termsLink')}
            ></a>,
            <a
              key={'data-link'}
              rel="noopener noreferrer"
              target="_blank"
              href={t('common:dataLink')}
            ></a>,
          ]}
        </Trans>
      </span>
      {showOption && (
        <span className={`${css.termsLogin} ${css.switchLogin}`}>
          {t("Don't have a BankID?")}{' '}
          <Link primary onClick={() => switchMode('mail')}>
            {t('Login with email')}
          </Link>
        </span>
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({
  loginStatus: state.bankidLogin.loginStatus,
})
const mapDispatchToProps = (dispatch) => ({
  login: (setQr, isInApp) => dispatch(login(setQr, isInApp)),
})
export default connect(mapStateToProps, mapDispatchToProps)(InputBankid)
