
import {
  status,
  types,
} from '../constants/action-types';

const defaultAccept = {
  acceptStatus: status.DEFAULT
};



const accept = (state = defaultAccept, action) => {
  switch (action.type) {
    
    case types.RESET_ACCEPT:
      return {
        ...state,
        acceptStatus: status.DEFAULT
      };
    case types.ACCEPT_PAYOUT:
      switch (action.meta.status) {
        case status.BEGIN:
          return {
            ...state,
            acceptStatus: status.BEGIN,
          }
        case status.SUCCESS:
          return {
            ...state,
            acceptStatus: status.SUCCESS,
          }
        case status.ERROR:
          return {
            ...state,
            acceptStatus: status.ERROR,
          }
        default:
          return state;
      }
    default:
      return state;
  }
}
export default accept;