import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom'
import * as qs from 'query-string';
import {
  Button,
  Icon,
  Title
} from 'gigapay-ui'
import { SuccessAnimation } from 'components/'
import { numberWithSpaces } from 'utils/price'
import { fetchInvoice } from 'actions/invoice';

const Done = ({
  recipients,
  fetchInvoice,
  invoice
}) => {
  const history = useHistory();
  const location = useLocation();
  const queryStrings = qs.parse(location.search);
  const { id, pdf } = invoice;
  useEffect(() => {
    if (queryStrings.id) {
      fetchInvoice(queryStrings.id);
    }
  }, []);
  const { t } = useTranslation(['payout', 'common']);
  return (
    <div style={{ textAlign: 'center' }}>
      <SuccessAnimation />
      <Title>{t('Done')}</Title>
      <div style={{ marginTop: 10 }}>
        {t('The salary is paid to')}
        <div style={{ display: 'inline' }} className={"tooltip-container"}>
          <span className={"tooltip-label"}>{t('theRecipient', { count: recipients.length })}</span>
          <ul className="tooltip-summay">
            <li className="tooltip-summay-header"><span className={"tooltip-summay-name"}>{t('Recipient')}</span><span className={"tooltip-amount"}>{t('common:GrossSalary')}</span></li>
            {recipients.map((r) => (
              <li key={r.id}>
                <span className={"tooltip-summay-name"} title={`${r.employee.name}`}>{r.employee.name} </span>
                <span className={"tooltip-amount"}>{numberWithSpaces(r.pricing.amount)} kr</span>
              </li>
            ))}
          </ul>
        </div>
        {t('as soon as they accepted the payment.')}
      </div>
      <div style={{ margin: '20px auto', width: 250 }}>
        <Button
          onClick={() => {
            window.open(pdf)
          }}
          style={{ margin: '10px auto' }}
          primary
          responsive
        >
          {t('Download receipt')} (PDF)
        </Button>
        <Button
          onClick={() => {
            history.push('/dashboard')
          }}
          responsive
        >
          {t('Go to dashboard')} <Icon size={15} name={'arrow-forward'} />
        </Button>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  recipients: state.payout.recipients,
  invoice: state.invoice,
});
const mapDispatchToProps = {
  fetchInvoice
};


export default connect(mapStateToProps, mapDispatchToProps)(Done);