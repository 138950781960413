
import {
LOGIN_SUCCESS,
UPDATE_SIMPLIFIED_INVOICE,
UPDATE_URL,
URL_FAILED,
RESET_URL,

status,
} from '../constants/action-types';

const defaultSimplifiedInvoice = {
    to: '',
    description: '',
    amount: '',
    loading: false,
    status: status.DEFAULT,

    url: '',
};


const SimplifiedInvoice = (state = defaultSimplifiedInvoice, action) => {
    switch (action.type) {

        case URL_FAILED: 
            return {
                status: status.ERROR,
            };
        case RESET_URL: 
            return {
                ...state,
                status: status.DEFAULT,
                url: '',
            }
        case UPDATE_URL: 
            return {
                ...state,
                status: status.SUCCESS,
                ...action.payload
            }
        case UPDATE_SIMPLIFIED_INVOICE: 
            return {
                ...state,
                loading: false,
                ...action.payload,
            }
        default: 
        return state;
    }
}
export default SimplifiedInvoice;