import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  Paragraph,
  Title,
  BoxAction,
  Icon,
  Alert,
  Strong
} from 'gigapay-ui'
import { useHistory, useLocation } from 'react-router-dom'
import * as qs from 'query-string';
import css from './verify.module.css';
import { getOnboardingStatus, createUserLead } from 'actions/verify';
import { resetCheckClaim, checkClaim } from 'actions/employee';
import Footer from '../Footer';
import {configFile} from "../../../../config";


const List = ({
  checkedForExisting,
  work_permit_type,
  claimCheck,
  resetCheckClaim,
  checkClaim,
  onSubmit,
  getOnboardingStatus,
  createUserLead,
  id,
  identity_number,
  work_permit_front,
  work_permit_back,
  passport_front,
  passport_back,
  loading,
}) => {
  const history = useHistory();
  const location = useLocation();
  const query = qs.parse(location.search);
  const { t } = useTranslation('signup');
  const [canOnboard, setCanOnboard] = useState(true);
  const [processing, setProcessing] = useState(false);
  const firstUpdate = useRef(true);

  useEffect(() => {
    getOnboardingStatus();
    if (query.c) {
      resetCheckClaim();
      checkClaim(query.c);
    } else {
      setCanOnboard(false);
    }
  }, []);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
    } else if (id === null) {
      createUserLead({token: query.c})
    }
  }, [checkedForExisting]);

  useEffect(() => {
    if (id !== null) {
      setProcessing(true);
      setCanOnboard(true);
    }
  }, [id]);

  const { name = '', loading: checkLoading } = claimCheck;

  const submit = () => {
    onSubmit()
  };

  const hasValidWorkPermit = (
    work_permit_type !== null && (
      work_permit_type === 'EU-citizen' ||
      (work_permit_back !== null && work_permit_front !== null)
    )
  );

  const isDone = (
    identity_number &&
    hasValidWorkPermit &&
    passport_back !== null && passport_front !== null
  );

  if (configFile === 'payhour'){
    return (
      <div>
        <Title.Line small>Payhour behandlar just nu din förfrågan om att logga in till din lönesida via email</Title.Line>
        <Paragraph small margins={false}>Då du valde att inte logga in med Bankid måste vi lägga till dig manuellt i vårt lönesystem för att kunna styrka din identitet. Efter det är gjort kommer du kunna logga in med din angivna email. Det tar inte lång tid att lägg till dig i vårt system och du får snart ett meddelande från oss på Payhour när det är gjort! </Paragraph>
        <Paragraph small>Om du har några frågor är du välkommen att kontakta oss vi chatten i Payhour-appen eller skicka ett mail till hello@payhour.se</Paragraph>
        <Paragraph small margins={false}>Vänliga hälsningar,</Paragraph>
        <Paragraph small margins={false}>Team Payhour</Paragraph>
      </div>
    )
  }

  return (
    <div>
      <Title.Line small>{t("Let's get to know you")}</Title.Line>
      <Paragraph small margins={false}>{t('Complete in the following order')}</Paragraph>

      {!canOnboard && !loading && (
        <Alert.Warning style={{ margin: '16px 0' }}>
          {t('You need to be invited from a company in order to sign up for Gigapay. If you have received an invite follow the link to sign up.')}
        </Alert.Warning>
      )}
      <div className={css.actions}>
        <BoxAction
          label={t('Personal information')}
          description={
            identity_number ?
              t('Submitted') :
              t('Add information about your address and coordination number')}
          Icon={<Icon name={'person'} size={30} />}
          extra={identity_number ? <Icon name={'checkmark'} className={css.checkmark} size={40} /> : <Icon name={'arrow-forward'} size={25} />}
          onClick={
            canOnboard ?
              () => history.push('/verify/personal/') :
              () => { }
          }
          className={css.actionsItem}
          disabled={(identity_number !== '') || !canOnboard}
        />
        <BoxAction
          label={t('Verify identity')}
          description={
            passport_front && passport_back ?
              t('Submitted') :
              t('Submit an image of your valid passport')
          }
          Icon={<Icon name={'licens'} color={'#000'} size={34} />}
          extra={passport_front && passport_back ? <Icon name={'checkmark'} className={css.checkmark} size={40} /> : <Icon name={'arrow-forward'} size={25} />}
          onClick=
          {(() => {
            if (id === null || (passport_front !== null && passport_back !== null)) {
              return () => { }
            } else if (passport_front === null) {
              return () => history.push('/verify/id/front/')
            } else if (passport_back === null) {
              return () => history.push('/verify/id/back/')
            }
          })()}
          className={css.actionsItem}
          disabled={id === null || (passport_front && passport_back)}
        />
        <BoxAction
          label={t('Working permit')}
          description={
            (hasValidWorkPermit) ?
              t('Submitted') :
              t('Submit information about your work permit')
          }
          Icon={<Icon name={'document'} size={30} />}
          extra={hasValidWorkPermit ? <Icon name={'checkmark'} className={css.checkmark} size={40} /> : <Icon name={'arrow-forward'} size={25} />}
          onClick=
          {(() => {
            if (id === null || (work_permit_front !== null && work_permit_back !== null) || work_permit_type === 'EU-citizen') {
              return () => { }
            } else {
              return () => history.push('/verify/permit/');
            }
          })()}
          className={css.actionsItem}
          disabled={id === null || (hasValidWorkPermit)}
        />
      </div>
      {isDone && (
        <Alert.Success>
          <Strong style={{ color: 'currentColor' }}>{t('You’re all done')}</Strong>
          <Paragraph margins={false}>{t('We’ll get back to once we’ve verified your documents. Hang tight!')}</Paragraph>
        </Alert.Success>
      )}
      <Footer />
    </div>
  )
};

const mapStateToProps = (state) => ({
  id: state.verify.id,
  checkedForExisting: state.verify.checkedForExisting,
  identity_number: state.verify.identity_number,
  reception_unit: state.verify.reception_unit,
  work_permit_front: state.verify.work_permit_front,
  work_permit_back: state.verify.work_permit_back,
  work_permit_type: state.verify.work_permit_type,
  passport_front: state.verify.passport_front,
  passport_back: state.verify.passport_back,
  loading: state.verify.loading,
  claimCheck: state.employee.claimCheck
});

const mapDispatchToProps = {
  getOnboardingStatus,
  createUserLead,
  checkClaim,
  resetCheckClaim,
};

export default connect(mapStateToProps, mapDispatchToProps)(List);

