import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import {
  DatePicker,
  Fieldset,
  Input,
  Icon,
  Title,
  Subtitle,
  Paragraph,
  Button,
  Alert
} from 'gigapay-ui'
import { useTranslation } from 'react-i18next'
import uuid from 'uuid-random';
import { useHistory, useLocation } from 'react-router-dom';
import * as qs from 'query-string';
import RecipientProvider2, { useRecipients, useEmployees } from 'hooks/recipients';
import RecipientProvider, { usePayouts } from 'hooks/payoutsV2'
import RecipientsForm from '../components/RecipientsForm';
import { PriceBarList } from 'components/'
import { addRecipientsNotice, updateNoticeData } from 'actions/payout'
import { status } from 'constants/action-types';
import { transformPayout } from 'utils/requestHelpers';

const Notice = ({ addRecipientsNotice, noticeData, employees, updateNoticeData }) => {
  const history = useHistory();
  const { t } = useTranslation(['payout', 'common'])
  const [errorMsg, setErrorMsg] = useState(null)
  const { payouts, error, set } = usePayouts()
  const [showError, setShowError] = useState(false)
  const [range, setRange] = useState({
    to: null,
    from: null
  });
  const [description, setDescription] = useState('')

  useEffect(() => {
    if (noticeData.status === status.SUCCESS) {
      updateNoticeData({ status: status.DEFAULT });
      history.push(`/payout/step/notice/done/?id=${noticeData.invoiceId}`)
    }
  }, [noticeData])

  const submit = (e) => {
    e.preventDefault();
  
    if (range.from === null || range.to === null) {
      setErrorMsg(t('Choose a period for the assignment'));
      return;
    }

    {/* Shift the dates to start at 8 AM and end at 5PM instead of midnight. */}
    const body = transformPayout(payouts.map(r => ({
      ...r,
      start_at: new Date(range.from.getTime() + 28800000).toISOString(),
      end_at: new Date(range.to.getTime() + 61200000).toISOString(),
    })));
    addRecipientsNotice(body)
  };

  return (
    <div>
      <Title.Line small>{t('New pre-registration')}</Title.Line>
      <Paragraph>{t('Here you can pre-register upcoming assignments to take advantage of all our insurances.')}</Paragraph>
      <Subtitle small>{t('Duty')}</Subtitle>
      <Fieldset>
        <DatePicker.Range
          Icon={<Icon name={'calendar'} />}
          label={t('Period')}
          options={{
            minDate: new Date(),
          }}
          onChange={({ from, to }) => setRange({ from, to })}
          value={{
            from: range.from,
            to: range.to
          }}
        />
      </Fieldset>
      <RecipientsForm />
      <PriceBarList recipients={payouts} />
      {
        showError && (error || errorMsg !== null) && (
          <Alert.Error style={{ marginBottom: 12 }}>
            {error && error}
            {errorMsg && <span>{errorMsg}</span>}
          </Alert.Error>)
      }
      {payouts.length !== 0 && <Button.Primary
        responsive
        disabled={error !== null}
        loading={noticeData.status === 'BEGIN'}
        disabledOnClick={() => setShowError(true)}
        onClick={submit}
      >
        {t('common:Next')}
      </Button.Primary>}
    </div >
  )
}
const mapStateToProps = (state) => ({
  noticeData: state.payout.notice,
  employees: state.employee.list.results || [],
});
const mapDispatchToProps = {
  addRecipientsNotice,
  updateNoticeData
};
const NoticeConnected = connect(mapStateToProps, mapDispatchToProps)(Notice);




const NoticeWrapper = () => {

  const location = useLocation();
  const queryStrings = qs.parse(location.search);
  const employees = useEmployees();
  const [initData, setInitData] = useState([{
    id: uuid(),
    inputValue: '',
    inputType: 'gross',
    employee: {
      name: '',
      cellphone_number: '',
      value: ''
    }
  }])
  useEffect(() => {
    if (queryStrings.employee && employees.length !== 0) {
      const list = queryStrings.employee.split(',');
      const d = employees
        .filter(e => list.indexOf(e.id) >= 0)
        .map(e => ({
          id: uuid(),
          inputValue: '5',
          inputType: 'gross',
          employee: {
            
            name: e.name,
            cellphone_number: e.cellphone_number,
            email: e.email,
            value: e.cellphone_number || e.email || '',
            ...e,
          }
        }));
      setInitData(d);
    }
  }, [employees]);

  return (
    <RecipientProvider init={initData} >
      <NoticeConnected />
    </RecipientProvider>
  )
}

export default NoticeWrapper

