import React, { Component } from 'react'
import * as Sentry from '@sentry/browser';
import { Title, Button, Paragraph } from 'gigapay-ui'
import { sentryDSN } from 'config';


if (sentryDSN) {
    Sentry.init({ dsn: sentryDSN });
}

class ErrorPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null
    }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo
    });
    Sentry.withScope(scope => {  /* dummy if Sentry.init wasn't called. https://github.com/getsentry/sentry-javascript/tree/master/packages/browser#usage */
      scope.setExtras(errorInfo);
    });
  }

  render() {
    if (this.state.error) {
      return (
        <div style={{ width: '100vw', height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <div style={{ maxWidth: 460 }}>
            <Title>Det ser ut som att något gick fel.</Title>
            <Paragraph large style={{ marginBottom: 17, marginTop: 17, fontSize: 17 }}>Vi spårar dessa fel automatiskt, men om problemet kvarstår kan du kontakta oss.</Paragraph>
            <Button 
              onClick={() => document.location = '/dashboard'}
              primary>&#x2190; Till översikten</Button>

            <div style={{ display: 'block', width: '100%', float: 'left', padding: '8px 0', fontSize: '12px', color: 'gray'}}>
             {this.state.error && `Error: ${this.state.error.toString()}`}
            </div>
          </div>
        </div>
      )
    }
    return this.props.children;

  }
}

export default ErrorPage;
