import React, { useEffect, useState } from 'react'

import QRLib from 'qrcode'

const qrCodeOptions = {
  width: 200,
  margin: 3,
  errorCorrectionLevel: 'L',
}

const QrCode = ({ qrCode }) => {
  const [qrImage, setQrImage] = useState()

  useEffect(() => {
    QRLib.toDataURL(qrCode, qrCodeOptions)
      .then((url) => {
        setQrImage(url)
      })
      .catch(() => {
        setQrImage(undefined)
      })
  }, [qrCode])

  if (!qrImage) {
    return null
  }

  return <img src={qrImage} alt="qr code" />
}

export default QrCode
