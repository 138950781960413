import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import _ from "lodash";


import { Loader } from 'gigapay-ui';

import { status } from 'constants/action-types';
import { listPayoutBackends, resetPayoutBackends } from "../../actions/payout-backends";
import BankGiroBackend from "./BankGiro";
import BriteBackend from "./Brite";
import TrustlyBackend from "./Trustly";
import SwishBackend from "./Swish"
import * as config from 'config/';

import css from './payout_backends.module.css'


const Backends = {
  bankgiro: {
    Component: BankGiroBackend,
    name: "Bank Transfer",
  },
  brite: {
    Component: BriteBackend,
    name: "Bank Transfer",
  },
  swish: {
    Component: SwishBackend,
    name: "Swish",
  },
  trustly: {
    Component: TrustlyBackend,
    name: "Bank Transfer",
  },
};

const PayoutBackend = ({ method, payoutBackends, settingsView }) => {
  /*
  Wrapper around each individual PayoutBackend, adds the name and an description.
   */

  const Backend = Backends[method];

  // If we are rendering swish in the payout screen, make sure to set it to the preferred option in the backend
  const active = (method==='swish')&&!settingsView

  return (
    <div key={method}>
      <Backend.Component 
        backend={payoutBackends[method]} 
        active={active}
      />
    </div>
  );
};


const LoadingBackend = () => {
  return (
    <div className={css.viewLoadingDetails}>
      <i className={`${css.bankType} ${css.loadingbanken}`} />
      <div className={css.infoPlaceholder} />
      <Loader className={css.loader}/>
    </div>
  )
};


const PayoutBackends = ({settingsView=false}) => {
  const payoutBackends = useSelector((state) => state.payoutBackends);
  const dispatch = useDispatch()

  // If the PayoutBackends haven't been fetched yet, fetch them.
  useEffect(() => {
    if (payoutBackends.status === status.DEFAULT) {
      dispatch(listPayoutBackends())
    }
  }, [dispatch, payoutBackends.status]);

  // remove all errors on componentDidUnMount
  useEffect(() => {
      return () => {
        dispatch(resetPayoutBackends())
      }
  }, [dispatch]);

  const loading = payoutBackends['loading'] || payoutBackends['status'] === status.DEFAULT ;
  
  // Filters differently if we are in the settings view
  const filteredBackends = settingsView
      ? _.fromPairs(
            _.toPairs(Backends).filter(([key, value]) =>
                config.payoutBackendsSettings.includes(key)
            )
        )
      : _.fromPairs(
          _.toPairs(Backends).filter(([key, value]) =>
            config.payoutBackends.includes(key)
        ));

  return <>
      {loading && (
        <div className={css.paymentMethodList}>
          <div className={css.paymentMethodItem}>
            <LoadingBackend/>
          </div>
        </div>
      )}
      <div />
      {!loading && Object.keys(filteredBackends).map((method) => (
        <PayoutBackend
          key={method}
          method={method}
          payoutBackends={payoutBackends}
          settingsView={settingsView}
        />
      ))}
    </>
};

export default (withTranslation('common')(PayoutBackends));
