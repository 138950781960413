import React, {useEffect} from 'react';
import { connect } from 'react-redux';

import { reset } from "./actions"


const Logout = ({reset}) => {
  /*
  Component used a last-ditch effort to reset the entire React app.

  We simply instruct the user to click app.gigapay.se/reset/ and then
  log back in if they find themselves stuck in a bad state.
  */
  useEffect(() => {
    reset();
    localStorage.clear();
    window.location.href = '/login/'
  }, []);

  return null
};


const mapDispatchToProps = {
  reset
};

export default connect(null, mapDispatchToProps)(Logout);

