import { isValid } from 'swedish-personal-identity-number-validator';
import { parsePhoneNumberFromString } from 'libphonenumber-js'

export const validCoordinationNumber = value => {
  const hasCorrectChecksum = (input) => {
    const cleaned = input.replace(/[+-]/, '').slice(-10);
    const sum = cleaned
      .split('')
      .reverse()
      .map(Number)
      .map((x, i) => i % 2 ? x * 2 : x)
      .map((x) => x > 9 ? x - 9 : x)
      .reduce((x, y) => x + y);

    return sum % 10 === 0;
  };
  let pn = value.replace(/\D/g, "");
  if (pn.length === 10) pn = "19" + pn;

  if (pn.length === 12) {
    const date = parseInt(pn.slice(6, 8));
    const valid = (date >= 61 && date <= 91) && hasCorrectChecksum(pn)
    return valid
  }
  return false
}
export const validPersonalNumber = (value) => {
  let pn = value.replace(/\D/g, "");
  if (pn.length === 10) pn = "19" + pn;
  return isValid(pn);
}
export const validateOrgNumber = (value) => {
  let pn = value.replace(/\D/g, "");
  return (pn.length === 10);
}

export const validPhoneNumber = (value, dialCode = '+46') => {
  if (!value || value.length < 1 || typeof value !== 'string') return false;

  let phoneString = value;
  if (!phoneString.includes('+')) {
    phoneString = `${dialCode}${phoneString}`;
  }
  const phoneNumber = parsePhoneNumberFromString(phoneString)
  if (!phoneNumber) {
    return false;
  }
  const re = /^((((0{2}?)|(\+){1})46)|0)7[\d]{8}/;
  return phoneNumber.isValid() && re.test(phoneNumber.number);
}


export const validEmail = (value = '') => {
  if (!value) return false;
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(value.trim());
}

export const validEmailorEmpty = (value) => {
  return value === "" || validEmail(value)
};