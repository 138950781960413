import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';


class Title extends Component {
  componentDidMount(){
    const { t } = this.props;
    document.title = t('PageTitle');
  }
  render() {
    const { t } = this.props;
    document.title = t('PageTitle');
    return null;
  }
}


const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, null)(withTranslation('common')(Title));
