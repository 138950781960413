import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Title, Link, Button, Icon, Table, Dialog } from 'gigapay-ui'
import { status } from 'constants/action-types'
import { Shield } from 'components/'
import { Row, Text, Title as PTitle } from 'components/Placeholder/'
import { fetchEmployee, saveEmployee, saveEmployeeReset, fetchEmployeePayouts } from 'actions/employee';
import { numberWithSpaces } from 'utils/price'
import { format } from 'utils/dateTime';
import EditEmployee from './components/EditEmployee'
import css from './employee.module.css';

const Employee = ({
  fetchEmployee,
  fetchEmployeePayouts,
  updateError,
  updateStatus,
  saveEmployeeReset,
  saveEmployee,
  employee: eObj
}) => {

  const history = useHistory();
  let { employee: eId } = useParams();

  useEffect(() => {
    fetchEmployee(eId);
    fetchEmployeePayouts({ employee: eId, limit: 400 })
  }, []);

  const employee = eObj[eId] || {};
  const {
    name = '',
    email = '',
    cellphone_number = '',
    verified_at = null,
    id = null,
    payouts = { results: [], count: 0 }
  } = employee;
  let isReady = id !== null;
  const getStatus = props => {
    if (props.accepted_at) {
      return <span style={{ color: 'green' }}>{t('Paid out')}</span>
    } else if (props.notified_at) {
      return <span>{t('Awaiting to be claimed by recipient')}</span>
    } else {
      return <span style={{ color: 'orange' }}>{t('Waiting for payment')}</span>
    }
  };

  const { t } = useTranslation('employees');
  const [showDialog, setShowDialog] = useState(false)
  const closeDialog = () => {
    setShowDialog(false)
  };
  useEffect(() => {
    if (updateStatus === status.SUCCESS){
      saveEmployeeReset();
      closeDialog();
    }
  },[updateStatus]);
  return (
    <>
      <Dialog
        show={showDialog}
        onBackdropClick={() => closeDialog()}
        onEscapeKeyDown={() => closeDialog()}
        style={{ padding: '12px 30px' }}
      >
        <EditEmployee
          updateError={updateError}
          updateStatus={updateStatus}
          name={name}
          email={email}
          cellphone_number={cellphone_number}
          onSubmit={(data) => {
            if (id) {
              saveEmployee(id, data)
            }
            
          }}
        />
      </Dialog>
      <div className="content">
        <div className="flex-row-md">
          <div className={css.userData}>
            <div className={css.avatarContainer}>
              <div className={css.avatar} >
                <Icon name={'person'} size={40} className={css.avatarIcon} color={'#888888'} />
                <div className={css.shieldContainer}>
                  <Shield size={'medium'} verified_at={verified_at} />
                </div>
              </div>
            </div>
            <div className="flex-col-md-6">
              <PTitle ready={isReady}>
                <Title margins={false} small>
                  {name}
                  <div className={css.editBtn} onClick={() => setShowDialog(true)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24"><path d="M7.127 22.562l-7.127 1.438 1.438-7.128 5.689 5.69zm1.414-1.414l11.228-11.225-5.69-5.692-11.227 11.227 5.689 5.69zm9.768-21.148l-2.816 2.817 5.691 5.691 2.816-2.819-5.691-5.689z"/></svg>
                  </div>
                </Title>

              </PTitle>
              <div className={css.information}>
                <div className={css.item}>
                  <Text ready={isReady}>
                    <span>{t('common:Email')}</span>
                    {email !== '' && <strong>{email}</strong>}
                    {email === '' || email === null && <strong className={css.noEmail}>-</strong>}
                  </Text>
                </div>
                <div className={css.item}>
                  <Text ready={isReady}>
                    <span>{t('common:Phone number')}</span>
                    <strong>
                      {cellphone_number}
                    </strong>
                  </Text>
                </div>
                <div className={css.item} />
              </div>
            </div>
          </div>
          <div className={`flex-col-md-3 vcenter ${css.actionsButtons}`} >
            <Button.Primary
              responsive
              onClick={() => {
                history.push(`/payout/new/?employee=${id}`)
              }}
            >
              {t('dashboard:New payout')}
            </Button.Primary>
            <Link
              primary
              bold
              className="notice-link"
              onClick={() => {
                history.push(`/payout/step/notice/?employee=${id}`)
              }}>
              {t('dashboard:New pre-registration')}
            </Link>
          </div>
        </div>
        <div className="spacing" />
        <div className="flex-row-md">
          <div className="flex-col-md-1">
            <PTitle ready={isReady}>
              <Title small>{t('dashboard:Payouts')}</Title>
            </PTitle>
            <Table
              keyExtractor={(item) => item.id}
              data={id !== null && payouts.results || []}
              columns={[{
                Header: t('common:Date'),
                Cell: props => <span>{format(props.created_at)}</span>,
                id: 'date',
                className: css.dateCell,
                headerClassName: css.dateCell
              },
              {
                Header: t('dashboard:Invoice'),
                Cell: props => <Link onClick={() => history.push(`/invoices/${props.invoice}/`)}>{t('Show invoice')}</Link>,
                id: 'invoice',
                className: 'tableCell-desktopOnly',
                headerClassName: 'tableCell-desktopOnly'
              },
              {
                Header: t('common:Status'),
                Cell: props => getStatus(props),
                id: 'status',
                className: '',
                headerClassName: ''
              },
              {
                Header: t('common:Amount'),
                Cell: props => <span>{numberWithSpaces(props.amount)} {props.currency}</span>,
                id: 'amount',
                className: '',
                headerClassName: ''
              }]}
            />
            {id === null && <Row cols={5} />}
            {id === null && <Row cols={5} />}
          </div>
        </div>
      </div>
    </>
  );
}


const mapStateToProps = (state) => ({
  list: state.employee.list.results,
  employee: state.employee.employee,
  loading: state.employee.loading,
  createStatus: state.employee.createStatus,
  createError: state.employee.createError,
  updateError: state.employee.updateError,
  updateStatus: state.employee.updateStatus
});
const mapDispatchToProps = {
  fetchEmployee,
  fetchEmployeePayouts,
  saveEmployee,
  saveEmployeeReset
};


export default connect(mapStateToProps, mapDispatchToProps)(Employee);
