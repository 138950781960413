import {
    SET_USER_INFO,
    LOGOUT,
    FETCH_USER_BEGIN,
    FETCH_USER_SUCCESS,
    FETCH_USER_FAILURE,
    types,
} from '../constants/action-types';
import { privateUrl } from '../config';
import i18n from '../I18n/'
import {post as post2, get as get2, patch } from "../utils/fetchV3";

const privateUri = (parms = '') => config =>
  `${config.privateUrl}/${parms}`;

const hostUrl = (parms = '') => config =>
  `${config.host}/${parms}`;

export const fetchMe = () => 
  get2(types.FETCH_ME, privateUri('users/'))

export const save = data => 
  patch(types.SAVE_USER,  privateUri(`users/`), data );

export const verifyEmail = token => 
  post2(types.VERIFY_EMAIL,  privateUri(`users/verify_email/`), { token } );




export const setLang = lang => ({
    type: types.SET_LANG,
    payload: lang,
})

export const changeLanguage = lang => dispatch => {
   i18n.changeLanguage(lang);
   dispatch(setLang(lang));
}
export const footerVisible = value => ({
    type: types.SHOW_FOOTER,
    payload: value,
})

export const hideNavBar = () => ({
    type: types.SHOW_NAVBAR,
    payload: false,
})
export const showNavBar = () => ({
    type: types.SHOW_NAVBAR,
    payload: true,
})
export const logout = () => ({
    type: LOGOUT,
});
export const updateUserInfo = (info) => ({
    type: SET_USER_INFO,
    payload: info,
});


export const fetchUserBegin = () => ({
    type: FETCH_USER_BEGIN,
});
export const fetchUserSuccess = (data) => ({
    type: FETCH_USER_SUCCESS,
    payload: data,
});
export const fetchUserFailure = (error) => ({
    type: FETCH_USER_FAILURE,
    payload: error
});

export const registerEmail = data =>
  post2(types.REGISTER_EMAIL, hostUrl(`leads/email/`), data);
  
export const fetchUserCompanies = () =>
  get2(types.FETCH_USER_COMPANIES, `${privateUrl}/companies/`);

export const fetchCompanyLeads = () => 
  get2(types.FETCH_COMPANY_LEADS, hostUrl(`leads/company/`));

