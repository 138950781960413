import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Title,
  Subtitle,
  Paragraph,
  Button,
  Link,
  Loader,
} from 'gigapay-ui'
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Row, Text, Title as PTitle, Table as PTable } from 'components/Placeholder/'
import { fetchDispatchPayouts, fetchReceivedPayout } from 'actions/payouts';
import css from './payouts.module.css';
import { TableFooter } from 'components/'
import { CompanyTable, PersonTable, Filter } from './components/';
import { useLocationQuery } from 'hooks/utils';
import { format } from 'utils/url'

const Payouts = ({
  loading,
  list,
  mode,
  hasNext,
  fetchDispatchPayouts,
  fetchReceivedPayout
}) => {
  const { page, isClear, created_at_after, created_at_before } = useLocationQuery({
    page: 1,
    created_at_after: null,
    created_at_before: null,
    isClear: null
  });
  const hasFilters = created_at_after !== null && created_at_before !== null || isClear !== null;


  const { t } = useTranslation('payouts');
  const history = useHistory();

  useEffect(() => {
    if (mode === 'company') {
      // fetch dispatched payouts
      fetchDispatchPayouts({ page, expand: 'employee', ...formatFilters({ created_at_before, created_at_after }) });
    } else {
      /// fetch reccived payouts
      fetchReceivedPayout({ page, ...formatFilters({ created_at_before, created_at_after }) })
    }
  }, [created_at_before, created_at_after, page]);


  const formatFilters = filters => {
    const d = {};
    if (filters.created_at_after) {
      d.created_at_after = moment(filters.created_at_after).set("hour", 0).set("minute", 1).toISOString();
    }
    if (filters.created_at_before) {
      d.created_at_before = moment(filters.created_at_before).set("hour", 23).set("minute", 59).toISOString();
    }
    return d;
  };

  const Table = mode === 'company' ? CompanyTable : PersonTable;
  const isReady = !(loading && list.length === 0) || hasFilters;
  const isFilterSearching = hasFilters && loading;
  return (
    <div className="content">
      <div className="flex-row-md">
        <div className="flex-col-md-9">
          <PTitle large ready={isReady}>
            <Title.Line margins={false}>{t('Payouts')}</Title.Line>
            <span className={css.subtitle}>{t('Here you can view your past payouts.')}</span>
          </PTitle>
        </div>
        <div className="flex-col-md-3 vcenter">
          {mode === 'company' && (
            <Button.Primary
              responsive
              onClick={() => {
                history.push('/payout/new')
              }}
            >
              {t('dashboard:New payout')} +
            </Button.Primary>
          )}
        </div>
      </div>
      <div className="spacing" style={{ marginBottom: 20 }} />
      {isReady && (
        <Filter
          values={{
            created_at_after,
            created_at_before
          }}
          onComplete={(filters) => {
            history.push(format('/payouts/', formatFilters(filters)))
          }}
          onClear={() => {
            history.push(format('/payouts/', { isClear: 'true' }))
          }}
        />)}
      {(loading || (!loading && list.length !== 0)) && (
        <div className="flex-row-md">
          <div className="flex-col-md-1">
            <PTable ready={isReady} cols={3}>
              <Table
                data={list}
              />
              {hasNext && (
                <TableFooter
                  paging
                  loading={loading}
                  hasNext={hasNext}
                  onClick={() => {
                    history.push(format('/payouts/', { isClear: 'true', page: parseInt(page) + 1, created_at_after, created_at_before }))
                  }}
                />
              )}
            </PTable>
          </div>
        </div>
      )}
      {isFilterSearching && (
        <Loader inline />
      )}
      {hasFilters && !loading && list.length === 0 && (
        <div style={{ minHeight: 200, textAlign: 'center' }}>
          <Subtitle small>{t('No result')}</Subtitle>
          <Paragraph>{t("You haven't created any payouts during this period.")}</Paragraph>
          <Link primary bold onClick={() => history.push(format('/payouts/', { isClear: 'true' }))}>Clear filters</Link>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  list: state.payouts.list,
  loading: state.payouts.loading,
  mode: state.user.mode,
  hasNext: state.payouts.hasNext
});

const mapDispatchToProps = {
  fetchDispatchPayouts,
  fetchReceivedPayout
};


export default connect(mapStateToProps, mapDispatchToProps)(Payouts);