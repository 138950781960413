import { types } from '../constants/action-types';
import { put } from '../utils/fetchV3';

const privateUrlFunc = (parms = '') => config =>
  `${config.privateUrl}/${parms}`;

export const acceptPayouts = data => 
  put(types.ACCEPT_PAYOUT, privateUrlFunc(`payouts/`), data );

export const resetAccept = () => {
  return { type: types.RESET_ACCEPT, payload: [] };
};
