import React from 'react';
import ReactPlaceholder from 'react-placeholder';
import css from './placeholder.module.css';

export const Text = ({ children = null, className = '', ready = false, style = null, rows= 1 }) => {
  return (
    <ReactPlaceholder className={`${css.center} ${className}`} showLoadingAnimation={true} style={{ marginTop: 3, maxWidth: 150, ...style }} rows={rows} type='text' ready={ready} color='#F3F3F3'>
      {children}
    </ReactPlaceholder>
  )
};

export const Title = ({ children = null, ready = false, style = null }) => {
  return (
    <ReactPlaceholder  showLoadingAnimation={true} style={{ marginTop: 3, height: 32, margin: '15px 0', maxWidth: 150, ...style }} type='textRow' ready={ready} color='#F3F3F3'>
      {children}
    </ReactPlaceholder>
  )
};

export const Row = ({ children = null, ready = false, style = null, cols = 5 }) => {
  return (
    <div className={css.tableRow}>
      {
        Array(cols).fill().map((_, i) => <div key={`row-${i}`} className={css.td}><Text></Text></div>)
      }
    </div>
  )
};

export const Table = ({ children = null, ready = false, style = null, cols = 5, rows = 2 }) => {
  if(ready){
    return children;
  }
  return (
    <div className={css.table}>
      {
        Array(rows).fill().map((_, i) => (
          <div className={css.tableRow}>
            {
              Array(cols).fill().map((_, i) => <div key={`row-${i}`} className={css.td}><Text></Text></div>)
            }
          </div>
        ))
      }
    </div>
  )
};

const Placehoder = () => {
  return null;
};

Placehoder.Text = Text;
Placehoder.Title = Title;
Placehoder.Row = Row;
Placehoder.Table = Table;
export default Placehoder;