import React, { Component, Fragment } from 'react';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
  Title,
  Button,
  Fieldset,
  Input,
  Subtitle,
  Icon,
  Paragraph
} from 'gigapay-ui'

import { uploadLogo, saveCompany, currentCompany } from '../../../../actions/company'

import { validEmail, validEmailorEmpty } from '../../../../utils/validate'
import { Msg } from '../../../../components/';

const HR = () => (
  <div className="hr"></div>
);


class General extends Component {


  state = {
    email: '',
    invoice_email: '',
    error: null,
  }

  componentDidMount() {
    this.setState({
      invoice_email: this.props.company.invoice_email,
      email: this.props.company.email
    })
  }

  asValue = (v) => (v === null) ? '' : v;

  submit = (e) => {
    e.preventDefault();
    const { email, invoice_email } = this.state;
    if (!validEmail(email)) {
      this.setState({ error: 'Invalid email' })
      return;
    }
    if (!validEmailorEmpty(invoice_email)) {
      this.setState({ error: 'Invalid invoice email' });
      return;
    }
    this.props.saveCompany(this.props.company.id, {
      email, invoice_email
    });
  };

  onDrop = (acceptedFiles, rejectedFiles) => {
    // Do something with files
    for (var i = 0; i < acceptedFiles.length; i++) {
      var data = new FormData();
      data.append('logo', acceptedFiles[i]);
      this.props.uploadLogo(this.props.company.id, data);
      break;
      //alert('Laddar upp '+acceptedFiles[i].name);
    }
  };

  render() {

    const { hasCompany, company, t } = this.props;

    return (
      <Fragment>
        <div className="flex-row-md">
          <div className="flex-col-md-12">
            <Subtitle small style={{ marginTop: 24 }}>{t('The company')}</Subtitle>
            <Paragraph margins={false}>{t('Information about your company.')}</Paragraph>
          </div>
        </div>

        <div className="flex-row-md">
          <div className="flex-col-md-7">
            <Fieldset>
              <Input
                leftIcon={<Icon name={'mail'} />}
                label={"Email"}
                value={this.asValue(this.state.email)}
                onChange={(email) => this.setState({ email })}
                validate={validEmail}
              />
            </Fieldset>
          </div>
          <div className="flex-col-md-5"></div>
        </div>

        <div className="flex-row-md">
          <div className="flex-col-md-7">
            <Fieldset>
              <Input
                leftIcon={<Icon name={'mail'} />}
                label={t('Invoice email')}
                value={this.asValue(this.state.invoice_email)}
                onChange={(invoice_email) => this.setState({ invoice_email })}
                validate={validEmail}
                />
            </Fieldset>
          </div>
          <div className="flex-col-md-5"></div>
        </div>


        <div className="flex-col-md-5">
          <div className="edit-logo" style={{ display: 'flex' }}>
            <div className="edit-logo-placeholder" style={{
              backgroundSize: '75% auto',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              marginRight: 15,
              width: 65,
              height: 65,
              borderRadius: 5,
              marginTop: 10,
              border: '1px solid #E8E8E8',
              flex: '0 0 65px',
              backgroundColor: '#fff',
              backgroundImage: (company.logo !== null) ? `url(${company.logo})` : 'none'
            }}></div>
            <div className="edit-logo-upload" style={{ backgroundColor: '#fff', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>

              <Dropzone accept="image/*" onDrop={this.onDrop} multiple={false}>
                {({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject }) => {
                  return (
                    <div
                      style={{
                        border: '1px solid #E8E8E8',
                        borderRadius: 20,
                        padding: '5px 0px',
                        width: 150,
                        textAlign: 'center',
                        fontSize: 13,
                        fontWeight: 'bold',
                        cursor: 'pointer'

                      }}
                      {...getRootProps()}
                    >
                      <input {...getInputProps()} />

                      {t('Upload logo')}


                    </div>
                  )
                }}
              </Dropzone>
              <span style={{ fontSize: 12, color: 'gray', marginTop: 5 }}>{t('Size 250 x 250, PNG or JPG')}</span>
            </div>
          </div>
        </div>

        <div className="flex-row-md">
          <div className="flex-col-md-12">
            <HR />
            <Button loading={this.props.loading} inline onClick={this.submit} primary style={{ marginTop: '5px', float: 'left' }}>Spara</Button>

            <Msg
              status={this.props.status}
              onSuccess={t('The change is saved')}
              onError={t('Something went wrong')}
              style={{ lineHeight: '48px', marginLeft: '10px' }}
            />
          </div>
        </div>
      </Fragment>
    )
  }
}


const mapStateToProps = (state) => ({
  companyStatus: state.company.status,
  companyLoading: state.company.loading,
  company: currentCompany(state),
  status: state.company.status,
  loading: state.company.loading
});
const mapDispatchToProps = dispatch => ({
  uploadLogo: (id, img) => {
    dispatch(uploadLogo(id, img));
  },
  saveCompany: (id, data) => {
    dispatch(saveCompany(id, data));
  },
});


export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(['settings', 'common'])(General));
