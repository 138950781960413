import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Paragraph, Loader, Alert, Link } from 'gigapay-ui';
import { useLocation, useHistory } from 'react-router-dom'
import { useTranslation, Trans } from 'react-i18next';
import * as qs from 'query-string';
import { status } from 'constants/action-types';
import { updateUserInfo, verifyEmail } from '../../actions/user';

const EmailVerify = ({ updateUserInfo, verifyEmailError, verifyEmail, verifyEmailStatus }) => {
  const location = useLocation();
  const history = useHistory();
  const query = qs.parse(location.search);
  const [error, setError] = useState(null)
  const { t } = useTranslation(['settings', 'common'])
  const contactSupport = () => {
    window.Intercom('showNewMessage', '');
  }
  useEffect(() => {
    if (query.token) {
      // switch mode
      updateUserInfo({ mode: 'person', currentCompany: null });
      verifyEmail(query.token)
    }
  }, [])
  useEffect(() => {
    if (verifyEmailStatus === status.SUCCESS) {
      history.replace('/settings/?verifed=true')
      updateUserInfo({ verifyEmailStatus: status.DEFAULT });
    }
    if (verifyEmailStatus === status.ERROR) {
      setError(
        <Trans i18nKey={
          verifyEmailError.token.length === 1 && verifyEmailError.token[0] !== 'Email already exists.' ? 
            'settings:invalidToken' :
            'settings:emailAlreadyInUse'
        }>
          {[
            <Link key={'api-link'} onClick={contactSupport}></Link>
          ]}
        </Trans>
      )
      updateUserInfo({ verifyEmailStatus: status.DEFAULT });
    }
  }, [verifyEmailStatus])
  return (
    <div style={{ textAlign: 'center', paddingTop: 30 }}>
      {error === null && (
        <>
          <Loader />
          <Paragraph>{t('Verifying email')}</Paragraph>
        </>
      )}
      {error && (
        <Alert.Error>
          {error}
        </Alert.Error>
      )}

    </div>
  )
}

const mapStateToProps = (state) => ({
  verifyEmailError: state.user.verifyEmailError,
  verifyEmailStatus: state.user.verifyEmailStatus,
});

const mapDispatchToProps = {
  updateUserInfo,
  verifyEmail
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailVerify);