import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom'
import * as qs from 'query-string';
import Authenticator from 'shared/Authenticator'
import { status } from 'constants/action-types';
import { format as formatUrl } from 'utils/url';
import { claimEmployee } from 'actions/employee';
import { updateUserInfo } from 'actions/user'

const Login = ({
  claimEmployee,
  claimStatus,
  updateUserInfo,
  payoutBackends
}) => {
  const history = useHistory();
  const location = useLocation();
  const query = qs.parse(location.search);
  const [redirectUrl, setRedirectUrl] = useState('/');
  const hasPaymentMethod = Boolean(payoutBackends.preferred_payment_method);
  const getUrl = () => {
    if (query.c) {
      return formatUrl(`/ok/claim/`, { ...query, claim: true })
    } else if (query.id) {
      return formatUrl(`/ok/payout/`, query);
    }
    return '';
  };
  useEffect(() => {
    setRedirectUrl(getUrl());
  }, []);


  useEffect(() => {
    if (claimStatus === status.SUCCESS) {
      if (query.id) {
        history.push(formatUrl(`/ok/payout/`, { id: query.id }))
      } else {
        history.push(formatUrl(`/ok/claimed/`, { auto: query.auto }))
      }
      updateUserInfo({ claimStatus: status.DEFAULT })
    }
    if (claimStatus === status.ERROR) {
      history.push(formatUrl(`/ok/claim/`, query));
      updateUserInfo({ claimStatus: status.DEFAULT })
    }
  }, [claimStatus]);

  return (
    <div className="content center">
      <Authenticator
        onComplete={() => {
          const u = getUrl();
          if (query.c && hasPaymentMethod) {
            claimEmployee(query.c)
          } else if ((query.auto === 'True') && !hasPaymentMethod) {
            history.push(formatUrl(`/ok/bankinfo/`, query))
          } else {
            history.push(u)
          }
        }}
        baseName="/ok/login"
        redirectUrl={redirectUrl}
      />
    </div>
  )
};

const mapStateToProps = (state) => ({
  claimStatus: state.user.claimStatus,
  claimCheck: state.employee.claimCheck,
  payoutBackends: state.payoutBackends
});

const mapDispatchToProps = {
  claimEmployee,
  updateUserInfo
};


export default connect(mapStateToProps, mapDispatchToProps)(Login);