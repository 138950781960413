import { types } from './action-types';
import { post } from 'utils/fetchV3';

const privateUrl = (parms = '') => config =>
  `${config.privateUrl}/${parms}`;

export const requestToken = (data) => 
  post(types.REQUEST_TOKEN, privateUrl(`login/request/`), data);

export const reset = () => ({
  type: types.RESET,
})
