import React from 'react';
import css from './container.module.css';

const Container = ({ children = null}) => {
  return (
    <div className={css.invoiceContainer}>
      { children }
    </div>
  )
}

export default Container;