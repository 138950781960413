import React from 'react'
import Intercom from 'react-intercom';
import { connect } from 'react-redux'

const IntercomComponent = ({ appID = '', isAuthenticated, name = null, cellphone_number = null,
                             email = null, isVerified = null , uuid = null, intercom_hmac = null}) => {
  const user = {}
  if (isAuthenticated){
    user.name = name;
    user.verified = isVerified;
    user.user_id = uuid;
    user.user_hash = intercom_hmac;
    if (cellphone_number) {
      user.phone = cellphone_number;
    }
    if (email) {
      user.email = email;
    }
  }
  return <Intercom appID={appID} {...user} />
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  isVerified: state.auth.isVerified,
  uuid: state.user.uuid,
  intercom_hmac: state.user.intercom_hmac,
  name: state.user.name,
  cellphone_number: state.user.cellphone_number,
  email: state.user.email,
});


export default connect(mapStateToProps, null)(IntercomComponent)
