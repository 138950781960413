import { privateUrl } from '../config';
import { persistor } from '../store/'
const loginUrl = `${privateUrl}/users/`;

/*
    authentication.login("199305311574")
    authentication.loginCancel()

    authentication.logut("")

    authentication.isValid("")

    authentication.isLoggedIn()
*/

class authentication  {
    
    showLog = false;
    
    status = {
        DEFAULT: "DEFAULT",
        IN_PROGRESS: "IN_PROGRESS",
        COMPLETED: "COMPLETED",
    }; 

    collecting = false;
    currentStatus = (localStorage.getItem('gigapay-auth-v2') === null) ? 
            {
                status: this.status.DEFAULT,
                time: new Date(),
                type: 'sign',
                ref: null,
                data: null,
            }
            : JSON.parse(localStorage.getItem('gigapay-auth-v2'));


    user = (localStorage.getItem('gigapay-user-v2') === null) ? {
        token: null,
        personal_number: null,
        created: null,
        language: 'sv',
    } : JSON.parse(localStorage.getItem('gigapay-user-v2'));

    

    isLoggedIn(){
        return (this.user.token !== null)
    }

    getToken(){
        return this.user.token;
    }
    getLanguage(){
      return this.user.language;
    }

    getUser(){
        return this.user;
    }

    canResumeLogin = () => this.currentStatus.type === 'login' && this.currentStatus.status === this.status.IN_PROGRESS && !this.collecting;
    canResumeSign = () => this.currentStatus.type === 'sign' && this.currentStatus.status === this.status.IN_PROGRESS && !this.collecting;
    
    updateLoginStatus(status, payload = {}){
        const { ref=null, data=null }  = payload;
        this.currentStatus = {
            status,
            time: new Date().getTime(),
            ref,
            data,
            type: 'login',
        }
        localStorage.setItem('gigapay-auth-v2', JSON.stringify(this.currentStatus));
    }
    updateSignStatus(status, ref = null){
        
        this.currentStatus = {
            status,
            time: new Date().getTime(),
            ref,
            type: 'sign',
        }
        localStorage.setItem('gigapay-auth-v2', JSON.stringify(this.currentStatus));
    }

    updateUser(update){
    
        this.user = {
            ...this.user,
            ...update,
            created: new Date().getTime(),
        };

        if(this.showLog){
            console.log("update gigapay-user", JSON.stringify(this.user), { update });
        }
        localStorage.setItem('gigapay-user-v2', JSON.stringify(this.user));
    }

    
    signCancel(){

        // check orderRef
        if(this.currentStatus.ref === null){
            return;
        }
        const { orderRef, signature, data, url} = this.currentStatus.ref;
        
        if(typeof orderRef === 'undefined') return;
        
        this.currentStatus = this.status.DEFAULT;
        fetch(url, {
            method: 'PUT', // or 'PUT'
            headers:{
                'Content-Type': 'application/json',
                'BANKID-ORDER-REFERENCE': orderRef,
                'BANKID-SIGNATURE': signature,
                'BANKID-CANCEL': true,
                'Authorization': `Token ${this.user.token}`,
            },
            body: data && JSON.stringify(data),
        })
        .then(res => res.text())
        .then(res => console.log(JSON.parse(res)))
        .catch(error => console.error('Error:', error));
        
        this.updateSignStatus(this.status.DEFAULT);
        return;

    }

    _checkSign = (url, orderRef, signature, data) => new Promise((resolve, reject) => {

        if(orderRef === undefined || signature === undefined){
            reject("invalid_orderRef");
        }
        fetch(url, {
            method: 'PUT', // or 'PUT'
            headers:{
                'Content-Type': 'application/json',
                'BANKID-ORDER-REFERENCE': orderRef,
                'BANKID-SIGNATURE': signature,
                'Authorization': `Token ${this.user.token}`,
            },
            body: data && JSON.stringify(data),
        })
        .then(res => res.text())
        .then(res => resolve(JSON.parse(res)))
        .catch(error => console.error('Error:', error));

    });
    
    _collectSign = (url, orderRef, signature, data) => new Promise((resolve, reject) => {

        if(!orderRef) return;
        this.collecting = true;
        // Periodically try collecting the token (i.e. login was successful).
        const collectUntilSuccess = () => {

            this._checkSign(url, orderRef, signature, data)
            .then((result) => {
                
                if(this.showLog){
                    console.log("_collectSign", result);
                }
                
                // check if user pressed cancel
                if(this.currentStatus.status !== this.status.IN_PROGRESS){
                    reject("user_canceled");
                    this.updateSignStatus(this.status.DEFAULT);
                    return;
                }else if(result.status === 'failed'){
                    reject("failed");
                    this.updateSignStatus(this.status.DEFAULT);
                    return;
                }else if(result.status === 'pending'){
                    setTimeout(collectUntilSuccess, 1000);
                    return
                }else if(typeof result.status === 'undefined'){
                    this.updateSignStatus(this.status.COMPLETED);
                    resolve(data);
                }

            })
            .catch(err => {
                console.log("error collect")
                reject(err);
            });

        };

        collectUntilSuccess();

    });
    _startSign = (url, data) =>  new Promise((resolve, reject) => {
        try{
            fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'BANKID-PERSONAL-NUMBER': this.user.personal_number,
                    'Authorization': `Token ${this.user.token}`,
                },
                body: data && JSON.stringify(data),
            })
            .then(res => res.text())
            .then(res => {
                console.log(res)
                resolve(JSON.parse(res))
            })
            .catch(error =>  {
                console.log({ error })
                reject(error)
            });
        }catch(err) {
            console.log("err", err)
            reject(err);
        }
    });

    resumeSign = () => new Promise((resolve, reject) => {
      
         // check if the date is close to now
         const mseconds = 2*60*1000;
         if((new Date() - new Date(this.currentStatus.time)) > mseconds) {
            // cancel signs
            this.signCancel();
             reject();
         }
 
         // check orderRef
         if(this.currentStatus.ref === null){
             reject();
         }
         const {url, orderRef, signature, data } = this.currentStatus.ref;
 
         
         this._collectSign(url, orderRef, signature, data)
         .then((data) => {
             resolve(data);
         })
         .catch((error) => reject(error));

    });
    
    sign = (url, data) => new Promise((resolve, reject) => {

        if(this.user.personal_number.length !== 12){
            reject("invalid_personal_number")
        }

        if(this.user.token === null){
            reject("undefined_token");
        }
       
        this._startSign(url, data).then((signRef) => {
            
            const {orderRef, signature } = signRef;
            this.updateSignStatus(this.status.IN_PROGRESS, { ...signRef, data, url });

            //CollectSign
            this._collectSign(url, orderRef, signature, data)
            .then((data) => {
                resolve(data);
                this.collecting = false;
            })
            .catch((error) => {
                reject(error);
                this.collecting = false;
            });

        })
        .catch(error => {
            console.log(error);
            reject()
        });
        

    });



    /*
    LOGIN
    */
    _startLogin = (personalNumber, data={}) => new Promise((resolve, reject) => {
     
        try{
            fetch(loginUrl, {
                method: 'POST', // or 'PUT'
                headers:{
                    'Content-Type': 'application/json',
                    'BANKID-PERSONAL-NUMBER': personalNumber,
                },
                body: data && JSON.stringify(data)
            })
            .then(res => res.text())
            .then(res => resolve(JSON.parse(res)))
            .catch(error => {
                reject()
            });
        }catch(err) {
            console.error("err", err);
            reject(err);
        }
        
    });


   

    loginCancel = () => {
        
        // check orderRef
        if(this.currentStatus.ref === null){
            return;
        }
        const { orderRef, signature} = this.currentStatus.ref;
        
        if(typeof orderRef === 'undefined') return;
        
        this.currentStatus = this.status.DEFAULT;
        fetch(loginUrl, {
            method: 'POST', // or 'PUT'
            headers:{
                'Content-Type': 'application/json',
                'BANKID-ORDER-REFERENCE': orderRef,
                'BANKID-SIGNATURE': signature,
                'BANKID-CANCEL': true,
            }
        })
        .then(res => res.text())
        .then(res => console.log(JSON.parse(res)))
        .catch(error => console.error('Error:', error));
        
        this.updateLoginStatus(this.status.DEFAULT);
        return;
    };
    
    _saveUser = (data) => {

        this.user = {
            ...this.user,
            ...data,
        }

        if(this.showLog){
            console.log("gigapay-user", JSON.stringify(this.user), { data });
        }
        localStorage.setItem('gigapay-user-v2', JSON.stringify(this.user));
        return this.user;
    }

    fromToken = (token) => new Promise((resolve, reject) => {
        let statusCode = -1;
        fetch(`${loginUrl}token/`, {
            method: 'POST', // or 'PUT'
            headers:{
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({token}),
        })
        .then(res => {
            statusCode = res.status;
            return res.text()
        })
        .then(res => {

       
            if (statusCode >= 200 && statusCode < 300){
                const data = JSON.parse(res); 
                if(data.token){
                      this._saveUser({
                        token: data.token
                      })
                    resolve(data);
                    return
                }else{
                    reject("invalid_token");
                }
            }else{
                reject("invalid_token");
            }
            
            
        })
        .catch(error => console.error('Error:', error));
    })

    
    openBankId = () => {
        
        // Only trigger when signature is in progress
        if(this.currentStatus.status !== this.status.IN_PROGRESS) return;
        
        try{
            if(!this.currentStatus.ref.autoStartToken) return;
            const { autoStartToken } = this.currentStatus.ref;
            // Only trigger when valid autostarttoken
            if(typeof autoStartToken === 'undefined') return;
            
            // Only trigger on mobile devices.
            if (/Mobi/.test(navigator.userAgent)) {
            // On iOS we need to explicitely redirect the user back to our page.
            const redirect =
                /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
                 ? encodeURIComponent(window.location.href)
                : "null";
            //const redirect = "https://app.gigapay.se/login/bankid";
            const url = `bankid:///?autostarttoken=${autoStartToken}&redirect=${redirect}`;
        
            // Some browsers (like Chrome on iOS) don't like setting the window location during an ongoing request.
            if (/CriOS/.test(navigator.userAgent)) {
                const iframe = document.createElement("iframe");
                iframe.src = url;
                iframe.height = 0;
                iframe.width = 0;
                iframe.style.visibility = "hidden";
                iframe.style.position = "fixed";
                document.body.appendChild(iframe);
            } else {
                window.location = url;
            }
            }else{
                console.log("not on desktop");
            }
        } catch (err) {
          return false;
        }
    }

    resumeLogin = () => new Promise((resolve, reject) => {
       
        // check if the date is close to now
        const mseconds = 2 * 60 * 1000;
        if((new Date() - new Date(this.currentStatus.time)) > mseconds) {
            this.loginCancel();
            reject();
        }

        // check orderRef
        if(this.currentStatus.ref === null){
            reject();
        }
        const { orderRef, signature} = this.currentStatus.ref;
        const { data } = this.currentStatus;
        
        this._collectLogin(orderRef, signature, data)
        .then((user) => {
            resolve(user);
        })
        .catch((error) => reject(error));

    });

    _checkLogin = (orderRef, signature, data={}) => new Promise((resolve, reject) => {

        fetch(loginUrl, {
            method: 'POST', // or 'PUT'
            headers:{
                'Content-Type': 'application/json',
                'BANKID-ORDER-REFERENCE': orderRef,
                'BANKID-SIGNATURE': signature,
            },
            body: data && JSON.stringify(data)
        })
        .then(res => res.text())
        .then(res => resolve(JSON.parse(res)))
        .catch(error => console.error('Error:', error));

    });

    _collectLogin = (orderRef, signature, data={}) => new Promise((resolve, reject) => {
        this.collecting = true;
        // Periodically try collecting the token (i.e. login was successful).
        const collectUntilSuccess = () => {

            this._checkLogin(orderRef, signature, data)
            .then((data) => {
                
                
                
                if(this.showLog){
                    console.log("collectLogin", data);
                }

                if(data.errors){
                    this.updateLoginStatus(this.status.DEFAULT);
                    reject("user_canceled");
                    return;
                }
                
                // check if login is done
                if(data.token){
                    this._saveUser({ token: data.token })
                    this.updateLoginStatus(this.status.COMPLETED);
                    resolve(data);
                    return;
                }

                // check if user pressed cancel
                if(this.currentStatus.status !== this.status.IN_PROGRESS){
                    reject("user_canceled");
                    this.updateLoginStatus(this.status.DEFAULT);
                    return;
                }
                if(data.status === 'failed'){
                    reject("failed");
                    this.updateLoginStatus(this.status.DEFAULT);
                    return;
                }
                

                if(data.status === 'pending'){
                    setTimeout(collectUntilSuccess, 1000);
                }

            })
            .catch(err => {
                if (err.networkError) {
                    return setTimeout(collectUntilSuccess, 1000);
                }
                this.loginCancel();
            });

        };

        collectUntilSuccess();

    })

    
    login = (personalNumber, data={}) => new Promise((resolve, reject) => {
        

        

        if(personalNumber.length !== 12){
            reject("invalid_personal_number")
        }

        try {
            this._startLogin(personalNumber, data).then((resp_data) => {
               
                const {orderRef, signature, autoStartToken} = resp_data;

                

                if(typeof orderRef === 'undefined'){
                    console.log("invalid_ref", resp_data);
                    this.updateLoginStatus(this.status.DEFAULT);
                    reject("invalid_ref");
                    return;
                }
                
                // update the orderRefs
                console.log("update ref", resp_data);
                this.updateLoginStatus(this.status.IN_PROGRESS, {
                  ref: resp_data,
                  data,
                });

                this._collectLogin(orderRef, signature, data)
                .then((user) => {
                    resolve(user);
                    this.collecting = false;
                })
                .catch((error) => {
                    reject(error);
                    this.collecting = false;
                });
                

            })
            .catch(err => {
                reject();
            });

        } catch (err) {
            reject(err);
        }

    })
    
    

    logout = () => new Promise((resolve, reject) => {
        
        this.user = {
            token: null,
            created: null,
            language: this.user.language ? this.user.language : 'sv',
        };
        this.updateLoginStatus(this.status.DEFAULT);
        localStorage.setItem('gigapay-user-v2', JSON.stringify(this.user));
        //localStorage.removeItem('gigapay-user');
        resolve();
    });
    
    isValid(){
        // check to server if token is valid
        // return promise 
    }
}

const auth =  new authentication();
if(process.env.NODE_ENV === "development"){
    auth.showLog = true;
}

export default auth;

