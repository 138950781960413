import {
  types,
  status,
} from '../constants/action-types';

const defaultInvoice = {
  id: '',
  price: 0,
  paid_at: null,
  ocr_number: null,
  created_at: null,
  payouts: [],
  loading: false,
};


const invoice = (state = defaultInvoice, action) => {
  switch (action.type) {
    case types.FETCH_COMPANY_INVOICE:
      switch (action.meta.status) {
        case status.BEGIN:
          return {
            ...state,
            price: '',
            id: '',
            orc_number: null,
            created_at: null,
            payouts: [],
            loading: true,
          };
        case status.SUCCESS:
          return {
            ...state,
            ...action.payload,
            loading: false,
          };
        case status.ERROR:
          return {
            ...state,
            loading: false,
          };
        default:
          return state;
      }
    
    case types.FETCH_COMPANY_INVOICE_PAYOUTS:
      switch (action.meta.status) {
        case status.BEGIN:
          return {
            ...state,
            loading: true,
          };
        case status.SUCCESS:
          return {
            ...state,
            payouts: action.payload.results,
            loading: false,
          };
        case status.ERROR:
          return {
            ...state,
            loading: false,
          };
        default:
          return state;
      }


    default:
      return state;

  }
}
export default invoice;
