import { types} from '../constants/action-types';
import { post, get, patch, patchRaw } from '../utils/fetchV3';

const host = (parms = '') => config =>
  `${config.host}/${parms}`;

export const createUserLead = data =>
  post(types.CREATE_USER_LEAD, host(`leads/onboarding/`), data);
   
export const updateUserLead = data =>
  patch(types.UPDATE_USER_LEAD, host(`leads/onboarding/`), data);
   
export const getOnboardingStatus = () => 
  get(types.GET_ONBOARDING_STATUS, host(`leads/onboarding/`));

export const uploadFile = formData => 
  patchRaw(types.UPLOAD_VERIFY_DOCUMENT, host(`leads/onboarding/`), formData);
