import { useEffect } from "react";
import { useLocation } from 'react-router-dom';
import * as qs from 'query-string';

const useOutsideClick = (ref, callback) => {
  const handleClick = e => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  });
};

const useLocationQuery = (def = {}) => {
  const location = useLocation();
  const query = qs.parse(location.search);
  return {...def, ...query};
}


export { useOutsideClick, useLocationQuery };