import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { Transition } from 'components/';
import RecipientProvider from 'hooks/payoutsV2';
import uuid from 'uuid-random';
import { Subtitle, Paragraph, Button, Icon } from 'gigapay-ui'
import {
  Create,
  Preview,
  Delivered
} from './containers/'
import { validEmail } from 'utils/validate'

const initRecipient = () => {
  return [{
    id: uuid(),
    inputValue: '',
    inputType: 'cost',
    description: '',
    employee: {
      name: 'Ludwg',
      cellphone_number: '',
      value: '',
    }
  }];
};

const NewInvoice = ({ email }) => {
  const { t } = useTranslation(['invoices']);
  const history = useHistory();
  const [payout, setPayout] = useState({
    company: {
      name: '',
      email: '',
      address_line_1: '',
      zip_code: '',
      city: '',
    },
    amountType: '',
    description: '',
    input: '',
    amount: 0,
    cost: 0,
    vat: 0,
  });
  if (!validEmail(email)) {
    return (
      <div className="payout">
        <div className="content center"  style={{ textAlign: 'center' }}>
          <Icon name={'mail'} size={55} />
          <Subtitle>{t('What is your email?')}</Subtitle>
          <div>
            <Paragraph>{t('Before sending your first invoice you need to verify your email.')}</Paragraph>
            <Button.Primary onClick={() => history.push(`/settings/`)}>{t('Go to settings')}</Button.Primary>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className="payout">
      <div className="content center">
        <RecipientProvider init={initRecipient()} >
          <Transition
            root={'/invoices/new'}
          >
            <Transition.Item path={root => `${root}/preview/`}>
              <Preview payout={payout} />
            </Transition.Item>
            <Transition.Item path={root => `${root}/delivered/`}>
              <Delivered />
            </Transition.Item>
            <Transition.Item path={root => `${root}/`}>
              <Create payout={payout} onSubmit={p => setPayout(p)} />
            </Transition.Item>

          </Transition>
        </RecipientProvider>
      </div>
    </div>
  )
};

const mapStateToProps = (state) => ({
  email: state.user.email,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NewInvoice);