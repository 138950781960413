import React, { Suspense } from 'react';
import PropTypes from 'prop-types'
import { Loader } from 'gigapay-ui';
import css from './suspenseloader.module.css';
const SuspenseLoader = ({ children }) => {
  return (
    <Suspense fallback={(
      <div className={css.container}>
        <Loader inline />
      </div>
    )}>
      { children }
    </Suspense>
  )
}
SuspenseLoader.propTypes = {
  children: PropTypes.element
}
SuspenseLoader.defaultProps = {
  children: null
}

export default SuspenseLoader;