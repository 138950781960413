import React, { useEffect, useState, useRef } from 'react';
import {
  Subtitle,
  Button,
  Icon,
  Fieldset,
  Input,
  Alert
} from 'gigapay-ui'
import { useTranslation } from 'react-i18next';
import { validPhoneNumber, validEmail } from 'utils/validate'
import { asPhoneNumber } from 'utils/number'


const CreateEmployee = ({ onSubmit, createError }) => {
  const phoneRef = useRef(null);
  const { t } = useTranslation('employees');
  const [phone, setPhone] = useState('')
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [error, setError] = useState(null)
  const onSubmitPressed = () => {
    if (email !== '' && !validEmail(email)) {
      setError(t('Invalid email'))
      return
    };

    if (!validPhoneNumber(phone) && !validEmail(email)) {
      setError(t('Email or phone number is required'));
      phoneRef.current.focus()
      return
    }
    onSubmit({
      cellphone_number: phone !== '' ? asPhoneNumber(phone) : null,
      email,
      name
    })

  }
  const hasError = key => createError && createError[key] ? createError[key] : null;
  const errorLabel = key => createError && createError[key] ? createError[key] : '';
  return (
    <div>
      <Subtitle>{t('New recipient')}</Subtitle>
      <Fieldset>
        <Input
          label={`${t('common:Name')}*`}
          leftIcon={<Icon name={'person'} />}
          value={name}
          onChange={name => setName(name)}
        />
        <Input
          label={`${t('common:Phone number')} ${errorLabel('cellphone_number')}`}
          inputMode="tel"
          type={"tel"}
          validate={i => i === '' || validPhoneNumber(i)}
          onChange={phone => setPhone(phone)}
          value={phone}
          ref={phoneRef}
          error={errorLabel('cellphone_number') !== ''}
          leftIcon={<Icon name={'phone'} />}
        />
        <Input
          label={`${t('common:Email')} ${errorLabel('email')}`}
          leftIcon={<Icon name={'mail'} />}
          validate={i => i === '' || validEmail(i)}
          value={email}
          error={errorLabel('email') !== ''}
          onChange={email => setEmail(email)}
        />
      </Fieldset>
      {error && (
        <Alert.Error style={{ marginBottom: 12 }}>
          <span>{error}</span>
        </Alert.Error>)
      }
      <Button primary responsive onClick={onSubmitPressed}>
        Lägg till
    </Button>
    </div>
  )
};
export default CreateEmployee;