import React from 'react';
import { useTranslation } from 'react-i18next';
import { Subtitle, Paragraph } from 'gigapay-ui'
import PayoutBackends from '../../../../shared/PayoutBackends/'

const Bank = () => {
  const { t } = useTranslation(['settings', 'common']);

  return (
    <div style={{ position: 'relative' }}>
      <div className="flex-row-md">
        <div className="flex-col-md-12">
          <Subtitle style={{ marginTop: 24 }} small>{t('Your bank information')}</Subtitle>
          <Paragraph>{t('Here you fill in the account that you want your salary to be paid to')}</Paragraph>
        </div>
      </div>

      <div className="flex-row-md">
        <div className="flex-col-md-7">
          <PayoutBackends settingsView={true} />
        </div>
        <div className="flex-col-md-5"></div>
      </div>
    </div>
  )
};


export default Bank;