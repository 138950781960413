import React, { useEffect } from 'react';
import { Route, Switch, useLocation, useHistory } from 'react-router-dom'
import { connect } from 'react-redux';
import * as qs from 'query-string';
import { format as formatUrl } from 'utils/url';
import {Claim, ClaimedComplete, Payout, Login, TokenLogin, BankInfo} from './containers/';

const Accept = ({ isAuthenticated }) => {
  const location = useLocation();
  const history = useHistory();
  const query = qs.parse(location.search);

  const url = location.pathname;
  useEffect(() => {
    if (url === '/ok/' || url === '/ok') {
      if (query.c) {
        history.push(formatUrl(`/ok/claim/`, query))
      } else if (query.l && isAuthenticated && !query.id) {
        history.push('/dashboard/')
      } else if (query.l && !isAuthenticated) {
        history.push(formatUrl(`/ok/l/`, query))
      } else if (!query.c && query.id && isAuthenticated) {
        history.push(formatUrl(`/ok/payout/`, query))
      } 
    }
  }, []);

  return (
    <Switch>
      <Route path={'/ok/claim/'} component={Claim} />
      <Route path={'/ok/login/'} component={Login} />
      <Route path={'/ok/claimed'} component={ClaimedComplete} />
      <Route path={'/ok/payout/'} component={Payout} />
      <Route path={'/ok/l/'} component={TokenLogin} />
      <Route path={'/ok/bankinfo'} component={BankInfo} />
    </Switch>
  )
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatchToProps = {};


export default connect(mapStateToProps, mapDispatchToProps)(Accept);
