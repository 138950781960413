import { parsePhoneNumberFromString } from 'libphonenumber-js'

export const asNumber = (n) => {
    if(isNaN(parseFloat(n))){
        return 0;
    }
    return parseFloat(n);
};

export const asPhoneNumber = (v, dialCode = '+46') => {
  if(!v) return null;
  let phoneString = '' + v;  // Force to string, in case it's passed as a number
  if (!phoneString.includes('+')) {
    phoneString = `${dialCode}${phoneString}`;
  }
  const phoneNumber = parsePhoneNumberFromString(phoneString);
  if(phoneNumber && phoneNumber.isValid()){
    return phoneNumber.number;
  }
  return null;

};

export const asPersonalNumber = (n = '') => {
    let pno = n.replace(/\D/g, "");
    if (pno.length === 10) pno = "19" + pno;
    return pno;

};