import React, { useState, useEffect } from 'react';
//import AES from 'crypto-js/aes';
import CryptoJS from 'crypto-js';
import { encryptionKey } from '../config'

export const useEncryption = (init) => {
  const [obj, setObj] = useState(init);
  const [encrypted, setEncrypted] = useState('')
  useEffect(() => {
    const str = CryptoJS.AES.encrypt(JSON.stringify({
      ...obj,
      valid: true
    }), encryptionKey).toString();
    setEncrypted(str)
  }, [obj])


  return [encrypted, setObj]
}

export const useDecrypt = (input = '') => {

  const [str, setString] = useState(input);
  const [decrypted, setDecrypted] = useState('')
  useEffect(() => {
    try {
      if (str !== '') {
        const s = CryptoJS.AES.decrypt(str, encryptionKey).toString(CryptoJS.enc.Utf8);
        setDecrypted(s)
      }
    } catch (error) {
      console.log(error)
    }

  }, [str]);
  return [decrypted, setString]
}
