import {
  LOGIN_BEGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_CANCEL,
  RESET_ERROR,
  NOT_LOGIN,

  types
} from '../constants/action-types';
import { post } from '../utils/fetchV3';

const privateUrl = (parms = '') => config =>
  `${config.privateUrl}/${parms}`;

export const login = token =>
  post(types.LOGIN, privateUrl(`login/token/`), { token });
  

export const notLoggedIn = () => ({
  type: NOT_LOGIN,
});
