import React, { useState, useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types'
import { Avatar as UserAvatar } from 'components/'
import css from './drop.module.css';
import { useEmployees } from 'hooks/recipients'
import { useTranslation } from 'react-i18next';
import { validPhoneNumber } from 'utils/validate'


const DropDown = ({ show, value, onClick, onBlur }) => {

  const employees = useEmployees()
  const { t } = useTranslation('payout')
  const wrapperRef = useRef(null);
  const handleClickOutside = event => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      onBlur()
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    document.addEventListener('touchstart', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('touchstart', handleClickOutside)
    };
  }, [])
  // filter
  const listItems = employees.filter(v => {
    if (value === '' || !value) return true;
    const q = value.toLowerCase()
    const { name, email = '', cellphone_number = '' } = v;
    return (
      name.toLowerCase().includes(q) ||
      email && email.includes(q.replace(/\s/g, '')) ||
      cellphone_number && cellphone_number.includes(q.replace(/\s/g, '')) ||
      cellphone_number && cellphone_number.replace('+46', '0').includes(q.replace(/\s/g, ''))
    )
  });


  if (!show || listItems.length === 0) return null;
  return (
    <ul tabIndex={-1} className={`${css.drop} ${show ? css.show : ''}`} ref={wrapperRef}>
      <li className={css.myEmployees}>{t('My Team')}</li>
      {listItems.map(r => (
        <li className={css.employee} onClick={() => onClick(r)} key={r.id}>
          <div className={css.symbolContainer}>
            <UserAvatar name={r.name} />
          </div>
          <div className={css.info}>
            {r.name && <span className={css.name}>{r.name}</span>}
            {!r.name && <span className={css.noName}>No name</span>}
            {(r.cellphone_number !== '' && r.cellphone_number !== null) && <span className={css.cellphone}>{r.cellphone_number}</span>}
            {(r.cellphone_number === '' || r.cellphone_number === null)  && <span className={css.cellphone}>{r.email}</span>}
          </div>
        </li>
      ))}
    </ul>
  )
}

DropDown.propTypes = {
  value: PropTypes.string,
  show: PropTypes.bool,
  onClick: PropTypes.func,
  onBlur: PropTypes.func
}
DropDown.defaultProps = {
  value: '',
  show: false,
  onClick: () => { },
  onBlur: () => { }
}

export default DropDown;