import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import {
  Title,
  Paragraph,
  Button,
  Loader,
  Card,
  List
} from 'gigapay-ui'
import * as qs from 'query-string';
import { useTranslation } from 'react-i18next'
import moment from 'moment-timezone';
import { useHistory, useLocation } from 'react-router-dom';
import { SuccessAnimation } from 'components'
import { numberWithSpaces } from 'utils/price';
import { fetchInvoice } from 'actions/invoice';


const NoticeDone = ({
  fetchInvoice,
  invoice,
  loading
}) => {

  const [price, setPrice] = useState(0);
  const history = useHistory();
  const location = useLocation();
  const queryStrings = qs.parse(location.search);
  const { id, pdf } = invoice;
  useEffect(() => {
    if (queryStrings.id) {
      fetchInvoice(queryStrings.id);
    }
  }, []);
  useEffect(() => {
    if (invoice.price) {
      setPrice(invoice.price);
    }
  }, [invoice])
  const { t } = useTranslation(['payout', 'common']);
  return (
    <div style={{ textAlign: 'center' }}>
      <SuccessAnimation />
      <Title>{t('Your pre-registration is created')}</Title>

      <div className="invoice-info">
        <div style={{ marginTop: 10 }}>
          <Paragraph>{t('When the assignment is completed and the invoice is paid, the money will be paid out to the recipient.')}</Paragraph>
        </div>
        {invoice.price !== '' && (
          <>
            <Card>
              <List
                items={[
                  { label: t('Amount to pay'), value: <span className="success-value">{numberWithSpaces(price)} kr</span>, key: 'price' },
                  { label: t('common:Bankgiro'), value: <span className="success-value">5129-2423</span>, key: 'bankgiro' },
                  {
                    label: t('common:OCR'),
                    value: (
                      <span className="success-value">
                        {invoice.ocr_number === null && <span className={'loading-ocr-container'}>{t('Receiving OCR')} <Loader small inline className={"loading-ocr-loader"} /></span>}
                        {invoice.ocr_number !== null && `${invoice.ocr_number}`}
                      </span>
                    ),
                    key: 'ocr'
                  },
                ]}
              />
            </Card>

            <Button
              onClick={() => {
                window.open(invoice.pdf)
              }}
              style={{ margin: '10px auto' }}
              primary
              disabled={invoice.pdf === null}
              responsive
            >
              {t('Download invoice')} (PDF)
            </Button>
            <div>
              <a href={`/invoices/${invoice.id}/`} className="action">{t('Show summary')}</a>
            </div>
          </>
        )}
        {invoice.price === '' && loading && <Loader inPlace style={{ marginTop: 20 }} />}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  recipients: state.payout.recipients,
  loading: state.invoice.loading,
  invoice: state.invoice,
});
const mapDispatchToProps = {
  fetchInvoice
};

export default connect(mapStateToProps, mapDispatchToProps)(NoticeDone);


