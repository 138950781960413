import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Transition from 'components/TransitionGroupLocal/';
import InputBankid from './components/InputBankid';
import ShowProgress from './components/ShowProgress';
import { status } from 'constants/action-types'
import { resume } from './actions';
import auth from './bankid-auth';
import { Loader } from 'gigapay-ui';

const BankId = ({ root, resume, loginStatus, ...rest }) => {
  const [state, setState] = useState('login');
  const [redirected, setRedirected] = useState(false);
  useEffect(() => {
    if (loginStatus === status.BEGIN) {
      setState('processing')
    }
    if (loginStatus === status.ERROR) {
      setState('login')
    }
  }, [loginStatus]);

  // check if we can resume login
  useEffect(() => {
    if (auth.canResumeLogin()) {
      resume();
      setRedirected(true)
    }
  }, []);

  // If we're redirected back to the page, just show the loader, this prevent the
  // BankID page from flashing by on the screen as it collects the last time.
  if (redirected) {
    return (
      <div className="loading-container">
          <Loader.Fill large inline />
        </div>
    )
  }

  return (
    <Transition state={state}>
      <Transition.Item path={`processing`}>
        <ShowProgress {...rest} />
      </Transition.Item>
      <Transition.Item path={`login`}>
        <InputBankid {...rest} />
      </Transition.Item>
    </Transition>
  )
};

BankId.propTypes = {
  switchMode: PropTypes.func,
};

BankId.defaultProps = {
  switchMode: () => { },
};

const mapStateToProps = (state) => ({
  loginStatus: state.bankidLogin.loginStatus,
});
const mapDispatchToProps = {
  resume
};
export default connect(mapStateToProps, mapDispatchToProps)(BankId)