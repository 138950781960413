import {
  types,
  status,
} from '../constants/action-types';

const defaultVerify = {
  loading: false,
  personalStatusResponse: null,
  checkedForExisting: false,

  id: null,
  identity_number: "",
  reception_unit: "",
  work_permit_type: null,
  passport_back: null,
  passport_front: null,
  work_permit_front: null,
  work_permit_back: null,
};


const verify = (state = defaultVerify, action) => {
  switch (action.type) {

    case types.UPDATE_USER_LEAD:
      switch (action.meta.status) {
        case status.BEGIN:
          return {
            ...state,
            loading: true,
          };
        case status.SUCCESS:
          return {
            ...state,
            loading: false,
            ...action.payload
          };
        case status.ERROR:
          return {
            ...state,
            loading: false,
          };
        default:
          return state;
      }

    case types.GET_ONBOARDING_STATUS:
      switch (action.meta.status) {
        case status.BEGIN:
          return {
            ...state,
            loading: true,
          };
        case status.SUCCESS:
          return {
            ...state,
            loading: false,
            checkedForExisting: true,
            ...action.payload
          };
        case status.ERROR:
          return {
            ...state,
            loading: false,
            checkedForExisting: true,
          };
        default:
          return state;
      }

    case types.UPLOAD_VERIFY_DOCUMENT:
      switch (action.meta.status) {
        case status.BEGIN:
          return {
            ...state,
            loading: true,
          };
        case status.SUCCESS:
          return {
            ...state,
            work_permit_front: action.payload.work_permit_front,
            work_permit_back: action.payload.work_permit_back,
            passport_front: action.payload.passport_front,
            passport_back: action.payload.passport_back,
            loading: false,
          };
        case status.ERROR:
          return {
            ...state,
            loading: false,
          };
        default:
          return state;
      }

    case types.CREATE_USER_LEAD:
      switch (action.meta.status) {
        case status.BEGIN:
          return {
            ...state,
            personalStatusResponse: null,
            loading: true,
          };
        case status.SUCCESS:
          return {
            ...state,
            ...action.payload,
            loading: false,
          };
        case status.ERROR:
          return {
            ...state,
            personalStatusResponse: action.payload,
            loading: false,
          };
        default:
          return state;
      }

    default:
      return state;

  }
};

export default verify;
