import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { configFile } from 'config';
import Greating from '../Greating/'
import { Header, ReceivedPayouts } from './components/'
import {
  fetchReceivedPayout,
  fetchToApprovePayout,
  fetchStatistic
} from '../../../../actions/data';

const PersonDashboard = ({
  name,
  statistic,
  loadingStatistic,
  receivedPayouts,
  toApprovePayouts,

  fetchReceivedPayout,
  fetchToApprovePayout,
  fetchStatistic,
}) => {
  const history = useHistory()
  const { t } = useTranslation('dashboard')
  const [receivedPayoutsPage, setReceivedPayoutsPage] = useState(1)
  const [toApprovePage, setToApprovePage] = useState(1)

  useEffect(() => {
    fetchStatistic('users')
    fetchReceivedPayout({ page: 1, is_accepted: true });
    fetchToApprovePayout({ page: 1, is_accepted: false })
  }, []);

  return (
    <div className="content">
      <div className="flex-row-md">
        <div className="flex-col-md-8">
          <Greating name={name} />
        </div>
        <div className="flex-col-md-4 vcenter">
        </div>
      </div>
      <Header
        statistic={statistic}
        loadingStatistic={loadingStatistic}
      />

      <ReceivedPayouts
        data={toApprovePayouts}
        canApprove
        title={t("Awaiting approval")}
        subTitle={t("explanatoryMessage")}
        onApprove={() => { }}
        loadMore={() => {
          const nextPage = toApprovePage + 1;
          setToApprovePage(nextPage);
          fetchToApprovePayout({ page: nextPage, is_accepted: false }, false);
        }}
      />
      <ReceivedPayouts
        data={receivedPayouts}
        title={t('Received payouts')}
        onApprove={() => { }}
        loadMore={() => {
          const nextPage = receivedPayoutsPage + 1;
          setReceivedPayoutsPage(nextPage);
          fetchReceivedPayout({ page: nextPage, is_accepted: true }, false);
        }}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  statistic: state.data.statistic,
  loadingStatistic: state.data.loadingStatistic,
  receivedPayouts: state.data.receivedPayouts,
  toApprovePayouts: state.data.toApprovePayouts,
  name: state.user.name,
});
const mapDispatchToProps = dispatch => ({
  fetchStatistic: (mode) => {
    dispatch(fetchStatistic(mode));
  },
  fetchReceivedPayout: (args, params) => {
    dispatch(fetchReceivedPayout(args, params));
  },
  fetchToApprovePayout: (args, params) => dispatch(fetchToApprovePayout(args, params)),
});


export default connect(mapStateToProps, mapDispatchToProps)(PersonDashboard);