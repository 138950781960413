import React, { useState } from 'react';
import { Loader } from 'gigapay-ui'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next';
import { useTotalAmount } from 'hooks/payout';
import { ToolTip } from 'gigapay-ui'
import { formatPrice, numberWithSpaces } from 'utils/price'
import css from './pricetable.module.css';

const PriceTable = ({ payouts }) => {
  const [showList, setShowList] = useState(false)
  const { t } = useTranslation(['common'])
  const { vat, amount, payroll, fee, cost, loading } = useTotalAmount(payouts);
  // TODO: make sure every payout has a price

  const totalInkVAT = parseFloat(cost) + vat;
  const toggleList = (e) => {
    e.preventDefault();
    setShowList(!showList)
  }
  return (
    <div className={css.summary}>
    
      <ul className={`${css.details} ${showList ? css.show : ''}`} style={{ maxHeight:   showList ? ( 9 * 29 + payouts.length * 29) : 0 }}>
        <li className={css.summaryRecipientHeader}>
          <div className={css.summaryPerson}>
            {t('common:Name')}
          </div>
          <div className={css.summaryNet}>
            {t('NetPay')} <ToolTip width={115} text={t('The sum that will be paid to the recipient. Including 12% vacation pay.')} />
          </div>
          <div className={css.summaryTax}>
            {t('common:Tax')} <ToolTip width={116} text={t('Paid by law to the tax authorities by Gigapay')} />
          </div>
          <div className={css.summaryGross}>
            {t('Gross Pay')}
          </div>
        </li>
        {
          payouts.map((r, i) => {
            const { amount, payroll } = r.pricing;
            //const amount = details[i] && details[i].amount ? details[i].amount : 0;
            const net = parseFloat(amount) * 0.7;
            return (
              <li key={r.id} className={css.summaryRecipientRow}>
                <div className={css.summaryPerson}>
                  {r.employee.name}
                </div>
                <div className={css.summaryNet}>
                  {formatPrice(net)} kr
                </div>
                <div className={css.summaryTax}>
                  {formatPrice(parseFloat(amount) - net)} kr
                </div>
                <div className={css.summaryGross}>
                  {numberWithSpaces(amount)} kr
                </div>
              </li>
            )
          })
        }
        <li className={css.bold}>{t('Total gross salary')} <span className={css.amount}>{formatPrice(amount)} kr</span></li>
        <li className="info-row">{t('PayrollTax')} <ToolTip width={116} text={t('Paid by law to the tax authorities by Gigapay')} /><span className={css.amount}>{formatPrice(payroll)} kr</span></li>
        <li className="info-row">{t('GigapayFee')} <ToolTip width={150} text={t('This helps us run our platform.')} /><span className={css.amount}>{formatPrice(fee)} kr</span></li>
        <li className={css.bold}>{t('Total amount excl. VAT')} <span className={css.amount}>{formatPrice(cost)} kr</span></li>
        <li className="info-row">{t('VAT')} (25%) <span className={css.amount}>{formatPrice(vat)} kr</span></li>
        <li className={css.bold}>{t('Total amount incl. VAT')} <span className={css.amount}>{formatPrice(totalInkVAT)} kr</span></li>
      </ul>
      
      <div className={css.summarySum}>
        { loading && <div style={{ marginBottom: 17, display: 'block'}}><Loader /></div>}
        { !loading && <span>{formatPrice(totalInkVAT)} kr</span>}
      </div>
      <div className={css.summarySubtitle}>
        {t('Total amount incl. VAT')}
        {payouts.length !== 0 && (
          <a href="#" className={css.toggleList} onClick={toggleList}>
            {(showList) ? t('Hide') : t('Show')} {t('details')}
          </a>
        )}
      </div>
    </div>
  );
  
}

PriceTable.propTypes = {
  recipients: PropTypes.array,
}
PriceTable.defaultProps = {
  recipients: [],
}
export default PriceTable;