import React, { useState } from 'react';
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next';
import {
  Button,
  Paragraph,
  Alert,
  Loader
} from 'gigapay-ui';
import { usePayouts } from 'hooks/payoutsV2'
import RecipientsForm from '../../../components/RecipientsForm';
import { PriceBarList } from 'components/'
const Simple = ({ onSubmit, loading }) => {
  const { t } = useTranslation(['payout', 'common'])
  const [showError, setShowError] = useState(false)
  const { payouts, error, loading: priceLoading } = usePayouts();

  return (
    <>
      <Paragraph>{t('When you have a few salaries to pay out.')}</Paragraph>
      {loading && <Loader />}
      {!loading && <RecipientsForm />}
      <PriceBarList recipients={payouts} />
      {
        showError && error && (
          <Alert.Error>
            {t(error)}
          </Alert.Error>)
      }

      <div className={"spacing"} />
      {payouts.length !== 0 && !loading && (
        <Button.Primary
          responsive
          loading={priceLoading}
          disabled={error !== null ||priceLoading}
          disabledOnClick={() => setShowError(true)}
          onClick={() => onSubmit(payouts)}
        >
          {t('common:Next')}
        </Button.Primary>
      )}

    </>
  )
}

Simple.propTypes = {
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
}
Simple.defaultProps = {
  onSubmit: () => { },
  loading: false
}
export default Simple;