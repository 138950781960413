import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next';
import { List, Strong } from 'gigapay-ui';
import { formatPrice, numberWithSpaces } from 'utils/price';
import { Placeholder as P } from 'components/'

import { payoutFeePercent } from 'config';
import css from './details.module.css';

const PlaceholderDetails = ({ ready }) => (
  <div className={css.placeholder}>
    <ul className={css.payouts}>
      <div className={css.row}>
        <div className={css.item}><P.Text>j</P.Text></div>
        <div className={css.item}><P.Text style={{ maxWidth: 40 }}>-</P.Text></div>
      </div>
      <div className={css.row}>
        <div className={css.item}><P.Text>j</P.Text></div>
        <div className={css.item}><P.Text style={{ maxWidth: 40 }}>-</P.Text></div>
      </div>
    </ul>
  </div>
);

const PT = ({ children, ready = false, style = {} }) => (
  <P.Text ready={ready} className={css.t} style={{ display: 'block', float: 'left', width: 100, marginTop: 5, ...style }}>
    {children}
  </P.Text>
);

const Details = ({ payouts, loading, totalAmount, totalTax, canBeAccepted, totalFee }) => {
  const { t } = useTranslation(['accept', 'common']);

  const show_specification = (payouts.length === 1);
  const full_specification = show_specification && (payouts[0].payroll !== null);

  return (
    <div>
      <div className="accept-thead">
        <P.Text ready={!loading} style={{ float: 'left', maxWidth: 50 }}>
          <span className="employer">{t('From')}</span>
        </P.Text>
        <P.Text ready={!loading} style={{ float: 'right', maxWidth: 50 }}>
          <span className="amount">{full_specification ? t('common:Amount') : t('accept:GrossSalary')}</span>
        </P.Text>
      </div>

      {loading && <PlaceholderDetails />}
      {!loading && (
        <ul className="payout-company-list">
          {payouts.map(item => (
            <li key={item.id}>
              <PT ready={true}>
                <div className="employer">
                  <span>{item.employer} ({item.description})</span>
                  {!show_specification && <a href={`/ok/payout/?id=${item.id}`} className="salary-specification">{t('common:Salary specification')}</a>}
                </div>
              </PT>
              {(payoutFeePercent !== null && !canBeAccepted) ?
                <span className="amount">{formatPrice(Math.round(parseFloat(item.fee) + parseFloat(item.amount)))} kr</span> :
                <span
                  className="amount">{full_specification ? numberWithSpaces(item.cost) : numberWithSpaces(item.amount)} kr</span>
              }
            </li>
          ))}
        </ul>
      )}

      {(!loading && full_specification) &&(
        <List
          small
          items={[
            {
              label: <PT ready={!loading}><span>{t('common:Payroll tax')}</span></PT>,
              value: <PT style={{ width: 50 }} ready={!loading}><span>-{numberWithSpaces((payouts.length && payouts[0].payroll) ? payouts[0].payroll : "0.00" )} kr</span></PT>,
              key: 'payroll_tax'
            },
            {
              label: <PT ready={!loading}><span>{t("common:Gigapay's fee")}</span></PT>,
              value: <PT style={{ width: 50 }} ready={!loading}><span>-{numberWithSpaces(payouts[0].fee)} kr</span></PT>,
              key: 'fee'
            },
          ]}
        />
      )}

      <div className="spacing" />
      <List
        small
        items={[
          ...((payoutFeePercent === null) ? [
            {
              label: (
                <Strong>
                  <PT ready={!loading}>
                    <span>{t('accept:GrossSalary')}</span>
                    <small className="payout-small">
                      {t('accept:Whereof 12% vacation pay')}:
                    </small>
                  </PT>
                </Strong>
              ),
              value: (
                <Strong>
                  <PT style={{ width: 50 }} ready={!loading}>
                    <span>{formatPrice(totalAmount)} kr</span>
                    <small className="payout-small amount">
                      {formatPrice(0.1071 * parseFloat(totalAmount))} kr
                    </small>
                  </PT>
                </Strong>
              ),
              key: 'gross'
            },

            {
              label: <PT ready={!loading}><span>{t('common:Tax')}</span></PT>,
              value: <PT style={{ width: 50 }} ready={!loading}><span>-{numberWithSpaces(totalTax)} kr</span></PT>,
              key: 'tax'
            },

            {
              label:
              (
                <Strong>
                  <PT ready={!loading}>
                    {t('common:Net Pay')}
                  </PT>
                </Strong>
              ),
              value: <PT style={{ width: 50 }} ready={!loading}><span className="payout-amount">{formatPrice(parseFloat(totalAmount) - parseFloat(totalTax))} kr</span></PT>,
              key: 'netpay'
            }
            ]: []
          ),
           ...((payoutFeePercent !== null && canBeAccepted) ? [
             {
              label: (
                <Strong>
                  <PT ready={!loading}>
                    <span>{t('accept:GrossSalary')}</span>
                    <small className="payout-small">
                      {t('accept:Whereof 12% vacation pay')}:
                    </small>
                  </PT>
                </Strong>
              ),
              value: (
                <Strong>
                  <PT style={{ width: 50 }} ready={!loading}>
                    <span>{formatPrice(totalAmount)} kr</span>
                    <small className="payout-small amount">
                      {formatPrice(0.1071 * parseFloat(totalAmount))} kr
                    </small>
                  </PT>
                </Strong>
              ),
              key: 'gross'
            },

            {
              label: <PT ready={!loading}><span>{t('common:Tax')}</span></PT>,
              value: <PT style={{ width: 50 }} ready={!loading}><span>-{numberWithSpaces(totalTax)} kr</span></PT>,
              key: 'tax'
            },

            {
              label: <PT ready={!loading}><span>{t('accept:Express fee')}</span></PT>,
              value: <PT style={{ width: 50 }} ready={!loading}><span>-{formatPrice(payoutFeePercent * (parseFloat(totalAmount) - parseFloat(totalTax)) / 100)} kr</span></PT>,
              key: 'express fee'
            },

            {
              label:
              (
                <Strong>
                  <PT ready={!loading}>
                    {t('common:Net Pay')}
                  </PT>
                </Strong>
              ),
              value: <PT style={{ width: 50 }} ready={!loading}><span className="payout-amount">{formatPrice(parseFloat(totalAmount) - parseFloat(totalTax) - payoutFeePercent * (parseFloat(totalAmount) - parseFloat(totalTax)) / 100)} kr</span></PT>,
              key: 'netpay'
            }

            ] : []),
          ...((payoutFeePercent !== null && !canBeAccepted) ? [
             {
              label: (
                <Strong>
                  <PT ready={!loading}>
                    <span>{t('accept:GrossSalary')}</span>
                    <small className="payout-small">
                      {t('accept:Whereof 12% vacation pay')}:
                    </small>
                  </PT>
                </Strong>
              ),
              value: (
                <Strong>
                  <PT style={{ width: 50 }} ready={!loading}>
                    <span>{formatPrice(Math.round(parseFloat(totalFee) + parseFloat(totalAmount)))} kr</span>
                    <small className="payout-small amount">
                      {formatPrice(0.1071 * 1.0526 * Math.round(parseFloat(totalFee) + parseFloat(totalAmount)))} kr
                    </small>
                  </PT>
                </Strong>
              ),
              key: 'gross'
            },

            {
              label: <PT ready={!loading}><span>{t('common:Tax')}</span></PT>,
              value: <PT style={{ width: 50 }} ready={!loading}><span>-{numberWithSpaces(totalTax)} kr</span></PT>,
              key: 'tax'
            },

            {
              label: <PT ready={!loading}><span>{t('accept:Express fee')}</span></PT>,
              value: <PT style={{ width: 50 }} ready={!loading}><span>-{numberWithSpaces((payouts.length && payouts[0].fee) ? payouts[0].fee : "0.00" )} kr</span></PT>,
              key: 'express fee'
            },

            {
              label:
              (
                <Strong>
                  <PT ready={!loading}>
                    {t('common:Net Pay')}
                  </PT>
                </Strong>
              ),
              value: <PT style={{ width: 50 }} ready={!loading}><span className="payout-amount">{numberWithSpaces(Math.round(parseFloat(totalAmount) - parseFloat(totalTax)))} kr</span></PT>,
              key: 'netpay'
            }

            ] : [])
          ]}
      />
      <div className="spacing" />
    </div>
  )
};

Details.propTypes = {
  payouts: PropTypes.array,
  loading: PropTypes.bool
};
Details.defaultProps = {
  payouts: [],
  loading: true
};

export default Details
