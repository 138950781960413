import { types, status } from '../constants/action-types';

const defaultEmployee = {
  list: {
    count: 0,
    next: null,
    previous: null,
    results: []
  },
  loading: false,
  createStatus: null,
  createError: null,
  updateStatus: null,
  updateError: null,

  importEmployees: {
    error: null,
    status: status.DEFAULT,
  },

  employee: {},
  claimCheck: {
    name: '',
    logo: null,
    message: '',
    loading: false,
    status: status.DEFAULT
  }
};

const employee = (state = defaultEmployee, action) => {
  switch (action.type) {

    case types.CHECK_CLAIM_RESET:
      return {
        ...state,
        claimCheck: {
          ...state.claimCheck,
          status: status.DEFAULT
        }
      };

    case types.CHECK_CLAIM:
      switch (action.meta.status) {
        case status.BEGIN:
          return {
            ...state,
            claimCheck: {
              name: '',
              logo: null,
              message: '',
              loading: true,
              status: status.BEGIN,
            },
          };
        case status.SUCCESS:
          return {
            ...state,
            claimCheck: {
              name: action.payload.name,
              logo: action.payload.logo,
              message: action.payload.message,
              loading: false,
              status: status.SUCCESS,
            },
          };
        case status.ERROR:
        case status.TIMEOUT:
          return {
            ...state,
            claimCheck: {
              name: '',
              logo: null,
              message: '',
              loading: false,
              status: status.ERROR
            },
          };
        default:
          return state;
      }

    case types.FETCH_EMPLOYEES:
      switch (action.meta.status) {
        case status.BEGIN:
          return {
            ...state,
            loading: true,
          };
        case status.SUCCESS:
          return {
            ...state,
            list: action.payload,
            loading: false,
          };
        case status.ERROR:
        case status.TIMEOUT:
          return {
            ...state,
            loading: false,
          };
        default:
          return state;
      }

    case types.CREATE_EMPLOYEE_RESET:
      return {
        ...state,
        createStatus: status.DEFAULT,
        createError: null,
      };

    case types.CREATE_EMPLOYEE:
      switch (action.meta.status) {
        case status.BEGIN:
          return {
            ...state,
            createStatus: status.BEGIN,
            createError: null,
          };
        case status.SUCCESS:
          return {
            ...state,
            list: {
              ...state.list,
              results: [action.payload, ...state.list.results],
              count: state.list.count + 1,
            },
            createStatus: status.SUCCESS
          };
        case status.ERROR:
        case status.TIMEOUT:
          return {
            ...state,
            createStatus: status.ERROR,
            createError: action.payload.error || null
          };
        default:
          return state;
      }

    case types.UPDATE_EMPLOYEE:
      switch (action.meta.status) {
        case status.BEGIN:
          return {
            ...state,
            updateStatus: status.BEGIN,
            updateError: null,
          };
        case status.SUCCESS:
          return {
            ...state,
            updateStatus: status.SUCCESS,
            list: {
              ...state.list,
              results: state.list.results.map(e => {
                if (e.id === action.payload.id) {
                  return action.payload
                }
                return e;
              }),
            },
            employee: {
              ...state.employee,
              [action.payload.id]: {
                ...state.employee[action.payload.id],
                ...action.payload
              }
            }
          };
        case status.ERROR:
        case status.TIMEOUT:
          return {
            ...state,
            updateStatus: status.ERROR,
            updateError: action.payload.error || null
          };
        default:
          return state;
      }
    case types.UPDATE_EMPLOYEE_RESET:
      return {
        ...state,
        updateStatus: status.DEFAULT,
        updateError: null,
      };

    case types.RESET_BATCH_CREATE_EMPLOYEE:
      return {
        ...state,
        importEmployees: {
          error: null,
          status: status.DEFAULT,
        },
      }

    case types.BATCH_CREATE_EMPLOYEE:
      switch (action.meta.status) {
        case status.BEGIN:
          return {
            ...state,
            importEmployees: {
              error: null,
              status: status.BEGIN,
            },
          };
        case status.SUCCESS:
          return {
            ...state,
            importEmployees: {
              error: null,
              status: status.SUCCESS,
            },
          };
        case status.ERROR:
        case status.TIMEOUT:
          return {
            ...state,
            importEmployees: {
              error: action.payload.error || {},
              status: status.ERROR,
            },
          };
        default:
          return state;
      }

    case types.FETCH_EMPLOYEE_PAYOUTS:
      switch (action.meta.status) {
        case status.BEGIN:
          return {
            ...state,
          };
        case status.SUCCESS:
          return {
            ...state,
            employee: {
              ...state.employee,
              [action.meta.params]: {
                ...state.employee[action.meta.params],
                payouts: action.payload,
              }
            }
          };
        case status.ERROR:
        case status.TIMEOUT:
          return {
            ...state,
          };
        default:
          return state;
      }

    case types.FETCH_EMPLOYEE:
      switch (action.meta.status) {
        case status.BEGIN:
          return {
            ...state,
          };
        case status.SUCCESS:
          return {
            ...state,
            employee: {
              ...state.employee,
              [action.payload.id]: {
                ...state.employee[action.payload.id],
                ...action.payload
              }
            }
          };
        case status.ERROR:
        case status.TIMEOUT:
          return {
            ...state,
          };
        default:
          return state;
      }


    default:
      return state;
  }
}
export default employee;