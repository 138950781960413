import bankid from './bankid-auth'
import { types } from './action-types'

import { login as tokenLogin } from '../../../../actions/user-login'

export const login = (setQr, isInApp) => (dispatch) => {
  dispatch(loginBegin())
  bankid
    .login(setQr, isInApp)
    .then((response) => {
      dispatch(tokenLogin(response.loginToken))
      dispatch(loginSuccess())
    })
    .catch((err) => {
      dispatch(loginCancel())
    })
}

export const resume = () => (dispatch) => {
  dispatch(loginBegin())
  bankid
    .resumeLogin()
    .then((response) => {
      dispatch(tokenLogin(response.loginToken))
      dispatch(loginSuccess())
    })
    .catch((error) => {
      dispatch(loginCancel())
    })
}

export const openApp = () => (dispatch) => {
  bankid
    .openApp()
    .then((response) => {
      dispatch(tokenLogin(response.loginToken))
      dispatch(loginSuccess())
    })
    .catch((error) => {
      dispatch(loginCancel())
    })
}

export const cancel = () => (dispatch) => {
  bankid.cancel()
  dispatch(loginCancel())
}

export const loginBegin = () => ({
  type: types.BANKID_LOGIN_BEGIN,
})

export const loginSuccess = () => ({
  type: types.BANKID_LOGIN_SUCCESS,
})

export const loginCancel = () => ({
  type: types.BANKID_LOGIN_ERROR,
})
