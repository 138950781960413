import React, { useEffect, useState, useRef } from 'react';
import {
  Subtitle,
  Button,
  Radio,
  Link,
  Paragraph, Dialog
} from 'gigapay-ui'
import { useTranslation } from 'react-i18next';
import {useHistory, useLocation} from "react-router-dom";
import _ from "lodash";
import { updateUserInfo } from 'actions/user';
import * as qs from "query-string";
import {connect} from "react-redux";


const LoginRedirect = () => {
  const { t } = useTranslation('invoices')
  const history = useHistory();
  const location = useLocation();
  const query = qs.parse(location.search);
  const url = `/login/?redirect_url=${encodeURIComponent(`${location.pathname}?token=${query.token}`)}`
  return (
    <div>
      <Subtitle small>{t('You are not logged in')}</Subtitle>
      <Paragraph>{t('In order to connect an invoice to your account you have to login.')}</Paragraph>
      <Button primary responsive onClick={() => history.push(url)}>{t('Login')} </Button>
    </div>
  )
};


const PickCompany = ({ companyList, selectCompany, connect }) => {
  const { t } = useTranslation('invoices')
  const [company, setCompany] = useState(null)
  const history = useHistory();
  return (
    <div>
      <Subtitle small>{t('common:Choose a company')}</Subtitle>
      <div className="spacing" />
      <div style={{ maxWidth: 250, margin: 'auto', display: 'block' }}>
        <Radio
          options={companyList.map(company => ({
            label: company.name,
            value: company.id,
          }))}
          value={company}
          onChange={company => {
            setCompany(company)
          }}
        />
        <div className="spacing" />
        <Button.Primary
          responsive
          onClick={() => {
            const currentCompany = company ? company : companyList[0].id;
            selectCompany({ currentCompany, mode: 'company' });
            connect()
          }}
        >
          {t('common:Select')}
        </Button.Primary>
        <Link
          onClick={() => history.push('/signup/company')}
          className={'action-link'}
          primary
          bold
        >{t('common:Register company')} +</Link>
      </div>
    </div>
  )
};


const CreateCompany = () => {
  const { t } = useTranslation('invoices')
  const history = useHistory();
  return (
    <div>
      <Subtitle small>{t('You have no company registered')}</Subtitle>
      <Paragraph>{t('In order to connect an invoice to your account you need to register a company')}</Paragraph>
      <Button primary responsive onClick={() => history.push('/signup/company')}>{t('Register company')} </Button>
    </div>
  )
};


const ConnectModal = ({ showDialog, closeDialog, connect, mode, hasCompany, companies, selectCompany, isAuthenticated }) => {

  const companyList = _.values(companies);

  return (
    <Dialog
      show={showDialog}
      onBackdropClick={() => closeDialog()}
      onEscapeKeyDown={() => closeDialog()}
    >
      {!isAuthenticated ?
          <LoginRedirect />
       : mode === 'person' && companyList.length > 0 ?
          <PickCompany selectCompany={selectCompany} companyList={companyList} connect={connect}/>
       : !hasCompany ?
          <CreateCompany />
       : closeDialog()
		}
    </Dialog>
  )
};


const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  mode: state.user.mode,
  hasCompany: state.user.hasCompany,
  companies: state.user.companies,
});


const mapDispatchToProps = dispatch => ({
  selectCompany: (data) => {
    dispatch(updateUserInfo(data));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ConnectModal);
