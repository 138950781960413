import React from 'react'
import PropTypes from 'prop-types'
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Route, Switch, useLocation, Redirect } from "react-router-dom";
import styles from './transitionStyle.module.css';

const TransitionItem = ({ children }) => {
  return (
    <div className={styles.pane}>{children}</div>
  )
};
TransitionItem.propTypes = {
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  exact: PropTypes.bool,
};
TransitionItem.defaultProps = {
  path: "/",
  exact: true
};


const Transition = ({ children, root, defaultPath }) => {
  const location = useLocation();
  const child = Array.isArray(children) ? children : [children];

  return (
    <TransitionGroup style={{ position: 'relative' }}>
      <CSSTransition
        key={location.key}
        classNames={{ ...styles }}
        timeout={550}
      >
        <Switch location={location}>
          {child.map(c => {
            const path = c.props.path;
            const exact = c.props.exact;
            const url = typeof path === 'function' ? path(root) : path;
            return (
              <Route key={url} exact={exact} path={url} children={c} />
            )
          })}
          { defaultPath && <Redirect to={defaultPath} />}
        </Switch>
      </CSSTransition>
    </TransitionGroup >
  )
};
Transition.Item = TransitionItem;

Transition.propTypes = {
  root: PropTypes.string,
  defaultPath: PropTypes.string,
};
Transition.defaultProps = {
  root: "/",
  defaultPath: null
};

export default Transition;