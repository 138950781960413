import {
  types,
  status,
} from '../constants/action-types';

const dash = {
  loadingStatistic: false,
  statistic: {},
  receivedPayouts: {
    hasNext: false,
    loading: false,
    count: 0,
    data: [],
  },
  toApprovePayouts: {
    hasNext: false,
    loading: false,
    count: 0,
    data: [],
  },
  invoices: {
    hasNext: false,
    loading: false,
    count: 0,
    data: [],
  },
  dispatchedPayouts: {
    hasNext: false,
    loading: false,
    count: 0,
    data: [],
  },
  loading: false,
  error: null,
};


const data = (state = dash, action) => {
  switch (action.type) {
   
    case types.FETCH_DISPATCED:
      switch (action.meta.status) {
        case status.BEGIN:
          return {
            ...state,
            dispatchedPayouts: {
              hasNext: false, 
              count: 0,
              loading: true,
              data: (action.meta.params && action.meta.params.reset) ? [] : [ ...state.dispatchedPayouts.data ],
            }
          };
        case status.SUCCESS:
          return {
            ...state,
            dispatchedPayouts: {
              hasNext: action.payload.next !== null, 
              count: action.payload.count,
              loading: false,
              data: [ ...state.dispatchedPayouts.data, ...action.payload.results],
            }
          };
        case status.ERROR:
          return {
            ...state,
            dispatchedPayouts: {
              ...state.dispatchedPayouts,
              loading: false,
            }
          };
        default:
          return state;
      }
    
    case types.FETCH_STATISTIC:
      switch (action.meta.status) {
        case status.BEGIN:
          return {
            ...state,
            loadingStatistic: true,
            statistic: {},
          };
        case status.SUCCESS:
          return {
            ...state,
            loadingStatistic: false,
            statistic: action.payload,
          };
        case status.ERROR:
          return {
            ...state,
            loadingStatistic: false,
            statistic: {},
          };
        default:
          return state;
      }

    case types.FETCH_COMPANY_INVOICES:
      switch (action.meta.status) {
        case status.BEGIN:
          return {
            ...state,
            invoices: {
              hasNext: false, 
              count: 0,
              loading: true,
              data: (action.meta.params && action.meta.params.reset) ? [] : [ ...state.invoices.data ],
            }
          };
        case status.SUCCESS:
          return {
            ...state,
            invoices: {
              hasNext: action.payload.next !== null, 
              count: action.payload.count,
              loading: false,
              data: [ ...state.invoices.data, ...action.payload.results],
            }
          };
        case status.ERROR:
          return {
            ...state,
            invoices: {
              ...state.invoices,
              loading: false,
            }
          };
        default:
          return state;
      }
    
    case types.FETCH_RECEIVED:
      switch (action.meta.status) {
        case status.BEGIN:
          return {
            ...state,
            receivedPayouts: {
              hasNext: false, 
              count: 0,
              loading: true,
              data: (action.meta.params && action.meta.params.reset) ? [] : [ ...state.receivedPayouts.data ],
            }
          };
        case status.SUCCESS:
          return {
            ...state,
            receivedPayouts: {
              hasNext: action.payload.next !== null, 
              count: action.payload.count,
              loading: false,
              data: [ ...state.receivedPayouts.data, ...action.payload.results],
            }
          };
        case status.ERROR:
          return {
            ...state,
            receivedPayouts: {
              ...state.receivedPayouts,
              loading: false,
            }
          };
        default:
          return state;
      }
    
    case types.FETCH_TO_APPROVE:
      switch (action.meta.status) {
        case status.BEGIN:
          return {
            ...state,
            toApprovePayouts: {
              hasNext: false, 
              count: 0,
              loading: true,
              data: (action.meta.params && action.meta.params.reset) ? [] : [ ...state.toApprovePayouts.data ],
            }
          };
        case status.SUCCESS:
          return {
            ...state,
            toApprovePayouts: {
              hasNext: action.payload.next !== null, 
              count: action.payload.count,
              loading: false,
              data: [ ...state.toApprovePayouts.data, ...action.payload.results],
            }
          };
        case status.ERROR:
          return {
            ...state,
            toApprovePayouts: {
              ...state.toApprovePayouts,
              loading: false,
            }
          };
        default:
          return state;
      }

    default:
      return state;
  }
}
export default data;