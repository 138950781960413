import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom'
import * as qs from 'query-string';
import { Title, Icon, Paragraph, Alert } from 'gigapay-ui';
import { claimEmployee } from 'actions/employee';
import { updateUserInfo } from 'actions/user'
import PayoutBackends from 'shared/PayoutBackends/'
import { status } from 'constants/action-types';
import css from './bankinfo.module.css';
import { format as formatUrl } from 'utils/url';


const BankInfo = ({
  claimStatus,
  claimEmployee,
  updateUserInfo,
  payoutBackends
}) => {
  const [showError, setShowError] = useState(false);
  const { t } = useTranslation(['accept', 'settings']);
  const location = useLocation();
  const history = useHistory();
  const query = qs.parse(location.search);
  const hasPaymentMethod = Boolean(payoutBackends.preferred_payment_method);

  useEffect(() => {
    if ((payoutBackends.status === status.SUCCESS) && hasPaymentMethod) {
      claimEmployee(query.c)
    }
  }, [payoutBackends.status]);

  useEffect(() => {
    if (claimStatus === status.SUCCESS) {
      if (query.id) {
        history.push(formatUrl(`/ok/payout/`, { id: query.id }))
      } else {
        history.push(formatUrl(`/ok/claimed/`, { auto: query.auto }))
      }
      updateUserInfo({ claimStatus: status.DEFAULT })
    }
    if (claimStatus === status.ERROR) {
      history.push(formatUrl(`/ok/claim/`, query));
      updateUserInfo({ claimStatus: status.DEFAULT })
    }
  }, [claimStatus]);

  return (
    <div className="content center">
      <div className={css.box}>
        <div className={css.iconContainer}>
          <Icon name={'bank'} size={50} color={'#000'} />
        </div>
        <Title small margins={false}>{t('settings:Your bank information')}</Title>
        {!query.c && (
          <Alert.Error>
            {t('Incorrect token.')}
          </Alert.Error>
        )}
        {query.c && (
          <>
            <Paragraph>{t('settings:Here you fill in the account that you want your salary to be paid to')}</Paragraph>
            <PayoutBackends/>
            {
              showError && (
                <Alert.Error>
                  {t('settings:Incorrect bank details.')}
                </Alert.Error>
              )
            }
          </>
        )}
      </div>
    </div>
  )
};

const mapStateToProps = (state) => ({
  claimStatus: state.user.claimStatus,
  payoutBackends: state.payoutBackends
});

const mapDispatchToProps = {
  claimEmployee,
  updateUserInfo
};



export default connect(mapStateToProps, mapDispatchToProps)(BankInfo);