import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { Dialog, Subtitle, Paragraph, Button, Icon } from 'gigapay-ui';
import { configFile } from 'config';

import css from './dropdown.module.css';
import face from '../../../../assets/images/friendly-face-simple-yellow.jpg';

/**
 * Component that alerts if you click outside of it
 */
class DropDownMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      showDialog: false,
    }
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    document.addEventListener('touchstart', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    document.removeEventListener('touchstart', this.handleClickOutside);
  }

  /**
   * Set the wrapper ref
   */
  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      if (this.state.visible) {
        this.setState({ visible: false });
      }
    }
  }

  show = (e) => {
    this.setState({ visible: !this.state.visible });
  }

  logout = (e) => {
    e.preventDefault();
    this.props.logout();
    this.props.history.push('/login')
    //this.context.router.history.push("/login");
  }

  switchMode(mode, currentCompany = null) {
    this.props.switchMode({ mode, currentCompany });
    this.props.history.push('/dashboard')
    this.setState({ visible: false });
  }

  getFirstLetter(str) {
    return (str && str.length !== 0) ? str.charAt(0) : ''
  }
  render() {

    if (!this.props.isAuthenticated) return null;

    const { t } = this.props;
    const { visible, showDialog } = this.state;
    const toggleClass = visible ? `${css.profileDropdown} ${css.show}` : css.profileDropdown;

    const { first_name, companies, mode, currentCompany, companyLeads } = this.props;
    const companyList = _.values(companies);
    const hasCompany = companyList.length !== 0 || companyLeads.length !== 0;
    const activeCircle = (mode === 'person') ?
      <div className={css.activeCircle}><img src={face} className={css.happyFace} alt="Emoji" /></div> :
      <div className={`${css.activeCircle} ${css.companyCircle}`}>{this.getFirstLetter((currentCompany && companies[currentCompany].name) || '')}</div>;
    const inactiveCircle = (mode === 'company') ? <div className={css.inactiveCircle} ><img src={face} className={css.happyFace} /></div> : null;
    const nameField = (first_name !== '') ? first_name : t('Your profile');

    return (
      <React.Fragment>
        <Dialog
          show={showDialog}
          onBackdropClick={() => this.setState({ showDialog: false })}
          onEscapeKeyDown={() => this.setState({ showDialog: false })}
        >
          <Subtitle>Ditt företags behandlas</Subtitle>
          <Paragraph>Ditt företags behandlas. Detta tar normalt inte mer än 24h. Vi kontaktar dig när företaget är verifierat.</Paragraph>
          <Button primary responsive onClick={() => this.setState({ showDialog: false })}>
            OK
          </Button>
        </Dialog>

        <div className={css.logout} ref={this.setWrapperRef}  >
          <div onClick={this.show} className={`clearfix ${css.trigger}`} style={{ cursor: 'pointer' }}>
            <div className={css.circleContainer}>
              {activeCircle}
              {inactiveCircle}
            </div>
            <div className={`${css.arrow} ${toggleClass}`}><Icon size={20} color={'#515151'} name={'arrow-down'} /></div>
          </div>
   
          <ul className={toggleClass}>

            <li>

              {mode === 'person' && <a href="/dashboard" className={css.userInfo}>
                <span className={css.userName}>
                  {nameField}
                </span>
                <span className={css.userEmail}>{t('Personal')}</span>
              </a>}


              {mode === 'company' && <a href="/dashboard" className={css.userInfo}>
                <span className={css.userName}>
                  {(currentCompany && companies[currentCompany].name) || 'Your company'}
                </span>
                <span className={css.userEmail}>{t('Company')}</span>
              </a>}

            </li>
            {hasCompany && <li className={css.withSublist}>
              <ul className={css.subList}>
                {
                  companyList.filter(company => company.id !== currentCompany)
                    .map(company => (
                      <li key={`company-${company.id}`}>
                        <span className={css.a}
                          onClick={(e) => {
                            e.preventDefault();
                            this.switchMode('company', company.id);
                          }}
                        >
                          <span>
                            {company.name}
                          </span>
                        </span>
                      </li>
                    ))
                }

                {mode === 'company' && (
                  <li>
                    <span className={css.a} onClick={(e) => this.switchMode('person', null)}>
                      {nameField}
                      <span className={css.modeLabel}> • {t('Personal')}</span>
                    </span>
                  </li>
                )}
                {
                  companyLeads
                    .filter(company => _.findIndex(companyList, (o) => o.organisational_number === company.organisational_number) < 0)
                    .map(company => (
                      <li key={`company-${company.organisational_number}`}>
                        <span className={`${css.a} ${css.disabled}`} onClick={() => this.setState({ showDialog: true })}>
                          <span>
                            {company.name}
                          </span>
                          <span className={css.waiting}>{t('Processing')}</span>
                        </span>
                      </li>
                    ))
                }
              </ul>
            </li>}
            {(configFile !== 'payhour') && (
            <li className={css.withSublist}>
              <ul className={css.subList}>
                <li><span className={css.a}
                          onClick={() => this.props.history.push('/signup/company')}>{t('Create company')} +</span></li>
              </ul>
            </li>)}
            <li className={css.withSublist}>
              <ul className={css.subList}>
                <li><a target='_blank' href={t('termsLink')}>{t('Terms of Use')}</a></li>
                <li><a href={t('dataLink')} target='_blank'>{t('Privacy Policy')}</a></li>
              </ul>
            </li>
            <li className={css.logoutLink}><a href="#" onClick={this.logout}>{t('Log out')} <Icon name={'arrow-forward'} /></a></li>

          </ul>
        </div>
      </React.Fragment>
    )
  }
}
DropDownMenu.propTypes = {
  first_name: PropTypes.string,
};

DropDownMenu.defaultProps = {
  first_name: ''
}

DropDownMenu.contextTypes = {
  router: PropTypes.object
};

export default withTranslation('common')(withRouter(DropDownMenu))