
import {
    types
} from '../constants/action-types';
import { get } from '../utils/fetchV3';

const baseUrl = (parms = '') => config =>
  `${config.baseUrl}/${parms}`;

export const fetchPayoutsByInvoice = (invoiceId) => 
  get(types.FETCH_COMPANY_INVOICE_PAYOUTS, baseUrl(`payouts/?expand=employee&invoice=${invoiceId}&page_size=200`));
   
export const fetchInvoice = (invoiceId) => 
  get(types.FETCH_COMPANY_INVOICE, baseUrl(`invoices/${invoiceId}/`));

