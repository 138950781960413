import React, { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import _ from "lodash";
import { Fieldset, Input, Icon, ToolTip, Button, Loader, Link } from "gigapay-ui";

import { updateSwishBackend, updatePreferredPayoutBackend } from "actions/payout-backends";
import { status } from 'constants/action-types';
import { validCellPhoneNumber } from "utils/phoneNumbers";
import { formatCellphoneNumber } from "./utils";

import css from './swish.module.css'


const ViewSwishBackend = ({swishBackend, setEditView}) => {
    const {t} = useTranslation('common');
    return (
      <div className={css.viewPayhourDetails}>
        <Icon name={"swish"} size={24}/>
        <span>{swishBackend?.cellphone_number}</span>
        <Link onClick={() => setEditView(true)} className={css.edit}>{t('Edit')}</Link>
      </div>
    )
  };

const ErrorIcon = ({ message }) => {
return (
    <ToolTip
    label={(
        <div className={css.errorMessage}>
        <Icon name={'alert'} size={13} color="#b50707"/>
        </div>
    )}
    width="200px"
    text={message}
    style={{position:"absolute", bottom:"3px", margin: 0}}
    />
)
};


const SwishBackend = ({ backend: swishBackend, active}) => {
    const dispatch = useDispatch();
    
    const { t } = useTranslation('common');
    const cellphoneNumberRef = useRef(null);
    const [editView, setEditView] = useState(false);
    const [cellphone_number, setCellPhoneNumber] = useState(
        swishBackend?.cellphone_number
    );

    // switch away from the edit view when you have saved
    useEffect(() => {
        if (swishBackend.status === status.SUCCESS && !swishBackend.loading && editView) {
            setEditView(false);
        }
        if (swishBackend.status === status.DEFAULT && !swishBackend.cellphone_number){
            setEditView(true);
        }
    }, [swishBackend]);

    /* When we enter the payout screen, we send active true 
    to update the preferred method to swish */
    useEffect(() => {
        active&&dispatch(updatePreferredPayoutBackend({preferred_payment_method: 'swish'}))
    }, [active, dispatch])
    
    /** Modify error message container style to be responsive depending on a device's width.
     * This is a temporary hack to avoid big changes on paymentMethodContainer (overflow: hidden)
     * and gigapay-ui Input component.
     */

    const windowWidth = window.innerWidth;
    const styleOnSmallDevice = {
        fontSize: "12px",
        width: "100px",
        bottom: "-10px",
        marginLeft: "5px",
    };
    const errorStyle =
        windowWidth > 515
            ? { fontSize: "13px", bottom: "18px", width: "200px" }
            : { ...styleOnSmallDevice };

    const Submit = () => {
        dispatch(updateSwishBackend({ cellphone_number }));

    }

    const onNumberChange = (cellphone_number) => {
        cellphone_number = formatCellphoneNumber(cellphone_number);
        setCellPhoneNumber(cellphone_number);
    };


    return <div className={css.spacing}>
    {editView &&(
        <Fieldset>
            <Fieldset.Row className={css.fieldSetRow}>
                <Input
                    label={<p>{t("Your Swish Number")} {swishBackend?.error?.cellphone_number && <ErrorIcon message={swishBackend?.error?.cellphone_number}/>}</p>}
                    inputmode="tel"
                    value={cellphone_number}
                    onChange={(value) => onNumberChange(value)}
                    ref={cellphoneNumberRef}
                    leftIcon={<Icon name={"swish"} size={24} />}
                    leftIconStyle={{
                        width: "auto",
                        paddingTop: "2px",
                        paddingLeft: "10px",
                    }}
                    inputStyle={{ paddingLeft: "100px" }}
                    labelStyle={{ left: "100px" }}
                    validate={(i) => validCellPhoneNumber(i)}
                    errorCache={false}
                    errorStyle={errorStyle}
                    rightIcon={
                        <Button.Primary
                          onClick={Submit}
                          className={css.save}
                          disabled={!validCellPhoneNumber(cellphone_number)}
                        >
                          {swishBackend['loading'] ? <Loader small color={'#fff'}/> : t('Use')}
                        </Button.Primary>
                      }
                      rightIconStyle={{width: "76px", zIndex:2}}
                />
            </Fieldset.Row>
        </Fieldset>
    )}
    {!editView && (
      <ViewSwishBackend
        swishBackend={swishBackend}
        setEditView={setEditView}
      />
    )}
    </div>
};

export default SwishBackend;
