import { host } from '../../../../config'
const url = `${host}/auth/bankid`

class bankidAuth {
  showLog = false

  status = {
    DEFAULT: 'DEFAULT',
    IN_PROGRESS: 'IN_PROGRESS',
    COMPLETED: 'COMPLETED',
  }

  collecting = false
  currentStatus =
    localStorage.getItem('gigapay-auth-v2') === null
      ? {
          status: this.status.DEFAULT,
          time: new Date(),
          type: 'sign',
          ref: null,
          data: null,
        }
      : JSON.parse(localStorage.getItem('gigapay-auth-v2'))

  user =
    localStorage.getItem('gigapay-user-v2') === null
      ? {
          token: null,
          personal_number: null,
          created: null,
        }
      : JSON.parse(localStorage.getItem('gigapay-user-v2'))

  canResumeLogin = () =>
    this.currentStatus.type === 'login' &&
    this.currentStatus.status === this.status.IN_PROGRESS &&
    !this.collecting
  canResumeSign = () =>
    this.currentStatus.type === 'sign' &&
    this.currentStatus.status === this.status.IN_PROGRESS &&
    !this.collecting

  updateLoginStatus(status, payload = {}) {
    const { ref = null, data = null } = payload
    this.currentStatus = {
      status,
      time: new Date().getTime(),
      ref,
      data,
      type: 'login',
    }
    localStorage.setItem('gigapay-auth-v2', JSON.stringify(this.currentStatus))
  }

  /*
   * LOGIN
   */
  login = (setQr, isInApp = false) =>
    new Promise((resolve, reject) => {
      try {
        this._startLogin()
          .then((resp_data) => {
            const { orderRef } = resp_data

            if (typeof orderRef === 'undefined') {
              this.updateLoginStatus(this.status.DEFAULT)
              reject('invalid_ref')
              return
            }
            this.updateLoginStatus(this.status.IN_PROGRESS, {
              ref: resp_data,
            })
            const { autoStartToken } = this.currentStatus.ref

            if (isInApp) {
              // On iOS we need to explicitely redirect the user back to our page.
              const redirect =
                /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
                  ? encodeURIComponent(window.location.href)
                  : 'null'

              window.location.href = `bankid:///?autostarttoken=${autoStartToken}&redirect=${redirect}`
            }
            this._collectLogin(orderRef, setQr)
              .then((user) => {
                resolve(user)
              })
              .catch((err) => {
                reject()
              })
          })
          .catch((err) => {
            reject(err)
          })
      } catch (err) {
        reject(err)
      }
    })

  _startLogin = () =>
    new Promise((resolve, reject) => {
      try {
        fetch(`${url}/authenticate/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
        })
          .then((res) => res.text())
          .then((res) => resolve(JSON.parse(res)))
          .catch((error) => {
            console.error(error)
            reject()
          })
      } catch (err) {
        console.error('err', err)
        reject(err)
      }
    })

  _collectLogin = (orderRef, setQr) =>
    new Promise((resolve, reject) => {
      // Periodically try collecting the token (i.e. login was successful).

      const collectUntilSuccess = () => {
        if (this.currentStatus.status !== this.status.IN_PROGRESS) {
          this.collecting = false
          reject()
          return
        }

        if (!this.collecting) {
          return
        }

        this._checkLogin(orderRef)
          .then((data) => {
            if (this.showLog) {
            }
            if (data.errors) {
              this.updateLoginStatus(this.status.DEFAULT)
              reject('user_canceled')
              return
            }
            // check if login is done
            if (data.loginToken) {
              this.updateLoginStatus(this.status.COMPLETED)
              resolve(data)
              return
            }
            if (data?.qrData) {
              setQr(data.qrData)
            }
            // check if user pressed cancel
            if (this.currentStatus.status !== this.status.IN_PROGRESS) {
              reject('user_canceled')
              this.updateLoginStatus(this.status.DEFAULT)
              return
            }
            if (data.status === 'failed') {
              reject('failed')
              this.updateLoginStatus(this.status.DEFAULT)
              return
            }
            if (data.status === 'pending') {
              setTimeout(collectUntilSuccess, 1000)
            }
          })
          .catch((err) => {
            if (err.networkError) {
              return setTimeout(collectUntilSuccess, 1000)
            }
            this.cancel()
          })
      }

      this.collecting = true
      collectUntilSuccess()
    })

  _checkLogin = (order_ref) =>
    new Promise((resolve, reject) => {
      const data = { order_ref }
      fetch(`${url}/collect/`, {
        method: 'POST', // or 'PUT'
        headers: {
          'Content-Type': 'application/json',
        },
        body: data && JSON.stringify(data),
      })
        .then((res) => res.text())
        .then((res) => resolve(JSON.parse(res)))
        .catch((error) => console.error('Error:', error))
    })

  resumeLogin = () =>
    new Promise((resolve, reject) => {
      // check if the date is not close to now
      const mseconds = 2 * 60 * 1000
      if (new Date() - new Date(this.currentStatus.time) > mseconds) {
        this.cancel()
        reject()
      }

      // check orderRef
      if (this.currentStatus.ref === null) {
        reject()
      }
      const { orderRef } = this.currentStatus.ref
      this._collectLogin(orderRef)
        .then((user) => {
          resolve(user)
        })
        .catch((err) => {
          reject()
        })
    })

  cancel = () => {
    // check orderRef
    if (this.currentStatus.ref === null) {
      return
    }
    const { orderRef } = this.currentStatus.ref
    if (typeof orderRef === 'undefined') {
      return
    }
    this.currentStatus = this.status.DEFAULT
    const data = { order_ref: orderRef }
    fetch(`${url}/cancel/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: data && JSON.stringify(data),
    })
      .then((res) => res.text())
      .then((res) => {
        this.updateLoginStatus(this.status.DEFAULT)
      })
      .catch((error) => console.error('Error:', error))
  }

  openApp = () =>
    new Promise((resolve, reject) => {
      // Only trigger when signature is in progress
      if (this.currentStatus.status !== this.status.IN_PROGRESS) return

      try {
        // Only trigger when valid autostarttoken
        if (!this.currentStatus.ref.autoStartToken) return
        const { autoStartToken } = this.currentStatus.ref
        if (typeof autoStartToken === 'undefined') return

        // Stop polling. This prevents issues on Safari-iOS as the pages is refreshed,
        // possibly in the middle of a request, when we're redirected back. Start
        // polling again after 10 sek, in case the user decides to switch manually.
        this.collecting = false
        const { orderRef } = this.currentStatus.ref
        setTimeout(() => {
          this._collectLogin(orderRef)
            .then((user) => {
              resolve(user)
            })
            .catch((err) => {
              reject()
            })
        }, 10000)

        // Only trigger on mobile devices.
        if (/Mobi/.test(navigator.userAgent)) {
          // On iOS we need to explicitely redirect the user back to our page.
          const redirect =
            /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
              ? encodeURIComponent(window.location.href)
              : 'null'
          window.location = `bankid:///?autostarttoken=${autoStartToken}&redirect=${redirect}`
        }
      } catch (err) {
        return false
      }
    })
}

const auth = new bankidAuth()
if (process.env.NODE_ENV === 'development') {
  auth.showLog = true
}

export default auth
