export const types = {
  BANKID_LOGIN_BEGIN: 'BANKID_LOGIN_BEGIN',
  BANKID_LOGIN_ERROR: 'BANKID_LOGIN_ERROR',
  BANKID_LOGIN_SUCCESS: 'BANKID_LOGIN_SUCCESS',
};

export const status = {
  DEFAULT: 'DEFAULT',
  BEGIN: 'BEGIN',
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS',
  TIMEOUT: 'TIMEOUT',
};