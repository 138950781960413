import React, { useEffect, useState, useRef } from 'react';
import {
  Title,
  Subtitle,
  Paragraph,
  Button,
  Table,
  Icon,
  Dialog,
  Loader,
  Fieldset,
  Input,
  ContextMenu,
  Alert
} from 'gigapay-ui'
import * as qs from 'query-string';
import { Row, Text, Title as PTitle, Table as PTable } from 'components/Placeholder/'
import { Shield, Avatar as UserAvatar } from 'components/';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CreateEmployee from './components/CreateEmployee/';
import ActionBar from './components/ActionBar/'
import { fetchEmployees, createEmployee, createEmployeeReset } from 'actions/employee';
import css from './employees.module.css';
import { status } from '../../constants/action-types';

const AddButton = ({ onClick }) => {
  const { t } = useTranslation('employees');
  const [showContextMenu, setShowContextMenu] = useState(false)
  return (
    <div style={{ position: 'relative', flex: 1 }}>
      <Button
        responsive
        onClick={() => {
          setShowContextMenu(true)
        }}
        primary
      >
        {t('Add recipient')} +
              </Button>
      <ContextMenu
        visible={showContextMenu}
        arrow
        onBlur={() => setShowContextMenu(false)}
        style={{ top: 55 }}
      >
        <ContextMenu.Item
          onClick={() => onClick(0)}
          Icon={<Icon name={'create'} size={14} />}
        >
          <span>{t('Single')}</span>
        </ContextMenu.Item>

        <ContextMenu.Item
          onClick={() => onClick(1)}
          Icon={<Icon name={'cloud-upload'} size={14} />}
        >
          <span>{t('Import')}</span>
        </ContextMenu.Item>


      </ContextMenu>
    </div>
  )
}


const Employees = ({
  createEmployee,
  createEmployeeReset,
  createStatus,
  createError,
  fetchEmployees,
  list,
  loading }) => {
  const { t } = useTranslation('employees');
  const [showDialog, setShowDialog] = useState(false)
  const [showSuccess, setSuccessAlert] = useState(false)
  const [pickedEmployees, setPickedEmployees] = useState([])
  const history = useHistory()
  const location = useLocation();
  const query = qs.parse(location.search);

  useEffect(() => {
    fetchEmployees({ page_size: 400 });
    setSuccessAlert(false)
  }, []);
  useEffect(() => {
    if (createStatus === status.SUCCESS) {
      setShowDialog(false)
      setSuccessAlert(true)
    }
  }, [createStatus])

  const closeDialog = () => {
    createEmployeeReset();
    setShowDialog(false)
  }
  const isReady = !(loading && list.length === 0);

  return (
    <>
      <Dialog
        show={showDialog}
        onBackdropClick={() => closeDialog()}
        onEscapeKeyDown={() => closeDialog()}
        style={{ paddingTop: 12 }}
      >
        <CreateEmployee
          createError={createError}
          onSubmit={(data) => {
            createEmployee(data)
          }}
        />
      </Dialog>
      <div className="content">
        <div className="flex-row-md">
          <div className="flex-col-md-9">
            <PTitle large ready={isReady}>
              <Title.Line margins={false}>{t('Team')}</Title.Line>
              <span className={css.subtitle}>{t('You have {{x}} recipients.', { x: list.length })}</span>
            </PTitle>
          </div>
          <div className="flex-col-md-3 vcenter" >
            <AddButton
              onClick={(index) => {
                if (index === 0) {
                  // simple'
                  setShowDialog(true)
                } else {
                  // from fil
                  history.push('/employees/import')
                }
              }}
            />
          </div>
        </div>
        <div className="spacing" style={{marginBottom: 30}} />
        {
          query.numberOfImported && (
            <Alert.Success style={{ marginBottom: 16 }}>
              {t('{{x}} recipients have been imported.', { x: query.numberOfImported })}
            </Alert.Success>
          )
        }
        {showSuccess && (
          <Alert.Success style={{ marginBottom: 16 }}>
            {t('A new recipient has been added')}
          </Alert.Success>
        )}



        {(loading || (!loading && list.length !== 0)) && (
          <div className="flex-row-md">
            <div className="flex-col-md-1">
              <PTable ready={isReady} cols={3}>
                <Table
                  selectable
                  onChange={(rows) => {
                    setPickedEmployees(rows)
                  }}
                  keyExtractor={(item) => item.id}
                  data={list}
                  onClick={(props) => history.push("/employees/" + props.id)}
                  columns={[{
                    Header: t('common:Name'),
                    Cell: props => (
                      <div className="cell-container">
                        <div style={{ display: 'inline-block', marginRight: 8 }}>
                          <UserAvatar name={props.name} />
                        </div>
                        <span>
                          {
                            props.name !== '' ?
                              <span className="bold-mobile">{props.name}</span> :
                              <span style={{ fontStyle: 'italic', color: 'gray' }}>{t(`No name`)}</span>
                          }
                          <span className="mobileOnly table-subtitle">{props.cellphone_number}</span>
                        </span>
                      </div>),
                    id: 'name',
                    className: '',
                  }, {
                    Header: t('common:Phone number'),
                    Cell: props => <span>{props.cellphone_number}</span>,
                    id: 'phonenumber',
                    className: 'tableCell-desktopOnly',
                    headerClassName: 'tableCell-desktopOnly'
                  }, {
                    Header: t('common:Email'),
                    Cell: props => <span>{props.email || '-'}</span>,
                    id: 'date',
                    className: 'tableCell-desktopOnly',
                    headerClassName: 'tableCell-desktopOnly'
                  }, {
                    Header: t('Verified'),
                    id: 'verified',
                    className: '',
                    Cell: props => <Shield verified_at={props.verified_at} />
                  }, {
                    Header: null,
                    Cell: props => <Icon style={{ textAlign: 'right', marginRight: 16 }} name={'arrow-forward'} />,
                    id: 'arrow',
                    headerClassName: css.arrowCell,
                    className: css.arrowCell,
                  }]}
                />
              </PTable>
            </div>
          </div>
        )}

        <ActionBar
          itemCount={pickedEmployees.length}
          onClick={index => {
            if (index === 0) {
              history.push(`payout/new/?employee=${pickedEmployees.map(e => e.id).join(',')}`);
            } else {
              history.push(`payout/step/notice/?employee=${pickedEmployees.map(e => e.id).join(',')}`);
            }
          }}
        />

        {!loading && list.length === 0 && (
          <div style={{ minHeight: 200, textAlign: 'center' }}>
            <Subtitle small>{t('No recipients')}</Subtitle>
            <Paragraph>{t("You haven't added any recipients yet.")}</Paragraph>
            <Button onClick={() => setShowDialog(true)}>{t('Add recipient')}</Button>
          </div>
        )}

      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  list: state.employee.list.results,
  loading: state.employee.loading,
  createStatus: state.employee.createStatus,
  createError: state.employee.createError,
});

const mapDispatchToProps = {
  fetchEmployees,
  createEmployee,
  createEmployeeReset,
}


export default connect(mapStateToProps, mapDispatchToProps)(Employees);
