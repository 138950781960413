import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom'
import {
  Paragraph,
  Title,
  Button,
  Icon,
  Link,
  Loader,
} from 'gigapay-ui'
import Dropzone from 'react-dropzone'
import css from './upload.module.css';
import { uploadFile, updateUserLead } from 'actions/verify';
import Footer from '../Footer';
const Upload = ({
  onMobile,
  docRef = null,
  preview = null,
  fileType = '',
  isPermit = false,
  loading = false,
  onComplete = () => { },
  uploadFile,
  title,
  description,
  updateUserLead,
  work_permit_type
}) => {
  const { t } = useTranslation('signup');
  const history = useHistory();
  useEffect(() => {
    if (docRef !== null) {
      onComplete()
    }
  }, [docRef]);

  const onDrop = (acceptedFiles, rejectedFiles) => {
    for (var i = 0; i < acceptedFiles.length; i++) {
      var data = new FormData();
      data.append(fileType, acceptedFiles[i]);
      uploadFile(data);
      break;
    }
  };
  const [showPreview, setShowPreview] = useState(true);
  useEffect(() => {
    if (!preview) {
      setShowPreview(false);
    }
  }, []);

  useEffect(() => {
    if (work_permit_type === null && isPermit) {
      history.replace('/verify/permit/')
    }
  }, [work_permit_type]);

  const changePermitType = () => {
    updateUserLead({
      work_permit_type: null
    })
  };

  if (showPreview) {
    return (
      <div>
        <Title.Line small>{description}</Title.Line>
        <Paragraph small margins={false}>{t('Please be prepared with your card.')}</Paragraph>
        <div style={{ textAlign: 'center' }}>
          <img src={preview} className={css.preview} />
          <Button.Primary onClick={() => setShowPreview(false)}>{t('Next')}</Button.Primary>
          <div style={{ marginTop: 8}}>
            <Link primary onClick={changePermitType}>{t('Change working permit type')}</Link>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div>
      <Title.Line small>{description}</Title.Line>
      <Paragraph small margins={false}>{title}</Paragraph>
      <div>
        <Dropzone multiple={false} accept=".csv, image/*, text/csv, text/plain, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onDrop={onDrop}>
          {({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject }) => {
            return (
              <div
                {...getRootProps()}
                className={`${css.uploadFile} ${loading ? css.loading : ''}`}
              >
                {loading && <Loader.Fill />}
                <input {...getInputProps()} />
                {isDragReject && <div>Unsupported file type...</div>}
                {
                  isDragActive ?
                    <p>Try dropping some files here, or click to select files to upload.</p> :
                    <div>
                      <Icon name={'document'} size={80} />
                      <p className={css.instruction}> {t('payout:Drag and drop your file here')}</p>
                      <Button.Primary>{onMobile ? t('Take a photo') : t('Choose file')}</Button.Primary>
                    </div>
                }
              </div>
            )
          }}
        </Dropzone>
      </div>
      <Footer />
    </div>
  )
};


const mapStateToProps = (state) => ({
  onMobile: state.user.onMobile,
  work_permit_type: state.verify.work_permit_type,
  loading: state.verify.loading,
});

const mapDispatchToProps = {
  uploadFile,
  updateUserLead,
};

export default connect(mapStateToProps, mapDispatchToProps)(Upload);
