import React from 'react';
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next';
import { Button, Paragraph, Subtitle, Alert } from 'gigapay-ui'
import { PriceTable } from 'components'
import css from './invoice.module.css';

const Invoice = ({ loading, onSubmit, recipients }) => {
  const { t } = useTranslation('payout')
  return (
    <>
      <Paragraph>{t('The salary will be paid out to the recipient as soon as the invoice is paid')}.</Paragraph>
      <ul className={css.invoiceInfoList}>
        <li>{t('Pay when it suits you')}</li>
        <li>{t('No reminder fee')}</li>
        <li>{t('The salary is paid when the invoice is paid')}</li>
      </ul>
      <PriceTable payouts={recipients} />
      <Button.Primary
        loading={loading}
        onClick={() => onSubmit('invoice')}
        responsive
      >
        {t('Pay')}
      </Button.Primary>
    </>
  )
}

Invoice.propTypes = {
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  recipients: PropTypes.array,
}
Invoice.defaultProps = {
  loading: false,
  recipients: [],
  onSubmit: () => { },
}
export default Invoice;