import React from 'react';
import { status as globalStatus } from '../../constants/action-types';

const msgStyle = {
    lineHeight: '48px',
    marginLeft: '10px',
    marginTop: '5px',
    height: '48px',
    display: 'inline-block',
    float: 'left',
};
const Msg = ({ onSuccess, onError, status, style={}}) => {

    if(status === globalStatus.SUCCESS){
        return (<span style={{color:'green', ...msgStyle, ...style}}>{onSuccess}</span>);
    }

    if(status === globalStatus.ERROR){
        return (<span style={{color:'red', ...msgStyle, ...style}}>{onError}</span>);
    }
    return null;   
}

  
export default Msg;