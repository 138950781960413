import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom'
import moment from 'moment-timezone';
import { QRCode } from "react-qr-svg";
import {
  Button,
  Title,
  Paragraph,
  ToolTip,
  Loader,
  Card,
  List
} from 'gigapay-ui'
import { SuccessAnimation } from 'components/'
import { numberWithSpaces } from 'utils/price'
import { fetchInvoice } from 'actions/invoice';

const Invoice = ({
  loading,
  fetchInvoice,
  invoice
}) => {

  const [price, setPrice] = useState(0);
  const history = useHistory();
  const location = useLocation();
  const { id, pdf } = invoice;
  let invoice_id = useParams().invoice_id;
  useEffect(() => {
    if (invoice_id) {
      fetchInvoice(invoice_id);
    }
  }, []);
  useEffect(() => {
    if (invoice.price){
      setPrice(invoice.price);
    }
  }, [invoice]);
  const { t } = useTranslation(['payout', 'common']);
  const today = moment().format('YYYYMMDD')
  return (
    <div style={{ textAlign: 'center' }}>
      <SuccessAnimation />
      <Title>{t('Your invoice is created')}</Title>
      <div className="invoice-info">
        <div style={{ marginTop: 10 }}>
          <Paragraph>{t('The salary is paid to the recipient as soon as we receive the payment.')} <ToolTip width={200} text={t('Note that it can take up to one business day before the invoice is marked as paid.')} /></Paragraph>
        </div>

        {invoice.price !== '' && (
          <>
            <Card>
              <List
                items={[
                  { label: t('Amount to pay'), value: <span className="success-value">{numberWithSpaces(price)} kr</span>, key: 'amount' },
                  { label: t('common:Bankgiro'), value: <span className="success-value">5129-2423</span>, key: 'bankgiro' },
                  { label: t('common:Bank transfer'), value: <span className="success-value">5212-1028676 (SEB)</span>, key: 'transfer'},
                  {
                    label: t('common:OCR'),
                    value: (
                      <span className="success-value">
                        {invoice.ocr_number === null && <span className={'loading-ocr-container'}>{t('Receiving OCR')} <Loader small inline className={"loading-ocr-loader"} /></span>}
                        {invoice.ocr_number !== null && `${invoice.ocr_number}`}
                      </span>
                    ),
                    key: 'ocr'
                  },
                ]}
              />
            </Card>
            <Button
              onClick={() => {
                window.open(invoice.pdf)
              }}
              style={{ margin: '10px auto' }}
              primary
              responsive
              disabled={invoice.pdf === null}
            >
              {t('Download invoice')} (PDF)
            </Button>
            <div>
              <a href={`/invoices/${invoice.id}/`} className="action">{t('Show summary')}</a>
            </div>

            <Card className="qr-code-container">
              <div className="qr-code">
                <QRCode
                  level="Q"
                  value={JSON.stringify({
                    uqr: 1,
                    tp: 1,
                    nme: "Gigapay AB",
                    cid: "559070-3616",
                    iref: `${invoice.ocr_number}`,
                    ddt: today,
                    due: parseInt(price),
                    cc: "SE",
                    cur: "SEK",
                    bc: "ESSESESS",
                    pt: "BBAN",
                    acc: "52121028676",
                  })}
                />
              </div>
              <div className="qr-code-description">
                <b>{t('Scan with your banking app')} </b>
                <p>{t("If your bank's mobile app supports QR codes, you can easily scan this code to pay.")}</p>
              </div>
            </Card>
          </>
        )}
        {invoice.price === '' && loading && <Loader inPlace style={{ marginTop: 20 }} />}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  recipients: state.payout.recipients,
  loading: state.invoice.loading,
  invoice: state.invoice,
});
const mapDispatchToProps = {
  fetchInvoice
};


export default connect(mapStateToProps, mapDispatchToProps)(Invoice);