import _ from 'lodash';
import {

  SET_USER_INFO,
  LOGOUT,
  RESET_ERROR,

  status,
  types,
} from '../constants/action-types';

const auth = {
  hasCompany: false,
  companies: {},
  companyLeads: [],
  currentCompany: null,
  mode: 'person',

  name: '',
  email: null,
  latest_cellphone_number: '',

  error: null,
  loading: false,
  onMobile: (/Mobi/.test(navigator.userAgent)),
  showNavbar: true,
  showFooter: true,

  claimStatus: status.DEFAULT,
  claimError: null,
  saveStatus: status.DEFAULT,
  verifyEmailStatus: status.DEFAULT,
  verifyEmailError: null,
  hasFetchCompanies: false,
};

/*
    loginStatus: default, inProgress, completed,
*/




const user = (state = auth, action) => {
  switch (action.type) {

    case types.FETCH_ME:
    case types.LOGIN:
      switch (action.meta.status) {
        case status.BEGIN:
          return {
            ...state,
          };
        case status.SUCCESS:
          return {
            ...state,
            name: action.payload.first_name,
            email: action.payload.email,
            cellphone_number: action.payload.cellphone_number,
          };

        case status.ERROR:
          return {
            ...state,
            name: '',
            email: '',
            cellphone_number: ''
          };
        default:
          return state;
      }

    case types.CLAIM_EMPLOYEE:
      switch (action.meta.status) {
        case status.BEGIN:
          return {
            ...state,
            claimStatus: status.BEGIN,
            claimError: null
          };
        case status.SUCCESS:
          return {
            ...state,
            claimStatus: status.SUCCESS,
          };
        case status.ERROR:
          return {
            ...state,
            claimStatus: status.ERROR,
            claimError: action.payload.error,
          };
        default:
          return state;
      }

    case types.SHOW_FOOTER:
      return {
        ...state,
        showFooter: action.payload,
      };

    case types.SAVE_COMPANY:
      switch (action.meta.status) {
        case status.SUCCESS:
          return {
            ...state,
            companies: {
              ...state.companies,
              [action.meta.params.id]: {
                ...state.companies[action.meta.params.id],
                ...action.payload,
              },
            },
          };
        default:
          return state;
      }

    case types.UPLOAD_LOGO:
      switch (action.meta.status) {
        case status.SUCCESS:
          return {
            ...state,
            companies: {
              ...state.companies,
              [action.meta.params.id]: {
                ...state.companies[action.meta.params.id],
                ...action.payload,
              },
            },
          };
        default:
          return state;
      }

    case types.FETCH_COMPANY_LEADS:
      switch (action.meta.status) {
        case status.BEGIN:
          return {
            ...state,
            companyLeads: [],
          };
        case status.SUCCESS:
          return {
            ...state,
            companyLeads: action.payload,
          };
        default:
          return state;
      }

    case types.FETCH_USER_COMPANIES:
      switch (action.meta.status) {
        case status.BEGIN:
          return {
            ...state,
            hasFetchCompanies: false,
          };
        case status.SUCCESS:
          const cc = state.currentCompany;
          const currentCompany = (() => {
            if(state.mode === 'person' || action.payload.length === 0){
              return null;
            } else if(cc && _.keyBy(action.payload, 'id')[cc]){
              return cc;
            } else {
              return action.payload[0].id
            }
          })();
          return {
            ...state,
            companies: _.keyBy(action.payload, 'id'),
            currentCompany,
            hasCompany: (action.payload.length !== 0),
            mode: currentCompany ? 'company' : 'person',
            hasFetchCompanies: true,
            //mode: (action.payload.length !== 0) ? 'company' : 'person',
          };
        case status.ERROR:
          return {
            ...state,
            companies: {},
            currentCompany: null,
            hasCompany: false,
            hasFetchCompanies: true,
          };
        default:
          return state;
      }

    case types.SAVE_USER:
      switch (action.meta.status) {
        case status.BEGIN:
          return {
            ...state,
            saveStatus: status.BEGIN,
          };
        case status.SUCCESS:
          return {
            ...state,
            saveStatus: status.SUCCESS,
          };
        case status.ERROR:
          return {
            ...state,
            saveStatus: status.ERROR,
          };
        default:
          return state;
      }

    case types.VERIFY_EMAIL:
      switch (action.meta.status) {
        case status.BEGIN:
          return {
            ...state,
            verifyEmailStatus: status.BEGIN,
            verifyEmailError: null,
          };
        case status.SUCCESS:
          return {
            ...state,
            email: action.payload.email,
            name: action.payload.first_name,
            cellphone_number: action.payload.cellphone_number,
            verifyEmailStatus: status.SUCCESS,
          };
        case status.ERROR:
          return {
            ...state,
            verifyEmailStatus: status.ERROR,
            verifyEmailError: action.payload.error || null,
          };
        default:
          return state;
      }

    case types.SHOW_NAVBAR:
      return {
        ...state,
        showNavbar: action.payload
      };

    case LOGOUT:
      return {
        ...state,
        email: '',
        first_name: '',
        last_name: '',
      };
    case SET_USER_INFO:
      return {
        ...state,
        ...action.payload
      };
    case RESET_ERROR:
      return {
        ...state,
        error: null,
      };


    default:
      return state;
  }
};
export default user;