import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from 'moment-timezone';
import I18n from '../../I18n/'
import { footerVisible, save, changeLanguage } from 'actions/user';
import css from './footer.module.css';

class Footer extends Component {

  constructor(props) {
    super(props);

    const hideFooter = [
      'payout',
      'request',
      'signup',
      'verify',
      '/invoices/new/',
      '/invoices/new/preview/',
      '/invoices/new/delivered/',
      
    ];
    const initRoute = this.props.history.location.pathname.split('/')[1];
    this.props.footerVisible((hideFooter.indexOf(initRoute) < 0) && hideFooter.indexOf(this.props.history.location.pathname) < 0);

    this.props.history.listen((location, action) => {
      try {
        const route = location.pathname.split('/')[1];
        this.props.footerVisible(hideFooter.indexOf(route) < 0 && hideFooter.indexOf(location.pathname) < 0);
      } catch (error) {

      }
    });
  }

  changeLang = lang => {
    if (this.props.isAuthenticated) {
      this.props.save({
        language: lang,
      })
    }
    this.props.changeLanguage(lang);
  };

  componentDidMount() {
    I18n.changeLanguage(this.props.language);
  }

  render() {
    const { t, language, showFooter } = this.props;
    const languageList = [
      { key: 'sv', string: 'Svenska' },
      { key: 'en', string: 'English' },
    ];
    if (!showFooter) return null;
    return (
      <footer className={`content ${css.content}`}>
        <div className={`${css.column} ${css.links}`}>
          <ul>
            <li><span>&copy; {t('companyName')} {moment().format('YYYY')} {t('companyEmail')}</span></li>
            <li><a href={t('termsLink')}>{t('Terms of Use')}</a></li>
            <li><a href={t('dataLink')}>{t('Privacy Policy')}</a></li>
          </ul>
        </div>
        <div className={`${css.column} ${css.lang}`}>
          <ul>
            {
              languageList.map(l => {
                const isSelected = (l.key === language) ? css.selected : '';
                return (
                  <li key={l.key} className={isSelected}><span onClick={() => this.changeLang(l.key)}>{l.string}</span></li>
                )
              })
            }
          </ul>
        </div>
      </footer>
    )
  }
}

Footer.propTypes = {
  footerVisible: PropTypes.func,
};

Footer.defaultProps = {
  footerVisible: () => { },
};

const mapStateToProps = (state) => ({
  language: state.auth.language,
  showFooter: state.user.showFooter,
  isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatchToProps = {
  changeLanguage,
  footerVisible,
  save,
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(Footer)));
