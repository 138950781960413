import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { withTranslation, useTranslation } from 'react-i18next';
import { Fieldset, Input, Link, Loader, Button, ToolTip, Icon } from 'gigapay-ui';
import { validate } from 'utils/banktools'
import { status } from 'constants/action-types';
import { createBankGiroBackend, updateBankGiroBackend } from "actions/payout-backends";
import { trim, slugify} from "../utils";

import css from './bankgiro.module.css'


const ViewBankGiroBackend = ({bankgiroBackend, setEditView}) => {
  const {t} = useTranslation('common');
  const result = validate(bankgiroBackend.clearing, bankgiroBackend.account, 'bankgiro');
  return (
    <div className={css.viewPayhourDetails}>
      <i className={`${css.bankType} ${css[slugify(result.bank)] || ''}`} title={result.bank}>{trim(result.bank)}</i>
      <span>{result.clearing.format}-{result.account.format}</span>
      <Link onClick={() => setEditView(true)} className={css.edit}>{t('Edit')}</Link>
    </div>
  )
};


const ErrorIcon = ({ message }) => {
  return (
    <ToolTip
      label={(
        <div className={css.errorMessage}>
          <Icon name={'alert'} size={13} color="#b50707"/>
        </div>
      )}
      width="200px"
      text={message}
      style={{position:"absolute", bottom:"3px", margin: 0}}
    />
  )
};

const BankGiroBackend = ({
  backend: bankgiroBackend,
  createBankGiroBackend,
  updateBankGiroBackend,
}) => {

  const {t} = useTranslation('common');
  const clearingRef = useRef(null);
  const accountRef = useRef(null);
  const [editView, setEditView] = useState(false)
  const [clearing, setClearing] = useState('');
  const [account, setAccount] = useState('');
  const [bank, setBank] = useState({
    className: null,
    name: '',
  });

  // If you have already saved an account, pre-populate the state with it.
  useEffect(() => {
    const result = validate(bankgiroBackend.clearing, bankgiroBackend.account, 'bankgiro');
    setClearing(result.clearing.format);
    setAccount(result.account.format);
    setBank({name: result.bank, className: slugify(result.bank)})
  }, [bankgiroBackend.clearing, bankgiroBackend.account]);

  const saveBankGiroBackend = () => {
    if (!bankgiroBackend['clearing']) {
      createBankGiroBackend({clearing, account})
    } else {
      updateBankGiroBackend({clearing, account})
    }
  };

  // switch away from the edit view when you have saved
  useEffect(() => {
    if (bankgiroBackend.status === status.SUCCESS && !bankgiroBackend.loading && editView) {
      setEditView(false);
    }
    if (bankgiroBackend.status === status.DEFAULT && (!bankgiroBackend.clearing || !bankgiroBackend.account)){
      setEditView(true);
  }
  }, [bankgiroBackend]);

  // check format the input on key press
  const _onAccountKeyDown = (e) => {
    if (!accountRef || !clearingRef) return;

    if (!(e.keyCode < 48 || e.keyCode > 57)) {
      const av = accountRef.current.value + e.key;
      const result = validate(clearing, av.trimEnd(), 'bankgiro');
      setBank({name: result.bank, className: slugify(result.bank)});
      setAccount(result.account.format.trim());
      e.preventDefault()
    }
    if (accountRef && accountRef.current.value.length === 0 && e.keyCode === 8) {
      if (clearingRef.current) {
        clearingRef.current.focus();
        e.preventDefault()
      }
    }
  };

  // on clearing key up, check bank
  const onClearingKeyUp = (e) => {
    if (!accountRef || !clearingRef) return;

    const cv = clearingRef.current.value;
    const result = validate(cv, account, 'bankgiro');
    setBank({name: result.bank, className: slugify(result.bank)});
    if (result.clearing.isValid && e.keyCode !== 8) {
      accountRef.current.focus();
    }
    setClearing(result.clearing.format)
  };

  const clearingError = (bankgiroBackend.error && bankgiroBackend.error.clearing) ? bankgiroBackend.error.clearing: '';
  const accountError = (bankgiroBackend.error && (bankgiroBackend.error.account || bankgiroBackend.error.non_field_errors)) ? (bankgiroBackend.error.account || bankgiroBackend.error.non_field_errors): '';

  return <>
    {editView && (
      <div>
        <Fieldset>
          <Fieldset.Row className={css.fieldSetRow}>
            <Input
              className={css.NarrowInput}
              label={<p>{t('Clearing')} {clearingError && <ErrorIcon message={clearingError}/>}</p>}
              inputmode="numeric"
              pattern="[0-9\-]{4,6}"
              ref={clearingRef}
              onKeyUp={onClearingKeyUp}
              onChange={c => setClearing(c.trim())}
              value={clearing}
              error={clearingError}
              leftIcon={bank.className && (
                <i className={`${css.bankType} ${css[slugify(bank.className)] || ''}`} title={bank.name}>
                {trim(bank.name)}
                </i>
              )}
              inputStyle={bank.className? {paddingLeft: "76px"}: null}
              labelStyle={bank.className? {left: "76px"}: null}
            />
            <Input
              className={css.wideInput}
              label={<p>{t('Account')} {accountError && <ErrorIcon message={accountError}/>}</p>}
              inputmode="numeric"
              pattern="[0-9\- ]{7,15}"
              ref={accountRef}
              onKeyDown={_onAccountKeyDown}
              onChange={a => setAccount(a.trim())}
              value={account}
              error={accountError}
              rightIcon={
                <Button.Primary
                  onClick={saveBankGiroBackend}
                  className={css.save}
                  disabled={!(clearing && account)}
                >
                  {bankgiroBackend['loading'] ? <Loader small color={'#fff'}/> : t('Use')}
                </Button.Primary>
              }
              rightIconStyle={{width: "76px", zIndex:2}}
            />
          </Fieldset.Row>
        </Fieldset>
      </div>
    )}
    {!editView && (
      <ViewBankGiroBackend
        bankgiroBackend={bankgiroBackend}
        setEditView={setEditView}
      />
    )}
  </>
};

const mapDispatchToProps = {
  createBankGiroBackend,
  updateBankGiroBackend,
};

export default connect(null, mapDispatchToProps)(withTranslation('common')(BankGiroBackend));