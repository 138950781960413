import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Title,
  Button,
  Link,
  Subtitle,
  Paragraph,
  Table
} from 'gigapay-ui'
import css from './invoices.module.css';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Avatar } from 'components/'
import { Row, Text, Title as PTitle, Table as PTable } from 'components/Placeholder/'
import { format } from 'utils/dateTime';
import { numberWithSpaces } from 'utils/price';
import { fetchInvoices } from 'actions/invoicing';


const Invoiceings = ({ mode, fetchInvoices, invoices, loading }) => {
  const { t } = useTranslation('invoices');
  const history = useHistory()
  const isReady = true;
  const getStatus = ({ paid_at, created_at }) => {
    if (paid_at) {
      return 'Paid'
    } else if (created_at) {
      return <span style={{ color: 'orange' }}>{t('Unpaid')}</span>
    } else {
      return <span style={{ color: 'orange' }}>{t('Waiting for payment')}</span>
    }
  }
  useEffect(() => {
    if (mode === 'person') {
      fetchInvoices({ expand: 'company' })
    }
  }, [])
  if (mode === 'company') {
    return (
      <span>Under construction</span>
    )
  }
  return (
    <div className="content">
      <div className="flex-row-md">
        <div className="flex-col-md-9">
          <PTitle large ready={isReady}>
            <Title.Line margins={false}>{t('Invoices')}</Title.Line>
            <span className={css.subtitle}>{t('Here you can view and track the progress of the invoices you have sent.')}</span>
          </PTitle>
        </div>
        <div className="flex-col-md-3 vcenter">
          <Button.Primary
            responsive
            onClick={() => {
              history.push('/invoices/new/')
            }}
          >
            {t('New invoice')} +
            </Button.Primary>
        </div>
      </div>
      <div className="spacing" style={{ marginBottom: 20 }} />

      <div className="flex-row-md">
        <div className="flex-col-md-1">
          <PTable ready={isReady} cols={3}>
            <Table
              keyExtractor={(item) => item.id}
              data={invoices}
              onClick={(props) => history.push(`/invoices/${props.id}/`)}
              columns={[{
                Header: t('Customer'),
                Cell: props => (
                  <div className="cell-container">
                    <div style={{ display: 'inline-block', float: 'left', marginRight: 8 }}>
                      <Avatar name={props.company.name} />
                    </div>
                    <span>
                      <span> {props.company.name}</span>
                      <span className="tableCell-mobileOnly table-subtitle">{getStatus(props)} - {format(props.created_at, t)}</span>
                    </span>
                  </div>),
                id: 'name',
                className: 'main-cell',
                headerClassName: 'main-cell',
              }, {
                Header: t('common:Date'),
                Cell: props => <span>{format(props.created_at)}</span>,
                id: 'date',
                className: 'tableCell-desktopOnly',
                headerClassName: 'tableCell-desktopOnly'
              }, {
                Header: t('common:Status'),
                Cell: props => getStatus(props),
                id: 'status',
                className: 'tableCell-desktopOnly',
                headerClassName: 'tableCell-desktopOnly'
              }, {
                Header: <span className="text-align-right">{t('Invoiced amount')}</span>,
                Cell: props => <span className="text-align-right">{numberWithSpaces(props.price)} kr</span>,
                id: 'amount',
              }]}
            />
          </PTable>
        </div>
      </div>

      {!loading && invoices.length === 0 && (
        <div style={{ marginTop: 60, minHeight: 200, textAlign: 'center' }}>
          <Subtitle small>{t('You haven\'t created any invoices yet.')}</Subtitle>
          <Paragraph>{t("You can create and send simple invoices for work you have done.")}</Paragraph>
        </div>
      )}

    </div>
  )
}

const mapStateToProps = (state) => ({
  mode: state.user.mode,
  loading: state.invoices.loading,
  invoices: Object.values(state.invoices.invoices)
});
const mapDispatchToProps = {
  fetchInvoices
};

export default connect(mapStateToProps, mapDispatchToProps)(Invoiceings);