import React, { Component } from 'react';
import { CardNumberElement, CardExpiryElement, CardCVCElement } from 'react-stripe-elements';
import {
  Fieldset,
  Input,
  Icon
} from 'gigapay-ui'

const stripeContainer = {
  position: 'absolute',
  left: '40px',
  right: '0px',
  top: '15px',
  bottom: '15px',
  height: '18px',
};

const mobileStyle = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) ? { fontSize: '16px' } : {}
const elementStyle = {
  base: {
    fontSize: '15px',
    ...mobileStyle
  }
}

class CardForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      brand: "",
      locked: false,
    }
  }



  render() {

    const { brand, locked } = this.state;
    const lock = (locked) ? 'ion-locked' : 'ion-unlocked';
    return (
      <Fieldset>
        <Input
            leftIcon={<Icon name={'credit-card'} />}
            Component={(
              <div style={stripeContainer}>
                <CardNumberElement
                  style={elementStyle}
                  onChange={(o) => {

                    this.setState({
                      brand: (o.brand === 'unknown') ? '' : o.brand
                    });

                    if (o.complete) {
                      this.expiry.focus();
                    }
                  }}
                />
              </div>
            )}
          >
            <i className={`credit-card-type ${brand}`}></i>
          </Input>

        <Fieldset.Row>
          <Input
            leftIcon={<Icon name={'calendar'} />}
            Component={(
              <div style={stripeContainer}>
              <CardExpiryElement
                style={elementStyle}
                onReady={(expiry) => { this.expiry = expiry; }}
                onChange={(o) => {
                  if (o.complete) {
                    this.cvc.focus();
                  }
                }}
              />
              </div>
            )}
          />
          <Input
            leftIcon={<Icon name={locked ? 'lock' : 'unlock'} />}
            Component={(
              <div style={stripeContainer}>
              <CardCVCElement
                style={elementStyle}
                onReady={(cvc) => { this.cvc = cvc; }}
                onChange={(o) => {
                  this.setState({
                    locked: o.complete
                  });
                }}
              />
              </div>
            )}
          />
        </Fieldset.Row>

      </Fieldset>
    );
  }
}

export default CardForm;