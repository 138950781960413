import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types'
import NumberBox from '../../../NumberBox'
import { configFile } from 'config';
import css from './Header.module.css';

const Header = ({ statistic, loadingStatistic }) => {
  const { t } = useTranslation('dashboard');
  const months = ["Januari", "Februari", "Mars", "April", "Maj", "Juni", "Juli", "Augusti", "September", "Oktober", "November", "December"];
  let month = months[new Date().getMonth()];
  let nextMonth = months[(new Date().getMonth()+1) % 12];
  return (
    <Fragment>
      <div className="flex-row-md">
        <div className="flex-col-md-12">
          <span className="intro-text">{t('dashboard:WelcomeMessageConsumer')}</span>
        </div>
      </div>

      {(configFile === 'payhour') ?
        <div className={css.headerParent}>
          <div className={css.headerBox}>
            <NumberBox
              amount={statistic.monthly_amount}
              loading={loadingStatistic}
              label={t('Accepted this month')}
            />
          </div>
          <div className={css.headerBox}>
            <NumberBox
              amount={statistic.total_amount}
              loading={loadingStatistic}
              label={t('Paid out the 25th') + ' ' + month}
            />
          </div>
          <div className={css.headerBox}>
            <NumberBox
              amount={statistic.number_of_payouts}
              loading={loadingStatistic}
              label={t('Paid out the 25th') + ' ' + nextMonth}
            />
          </div>
        </div>
        :
        <div className={css.headerParent}>
          <div className={css.headerBox}>
            <NumberBox
              amount={statistic.monthly_amount}
              loading={loadingStatistic}
              label={t('Salary this month')}
            />
          </div>
          <div className={css.headerBox}>
            <NumberBox
              amount={statistic.total_amount}
              loading={loadingStatistic}
              label={t('Total Salary')}
            />
          </div>
          <div className={css.headerBox}>
            <NumberBox
              amount={statistic.number_of_payouts}
              loading={loadingStatistic}
              unit={""}
              label={t('Number of payouts')}
            />
          </div>
        </div>
        }
    </Fragment>
  )
};

Header.propTypes = {
  statistic: PropTypes.shape({
    monthly_amount: PropTypes.number,
    total_amount: PropTypes.number,
    number_of_payouts: PropTypes.number
  }),
  loadingStatistic: PropTypes.bool,
};

Header.defaultProps = {
  statistic: {
    monthly_amount: null,
    total_amount: null,
    number_of_payouts: null,
  },
  loadingStatistic: false,
};

export default Header;