import { phoneNumberValidation } from 'config';


export const formatPhoneNumber = (phoneNumber) => {
  phoneNumber = (`${phoneNumber}`).trim(); // force to string
  phoneNumber = phoneNumber.replace(/[^0-9\+]/g, "");

  if (phoneNumber === '') return phoneNumber;

  // If the phone number doesn't start with a country_code,
  // remove the leading 0 and add one.
  if (!phoneNumber.startsWith('+')) {
    const countryCode = {finland: '+358', sweden: '+46'}[phoneNumberValidation];
    phoneNumber = phoneNumber.startsWith('0') ? phoneNumber.substring(1) : phoneNumber
    phoneNumber = countryCode + phoneNumber
  }
  return phoneNumber;
};


export const validPhoneNumber = (phoneNumber) => {
  /* See gigapay/core/validators.py */
  phoneNumber = formatPhoneNumber(phoneNumber);

  const regex = {
    sweden: /^(?<national_prefix>\+46)(?<national_destination_code>\d{1,3})(?<subscriber_number>\d{5,8})$/,
    finland: /^(?<national_prefix>\+358)(?<national_destination_code>\d{2,5})(?<subscriber_number>\d{4,8})$/,
  }[phoneNumberValidation];

  return regex.test(phoneNumber);
};


export const validCellPhoneNumber = (phoneNumber) => {
  /* See gigapay/core/validators.py */
  phoneNumber = formatPhoneNumber(phoneNumber);

  return phoneNumber.length > 6
};