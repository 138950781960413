import React from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Button, Title, Loader, Paragraph, Link, Icon } from 'gigapay-ui'
import { cancel, openApp } from '../../actions'
import css from '../../bankid.module.css'
import QrCode from 'components/ScanQR/QRCode'
import { useAtomValue } from 'jotai'
import { qrCodeAtom } from 'store/bankIdAtom'

const BankIdIcon = () => (
  <div className={css.icon}>
    <Icon name={'bankid-color'} size={44} />
  </div>
)

const ShowProgress = ({ onMobile, openApp, cancel }) => {
  const { t } = useTranslation('common')
  const qrCode = useAtomValue(qrCodeAtom)
  return (
    <div style={{ textAlign: 'center' }}>
      <BankIdIcon />
      <Title>{t('Start the app for BankID')}</Title>
      <Paragraph>
        {t(
          'To log in, open your BankID application on your computer or mobile and verify that you are you.'
        )}
      </Paragraph>
      {qrCode && <QrCode qrCode={qrCode} />}
      <div className={css.bankIdCancel}>
        <Loader inline />
      </div>
      {onMobile && (
        <div
          style={{
            width: '100%',
            display: 'block',
            float: 'left',
            marginBottom: 15,
          }}
        >
          <Button
            primary
            onClick={openApp}
            responsive
            style={{
              width: 275,
              paddingLeft: 0,
              paddingRight: 0,
              float: 'none',
              margin: 'auto',
            }}
          >
            {t('Open BankID on this device')}
          </Button>
        </div>
      )}
      <Link className={css.action} primary bold onClick={cancel}>
        {t('Cancel')}
      </Link>
    </div>
  )
}

const mapStateToProps = (state) => ({
  onMobile: state.user.onMobile,
  loginStatus: state.bankidLogin.loginStatus,
})

const mapDispatchToProps = (dispatch) => ({
  openApp: () => dispatch(openApp()),
  cancel: () => dispatch(cancel()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ShowProgress)
