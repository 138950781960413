
import {
  types,
} from '../constants/action-types';
import { get, post, patch } from 'utils/fetchV3';

const privateUrlFunc = (parms = '') => config =>
  `${config.privateUrl}/${parms}`;


const url = (parms = '', args = {}) => config => {
  let url = `${config.baseUrl}/${parms}?`;
  const pairs = [];
  for (let [k, v] of Object.entries(args)) {
    pairs.push(`${k}=${v}`);
  }
  url = `${url}${pairs.join('&')}`;
  return url;
};

export const resetCheckClaim = () => 
  ({ type: types.CHECK_CLAIM_RESET });

export const checkClaim = token =>
  post(types.CHECK_CLAIM, privateUrlFunc(`employees/check/`), { token });

export const claimEmployee = token =>
  post(types.CLAIM_EMPLOYEE, privateUrlFunc(`employees/claim/`), { token });

export const fetchEmployees = args =>
  get(types.FETCH_EMPLOYEES, url(`employees/`, args));

export const createEmployee = data =>
  post(types.CREATE_EMPLOYEE, url(`employees/`), data);

export const saveEmployee = (id, data) =>
  patch(types.UPDATE_EMPLOYEE, url(`employees/${id}/`), data);

export const saveEmployeeReset = () => 
  ({ type: types.UPDATE_EMPLOYEE_RESET });

export const resetImportEmployee = () => 
  ({ type: types.RESET_BATCH_CREATE_EMPLOYEE });

export const batchCreateEmployee = data =>
  post(types.BATCH_CREATE_EMPLOYEE, url(`employees/`), data);

export const fetchEmployee = id =>
  get(types.FETCH_EMPLOYEE, url(`employees/${id}/`));

export const fetchEmployeePayouts = data =>
  get(types.FETCH_EMPLOYEE_PAYOUTS, url(`payouts/`, data), data.employee);

export const createEmployeeReset = () => 
  ({ type: types.CREATE_EMPLOYEE_RESET });
