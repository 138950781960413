import {
  ADD_RECIPIENT,
  UPDATE_RECIPIENT,
  REMOVE_RECIPIENT,
  VALID_RECIPIENT,
  INVALID_RECIPIENT,
  PAYOUT_CLEAR,
  PAYOUT_RESET,
  RESET_AND_ADD,

  FILE_PARSING_BEGIN,
  FILE_PARSING_FAILURE,
  FILE_PARSING_SUCCESS,
} from '../constants/action-types';
import uuid from 'uuid-random';
import { types, status } from '../constants/action-types';

import { asNumber } from '../utils/number';


const initState = {

  recipients: [],


  invoiceId: null,

  createPayoutStatus: 0,
  createPayoutResponse: null,
  createPayoutError: null,

  clientIntent: null,
  clientIntentError: null,
  paymentMethod: 'card',
  paymentStatus: 0,
  paymentResponse: null,
  loadingEmployees: false,


  isValid: false,
  validateMsg: { message: 'No value added' },
  loading: false,
  fileParsing: {
    loading: true,
    message: null,
  },
  payoutsById: {
    loading: false,
    data: [],
  },

  notice: {
    description: '',
    from: null,
    to: null,
    status: status.DEFAULT,
    invoiceId: null,
  }
};


const payout = (state = initState, action) => {
  switch (action.type) {
    case types.RESET_RECIPIENTS:
      return {
        ...state,
        recipients: [],
        invoiceId: null,
        createPayoutStatus: 0,
        createPayoutResponse: null,
        createPayoutError: null,
      }
    case types.SET_RECIPIENTS:
      return {
        ...state,
        recipients: action.payload
      }
    case types.ADD_RECIPIENT_NOTICE:
      switch (action.meta.status) {
        case status.BEGIN:
          return {
            ...state,
            notice: {
              ...state.notice,
              status: status.BEGIN,
            }
          };
        case status.SUCCESS:
          return {
            ...state,
            notice: {
              ...state.notice,
              status: status.SUCCESS,
              description: '',
              from: null,
              to: null,
              invoiceId: action.payload[0].invoice || null,
            }
          };
        case status.ERROR:
          return {
            ...state,
            notice: {
              ...state.notice,
              status: status.ERROR,
            }
          };
        default:
          return state;
      }

    case types.UPDATE_NOTICE_DATA:
      return {
        ...state,
        notice: {
          ...state.notice,
          ...action.payload
        }
      }
    case types.RESET_PAYOUTS_BY_ID:
      return {
        ...state,
        payoutsById: {
          loading: false,
          data: [],
        },
      }
    case types.PAYOUTS_BY_ID:
      switch (action.meta.status) {
        case status.BEGIN:
          return {
            ...state,
            payoutsById: {
              loading: true,
              data: [],
            },
          }
        case status.SUCCESS:
          return {
            ...state,
            payoutsById: {
              loading: false,
              data: action.payload,
            },
          }
        case status.ERROR:
          return {
            ...state,
            payoutsById: {
              loading: false,
              data: [],
            },
          }
        default:
          return state;
      }


    case FILE_PARSING_BEGIN:
      return {
        ...state,
        fileParsing: {
          loading: true,
          errorMessage: null,
        },

      };
    case FILE_PARSING_FAILURE:
      return {
        ...state,
        fileParsing: {
          loading: false,
          errorMessage: action.payload,
        },

      };
    case FILE_PARSING_SUCCESS:
      return {
        ...state,
        fileParsing: {
          loading: false,
          errorMessage: null,
        },

      };

    case PAYOUT_RESET:
      return {
        ...state,
        recipients: [{
          personalNumber: '',
          email: '',
          amount: '',
          tempId: action.payload,
          amountType: 'grossEarnings',
        }],
        invoiceId: null,
        createPayoutStatus: 0,
        createPayoutError: null,
      };
    case PAYOUT_CLEAR:
      return {
        ...state,
        recipients: [],
        fileParsing: {
          loading: false,
          errorMessage: null,
        },
        invoiceId: null,
        createPayoutStatus: 0,
        createPayoutError: null,
      }
    case RESET_AND_ADD:
      return {
        ...state,
        recipients: [{
          personalNumber: action.payload.personalNumber,
          cellphoneNumber: action.payload.cellphoneNumber,
          firstName: '',
          lastName: '',
          amount: asNumber(action.payload.amount),
          tempId: 'fasdh',
          amountType: 'payrollCosts',
        }],
        isValid: true,
      }
    case VALID_RECIPIENT:
      return {
        ...state,
        isValid: true,
        validateMsg: '',
      };

    case INVALID_RECIPIENT:
      return {
        ...state,
        isValid: false,
        validateMsg: action.payload,
      };

    case REMOVE_RECIPIENT:
      let removedRecipient = state.recipients.filter(element => element !== action.payload);
      return {
        ...state,
        recipients: removedRecipient,
      }

    case ADD_RECIPIENT:
      return {
        ...state,
        recipients: [...state.recipients, {
          personalNumber: '',
          email: '',
          amount: '',
          amountType: 'grossEarnings',
          firstName: '',
          loading: false,
          lastName: '',
          ...action.payload,
        }],
        isValid: false,
      }

    case types.SET_EMPLOYEES_BY_ID:
      const { employees, ids } = action.payload;
      return {
        ...state,
        recipients: employees
          .filter(e => ids.indexOf(e.id) >= 0)
          .map(e => ({
            id: uuid(),
            amount: '',
            amountType: 'gross',
            employee: {
              name: e.name,
              cellphone_number: e.cellphone_number || null,
              email: e.email || null,
              value: e.cellphone_number || e.email
            }
          })),
      }

    case UPDATE_RECIPIENT:

      let newRecipient = state.recipients.map((r) => {
        return (r.tempId === action.payload.tempId) ?
          action.payload :
          r;
      });

      return {
        ...state,
        recipients: newRecipient,
      };

    case types.CREATE_PAYMENT_INTENT:
      switch (action.meta.status) {
        case status.BEGIN:
          return {
            ...state,
            clientIntent: null,
            clientIntentError: null,
            loading: true,
          };
        case status.SUCCESS:
          return {
            ...state,
            loading: false,
            clientIntent: action.payload.intent
          };
        case status.ERROR:
          return {
            ...state,
            loading: false,
            clientIntent: null,
            clientIntentError: action.payload
          };
        default:
          return state;
      }

    case types.PAYMENT_RESET:
      return {
        ...state,
        loading: false,
        clientIntent: null,
        clientIntentError: null,
        paymentStatus: 0,
        paymentResponse: null,
      };

    case types.PAYMENT_BEGIN:
      return {
        ...state,
        loading: true,
        paymentStatus: 1,
        paymentResponse: null,
      };

    case types.PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        paymentStatus: 2,
      };

    case types.PAYMENT_FAILURE:
      return {
        ...state,
        loading: false,
        paymentStatus: 3,
        paymentResponse: action.payload.message,
      };

    case types.SET_PAYMENT_RESPONSE:
      return {
        ...state,
      };

    case types.CREATE_PAYOUT:
      switch (action.meta.status) {
        case status.BEGIN:
          return {
            ...state,
            createPayoutStatus: 1,
            createPayoutResponse: null,
            createPayoutError: null,
            loading: true,
          };
        case status.SUCCESS:
          return {
            ...state,
            createPayoutStatus: 2,
            createPayoutResponse: action.payload[0].invoice,
            invoiceId: action.payload[0].invoice,
            paymentMethod: action.meta.params.paymentMethod,

            isValid: false,
            loading: false,
          };
        case status.ERROR:
          return {
            ...state,
            createPayoutStatus: 3,
            createPayoutResponse: action.payload.response,
            createPayoutError: action.payload.error.msg,
            invoiceId: action.payload.error.invoiceId,
            loading: false,
          };
        default:
          return state;
      }

    case types.CREATE_PAYOUT_BEGIN:
      return {
        ...state,
        createPayoutStatus: 1,
        createPayoutResponse: null,
        createPayoutError: null,
        loading: true,
      };
    case types.CREATE_PAYOUT_SUCCESS:
      return {
        ...state,
        createPayoutStatus: 2,
        createPayoutResponse: action.payload.invoice,
        invoiceId: action.payload.invoice,
        paymentMethod: action.payload.method,

        isValid: false,
        loading: false,
      };
    case types.CREATE_PAYOUT_FAILURE:
      return {
        ...state,

        createPayoutStatus: 3,
        createPayoutResponse: action.payload.response,
        createPayoutError: action.payload.error.msg,
        invoiceId: action.payload.error.invoiceId,
        loading: false,
      };
    default:
      return state;
  }
};


export default payout;
