import { status, types } from '../constants/action-types';

const defaultPayoutBackends = {
  preferred_payment_method: '',
  status: status.DEFAULT,
  loading: false,

  trustly: {
    clearing: '',
    account: '',
    token: '',
    error: '',
    status: status.DEFAULT,
    loading: false,
  },

  bankgiro: {
    clearing: '',
    account: '',
    error: '',
    status: status.DEFAULT,
    loading: false,
  },

  brite: {
    clearing: '',
    account: '',
    error: '',
    status: status.DEFAULT,
    loading: false,
  },

  swish: {
    phoneNumber: '',
    error: '',
    status: status.DEFAULT,
    loading: false,
  }
};

const payoutBackends = (state = defaultPayoutBackends, action) => {
  switch (action.type) {

    case types.LIST_PAYOUT_BACKENDS:
      switch (action.meta.status) {
        case status.BEGIN:
          return {
            ...state,
            loading: true,
            status: status.BEGIN,
          };
        case status.SUCCESS:
          return {
            ...state,
            trustly: action.payload.trustly || defaultPayoutBackends.trustly,
            bankgiro: action.payload.bankgiro || defaultPayoutBackends.bankgiro,
            brite: action.payload.brite || defaultPayoutBackends.brite,
            swish: action.payload.swish || defaultPayoutBackends.swish,
            preferred_payment_method: action.payload.preferred_payment_method,
            loading: false,
            status: status.SUCCESS,
          };
        case status.ERROR:
          return {
            ...state,
            loading: false,
            status: status.ERROR,
          };
        default:
          return state;
      }

      case types.UPDATE_PREFERRED_PAYOUT_BACKEND:
        switch (action.meta.status) {
          case status.BEGIN:
            return {
              ...state,
            };
          case status.SUCCESS:
            return {
              ...state,
              preferred_payment_method: action.payload.preferred_payment_method,
            };
          case status.ERROR:
            return {
              ...state,
              ...action.payload,
            };
          default:
            return state;
        }

    case types.RESET_PAYOUT_BACKENDS:
      if (status.ERROR in [state.status, state.trustly.status, state.brite.status, state.swish.status, state.bankgiro.status]) {
        return defaultPayoutBackends
      } else {
        return state
      }

    case types.CREATE_TRUSTLY_BACKEND:
      switch (action.meta.status) {
        case status.BEGIN:
          return {
            ...state,
            trustly: {
              ...state.trustly,
              loading: true,
              status: status.BEGIN,
            }
          };
        case status.SUCCESS:
          return {
            ...state,
            preferred_payment_method: state.preferred_payment_method || 'trustly',
            trustly: {
              ...action.payload,
              loading: false,
              status: status.SUCCESS,
            }
          };
        case status.ERROR:
          return {
            ...state,
            trustly: {
              ...state.trustly,
              ...action.payload,
              loading: false,
              status: status.ERROR,
            }
          };
        default:
          return state;
      }

    case types.UPDATE_TRUSTLY_BACKEND:
      switch (action.meta.status) {
        case status.BEGIN:
          return {
            ...state,
            trustly: {
              ...state.trustly,
              loading: true,
              status: status.BEGIN,
            }
          };
        case status.SUCCESS:
          return {
            ...state,
            preferred_payment_method: state.preferred_payment_method || 'trustly',
            trustly: {
              ...action.payload,
              loading: false,
              status: status.SUCCESS,
            }
          };
        case status.ERROR:
          return {
            ...state,
            trustly: {
              ...state.trustly,
              ...action.payload,
              loading: false,
              status: status.ERROR,
            }
          };
        default:
          return state;
      }

    case types.CREATE_BANKGIRO_BACKEND:
      switch (action.meta.status) {
        case status.BEGIN:
          return {
            ...state,
            bankgiro: {
              ...state.bankgiro,
              loading: true,
              status: status.BEGIN,
            }
          };
        case status.SUCCESS:
          return {
            ...state,
            preferred_payment_method: state.preferred_payment_method || 'bankgiro',
            bankgiro: {
              ...action.payload,
              loading: false,
              status: status.SUCCESS,
            }
          };
        case status.ERROR:
          return {
            ...state,
            bankgiro: {
              ...state.bankgiro,
              ...action.payload,
              loading: false,
              status: status.ERROR,
            }
          };
        default:
          return state;
      }

    case types.UPDATE_BANKGIRO_BACKEND:
      switch (action.meta.status) {
        case status.BEGIN:
          return {
            ...state,
            bankgiro: {
              ...state.bankgiro,
              loading: true,
              status: status.BEGIN,
            }
          };
        case status.SUCCESS:
          return {
            ...state,
            preferred_payment_method: state.preferred_payment_method || 'bankgiro',
            bankgiro: {
              ...action.payload,
              loading: false,
              status: status.SUCCESS,
            }
          };
        case status.ERROR:
          return {
            ...state,
            bankgiro: {
              ...state.bankgiro,
              ...action.payload,
              loading: false,
              status: status.ERROR,
            }
          };
        default:
          return state;
      }

    case types.CREATE_BRITE_BACKEND:
      switch (action.meta.status) {
        case status.BEGIN:
          return {
            ...state,
            brite: {
              ...state.brite,
              loading: true,
              status: status.BEGIN,
            }
          };
        case status.SUCCESS:
          return {
            ...state,
            preferred_payment_method: state.preferred_payment_method || 'brite',
            brite: {
              ...action.payload,
              loading: false,
              status: status.SUCCESS,
            }
          };
        case status.ERROR:
          return {
            ...state,
            brite: {
              ...state.brite,
              ...action.payload,
              loading: false,
              status: status.ERROR,
            }
          };
        default:
          return state;
      }

    case types.UPDATE_BRITE_BACKEND:
      switch (action.meta.status) {
        case status.BEGIN:
          return {
            ...state,
            brite: {
              ...state.brite,
              loading: true,
              status: status.BEGIN,
            }
          };
        case status.SUCCESS:
          return {
            ...state,
            preferred_payment_method: state.preferred_payment_method || 'brite',
            brite: {
              ...action.payload,
              loading: false,
              status: status.SUCCESS,
            }
          };
        case status.ERROR:
          return {
            ...state,
            brite: {
              ...state.brite,
              ...action.payload,
              loading: false,
              status: status.ERROR,
            }
          };
        default:
          return state;
      }

    case types.UPDATE_SWISH_BACKEND:
      switch (action.meta.status) {
        case status.BEGIN:
          return {
            ...state,
            swish: {
              ...state.swish,
              loading: true,
              status: status.BEGIN,
            }
          };
        case status.SUCCESS:
          return {
            ...state,
            preferred_payment_method: 'swish',
            swish: {
              ...action.payload,
              loading: false,
              status: status.SUCCESS,
            }
          };
        case status.ERROR:
          return {
            ...state,
            swish: {
              ...state.swish,
              ...action.payload,
              loading: false,
              status: status.ERROR,
            }
          };
        default:
          return state;
      }


    default:
      return state;
  }
};

export default payoutBackends;