import {
  status,
  types,
} from '../constants/action-types';

const defaultPayouts = {
  loading: false,
  list: [],
  page: 1,
  hasNext: false,
  count: 0,
}


const payouts = (state = defaultPayouts, action) => {

  switch (action.type) {

    case types.FETCH_DISPATCED:
      switch (action.meta.status) {
        case status.BEGIN:
          return {
            ...state,
            loading: true,
            count: (action.meta.params && action.meta.params.reset) ? 0 : state.count,
            hasNext: false,
          };
        case status.SUCCESS:
          return {
            ...state,
            loading: false,
            count: action.payload.count,
            list: (action.meta.params && action.meta.params.reset) ? [...action.payload.results] : [...state.list, ...action.payload.results],
            hasNext: action.payload.next !== null,
          };
        case status.ERROR:
          return {
            ...state,
            loading: false,
          };
        default:
          return state;
      }

    case types.FETCH_RECEIVED:
      switch (action.meta.status) {
        case status.BEGIN:
          return {
            ...state,
            list: (action.meta.params && action.meta.params.reset) ? [] : [...state.list],
            loading: true,
            hasNext: false,
          };
        case status.SUCCESS:
          return {
            ...state,
            loading: false,
            list: (action.meta.params && action.meta.params.reset) ? [...action.payload.results] : [...state.list, ...action.payload.results],
            hasNext: action.payload.next !== null,
          };
        case status.ERROR:
          return {
            ...state,
            loading: false,
          };
        default:
          return state;
      }


    default:
      return state;
  }
}
export default payouts;