import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as qs from 'query-string';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import { Title, Paragraph, Button, Loader, Alert } from 'gigapay-ui';
import authentication from '../../../../shared/authentication';
import { updateUserInfo } from '../../../../actions/user';
import { SuccessAnimation } from '../../../../components/';
class CompanySuccess extends Component {

  state = {
    company: null,
  }

  componentDidMount() {
    const queryStrings = qs.parse(this.props.location.search);
    const id = queryStrings.id;
    if (typeof id !== 'undefined') {
      this.setState({ company: id })
    }
  }

  render() {
    const { companies, t } = this.props;
    const { company } = this.state;
    const companyList = _.values(companies).map(c => c.id.toString());
    const validCompany = !(companyList.indexOf(company) < 0);

    return (
      <div className="step" style={{ textAlign: 'center' }}>


        <SuccessAnimation />
        <Title small>{t('Your company has been created!')}</Title>
        <Paragraph>{ t('You can now go to your company dashboard or create your first payout.') }</Paragraph>

        <div className="spacing" />

        { !validCompany && <Alert.Error>Invalid company</Alert.Error> }

        { !company && <Loader inline /> }

        { validCompany && company && (
          <div style={{ maxWidth: 250, margin: 'auto' }}>
            <Button
              onClick={() => {
                this.props.updateUserInfo({
                  mode: 'company',
                  currentCompany: company,
                })
                this.props.history.push('/dashboard')
              }}
              responsive
              primary
            >
              { t('Show dashboard') }
            </Button>
            <div className="spacing" />
            <Button
              onClick={() => {
                this.props.updateUserInfo({
                  mode: 'company',
                  currentCompany: company,
                })
                this.props.history.push('/payout/new')
              }}
              responsive
            >
              { t('Create payout') } +
          </Button>
          </div>
        )}

      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  companies: state.user.companies,
});
const mapDispatchToProps = dispatch => ({
  updateUserInfo: (info) => {
    authentication.updateUser(info);
    dispatch(updateUserInfo(info));
  }
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation('signup')(CompanySuccess)));
