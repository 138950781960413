export default (path, opt, timeoutLimit = 50000) => {
  /* global fetch */
  const req = fetch(path, opt);
  return timeoutPromise(req, timeoutLimit, 'timeout')
}

function timeoutPromise(promise, timeout, error = '') {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      reject(error);
    }, timeout);
    promise.then(resolve, reject);
  });
}
