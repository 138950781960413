import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import { Button, Subtitle, Paragraph, Alert } from 'gigapay-ui';
import P from 'components/Placeholder'
import { useLocation, useHistory } from 'react-router-dom'
import * as qs from 'query-string';
import css from './claim.module.css';
import { status } from 'constants/action-types';
import { claimEmployee, resetCheckClaim, checkClaim } from 'actions/employee';
import { updateUserInfo } from 'actions/user'
import { format as formatUrl } from 'utils/url';

const Claim = ({
  isAuthenticated,
  resetCheckClaim,
  claimStatus,
  claimError,
  claimCheck,
  checkClaim,
  claimEmployee,
  isVerified,
  updateUserInfo,
  payoutBackends
}) => {
  const location = useLocation();
  const history = useHistory();
  const query = qs.parse(location.search);
  const [error, setError] = useState(null);
  const [checkError, setCheckError] = useState(null);
  const { t } = useTranslation(['accept', 'common']);
  const hasPaymentMethod = Boolean(payoutBackends.preferred_payment_method);

  useEffect(() => {
    if (query.claim === 'true' && isAuthenticated && isVerified) {
      claimEmployee(query.c);
    } else if (query.claim === 'true' && isAuthenticated) {
      history.push(formatUrl(`/verify/`, { c: query.c }))
    }

    if (query.c) {
      resetCheckClaim();
      checkClaim(query.c)
    }
  }, []);

  useEffect(() => {
    if (claimStatus === status.SUCCESS) {

      if (query.id) {
        history.push(formatUrl(`/ok/payout/`, { id: query.id }))
      } else {
        history.push(formatUrl(`/ok/claimed/`, {auto: query.auto}))
      }
      updateUserInfo({ claimStatus: status.DEFAULT })
    }

    if (claimStatus === status.ERROR) {
      setError(claimError.non_field_errors);
      updateUserInfo({ claimStatus: status.DEFAULT })
    }
  }, [claimStatus]);

  useEffect(() => {
    if (claimCheck.status === status.ERROR) {
      setCheckError(t('Invalid link'));
      if (query.id) {
        history.push(formatUrl(`/ok/payout/`, {id: query.id}))
      } else {
        history.push(formatUrl(`/dashboard/`));
      }
    }
  }, [claimCheck]);

  const submit = () => {
    if (!isAuthenticated) {
      history.push(formatUrl(`/ok/login/`, query))
    } else if ((query.auto === 'True') && !hasPaymentMethod) {
      history.push(formatUrl(`/ok/bankinfo/`, query))
    } else {
      claimEmployee(query.c)
    }

  };
  const { name, logo, message, loading: checkLoading } = claimCheck;
  const isReady = !checkLoading;

  return (
    <div className="content center">
      <div style={{ textAlign: 'center' }}>
        <>
          {checkError && (
            <Alert.Error style={{ marginBottom: 8 }}>
              {checkError}
            </Alert.Error>
          )}
          {!checkError && (
            <>
              {logo &&
                <div className={css.logo}>
                  <img src={logo} alt={name} title={name}/>
                </div>
              }
              <div className={css.container}>
                <P.Title style={{ margin: '15px auto' }} ready={isReady}>
                  <Subtitle small margins={false} >{t('{{name}} wants to add you to their team!', { name })}</Subtitle>
                </P.Title>
                <P.Text ready={isReady} style={{ margin: '15px auto', maxWidth: 320 }} rows={4}>
                  <Paragraph style={{whiteSpace: 'pre-line'}}>
                    {message? message: t('In order to receive your salary, you need to accept the invitation from {{name}}.', { name })}
                  </Paragraph>
                </P.Text>
                {error && (
                  <Alert.Error style={{ marginBottom: 8 }}>
                    {error}
                  </Alert.Error>
                )}
                <Button.Primary
                  responsive
                  disabled={!isReady}
                  loading={claimStatus === status.BEGIN}
                  onClick={submit}
                >
                  {query.id && t('Join and claim')}
                  {!query.id && t('Join')}
                </Button.Primary>
                {query.auto === 'True' && (
                  <div className={css.terms}>
                    <Subtitle small>{t('Terms')}</Subtitle>
                    <Paragraph xsmall margins={false} className="terms">
                      <Trans i18nKey="common:auto-agrement">
                        {[
                          <a key={'terms-link'} rel="noopener noreferrer" target="_blank" href={t('common:termsLink')}></a>,
                          <a key={'data-link'} rel="noopener noreferrer" target="_blank" href={t('common:dataLink')}></a>
                        ]}
                      </Trans>
                    </Paragraph>
                  </div>
                )}
              </div>
            </>
          )}
        </>
      </div>
    </div>
  )
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  isVerified: state.auth.isVerified,
  claimStatus: state.user.claimStatus,
  claimError: state.user.claimError,
  claimCheck: state.employee.claimCheck,
  payoutBackends: state.payoutBackends
});

const mapDispatchToProps = {
  claimEmployee,
  checkClaim,
  resetCheckClaim,
  updateUserInfo,
};


export default connect(mapStateToProps, mapDispatchToProps)(Claim);
