import React, { Component } from 'react'
import Lottie from 'react-lottie';
import PropTypes from 'prop-types';
import animationData from './success.json'

export default class SuccessAnimation extends Component {
  render() {

    const defaultOptions = {
      loop: false,
      autoplay: true, 
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

    return (
      <div>
        <Lottie 
          options={defaultOptions}
          height={this.props.size}
          width={this.props.size}
          isStopped={false}
          isPaused={!this.props.play}
        />
      </div>
    )
  }
}
SuccessAnimation.propTypes = {
  play: PropTypes.bool,
  size: PropTypes.number
}
SuccessAnimation.defaultProps = {
  play: true,
  size: 190
}