import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import { Transition } from '../../components/';



import Authenticator from '../../shared/Authenticator';
import ChooseCompany from './components/ChooseCompany/';
import AccountType from './components/AccountType';
import EmailForm from './components/EmailForm';
import CompanySuccess from './components/CompanySuccess';

const PERSONAL_SIGNUP = 1;
const COMPANY_SIGNUP = 2;


const Signup = ({ accountType, email }) => {
  const history = useHistory();
  const nav = {
    next: path => history.push(path),
    back: () => history.goBack(),
  };

  return (
    <div className="payout">
      <div className="content center">
        <Transition root={'/signup'}>
          <Transition.Item path={root => `${root}/`}>
            <AccountType
              onComplete={() => {
                history.push('/signup/email/')
              }}
              {...nav}
            />
          </Transition.Item>
          <Transition.Item path={root => `${root}/email/`}>
            <EmailForm
              onComplete={(email) => {
                history.push('/signup/login/')
              }}
              accountType={accountType}
              {...nav}
            />
          </Transition.Item>
          <Transition.Item exact={false} path={root => `${root}/login/`}>
            <Authenticator
              onComplete={() => {
                if (accountType === COMPANY_SIGNUP) {
                  history.push("/signup/company");
                } else {
                  history.push("/dashboard");
                }
              }}
              baseName="/signup/login"
              email={email}
              redirectUrl={accountType === COMPANY_SIGNUP ?
                '/signup/company' :
                '/dashboard/'
              }
              {...nav}
            />
          </Transition.Item>
          <Transition.Item path={root => `${root}/company`}>
            <ChooseCompany
              onComplete={() => {
                this.next("/dashboard");
              }}
              {...nav}
            />
          </Transition.Item>
          <Transition.Item path={root => `${root}/complete`}>
            <CompanySuccess
              onComplete={() => {
                this.next("/dashboard");
              }}
              {...nav}
            />
          </Transition.Item>
        </Transition>
      </div>
    </div>
  )
}
export class Signup2 extends Component {

  constructor(props) {
    super(props);
    this.state = {
      signupType: PERSONAL_SIGNUP,
      signingUp: false,
      email: null,
    }
  }

  next = (to) => {
    this.context.router.history.push(to)
  };

  back = () => {
    this.props.history.goBack();
  };

  render() {
    const { location, hasCompany } = this.props;
    const { signupType, signingUp, email } = this.state;
    const nav = {
      next: this.next,
      back: this.back,
    };

    if (this.props.isAuthenticated && !signingUp) {
      // return <Redirect to={'/dashboard'} />
    }

    return (
      <div className="payout">
        <div className="content center">
          <Transition root={'/signup'}>
            <Transition.Item path={root => `${root}/`}>
              <AccountType
                onComplete={() => {
                  this.next('/signup/login')
                }}
                {...nav}
              />
            </Transition.Item>
            <Transition.Item path={root => `${root}/email`}>
              <EmailForm
                onComplete={(email) => {
                  this.setState({ email, signingUp: true });
                  this.next('/signup/login')
                }}
                signupType={signupType}
                {...nav}
              />
            </Transition.Item>
            <Transition.Item path={root => `${root}/login`}>
              <Authenticator
                onComplete={() => {
                  if (this.props.accountType === COMPANY_SIGNUP) {
                    this.next("/signup/company");
                  } else {
                    this.next("/dashboard");
                  }
                }}
                email={email}
                baseName="/signup/login"
                redirectUrl={'/signup/company'}
                {...this.props}
              />
            </Transition.Item>
            <Transition.Item path={root => `${root}/company`}>
              <ChooseCompany
                onComplete={() => {
                  this.next("/dashboard");
                }}
                email={email}
                {...nav}
                {...this.props}
              />
            </Transition.Item>
          </Transition>
        </div>
      </div>
    )
  }
}

Signup.contextTypes = {
  router: PropTypes.object
};

const mapStateToProps = (state) => ({
  companies: state.user.companies,
  isAuthenticated: state.auth.isAuthenticated,
  hasCompany: state.user.hasCompany,
  accountType: state.signup.accountType,
  email: state.signup.email,
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(Signup)
