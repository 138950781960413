import {
  types,
  status,
} from '../constants/action-types';
import _ from 'lodash';
const defaultInvoices = {
  invoices: {},
  loading: false,
  hasNext: false,
  page: 1,
  createStatus: status.DEFAULT,
  createResponse: null,
};


const invoices = (state = defaultInvoices, action) => {
  switch (action.type) {
    case types.SET_INVOICING_DATA:
      return {
        ...state,
        ...action.payload
      }
    case types.FETCH_INVOICE:
      switch (action.meta.status) {
        case status.BEGIN:
          return {
            ...state,
            loading: true,
          };
        case status.SUCCESS:
          return {
            ...state,
            invoices: {
              ...state.invoices,
              [action.payload.id]: {
                ...state.invoices[action.payload.id],
                ...action.payload
              }
            },
            loading: false,
          };
        case status.ERROR:
          return {
            ...state,
            loading: false,
          };
        default:
          return state;
      }

    case types.FETCH_INVOICES:
      switch (action.meta.status) {
        case status.BEGIN:
          return {
            ...state,
            loading: true,
            hasNext: false,
          };
        case status.SUCCESS:
          let list = _.keyBy(action.payload.results, i => i.id);
          return {
            ...state,
            invoices: (action.meta.params && action.meta.params.reset) ? { ...list } : { ...state.invoices, ...list },
            loading: false,
            hasNext: action.payload.next !== null,
          };
        case status.ERROR:
          return {
            ...state,
            loading: false,
            invoices: {}
          };
        default:
          return state;
      }
    case types.CREATE_INVOICING_PAYOUT:
      switch (action.meta.status) {
        case status.BEGIN:
          return {
            ...state,
            createResponse: null,
            createStatus: status.BEGIN
          };
        case status.SUCCESS:
          return {
            ...state,
            createResponse: action.payload,
            createStatus: status.SUCCESS
          };
        case status.ERROR:
          return {
            ...state,
            createStatus: status.ERROR,
            createResponse: action.payload.error || null
          };
        default:
          return state;
      }
    default:
      return state;

  }
}
export default invoices;
