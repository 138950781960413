import React, { useState } from 'react';
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { Title, Dialog, Button, Table, ToolTip, Subtitle } from 'gigapay-ui';
import { numberWithSpaces } from 'utils/price';
import { TableFooter, } from 'components/';
import { format } from 'utils/dateTime';

const Invoices = ({ title, canApprove, data: propData, loadMore }) => {
  const { t } = useTranslation(['dashboard', 'common']);
  const [show, showModal] = useState(false);
  const history = useHistory();
  const { data, loading, hasNext } = propData;
    const [invoice, setInvoice] = useState({
    id: 0,
    paid_at: null,
    price: 0,
  });

  if (data.length === 0) return null;

  return (
    <div className="flex-row-md">
      <div className="flex-col-md-12">

        <Title small style={{ marginTop: '20px', marginBottom: '5px' }}>{title}</Title>
        <Dialog
          show={show}
          onBackdropClick={() => {
            showModal(false);
          }}
          onEscapeKeyDown={() => {
            showModal(false)
          }}
        >
          <div>
            <Subtitle>{t('Invoice')} {invoice.ocr_number}</Subtitle>
            <p>{t('common:Status')}: {(invoice.paid_at === null) ? t('Not paid') : t('Paid')}</p>
            <p>{t('common:Amount')}: {numberWithSpaces(invoice.price)} kr</p>
            {(invoice.paid_at === null) && (
              <Button
                onClick={() => {
                  history.push(`/invoices/${invoice.id}/`)
                }}
                style={{ marginBottom: 0, marginTop: 20 }}
                responsive
                primary
              >
                {t('Pay now')}
              </Button>
            )}
            {(invoice.paid_at !== null) && (
              <Button
                onClick={() => {
                  history.push(`/invoices/${invoice.id}/`)
                }}
                style={{ marginBottom: 0, marginTop: 20 }}
                responsive
                primary
              >
                {t('Show summary')}
              </Button>
            )}
            {invoice.pdf !== '' && (
              <Button
                onClick={() => {
                  window.open(invoice.pdf)
                }}
                style={{ marginBottom: 0, marginTop: 10 }}
                responsive
              >
                {t('Download invoice')}
              </Button>)}

          </div>
        </Dialog>
        <Table
          keyExtractor={(item, index) => item.id}
          data={data}
          onClick={props => {
            history.push(`/invoices/${props.id}/`)
          }}
          columns={[{
            Header: null,
            Cell: props => (props.open) ? (<ToolTip text={t('Open invoice')} label={(<i className="active-invoice-icon"></i>)} />) : null,
            id: 'is_active',
            headerStyle: { flex: '0 0 15px', paddingRight: '4px', paddingLeft: '4px'},
            style: { flex: '0 0 15px' }
          }, {
            Header: t('Invoice number'),
            Cell: props => props.ocr_number || '-',
            id: 'icon',
          }, {
            Header: t('Paid'),
            Cell: props => (props.paid_at === null) ? <span style={{ color: 'red' }}>{t('Not paid')}</span> : format(props.paid_at),
            id: 'date',
          }, {
            Header: <span className="text-align-right">{t('Amount')}</span>,
            Cell: props => <span className="text-align-right">{numberWithSpaces(props.price)} kr</span>,
            id: 'amount',
          }]}
        />
        <TableFooter
          loading={loading}
          hasNext={hasNext}
          onClick={() => {
            loadMore();
          }}
        />
      </div>
    </div>
  )
};


Invoices.propTypes = {
  data: PropTypes.shape({
    data: PropTypes.array,
    count: PropTypes.number,
    hasNext: PropTypes.bool,
    loading: PropTypes.bool,
  }),
  canApprove: PropTypes.bool,
  onChangePage: PropTypes.func,
  page: PropTypes.number,
};


Invoices.defaultProps = {
  data: {
    data: [],
    count: 0,
    hasNext: false,
    loading: false,
  },
  canApprove: false,
  onChangePage: () => { },
  page: 0,
};


export default Invoices;