import React, { Component, useEffect } from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Transition } from '../../../../components';
import Input from './components/InputEmail';
import CheckInbox from './components/CheckInbox';
import { requestToken, reset } from './actions';
import { validEmail } from 'utils/validate';

const Mail = ({ root, redirectUrl, requestToken, email, ...rest }) => {
  useEffect(() => {
    if (validEmail(email)) {
      requestToken({ email, redirect_url: redirectUrl })
    }
  }, []);

  // if email is pre-filled
  if (email) {
    return <CheckInbox email={email} {...rest} />
  }

  return (
    <>
      <Transition root={root}>
        <Transition.Item path={root => `${root}/verify`}>
          <CheckInbox {...rest} />
        </Transition.Item>
        <Transition.Item path={root => `${root}/`}>
          <Input redirectUrl={redirectUrl} {...rest} />
        </Transition.Item>
      </Transition>
    </>
  )
}
const mapDispatchToProps = dispatch => ({
  requestToken: data => dispatch(requestToken(data)),
  reset: () => dispatch(reset())
});

Mail.propTypes = {
  switchMode: PropTypes.func,
}
Mail.defaultProps = {
  switchMode: () => { },
}

export default connect(null, mapDispatchToProps)(Mail)
