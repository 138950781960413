import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { ReactReduxContext } from 'react-redux'
import { getHeaders } from 'utils/requestHelpers';
import { invoicingUrl } from 'config';

const CancelToken = axios.CancelToken;
let cancelToken = null;

export const usePricing = (v, t) => {
  const { store } = useContext(ReactReduxContext);
  const reduxState = store.getState();
  const [loading, setLoading] = useState(false);
  const [recipients, setRecipients] = useState([{
    pricing: {
      cost: 0,
      amount: 0,
      payroll: 0,
      fee: 0,
    }
  }]);
  const [error, setError] = useState(null);
  const d = {[t]: v};


  useEffect(() => {
    if (cancelToken && typeof cancelToken === 'function') {
      cancelToken();
      cancelToken = null;
    }
    setLoading(true);
    const r1 = {
      description: '-',
      company: {
        name: 'Placeholder AB',
        email: 'pricing@gigapay.co'
      },
      ...d,
    };
    axios({
      url: `${invoicingUrl}/pricing/?expand=company`,
      cancelToken: new CancelToken(function (cancel) {
        cancelToken = cancel;
      }),
      headers: { ...getHeaders(reduxState) },
      method: 'post',
      responseType: 'json',
      data: r1,
    })
      .then((response) => {
        setLoading(false);
        if (response.status === 201) {
          setRecipients([
            {
              pricing: response.data
            }
          ])
        }
      })
      .catch(function (error) {
        if (error.response) {
          setError(error.response.data)
        }
        setLoading(false);
      });
  }, [v, t]);
  return { recipients, loading, error };

};