import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import * as qs from 'query-string';
import { Icon, Loader, Alert, Paragraph } from 'gigapay-ui'
import { Header, Container, Section, List } from 'components/Invoice'
import moment from 'moment';
import {useHistory, useLocation, useParams} from 'react-router-dom'
import { fetchOpenInvoice, connectOpenInvoice } from 'actions/receivedInvoice';
import css from './invoice.module.css';
import PaymentModal from "../Invoice/ReceivedInvoice/containers/PaymentModal";
import ConnectModal from "./components/ConnectModal"
import {CARD_MAX_LIMIT} from 'config/';
import { asNumber } from '../../utils/number';
import { numberWithSpaces } from "../../utils/price";


const Symbol = ({ name }) => {
  const n = (!name || name.length === 0) ? '' : name.charAt(0);
  return (
    <div className={css.symbol}>{n}</div>
  )
};

const ReceivedInvoice = ({
  fetchOpenInvoice,
  connectOpenInvoice,
  loading,
  openInvoice,
  isAuthenticated,
  selectedCompany
}) => {
  const { t } = useTranslation('invoices');
  const location = useLocation();
  const query = qs.parse(location.search);
  const [showModal, setShowModal] = useState(false);
  const [showPaymentMsg, setShowPaymentMsg] = useState(false);
  const history = useHistory();
  let { id } = useParams();
  useEffect(() => {
    if (id) {
      fetchOpenInvoice(id, query.token)

    }
  }, []);
  const [showDialog, setShowDialog] = useState(false);
  const closeDialog = () => {
    setShowDialog(false)
  };

  if (!openInvoice.payouts) {
    return null;
  }
  const { payouts } = openInvoice;
  const toPay = loading ? <b><Loader inline small /></b> : (openInvoice.paid_at === null) ? <b>{numberWithSpaces(openInvoice.price)} {openInvoice.currency}</b> : <b>0 {openInvoice.currency}</b>
  const netSum = payouts.reduce((accumulator, currentValue) => accumulator + currentValue.cost, 0);
  const openIntercom = (e) => {
    e.preventDefault();
    window.Intercom('show');
  };
  const connect = (e) => {
    e.preventDefault();
    if (isAuthenticated && selectedCompany) {
      connectOpenInvoice(id, query.token)
    } else {
      setShowDialog(true)
    }
  };

  return (
    <Container>
      <ConnectModal
        showDialog={showDialog}
        closeDialog={closeDialog}
        connect={() => connectOpenInvoice(id, query.token)}
      />

      <PaymentModal
        invoice={openInvoice}
        visible={showModal}
        onComplete={() => {
          setShowModal(false);
          setShowPaymentMsg(true);
        }}
        onCancel={() => setShowModal(false)}
      />
      <>
        <Header>
          <Header.Title
            title={openInvoice.sender}
            subtitle={<span>{t('Invoice date')} {moment(openInvoice.created_at).format('YYYY-MM-DD')}</span>}
            recipient={(
              <>
                <span>{openInvoice.company.name}</span><br />
                {openInvoice.company.address_line_1}<br />
                {openInvoice.company.zip_code} {openInvoice.company.city}<br />
              </>
            )}
          />
          <Header.Summary
            alert={showPaymentMsg && <Alert.Success style={{ marginTop: 0, marginBottom: 20 }}>{t('Payment successful')}!</Alert.Success>}
            amountTitle={t('To pay')}
            amountComponent={(
              <>
                {
                  showPaymentMsg && <b>0 kr</b>
                }
                {
                  !showPaymentMsg && <>
                    {(toPay)}
                    <small>({t('incl. VAT')})</small>
                  </>
                }
              </>
            )}
            list={[
              { key: 'bankgiro', label: 'Bankgiro', value: '5129-2423' },
              { key: 'ocr', label: t('Invoice-/OCR-n'), value: openInvoice.ocr_number },
              { key: 'duedate', label: t('invoices:Due date'), value: moment(openInvoice.created_at).add(30, 'days').format('YYYY-MM-DD') },
            ]}

          />
          <Header.Buttons
            buttons={[
              { key: 'pay', label: <span><Icon name={'credit-card'} />{t('Pay by card')}</span>, onClick: () => setShowModal(true) , condition: (asNumber(openInvoice.price) < CARD_MAX_LIMIT && !showPaymentMsg && openInvoice.paid_at === null)},
              { key: 'download', label: (<a href={openInvoice.pdf}><Icon name={'document'} />{openInvoice.paid_at === null ? t('Download PDF') : t('Download receipt')} </a>), onClick: () => window.open(openInvoice.pdf)},
              { key: 'connect', label: <span><Icon name={'link'} />{t('Connect to account')}</span>, onClick: connect, condition: !openInvoice.connected },
              { key: 'contact', label: <a href={`mailto:${openInvoice.email}`}><Icon name={'mail'} />{t('Contact my supplier')}</a>, onClick: () => window.open(`mailto:${openInvoice.email}`)},
              { key: 'help', label: <span><Icon name={'help-buoy'} />{t('Help')}</span>, onClick: openIntercom },
            ]}
          />
        </Header>
        <Section title={t('Specification')}>
          <List
            label={t('From')}
            sum={t('Amount')}
            items={payouts.map(i => ({
              label: i.description,
              sum: i.cost
            }))}
            price={openInvoice.price}
          />
        </Section>
        <Section title={t('About the invoice')}>
          <Paragraph small>Efter förfallodagen debiteras dröjsmålsränta med 8.5%. För skriftlig betalningspåminnelse debiteras även påminnelseavgift enligt gällande lag. Faktura utställd genom faktureringstjänsten Gigapay Frilans av {openInvoice.sender}. Betalning sker med befriande verkan till Bankgiro 5129-2423 Gigapay AB. Frågor och anmärkningar gällande fakturan skall ställas till {openInvoice.email}.</Paragraph>
        </Section>
      </>
    </Container>
  )
};

const mapStateToProps = (state) => ({
  loading: state.receivedInvoice.loading,
  openInvoice: state.receivedInvoice.invoice,
  isAuthenticated: state.auth.isAuthenticated,
  selectedCompany: state.user.currentCompany,
});
const mapDispatchToProps = {
  fetchOpenInvoice,
  connectOpenInvoice
};
export default connect(mapStateToProps, mapDispatchToProps)(ReceivedInvoice);
