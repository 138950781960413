import React, { Fragment } from 'react';
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next';
import NumberBox from '../../../NumberBox'
import css from './HasCompanyHeader.module.css';

const HasCompanyHeader = ({ statistic, loadingStatistic, t, activePrice }) => (

  <Fragment>
    <div className="flex-row-md">
      <div className="flex-col-md-8">
        <span className="intro-text">{t('dashboard:WelcomeMessageCompany')}</span>
      </div>
      <div className="flex-col-md-4">
      </div>
    </div>

    <div className={css.headerParent}>
      {
        (activePrice !== 0) &&
        (<div className={css.headerBox}>
          <NumberBox
            amount={parseInt(activePrice)}
            loading={false}
            label={t('Active invoice amount')}
          />
        </div>
        )
      }
      <div className={css.headerBox}>
        <NumberBox
          amount={statistic.invoices_not_paid}
          loading={loadingStatistic}
          unit={""}
          label={t('Unpaid invoices')}
        />
      </div>
      <div className={css.headerBox}>
        <NumberBox
          amount={statistic.payouts_not_accepted}
          loading={loadingStatistic}
          unit={""}
          label={t("Payouts waiting for recipient")}
        />
      </div>
      <div className={css.headerBox}>
        <NumberBox
          amount={statistic.total_payouts}
          loading={loadingStatistic}
          unit={""}
          label={t('Number of payouts')}
        />
      </div>
    </div>


  </Fragment>
);

HasCompanyHeader.propTypes = {
  statistic: PropTypes.shape({
    total_payouts: PropTypes.number,
    payouts_not_accepted: PropTypes.number,
    invoices_not_paid: PropTypes.number
  })
};

HasCompanyHeader.defaultProps = {
  statistic: {
    total_payouts: null,
    payouts_not_accepted: null,
    invoices_not_paid: null,
  }
};


export default withTranslation('dashboard')(HasCompanyHeader);