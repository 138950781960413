import {Account} from 'js_bankkonto';


const supportedBanks = {
  trustly: [
    "SEB",
    "Nordea",
    "Swedbank",
    "Handelsbanken",
    "Danske Bank"
  ],
  brite: [
    "SEB",
    "Nordea",
    "Swedbank",
    "Handelsbanken",
  ],
  bankgiro: [],
};


export const validate = (clearing = '', account = '', provider='') => {
  const value = `${clearing}:${account}`;
  const a = new Account(value);
  const json = a.toJson();

  return {
    supportFastTransfer: supportedBanks[provider].indexOf(json.bank) >= 0,
    bank: json.bank,
    isValid: json.isValid,
    clearing: {
      isValid: json.clearing.isValid,
      format: json.clearing.format,
    },
    account: {
      isValid: json.account.isValid,
      format: json.account.format,
    },
    raw: json
  }
};