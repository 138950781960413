import {
  types,
  status,
} from '../constants/action-types';

const defaultInvoice = {
  loading: false,
  invoice: {},
};


const receivedInvoice = (state = defaultInvoice, action) => {
  switch (action.type) {

    case types.FETCH_OPEN_INVOICE:
      switch (action.meta.status) {
        case status.BEGIN:
          return {
            ...state,
            invoice: {},
            loading: true,
          };
        case status.SUCCESS:
          return {
            ...state,
            loading: false,
            invoice: action.payload
          };
        case status.ERROR:
          return {
            ...state,
            loading: false,
          };
        default:
          return state;
      }
    case types.CONNECT_OPEN_INVOICE:
      switch (action.meta.status) {
        case status.BEGIN:
          return {
            ...state,
            loading: true,
          };
        case status.SUCCESS:
          return {
            ...state,
            loading: false,
            invoice: action.payload
          };
        case status.ERROR:
          return {
            ...state,
            loading: false,
          };
        default:
          return state;
      }
    default:
      return state;

  }
};
export default receivedInvoice;
