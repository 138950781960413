import React, { Component, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation, useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Elements, StripeProvider, injectStripe } from 'react-stripe-elements';
import { stripePublicKey, CARD_MAX_LIMIT } from 'config/';
import {
  Icon,
  Loader,
  Alert,
} from 'gigapay-ui'
import moment from 'moment';
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { formatPrice, numberWithSpaces } from 'utils/price';

import { fetchInvoice, fetchPayoutsByInvoice } from 'actions/invoice';
import { currentCompany } from 'actions/company';
import PaymentModal from './containers/PaymentModal/';
import css from './invoice.module.css';

const Symbol = ({ name }) => {
  const n = (!name || name.length === 0) ? '' : name.charAt(0);
  return (
    <div className={css.symbol}>{n}</div>
  )
}

const ReceivedInvoice = ({
  fetchInvoice,
  fetchPayoutsByInvoice,
  invoice,
  companyName,
  loading
}) => {
  const { t } = useTranslation(['invoice'])
  const [showModal, setShowModal] = useState(false)
  const [showPaymentMsg, setShowPaymentMsg] = useState(false)
  const history = useHistory();
  const location = useLocation();
  let { id } = useParams();
  useEffect(() => {
    if (id) {
      fetchInvoice(id)
      fetchPayoutsByInvoice(id);
    }
  }, []);
  const { payouts } = invoice;
  const toPay = loading ? <b><Loader inline small /></b> : (invoice.paid_at === null) ? <b>{`${numberWithSpaces(invoice.price)} kr`}</b> : <b>0 kr</b>
  const netSum = payouts.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.cost), 0);

  return (
    <>
      <PaymentModal
        invoice={invoice}
        visible={showModal}
        onComplete={() => {
          setShowModal(false)
          setShowPaymentMsg(true)
        }}
        onCancel={() => setShowModal(false)}
      />
      <div className={css.invoiceContainer}>
        <section className="invoice-top">
          <header className="invoice-header">
            <div className="invoice-sender">
              <Symbol name={companyName} />
              <div className="name">
                {t('Invoice')}
                {invoice.created_at && <span>{t('Invoice date')} {moment(invoice.created_at).format('YYYY-MM-DD')}</span>}
              </div>
            </div>
            <div className="invoice-recipient">
              
            </div>
          </header>

          <summary>
            {
              showPaymentMsg && <Alert.Success style={{ marginTop: 0, marginBottom: 20 }}>{t('Payment successful')}!</Alert.Success>
            }
            <div className="amount-to-pay">
              <span>{t('To pay')}</span>
              {
                showPaymentMsg && <b>0 kr</b>
              }
              {
                !showPaymentMsg && toPay
              }
              <small>({t('ink VAT')})</small>
            </div>
            <dl>
              <dt>Bankgiro</dt>
              <dd>5129-2423</dd>

              <dt>{t('Invoice-/OCR-n')}</dt>
              <dd>{invoice.ocr_number}</dd>
            </dl>
          </summary>
          <div className="payment-buttons" style={{ marginTop: 15, float: 'left' }}>
            {
              (invoice.price !== "" && invoice.price < CARD_MAX_LIMIT && invoice.paid_at === null && !showPaymentMsg) && (<button onClick={() => setShowModal(true)}>
                <span><Icon name={'credit-card'} />{t('Pay by card')}</span>
              </button>)
            }
            <button><a href={`${invoice.pdf}`}><Icon name={'document'} />{invoice.paid_at === null ? t('Download PDF') : t('Download receipt')}</a></button>
          </div>
        </section>

        <section className="invoice-content">
          <h2>{t('Specification')}</h2>
          <div className="accept-thead">
            <span className="employer">{t('From')}</span>
            <span className="amount">{t('Amount')}</span>
          </div>
          <ul className="payout-company-list">
            {payouts.map((i) => (
              <li key="1s">
                <span className="employer">{i.employee.name} ({t('Gross salary')}: {numberWithSpaces(i.amount)} kr)</span>
                <span className="amount">{numberWithSpaces(i.cost)} kr</span>
              </li>
            ))}
          </ul>
          <div className={"spec"}>
            <ul className={"spec-list"}>
              <li>
                <span className="spec-label">{t('Net Sum')}</span>
                <span className="spec-sum">{formatPrice(netSum)} kr</span>
              </li>
              <li>
                <span className="spec-label">{t('VAT')} (25 %)</span>
                <span className="spec-sum">{formatPrice(parseFloat(invoice.price) - netSum)} kr</span>
              </li>
              <li className="bold">
                <span className="spec-label">{t('Gross Total')}</span>
                <span className="spec-sum">{numberWithSpaces(invoice.price)} kr</span>
              </li>
            </ul>
          </div>
        </section>
      </div>
    </>
  )
};



const errorMsg = {
  backgroundColor: '#ffeaea',
  display: 'block',
  padding: '5px',
  float: 'left',
  width: '100%',
  boxSizing: 'border-box',
  fontSize: '12px',
  textAlign: 'center',
  color: '#650000',
  border: '1px solid #ffbdbd',
  borderRadius: '3px',
  marginBottom: '10px',
}



const InjectedInvoice = injectStripe(withTranslation(['invoice'])(ReceivedInvoice));
const InjectedInvoiceView = (props) => (
  <StripeProvider apiKey={stripePublicKey}>
    <Elements>
      <InjectedInvoice {...props} />
    </Elements>
  </StripeProvider>
);


const mapStateToProps = (state) => ({
  invoice: state.invoice,
  loading: state.invoice.loading,
  payoutLoading: state.payout.loading,
  paymentStatus: state.payout.paymentStatus,
  companyName: state.user.currentCompany && currentCompany(state).name || '',
});
const mapDispatchToProps = {
  fetchInvoice,
  fetchPayoutsByInvoice
}
export default connect(mapStateToProps, mapDispatchToProps)(InjectedInvoiceView);
