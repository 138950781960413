import React, { useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash'
import { Transition } from 'components/';
import { useTranslation } from 'react-i18next';
import { Subtitle, Paragraph, Button, Radio, Link } from 'gigapay-ui'
import { useHistory } from 'react-router-dom'
import {
  AddRecipients,
  Pay,
  Done,
  Reset,
  Invoice,
  Notice,
  NoticeDone
} from './containers'
import { updateUserInfo } from 'actions/user';


const PickCompany = ({ companyList, selectCompany }) => {
  const { t } = useTranslation('payout');
  const [company, setCompany] = useState(null);
  const history = useHistory();
  return (
    <div className="payout">
      <div className="content center">
        <Subtitle style={{ textAlign: 'center' }}>{t('Choose a company')}</Subtitle>
        <div className="spacing" />
        <div style={{ maxWidth: 250, margin: 'auto', display: 'block' }}>
          <Radio
            options={companyList.map(company => ({
              label: company.name,
              value: company.id,
            }))}
            value={company}
            onChange={company => {
              setCompany(company)
            }}
          />
          <div className="spacing" />
          <Button.Primary
            responsive
            onClick={() => {
              const currentCompany = company ? company : companyList[0].id;
              selectCompany({ currentCompany, mode: 'company' });
            }}
          >
            {t('common:Next')}
          </Button.Primary>
          <Link
            onClick={() => history.push('/signup/company')}
            className={'action-link'}
            primary
            bold
          >{t('Register company')} +</Link>
        </div>

      </div>
    </div>
  )
};
const CreateCompany = () => {
  const { t } = useTranslation('payout');
  const history = useHistory();
  return (
    <div className="payout">
      <div className="content center" style={{ textAlign: 'center' }}>
        <Subtitle>{t('You have no company registered')}</Subtitle>
        <Paragraph>{t('In order to create a payout you need a company')}</Paragraph>
        <Button.Primary onClick={() => history.push('/signup/company')}>{t('Register company')} +</Button.Primary>
      </div>
    </div>
  )
};

const Payout = ({ mode, hasCompany, companies, selectCompany }) => {
  const companyList = _.values(companies);
  if (mode === 'person' && companyList.length > 0) {
    return <PickCompany selectCompany={selectCompany} companyList={companyList} />
  }
  if (!hasCompany) {
    return <CreateCompany />
  }

  return (
    <div className="payout">
      <div className="content center">
        <Transition
          root={'/payout'}
          defaultPath={`/payout/step/add`}
        >
          <Transition.Item path={root => `${root}/step/add`}>
            <AddRecipients />
          </Transition.Item>
          <Transition.Item path={root => `${root}/new`}>
            <Reset />
          </Transition.Item>
          <Transition.Item path={root => `${root}/step/pay`}>
            <Pay />
          </Transition.Item>
          <Transition.Item path={root => `${root}/step/done`}>
            <Done />
          </Transition.Item>
          <Transition.Item path={root => `${root}/step/invoices/:invoice_id/`}>
            <Invoice />
          </Transition.Item>
          <Transition.Item path={root => `${root}/step/notice`}>
            <Notice />
          </Transition.Item>
          <Transition.Item path={root => `${root}/step/notice/done`}>
            <NoticeDone />
          </Transition.Item>
        </Transition>
      </div>
    </div>
  )
};

const mapStateToProps = state => ({
  mode: state.user.mode,
  hasCompany: state.user.hasCompany,
  companies: state.user.companies,
});

const mapDispatchToProps = dispatch => ({
  selectCompany: (data) => {
    dispatch(updateUserInfo(data));
  }
});


export default connect(mapStateToProps, mapDispatchToProps)(Payout);

