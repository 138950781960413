import { asPhoneNumber } from 'utils/number';

export const errorToString = serverError => {
  let errorString = '';
  if (serverError !== null) {
    for (var key in serverError) {
      errorString = `${errorString} ${serverError[key]}`;
    }
  }
  return errorString;
};

export const transformPayout = recipients => {

  const body = recipients.map(r => {
    let data = {
      employee: {
        name: r.employee.name,
      },
      description: r.description || 'Lön genom Gigapay',
    };
    if (r.inputType === 'cost') {
      data = {
        ...data,
        cost: r.inputValue,
        full_salary_specification: true
      }
    } else {
      data = {
        ...data,
        amount: r.inputValue,
        full_salary_specification: false
      }
    }
    if (r.id){
      data.id = r.id;
    }
    if(r.start_at){
      data.start_at = r.start_at;
    }
    if(r.end_at){
      data.end_at = r.end_at;
    }
    if (r.employee.cellphone_number && r.employee.cellphone_number !== null && r.employee.cellphone_number !== '') {
      return {
        ...data,
        employee: {
          ...data.employee,
          cellphone_number: asPhoneNumber(r.employee.cellphone_number)
        }
      }
    }
    return {
      ...data,
      employee: {
        ...data.employee,
        email: r.employee.email
      }
    }
  });
  return body;

};


export const getHeaders = (state) => {
  const token = state.auth.token;
  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  if (token !== null) {
    headers = {
      ...headers,
      'Authorization': `Token ${token}`,
    };
  }
  const language = state.auth.language;
  if (language !== null) {
    headers = {
      ...headers,
      'Accept-Language': `${language}`,
    };
  }
  const currentCompany = state.user.currentCompany;
  if (currentCompany !== null && (state.user.companies && state.user.companies.length !== 0)) {
    headers = {
      ...headers,
      'Integration-ID': `${state.user.companies[currentCompany].webapp_integration_id}`,
    };
  }
  return headers;
};