import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Transition } from 'components/';
import { useHistory, useLocation } from 'react-router-dom'
import { paymentReset } from 'actions/payout-payment'
import { resetRecipients } from 'actions/payout';
import * as qs from 'query-string';
import { format as formatUrl } from 'utils/url';

const Reset = ({ paymentReset, resetRecipients, recipients }) => {
  const history = useHistory();
  const location = useLocation();
  // 
  const query = qs.parse(location.search);
  useEffect(() => {
    resetRecipients();
    paymentReset();
    history.replace(formatUrl('/payout/step/add/', query))
  }, []);

  return null;
}

const mapStateToProps = (state) => ({
  recipients: state.payout.recipients
});
const mapDispatchToProps = {
  paymentReset,
  resetRecipients
};


export default connect(mapStateToProps, mapDispatchToProps)(Reset);
