import React from 'react';
import { Table, Icon, Link } from 'gigapay-ui'
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { format } from 'utils/dateTime';
import { numberWithSpaces } from 'utils/price';
import css from '../../payouts.module.css';

const PersonTable = ({ data }) => {
  const history = useHistory();
  const { t } = useTranslation(['dashboard', 'common'])

  return (
    <Table
      selectable={false}
      keyExtractor={(item) => item.id}
      data={data}
      onClick={(props) => history.push("/ok/payout/?id=" + props.id)}
      columns={[{
        Header: t('common:Description'),
        Cell: props => (
          <div className="cell-container">
            <span>
              <span className="bold-mobile">{props.description}</span>
              <span className="mobileOnly table-subtitle">{props.employer}</span>
            </span>

          </div>),
        id: 'desciption',
        className: 'main-cell',
        headerClassName: 'main-cell',
      }, {
        Header: t('common:Date'),
        Cell: props => <span>{format(props.created_at)}</span>,
        id: 'date',
        className: 'tableCell-desktopOnly',
        headerClassName: 'tableCell-desktopOnly'
      }, {
        Header: t('common:Company'),
        Cell: props => <span>{props.employer}</span>,
        id: 'employer',
        className: 'tableCell-desktopOnly',
        headerClassName: 'tableCell-desktopOnly'
      }, {
        Header: <span className="text-align-right">{t('common:GrossSalary')}</span>,
        Cell: props => <span className="text-align-right">{numberWithSpaces(props.amount)} kr</span>,
        id: 'amount',
      }, {
        Header: null,
        Cell: props => <Icon style={{ textAlign: 'right', marginRight: 16 }} name={'arrow-forward'} />,
        id: 'arrow',
        headerClassName: 'arrow-cell',
        className: 'arrow-cell',
      }]}
    />

  )
}
export default PersonTable;