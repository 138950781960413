import { configFile } from 'config';


// Converts number to string and adds spaces after each third digit. Removes all decimals for Payhour.
export const numberWithSpaces = (x) => {
    if (configFile === 'payhour') {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ").replace(/([.][0-9]+$)/g, "")
    } else {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }
};

/*
    Rounding is not a bijective function. Since we need the price and tax-
    calculations to be invertible, we can't simply round the values to two
    decimal places (SEK.ÖRE).

    Instead we use this custom round function. It first rounds to three decimal
    places (The extra decimal has no physical meaning) and then truncates the
    value to two decimal places. Any uncertainty due to the rounding is
    contained in the last decimal, which is simply removed, and as a result
    we get nice, invertible calculations.
*/
export const customRounding = (decimal) => {
    const rounded = parseFloat(decimal.toFixed(3));
    const truncated = Math.floor(rounded * 100)/100;
    return truncated;
};

// Formats the price, change Math.round to custom round in backend.
export const formatPrice = (price) => {
    return numberWithSpaces(customRounding(price));
};
