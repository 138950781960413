import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom'
import { validPhoneNumber } from '../../../../utils/validate'
import {
  Title,
  Paragraph,
  Fieldset,
  Input,
  Icon,
  Strong,
  Subtitle,
  Button,
  Alert
} from 'gigapay-ui'
import { PriceBarList } from 'components';
import { useEncryption } from 'hooks/encryption';


const Create = ({ name }) => {
  const history = useHistory();
  const { t } = useTranslation('request');

  // user input
  const [description, setDescription] = useState('');
  const [amount, setAmount] = useState('')
  const [amountType, setAmountType] = useState('cost')
  const [cellphone_number, setCellphone] = useState('')

  const [error, setError] = useState(null)
  const [showError, setShowError] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const [encrypted, setEncrypted] = useEncryption()
  useEffect(() => {
    if (amount === '' || parseInt(amount) === 0) {
      setError(t('payout:No amount to pay'))
      setIsValid(false)
    } else if (description === '') {
      setError(t('payout:Invalid description'))
      setIsValid(false)
    } else if (!validPhoneNumber(cellphone_number)) {
      setError(t('payout:Invalid phone number'))
      setIsValid(false)
    } else {
      setError(null)
      setIsValid(true)
    }
    setEncrypted({
      description,
      amount,
      amountType,
      employee: {
        name,
        cellphone_number
      }
    })
  }, [amount, amountType, description, cellphone_number])

  const submit = () => {
    history.push(`/request/done/?url=${encodeURIComponent(encrypted)}`)
  }
  return (
    <div>
      <Title.Line small>{t('Request payout')}</Title.Line>
      <Paragraph small>{t('Fill in the amount and description so that a link is generated for your pay request.')}</Paragraph>

      <Subtitle small>{t('Work performed')}</Subtitle>
      <Fieldset>
        <Input
          leftIcon={<Icon name={'person'} size={24} />}
          label={t('common:Description')}
          onChange={(description) => setDescription(description)}
          value={description}
        />
        <Input
          leftIcon={<Icon name={'cash'} size={22} />}
          className="amount-row"
          label={t('common:Amount')}
          inputMode="numeric"
          pattern="[0-9]*"
          onChange={(amount) => setAmount(amount)}
          value={amount}
        >
          <Input.Picker
            onChange={v => setAmountType(v.value)}
            options={[
              { label: t('common:GrossSalary'), description: t('common:Taxes and fees will be added'), value: 'gross' },
              { label: t('common:PayrollCost'), description: t('common:Taxes and fees will be included'), value: 'cost' }
            ]}
            value={amountType}
          />
        </Input>
      </Fieldset>

      <div className={'spacing'} />

      <Subtitle small>{t('common:Your phone number')}</Subtitle>
      <Fieldset>
        <Input
          leftIcon={<Icon name={'phone'} />}
          label={t('common:Phone number')}
          validate={validPhoneNumber}
          inputMode="tel"
          type={"tel"}
          onChange={(cellphone_number) => {
            setCellphone(cellphone_number)
          }}
          value={cellphone_number || ''}
        />
      </Fieldset>
      {
        error && showError && (
          <Alert.Error>
            { error }
          </Alert.Error>
        )
      }
      <div style={{ marginBottom: 20, float: 'left', display: 'block', width: '100%' }}>
        <PriceBarList recipients={[{
          amount,
          amountType,
          employee: {
            name,
            cellphone_number
          }
        }]} />
        <Button 
          onClick={submit}
          disabled={!isValid}
          primary
          responsive
          disabledOnClick={() => {
            setShowError(true)
          }}
        >
          {t('Next')}
        </Button>
      </div>
      <Strong inline={false}>{t('What happens next?')}</Strong>
      <Paragraph small margins={false}>{t('By clicking Next, a link is generated to an invoice base that you send to companies you worked for. When they click on the link and pay the invoice, your salary will be deposited into your bank account after your approval. You can easily get paid without having your own company.')}</Paragraph>
    </div>
  )
}
const mapStateToProps = (state) => ({
  cellphone_number: state.user.cellphone_number,
  name: state.user.name
});
const mapDispatchToProps = dispatch => ({

});


export default connect(mapStateToProps, mapDispatchToProps)(Create);

