import React, { useState } from 'react'
import { connect } from 'react-redux';
import List from './components/List'
import { SuspenseWrapper } from 'components/'
import { batchCreateEmployee, resetImportEmployee } from 'actions/employee';
const Upload = React.lazy(() => import('./components/Upload'));


const ImportEmployee = ({ batchCreateEmployee, resetImportEmployee, error, status }) => {
  const [employees, setEmployees] = useState([]);
  const submit = e => {
    batchCreateEmployee(e);
  };
 
  return (
    <div className="content center">
      {employees.length === 0 && (
        <SuspenseWrapper>
          <Upload
            onSuccess={v => setEmployees(v)}
          />
        </SuspenseWrapper>
      )}
      {employees.length !== 0 && (
        <List
          employees={employees}
          onClear={() => setEmployees([])}
          onRemove={(id) => {
            resetImportEmployee();
            setEmployees(employees.filter(r => r.id !== id));
          }}
          onSubmit={submit}
          error={error}
          status={status}
          resetImportEmployee={resetImportEmployee}
        />
      )}
    </div>
  )
};


const mapStateToProps = (state) => ({
  status: state.employee.importEmployees.status,
  error: state.employee.importEmployees.error,
});

const mapDispatchToProps = {
  batchCreateEmployee,
  resetImportEmployee
} ;

export default connect(mapStateToProps, mapDispatchToProps)(ImportEmployee)

