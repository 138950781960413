import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import { changeLanguage } from 'actions/user';
import css from './footer.module.css';

const Footer = ({ language, changeLanguage }) => {
  const { t } = useTranslation('common')
  const languageList = [
    { key: 'sv', string: 'Svenska' },
    { key: 'en', string: 'English' },
  ];

  const changeLang = lang => {
    changeLanguage(lang);
  };

  return (
    <div className={css.tinyFooter}>
      <span className={css.c}>&copy; {t('companyName')} {moment().format('YYYY')}</span>
      <ul className={css.lang}>
        {
          languageList.map(l => {
            const isSelected = (l.key === language) ? css.selected : '';
            return (
              <li key={l.key} className={isSelected}><span onClick={() => changeLang(l.key)}>{l.string}</span></li>
            )
          })
        }
      </ul>
    </div>
  )
};

const mapStateToProps = state => ({
  language: state.auth.language,
});

const mapDispatchToProps = {
  changeLanguage
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
