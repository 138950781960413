import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import * as qs from 'query-string';
import { useLocation } from 'react-router-dom'
import {
  Paragraph,
  Title,
  Button,
  Input,
  Fieldset,
  Alert,
  Icon,
} from 'gigapay-ui'
import { updateUserLead } from 'actions/verify';
import { validCoordinationNumber } from 'utils/validate'
import { asPersonalNumber } from 'utils/number'
import { errorToString } from 'utils/requestHelpers';
import css from './personalinfo.module.css';


const PersonalInformation = ({
  onComplete,
  personalStatusResponse,
  identity_number,
  updateUserLead,
  loading
}) => {
  const location = useLocation();
  const query = qs.parse(location.search);
  const { t } = useTranslation('signup');
  const [residentPermit, setResidentPermit] = useState(null);
  const [identityNumber, setIdentityNumber] = useState('');
  const [address, setAddress] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [city, setCity] = useState('');
  const [name, setName] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    if (identity_number !== '') {
      onComplete()
    }
  }, [identity_number]);

  useEffect(() => {
    if (
      personalStatusResponse &&
      personalStatusResponse.token &&
      Array.isArray(personalStatusResponse.token) &&
      personalStatusResponse.token.length !== 0) {
      setError(personalStatusResponse.token[0])
    }
    if (
      personalStatusResponse &&
      personalStatusResponse.error) {
      setError(errorToString(personalStatusResponse.error))
    }
  }, [personalStatusResponse]);

  const isValid =  (!(address.length < 5) &&
    validCoordinationNumber(identityNumber) &&
    !(city.length < 2) &&
    (zipCode.length === 5));

  const submit = () => {


    if (!validCoordinationNumber(identityNumber)) {
      setError(t('Coordination number is required'));
      return
    }
    if (address.length < 5) {
      setError(t('Invalid address'));
      return
    }
    if (city.length < 2) {
      setError(t('Invalid city'));
      return
    }
    if (!(!isNaN(parseInt(zipCode)) && zipCode.length === 5)) {
      setError(t('Invalid zipcode'));
      return
    }
    const body = {
      identity_number: asPersonalNumber(identityNumber),
      zip_code: zipCode,
      address,
      city,
      name,
    };
    updateUserLead(body)
  };

  return (
    <div>
      <Title.Line small>{t('Personal information')}</Title.Line>
      <Paragraph small margins={false}>{t('Add information about where you live and your coordination number')}</Paragraph>
      <div className="spacing" />
      <div>
        <Fieldset>
          <Input
            label={t('Your name')}
            name={'name'}
            value={name}
            validate={v => v.length > 2}
            onChange={v => setName(v)}
            leftIcon={<Icon name={'person'} />}
          />
          <Input
            label={t('Address')}
            leftIcon={<Icon name={'business'} />}
            onChange={v => setAddress(v)}
            value={address}
            name={'address'}
            validate={v => v.length > 3}
          />
          <Fieldset.Row>
            <Input
              leftIcon={true}
              label={t('Zip code')}
              onChange={v => setZipCode(v)}
              value={zipCode}
              name={'zipcode'}
              validate={v => !isNaN(parseInt(v)) && v.length === 5}
            />
            <Input
              
              label={t('City')}
              value={city}
              name={'city'}
              validate={v => v.length > 2}
              onChange={v => setCity(v)}
            />
          </Fieldset.Row>

          <Input
            label={t('Coordination number')}
            value={identityNumber}
            onChange={v => setIdentityNumber(v)}
            validate={validCoordinationNumber}
            inputMode="numeric"
            pattern="[0-9]*"
          />

        </Fieldset>

        {error && <Alert.Error>{error}</Alert.Error>}

        <div className="spacing" />
        <Button.Primary
          responsive
          disabledOnClick={submit}
          disabled={!isValid}
          loading={loading}
          onClick={submit}
        >
          {t('Save')}
        </Button.Primary>
      </div>
    </div>
  )
};


const mapStateToProps = (state) => ({
  onMobile: state.user.onMobile,
  loading: state.verify.loading,
  identity_number: state.verify.identity_number,
  personalStatusResponse: state.verify.personalStatusResponse
});

const mapDispatchToProps = {
  updateUserLead
};


export default connect(mapStateToProps, mapDispatchToProps)(PersonalInformation);
