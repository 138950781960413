import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next';
import { Title, Icon, Subtitle } from 'gigapay-ui';
import { Placeholder as P } from 'components/'
import { configFile } from 'config';
import { format } from 'utils/dateTime';


const Header = ({ canBeAccepted, loading, payouts }) => {
  const { t } = useTranslation(['accept', 'common']);
  const dates = payouts.map(p => format(p.accepted_at)).join(', ');
  return (
      <P.Title ready={!loading} style={{ height: 44 }}>
        <Title.Line small>{canBeAccepted ? t('Request'): t('Payout')}</Title.Line>
        <Subtitle style={{fontSize: "16px"}}>{canBeAccepted ? t('The cost of receiving an instant payment with Swish is 5%.') : t("Requested payout {{dates}}, the payout will be in your account within a couple of minutes if you're connected to Swish.", { dates })}</Subtitle>
      </P.Title>
  )
};

Header.propTypes = {
  canBeAccepted: PropTypes.bool,
  loading: PropTypes.bool,
  payouts: PropTypes.array
};

Header.defaultProps = {
  canBeAccepted: false,
  loading: true,
  payouts: []
};

export default Header
