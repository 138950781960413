import React from 'react';
import { Subtitle, Link } from 'gigapay-ui';
import { useTranslation } from 'react-i18next';
import Recipient from './Recipient';
import { usePayouts } from 'hooks/payoutsV2'

const RecipientsForm = () => {
  const { t } = useTranslation('payout')
  const { payouts, add  } = usePayouts();
  return (
    <>
      <Subtitle small>{t('Recipient')}</Subtitle>
      <div className="receivers">
        {payouts.map((r, i) => (
          <Recipient
            key={`id-${r.id}`}
            {...r}
          />
        ))}
      </div>
      <Link onClick={() => add()} primary bold>{t('Add more')} +</Link>
    </>
  )
}


export default RecipientsForm;
