import React, { useReducer, useContext, useEffect, useState } from 'react';
import equal from 'deep-equal';
import _ from 'lodash';
import axios from 'axios';
import uuid from 'uuid-random';
import { ReactReduxContext } from 'react-redux'
import { privateUrl } from 'config';
import { validEmail, validPhoneNumber } from 'utils/validate'
import { getHeaders, transformPayout } from 'utils/requestHelpers';

const CancelToken = axios.CancelToken;
const Context = React.createContext();
const defaultPricing = {
  amount: 0,
  payroll: 0,
  fee: 0,
  cost: 0,
  error: null
};
const errorToString = serverError => {
  let errorString = '';
  delete serverError.id;
  if (serverError !== null) {
    for (var key in serverError) {
      errorString = `${errorString} ${serverError[key]}`;
    }
  }
  return errorString;
};
export const usePayouts = () => {
  const [error, setError] = useState(null);
  const { state, dispatch } = useContext(Context);
  
  useEffect(() => {
    if (state.loading) {
      return;
    }
    if (state.error !== null){
      setError(state.error);
      return;
    }

    const validPrices = state.payouts.every(p => p.pricing && p.pricing.error === null && p.pricing.cost !== 0);
    if (!validPrices) {
      setError('Enter valid amount');
      return
    }
    const validRecepient = state.payouts.every(recipient =>
      (
        validPhoneNumber(recipient.employee.cellphone_number) ||
        validEmail(recipient.employee.email)
      )
    );
    if (!validRecepient) {
      setError('Invalid phone number or email');
      return;
    }
    setError(null)
    //
  }, [state.payouts]);

  return {
    error,
    loading: state.loading,
    payouts: state.payouts,
    add: (args = {}) => dispatch({
      type: 'ADD',
      payload: {
        id: uuid(),
        inputValue: '',
        inputType: 'gross',
        description: '',
        pricing: { ...defaultPricing },
        employee: {
          name: '',
          cellphone_number: '',
        },
        ...args
      }
    }),
    addBatch: data =>  dispatch({ 
      type: 'ADD_BATCH', 
      payload: { payouts: data.map(p => ({
        ...p,
        pricing: { ...defaultPricing },
      })) } 
    }),
    set: data => dispatch({ type: 'SET', payload: { payouts: data } }),
    remove: id => dispatch({ type: 'REMOVE', payload: id }),
    update: d => dispatch({ type: 'UPDATE', payload: d }),
    clear: () => dispatch({ type: 'CLEAR', payload: { payouts: [] } }),
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE':
      return {
        ...state,
        payouts: state.payouts.map(r => {
          return r.id === action.payload.id ?
            { ...r, ...action.payload, pricing: { ...defaultPricing, ...r.pricing } } :
            r;
        })
      }
    case 'CLEAR':
        return {
          ...state,
          payouts: [],
        }
    case 'SET_PRICING_BEGIN':
      return {
        ...state,
        error: null,
        loading: true,
      };
    case 'SET_PRICING_ERROR':
      return {
        ...state,
        loading: false,
        error: action.payload && action.payload.detail ? action.payload.detail : null,
        payouts: state.payouts.map((r, i) => {
          const errorIndex = _.findIndex(action.payload, d => d.id === r.id);
          return (errorIndex === i) ?
            { ...r, pricing: { ...r.pricing, error: errorToString(action.payload[errorIndex]) } } :
            { ...r, pricing: { ...r.pricing, error: null } };
        })
      };
    case 'SET_PRICING':
      let prices = _.keyBy(
        action.payload.map(r => ({ ...r, error: null })
        ), 'id')
      return {
        ...state,
        loading: false,
        error: null,
        payouts: state.payouts.map(r => {
          if (prices[r.id]) {
            return {
              ...r,
              pricing: prices[r.id]
            }
          }
          return r;
        })
      }
    case 'SET':
      return {
        ...state,
        payouts: [...action.payload]
      }
    case 'REMOVE':
      return {
        ...state,
        payouts: state.payouts.filter(r => {
          return r.id !== action.payload;
        })
      }
    case 'ADD_BATCH':
      return {
        ...state,
        payouts: action.payload.payouts,
      }
    case 'ADD':
      return {
        ...state,
        payouts: [
          ...state.payouts,
          action.payload
        ]
      }
    default:
      return state;
  }
}


let cancelToken = null;

const RecipientProvider = ({ init, children }) => {
  const [state, dispatch] = useReducer(reducer, {
    payouts: init,
    error: null,
    loading: false,
  });

  const { store } = useContext(ReactReduxContext)
  const reduxState = store.getState();
  const [previusPayouts, setPreviusPayouts] = useState(init)
  useEffect(() => {
    const r1 = formatPayout(state.payouts);
    if (shouldFetchPrices(r1, previusPayouts)) {
      setPreviusPayouts(r1)
      if (cancelToken && typeof cancelToken === 'function') {
        cancelToken();
        cancelToken = null;
      }
      dispatch({ type: 'SET_PRICING_BEGIN' });
      axios({
        url: `${privateUrl}/pricing/?expand=employee`,
        cancelToken: new CancelToken(function (cancel) {
          cancelToken = cancel;
        }),
        headers: { ...getHeaders(reduxState) },
        method: 'post',
        responseType: 'json',
        data: r1,
      })
        .then((response) => {
          if (response.status === 201) {
            dispatch({ type: 'SET_PRICING', payload: response.data })
          }
        })
        .catch(function (error) {
          if (error.response) {
            dispatch({ type: 'SET_PRICING_ERROR', payload: error.response.data });
          }
        });

    }
    
  }, [state.payouts]);


  useEffect(() => {
    if (init.length !== 0) {
      dispatch({
        type: 'SET',
        payload: init.map(r => ({
          ...r,
          pricing: { ...defaultPricing },
        }))
      });
    }
  }, [init]);
  return (
    <Context.Provider value={{ state, dispatch }}>
      {children}
    </Context.Provider>
  )
}
export default RecipientProvider;


function shouldFetchPrices(payouts, previusPayouts) {
  return !(!payouts ||
    payouts.length === 0 ||
    equal(payouts, previusPayouts)
  );
}

function formatPayout(payout) {
  return transformPayout(
    payout
      .filter(r => (r.inputValue !== '' && r.inputValue !== null && !isNaN(parseInt(r.inputValue))))
      .filter(r => validEmail(r.employee.email) || validPhoneNumber(r.employee.cellphone_number))
      .map(p => {
        return {
          ...p,
          description: '-',
          employee: { ...p.employee, name: '-' }
        };
      }));
}