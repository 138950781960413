import React, { useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom'
import { Paragraph, Title, Button, Radio } from 'gigapay-ui'
import css from './selectworkinpermit.module.css';
import Footer from '../Footer';
import { updateUserLead } from 'actions/verify';

const SelectWorkPermit = ({ onMobile, work_permit_type, loading, updateUserLead }) => {
  const { t } = useTranslation('signup');
  const history = useHistory();
  const [workingPermitType, setWorkingPermitType] = useState('LMA-card');
  useEffect(() => {
    //work_permit_type
    if (work_permit_type === 'LMA-card') {
      history.push('/verify/permit/LMA/front')
    } else if (work_permit_type === 'Residence permit') {
      history.push('/verify/permit/residence/front')
    } else if (work_permit_type === 'Holiday working visa') {
      history.push('/verify/permit/holidayvisa/front')
    } else if (workingPermitType === 'EU-citizen') {
      history.replace('/verify')
    }
  }, [work_permit_type])
  const onSubmit = () => {
    updateUserLead({
      work_permit_type: workingPermitType
    });
  };
  return (
    <div>
      <Title.Line small>{t('Working permit')}</Title.Line>
      <Paragraph small margins={false}>
        <Trans i18nKey="signup:working-permit-info">
          {[
            <a href="#" className={css.supportLink}></a>
          ]}
        </Trans>
      </Paragraph>
      <div className="spacing" />
      <div>
        <Radio
          options={[
            {
              label: t('LMA Card'),
              description: t('LMA card for asylum seekers'),
              value: 'LMA-card'
            },
            {
              label: t('Swedish residence permit'),
              description: t('If you have been granted a residence permit'),
              value: 'Residence permit'
            },
            {
              label: t('Working holiday visa'),
              description: t('Working holiday visa for young people'),
              value: 'Holiday working visa'
            },
            {
              label: t('EU-citizen'),
              description: t('If you are a residence of an EU country'),
              value: 'EU-citizen'
            }
          ]}
          value={workingPermitType}
          onChange={value => {
            setWorkingPermitType(value)
          }}
        />
      </div>
      <div className={"spacing"} />
      <Button.Primary
        responsive
        loading={loading}
        onClick={onSubmit}
      >
        {t('Next')}
      </Button.Primary>
      <Footer />
    </div>
  )
};

const mapStateToProps = (state) => ({
  onMobile: state.user.onMobile,
  work_permit_type: state.verify.work_permit_type,
  loading: state.verify.loading,
});

const mapDispatchToProps = {
  updateUserLead,

};


export default connect(mapStateToProps, mapDispatchToProps)(SelectWorkPermit);
