import React from 'react';
import {Alert, Icon} from 'gigapay-ui'
import css from './header.module.css';

const Header = ({ children = null }) => {
  return (
    <div className={css.headerContainer}>
      {children}
    </div >
  )
}

const Summary = ({ amountTitle = '', amountComponent = null, list = [], alert = null}) => {
  return (
    <summary className={css.summary}>
      {alert}
      <div className={css.amountToPay}>
        <span className={css.amountToPayTitle}>{amountTitle}</span>
        {amountComponent}
      </div>
      <dl className={css.propList}>
        {list.map(item => (
          <React.Fragment key={item.key}>
            <dt>{item.label}</dt>
            <dd>{item.value}</dd>
          </React.Fragment>
        ))}
      </dl>
    </summary>
  )
}

const Buttons = ({ buttons = [] }) => {
  return (
    <div className={css.btnList}>
      {buttons.map(({ onClick = null, key, label, condition = true, href = null}) => (condition ?
          <button onClick={onClick} key={key}>
            {label}
          </button> : null
      ))}
    </div>
  )
}
const Symbol = ({ name }) => {
  if (name) {
    const n = (!name || name.length === 0) ? '' : name.charAt(0);
    return (
      <div className={css.symbol}>{n}</div>
    )
  }
  return (
    <div className={css.symbol}>
      <Icon name={'document'} size={29} />
    </div>
  )
};

const Title = ({
  title = null,
  subtitle = null,
  symbol = null,
  recipient = null
}) => {
  return (
    <header className={css.header}>
      <div className={css.titleContent}>
        <Symbol name={symbol} />
        <div className={css.titleWrapper}>
          {title && <span className={css.title}>{title}</span>}
          {subtitle && <span className={css.subtitle}>{subtitle}</span>}
        </div>
      </div>
      <div className={css.recipient}>
        {recipient}
      </div>
    </header>
  )
}
Header.Summary = Summary;
Header.Title = Title;
Header.Buttons = Buttons;

export default Header;