import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { configFile } from 'config';
import default_en from './en.json';
import default_sv from './sv.json';
import payhour from './payhour.json';
import payhour_en from './payhour-en.json';


if (configFile === 'payhour') {
  const en = payhour_en;
  const sv = payhour;

  i18n
    .use(initReactI18next)
    .init({
      fallbackLng: 'en',
      resources: {en, sv},
      keySeparator: false,
      react: {
        wait: true,
        bindStore: false,
        bindI18n: 'languageChanged',
      },
      debug: false
    });
} else {
  const en = default_en;
  const sv = default_sv;

  i18n
    .use(initReactI18next)
    .init({
      fallbackLng: 'en',
      resources: {en, sv},
      keySeparator: false,
      react: {
        wait: true,
        bindStore: false,
        bindI18n: 'languageChanged',
      },
      debug: false
    });
}


export default i18n;