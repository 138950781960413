import {
  types
} from '../constants/action-types';
import { get, post, patchRaw, patch } from '../utils/fetchV3';

export const currentCompany = (state) =>
  state.user.companies[state.user.currentCompany];

const url = (parms = '') => config =>
  `${config.host}/${parms}`;

const privateUrl2 = (parms = '') => config =>
  `${config.privateUrl}/${parms}`;

  
export const fetchCompanyEngagements = () =>
  get(types.FETCH_COMPANY_ENGAGEMENTS, url(`checkbiz/engagements/`));

export const createCompany = data => 
  post(types.CREATE_COMPANY, url(`leads/company/`), data);

export const verifyCompany = id => 
  post(types.VERIFY_COMPANY, url(`leads/company/${id}/approve/`));

export const uploadLogo = (id, formData) => 
  patchRaw(types.UPLOAD_LOGO, privateUrl2(`companies/${id}/`), formData, { id })

export const saveCompany = (id, data) =>
  patch(types.SAVE_COMPANY, privateUrl2(`companies/${id}/`), data, { id })
