import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { Title } from 'gigapay-ui';
import { useTranslation } from 'react-i18next';

const Greating = ({ name, company }) => {
  const { t } = useTranslation('dashboard');
  const hr = new Date().getHours();

  if (company) {
    return <Title.Line>{name}</Title.Line>
  }
  let timeOfTheDay = '';
  if (hr < 12) {
    timeOfTheDay = t('Good morning');
  } else if (hr >= 12 && hr <= 17) {
    timeOfTheDay = t('Good afternoon');
  } else if (hr >= 17 && hr <= 24)
    timeOfTheDay = t('Good evening');

  if (name === '')
    return <Title.Line>{timeOfTheDay}!</Title.Line>
  return (
    <Title.Line>{timeOfTheDay}, {name}!</Title.Line>
  );
}

Greating.propTypes = {
  name: PropTypes.string,
  company: PropTypes.bool,
}
Greating.defaultProps = {
  name: '',
  company: false,
}

export default Greating;