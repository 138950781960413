import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
import { theme } from 'config/';
import ThemeProvider from 'gigapay-ui';
import './I18n/'
import { configFile } from 'config';

import reduxStore from './store/';
import './assets/css/flex-grid.css';
import './assets/css/style.css';

import Title from './shared/Title';
import Navigation from './shared/Navigation/'

import ErrorHandler from './routes/ErrorHandler/';
import AuthenticationGate from './shared/AuthenticationGate/';
import Intercom from './shared/Intercom';
import Hotjar from './shared/Hotjar/'
import css from "./components/Invoice/Components/Header/header.module.css";
const { store, persistor } = reduxStore;


const App = (props) => {

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <ErrorHandler>
          <PersistGate loading={null} persistor={persistor}>
            <AuthenticationGate>
              <Title />
              <Hotjar />
              {(configFile !== 'payhour') && <Intercom appID="h3avgnar" />}
              <Navigation />
            </AuthenticationGate>
          </PersistGate>
        </ErrorHandler>
      </ThemeProvider>
    </Provider>
  )
};

export default App;