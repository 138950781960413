import React, { Component, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";
import {
  Paragraph,
  Title,
  Icon,
  Link,
} from 'gigapay-ui'
import * as qs from 'query-string';


const CheckInbox = ({ back, email }) => {
  const { t } = useTranslation()  
  const location = useLocation();
  const queryStrings = qs.parse(location.search);
  const emailString = queryStrings.email || email;
  useEffect(() => {
    console.log("CheckInbox is mounted")
  }, [])

  return (
    <div style={{ textAlign: 'center' }}>
      <Icon name={'mail'} size={55} />
      <Title small>{t('Verify your email by going to your inbox')}</Title>
      <Paragraph>{t(`We sent an email with login link to {{email}}. If you're unable to find the email, please check your spam folder.`, { email: emailString })}</Paragraph>
      <Link onClick={() => back()} primary bold>{t('Go back')}</Link>
    </div>
  );
}

export default CheckInbox;