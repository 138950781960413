import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  Title,
  Subtitle,
  Paragraph,
  Button,
  Link,
  Icon,
  Dialog,
  Loader,
  Fieldset,
  Input,
  ContextMenu,
  Alert,
  DatePicker,
  Select,
  Strong
} from 'gigapay-ui'
import { useTranslation } from 'react-i18next';
import css from '../../payouts.module.css';
import { useOutsideClick } from 'hooks/utils';
import moment from 'moment';
const Filter = ({
  values = {
    from: null,
    to: null
  },
  onComplete = () => { },
  onClear = () => { }
}) => {
  const wrapperRef = useRef(null);
  const { t } = useTranslation('payouts');
  const [visable, setVisable] = useState(false)
  const [dates, setDates] = useState({
    from: values.created_at_before ? new Date(values.created_at_after) : null,
    to: values.created_at_before ? new Date(values.created_at_before) : null
  })
  useEffect(() => {
    setDates({
      from: values.created_at_before ? new Date(values.created_at_after) : null,
      to: values.created_at_before ? new Date(values.created_at_before) : null
    })
  }, [values]);
  const hasFilter = !(values.created_at_after === null && values.created_at_before === null);
  useOutsideClick(wrapperRef, () => {
    if (visable) {
      setVisable(!visable)
    }
  })

  const clear = () => {
    onClear({
      created_at_before: null,
      created_at_after: null
    })
  }
  const save = React.useCallback(() => {
    onComplete({
      created_at_before: dates.to,
      created_at_after: dates.from
    });
    setVisable(false)
  }, [dates])

  return (
    <div className={css.filterContainer}>
      <div className={`${css.filterBtn} ${hasFilter ? css.activeBtn : ''}`} onClick={() => setVisable(!visable)}>
        <Icon name={'filter'} size={15} color={'currentColor'} />
        <span className={css.btnlabel}>{t('Filter')}</span>
      </div>
      <div
        className={`${css.filterDropDown} ${visable ? css.visible : ''}`}
        ref={wrapperRef}
      >
        <div className={css.filterHead}>
          <Strong>Filtera</Strong>
          <Link bold primary onClick={clear}>{t('Clear')}</Link>
        </div>
        <div>
          <Fieldset>
            <DatePicker.Range
              value={dates}
              label={t('Date')}
              Icon={<Icon name={'calendar'} />}
              onChange={dates => {
                setDates(dates)
              }}
              onBlur={() => { }}
              onFocus={() => { }}
              minDate={new Date('2018-09-12')}
              options={{

                maxDate: null,
                numberOfMonth: 1,
              }}
            />
            {/*<Select
              label={'Status'}
              Icon={<Icon name={'checkmark-circle'} color={'#A8A8A8'} size={18} />}
              options={[
                { value: 'all', label: 'All' },
                { value: '', label: 'Approved' },
                { value: 'waint', label: 'Wating' }
              ]}
            />
            */}
          </Fieldset>
        </div>
        <Button.Primary responsive onClick={save}>{t('Search')}</Button.Primary>
      </div>
    </div>
  )
}
export default Filter;