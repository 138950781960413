import {createStore,applyMiddleware,combineReducers} from "redux";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import thunk from 'redux-thunk';

import * as reducers from '../reducers/';
import * as loginRederucers from '../shared/Authenticator/modules/reducers';
const combine = {...loginRederucers, ...reducers};

const persistConfig = {
    key: 'root/v0.22',
    storage: storage,
    whitelist: ['payout','auth','user','signup'],
};


// Custom root-reducer that allows us the reset-the state
const appReducer = combineReducers(combine);
const rootReducer = (state, action) => {
  if (action.type === 'RESET') {
    state = undefined;
  }
  return appReducer(state, action)
};
const pReducer = persistReducer(persistConfig, rootReducer);


const createStoreWithMiddleware = applyMiddleware(thunk)(createStore);
const store = createStoreWithMiddleware(pReducer);

export const persistor = persistStore(store);

if(process.env.REACT_APP_STAGE === "development"){
   store.subscribe(() => console.log(store.getState()))
}

export default {store, persistor};
