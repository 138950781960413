import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button, Link } from 'gigapay-ui';
import Greating from '../Greating/'
import { fetchInvoices, fetchStatistic } from 'actions/data';
import { currentCompany } from 'actions/company';
import { Header, Invoices } from './components/'
import css from './company_dashboard.module.css';

const CompanyDashbard = ({
  companyName,
  currentCompany,
  invoices,
  statistic,
  loadingStatistic,
  fetchStatistic,
  fetchInvoices,
}) => {
  const { t } = useTranslation('dashboard');
  const history = useHistory();
  const [invoicePage, setInvoicePage] = useState(1);

  useEffect(() => {
    setInvoicePage(1);
    fetchInvoices({ page: 1, company: currentCompany});
    fetchStatistic(`companies/${currentCompany}`);
  }, [currentCompany]);

  // calculate the active price
  let activePrice = 0;
  const activeInvoices = invoices.data.filter(i => i.open === true);
  if (activeInvoices.length !== 0) {
    const activeInvoice = activeInvoices[0];
    if (activeInvoice && activeInvoice.price) {
      activePrice = activeInvoice.price;
    }
  }

  return (
    <div className="content">
      <div className={`flex-row-md ${css.companyFlexRow}`}>
        <div className="flex-col-md-8">
          <Greating company name={companyName} />
        </div>
        <div className="flex-col-md-4">
          <div className="company-create-container">
            <Button
              onClick={() => {
                history.push('/payout/new')
              }}
              responsive
              primary
            >
              {t('New payout')} +
            </Button>
            <Link
              primary
              bold
              className="notice-link"
              onClick={() => {
                history.push('/payout/step/notice')
              }}>
              {t('New pre-registration')}
            </Link>
          </div>
        </div>
      </div>
      <Header
        activePrice={activePrice}
        statistic={statistic}
        loadingStatistic={loadingStatistic}
      />

      <Invoices
        data={invoices}
        loadMore={() => {
          const nextPage = invoicePage + 1;
          setInvoicePage(nextPage);
          fetchInvoices({ page: nextPage, company: currentCompany });
        }}
        title={t('Invoices')}
      />

    </div>
  );
};

const mapStateToProps = (state) => ({
  hasCompany: state.user.hasCompany,
  invoices: state.data.invoices,

  currentCompany: state.user.currentCompany,
  companyName: state.user.currentCompany && currentCompany(state).name || '',
  statistic: state.data.statistic,
  loadingStatistic: state.data.loadingStatistic,
  loading: state.data.loading,
});
const mapDispatchToProps = dispatch => ({
  fetchStatistic: (mode) => dispatch(fetchStatistic(mode)),
  fetchInvoices: (args, params) => dispatch(fetchInvoices(args, params))
});


export default connect(mapStateToProps, mapDispatchToProps)(CompanyDashbard);