import React, { Fragment, useState } from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  Title,
  Fieldset,
  Input,
  Paragraph,
  Subtitle,
  Icon
} from 'gigapay-ui'
import { useTranslation, Trans, withTranslation } from 'react-i18next';
import { validEmail } from "../../../../utils/validate";
import { registerEmail } from "../../../../actions/user";

const EmailForm = ({
  accountType,
  registerEmail,
  onComplete
}) => {
  const { t } = useTranslation('signup');
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);

  const submit = (e) => {
    e.preventDefault();
    if (!validEmail(email)) {
      setError('Invalid email');
      return;
    }
    registerEmail({
      email,
      type: accountType
    })
    onComplete({ email })
  }

  return (
    <div className="step">
      <Title.Line small>{t('Your contact information')}</Title.Line>
      <Paragraph>{t('Enter an email that you want to connect to your account.')}</Paragraph>

      <Fragment>
        <Subtitle small>{t('Your email')}</Subtitle>
        <Fieldset>
          <Input
            leftIcon={<Icon name={'mail'} />}
            label={"Email"}
            type={"email"}
            onChange={(email) => {
              setEmail(email);
              setError(null);
            }}
            validate={validEmail}
            value={email}
            error={error}
          />
        </Fieldset>

        <Button
          primary
          responsive
          disabledPress={submit}
          onClick={submit}
        >
          {t('common:Next')}
        </Button>

      </Fragment>

    </div>
  )
}

EmailForm.propTypes = {
  accountType: PropTypes.number,
};

EmailForm.defaultProps = {
  accountType: ''
}

const mapDispatchToProps = dispatch => ({
  registerEmail: data => dispatch(registerEmail(data)),
});

export default connect(null, mapDispatchToProps)(withTranslation('signup')(EmailForm))
