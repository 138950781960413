import React from 'react';
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { Title, Table, Link } from 'gigapay-ui'
import { numberWithSpaces } from 'utils/price';
import { Avatar as UserAvatar } from 'components';
import { Title as PTitle, Table as PTable } from 'components/Placeholder'
import css from './dispatch.module.css';
import male from './assets/male.png';
import { format } from 'utils/dateTime';


const UserIcon = () => {
  return <img src={male} className="gender" alt="User icon" />
};
/*
    0: inväntar betalning
    1: Inväntar godkännande
    2: Godkänd
*/
const DispatchPayouts = ({ title, loadMore, data: propData, }) => {
  const { t } = useTranslation('dashboard');
  const history = useHistory();
  const { data, loading, hasNext } = propData;

  const getStatus = props => {
    if (props.accepted_at) {
      return <span style={{ color: 'green' }}>{t('Paid out')}</span>
    } else if (props.notified_at) {
      return <span>{t('Awaiting to be claimed by recipient')}</span>
    } else {
      return <span style={{ color: 'orange' }}>{t('Waiting for payment')}</span>
    }
  };

  const isReady = !loading;
  //if (data.length === 0) return null;

  return (
    <div className="flex-row-md">
      <div className="flex-col-md-12">

        {(loading || (!loading && data.length !== 0)) && (
          <>
            <PTitle ready={isReady}>
              <Title small>{title}</Title>
            </PTitle>
            <PTable ready={isReady}>
              <Table
                keyExtractor={(item, index) => item.id}
                data={data}
                columns={[{
                  Header: t('common:Person'),
                  Cell: props => (
                    <div className="cell-container">
                      <div style={{ display: 'inline-block', marginRight: 8 }}>
                        <UserAvatar name={props.employee.name} />
                      </div>
                      <span>
                        <Link
                          onClick={() => history.push(`employees/${props.employee.id}/`)}
                          className="bold-mobile"
                        >
                          {props.employee.name}
                        </Link>
                        <span className="tableCell-mobileOnly table-subtitle">{getStatus(props)} - {format(props.created_at, t)}</span>
                      </span>
                    </div>),
                  id: 'icon',
                  className: 'main-cell',
                  headerClassName: 'main-cell',
                }, {
                  Header: t('common:Date'),
                  Cell: props => <span>{format(props.created_at)}</span>,
                  id: 'date',
                  className: 'tableCell-desktopOnly',
                  headerClassName: 'tableCell-desktopOnly'
                }, {
                  Header: t('common:Status'),
                  Cell: props => getStatus(props),
                  id: 'status',
                  className: 'tableCell-desktopOnly',
                  headerClassName: 'tableCell-desktopOnly'
                }, {
                  Header: <span className="text-align-right">{t('common:GrossSalary')}</span>,
                  Cell: props => <span className="text-align-right">{numberWithSpaces(props.amount)} kr</span>,
                  id: 'amount',
                }]}
              />
            </PTable>
          </>
        )}
        {
          hasNext && (
            <div className={css.showAll}>
              <Link primary bold>Show all</Link>
            </div>
          )
        }
      </div>
    </div>
  )
};

DispatchPayouts.propTypes = {
  data: PropTypes.shape({
    data: PropTypes.array,
    count: PropTypes.number,
    hasNext: PropTypes.bool,
    loading: PropTypes.bool,
  }),
  canApprove: PropTypes.bool
};

DispatchPayouts.defaultProps = {
  data: {
    data: [],
    count: 0,
    hasNext: false,
    loading: false,
  },
  canApprove: false,
};


export default DispatchPayouts;