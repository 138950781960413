import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types'
import { Button, Fieldset, Input, Subtitle, Icon, Select, Alert, Paragraph} from 'gigapay-ui'
import { useLocation } from 'react-router-dom'
import * as qs from 'query-string';
import { useTranslation } from 'react-i18next';
import { status } from 'constants/action-types';
import { validEmailorEmpty, validEmail } from 'utils/validate'
import { Msg } from 'components/';
import i18n from 'I18n/'
import css from './personalsettings.module.css';


const HR = () => (
  <div className="hr"></div>
);


const PersonalSettings = ({ save, email, saveStatus, language = 'sv', updateUserInfo }) => {
  const location = useLocation();
  const query = qs.parse(location.search);
  const [showMsg, setShowMsg] = useState(null);
  const [showVerifiedMessage, setShowVerifiedMessage] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const { t } = useTranslation(['settings', 'common']);
  const [emailChanged, setEmailChanged] = useState(false);
  const [userInfo, setUserInfo] = useState({ email, language });

  useEffect(() => {
    setUserInfo({ ...userInfo, email });
    if (validEmail(email)) {
      setIsValid(true);
    }
  }, [email]);

  useEffect(() => {
    if (saveStatus === status.SUCCESS) {
      updateUserInfo({ saveStatus: status.DEFAULT })
    }
    if (saveStatus === status.ERROR || saveStatus === status.SUCCESS) {
      setShowMsg(saveStatus)
    }

  }, [saveStatus]);

  useEffect(() => {
    if (query.verifed) {
      setShowVerifiedMessage(true)
    }
  }, []);

  const submit = () => {
    const newData = {};
    if (!validEmailorEmpty(userInfo.email)) {
      setIsValid(false);
      return;
    }
    if (userInfo.email !== email) {
      setEmailChanged(true);
      newData.email = userInfo.email;
    }
    if(language !== userInfo.language){
      newData.language = userInfo.language;
      i18n.changeLanguage(userInfo.language);
    }
    save(newData);
  };

  return (
    <>
      <div className="flex-row-md">
        <div className="flex-col-md-12">
          {emailChanged && (
            <Alert.Warning className={css.warning}>
              <div className={css.verifyEmailContainer}>
                <div className={css.iconContainer}>
                  <Icon name={'mail'} className={css.mailIcon} />
                </div>
                <div className={css.verifyEmailContent}>
                  <b>{t('Verify Your E-mail Address')}</b>
                  <span>{t('We have sent an e-mail to you for verification. Follow the link to update your email. Please contact us if you do not receive it within a few minutes.')}</span>
                </div>
              </div>
            </Alert.Warning>
          )}
          {showVerifiedMessage && !emailChanged && (
            <Alert.Success className={css.warning}>
              {t('Your email has been verifed.')}
            </Alert.Success>
          )}
        </div>
      </div>
      <div className="flex-row-md">
        <div className="flex-col-md-12">
          <Subtitle small style={{ marginTop: '24px' }}>{t('Your information')}</Subtitle>
          <Paragraph margins={false}>{t('We\'d like your email so we can reach out to you.')}</Paragraph>
        </div>
      </div>
      <div className="flex-row-md">
        <div className="flex-col-md-7">
          <Fieldset>
            <Input
              leftIcon={<Icon name={'mail'} size={19} />}
              label={"Email"}
              value={userInfo.email}
              type="email"
              validate={validEmailorEmpty}
              onChange={email => {
                setIsValid(validEmail(email));
                setUserInfo({
                  ...userInfo,
                  email
                })
              }}
            />
            <Select
              value={userInfo.language}
              label={t('Language')}
              Icon={<Icon name={'globe'} color={'#A8A8A8'} size={23} />}
              onChange={v => {
                setUserInfo({...userInfo, language: v.value })
              }}
              onBlur={() => { }}
              onFocus={() => { }}
              options={[
                { value: 'sv', label: 'Swedish' },
                { value: 'en', label: 'English' }
              ]}
            />
          </Fieldset>
        </div>
        <div className="flex-col-md-5"></div>
      </div>

      <div className="flex-row-md" style={{ marginTop: 8 }}>
        <div className="flex-col-md-12">
          <Button.Primary
            style={{ float: 'left' }}
            onClick={submit}
            disabled={!isValid}
            loading={saveStatus === status.BEGIN}
          >
            {t('common:Save')}
          </Button.Primary>
          <Msg
            status={showMsg}
            onSuccess={t('The change is saved')}
            onError={t('Something went wrong')}
            style={{ lineHeight: '48px', marginLeft: '10px' }}
          />
        </div>
      </div>
    </>
  )
};

PersonalSettings.propTypes = {
  isValid: PropTypes.bool,
  onSubmit: PropTypes.func,
};

PersonalSettings.defaultProps = {
  isValid: false,
  onSubmit: () => { }
};


export default PersonalSettings;