import React from 'react';
import { useHistory, useLocation } from "react-router-dom";
import * as qs from 'query-string';
import { connect } from 'react-redux';

import Authenticator from '../../shared/Authenticator';


const Login = (props) => {
  const history = useHistory();
  const location = useLocation();
  const queryStrings = qs.parse(location.search);
  const redirectUrl = queryStrings.redirect_url || '/dashboard';

  return (
    <div
      className="content center"
      style={{
        minHeight: '75vh',
      }}
    >
      <Authenticator
        onComplete={() => {
          history.push(redirectUrl)
        }}
        baseName="/login"
        {...props}
      />
    </div>
  );
}



export default connect(null, null)(Login);

