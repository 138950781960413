import React from 'react'
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import * as qs from 'query-string';
import {
  Title,
  Paragraph,
  Fieldset,
  Input,
  Icon,
  Strong,
  Subtitle,
  Button,
  Alert
} from 'gigapay-ui'
import { SuccessAnimation } from 'components';
import { appUrl } from 'config';

export const Success = () => {
  const { t } = useTranslation('request')
  const location = useLocation();
  const queryStrings = qs.parse(location.search);
  if(!queryStrings.url){
    return <span>Link missing</span>
  }
  return (
    <div style={{ textAlign: 'center' }}>
      <SuccessAnimation />
      <Title>{t('Done')}!</Title>
      <div style={{ maxWidth: 320, margin: 'auto' }}>
        <Fieldset>
          <Input
            leftIcon={<Icon name={'link'} />}
            readOnly
            value={`${appUrl}/payout/step/add?source=${encodeURIComponent(queryStrings.url)}`}
            label={t('Your link')}
          />
        </Fieldset>
      </div>
      <div style={{ margin: 'auto', display: 'block', float: 'left', marginTop: 30 }}>
        <Subtitle small>{t('What happens now?')}</Subtitle>
        <p style={{ fontSize: 14, marginTop: 5 }}>{t('Send the link to the company you worked for. When they click on the link and pay the invoice, your salary will be deposited into your bank account after your approval. This way you can easily get paid without having your own company.')}</p>
      </div>
    </div>
  )
}
const mapStateToProps = (state) => ({

});
const mapDispatchToProps = dispatch => ({

});


export default connect(mapStateToProps, mapDispatchToProps)(Success);

