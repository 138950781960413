import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom'
import * as qs from 'query-string';
import uuid from 'uuid-random';
import {
  Title,
  Icon,
  BoxSelector,
} from 'gigapay-ui';
import { SuspenseWrapper } from 'components/'
import RecipientProvider from 'hooks/payoutsV2';
import { setRecipients, setEmployeesById } from 'actions/payout'
import Simple from './components/Simple'
import { useDecrypt } from 'hooks/encryption';
const File = React.lazy(() => import('./components/File'));


const initRecipient = (reset) => {
  return reset.length === 0 ? [{
    id: uuid(),
    inputValue: '',
    inputType: 'gross',
    description: '',
    employee: {
      name: '',
      cellphone_number: '',
      value: '',
    }
  }] : reset;
};

const AddRecipients = ({ recipients, setRecipients, loadingEmployees, employee, setEmployeesById }) => {
  const { t } = useTranslation(['payout', 'common']);
  const [mode, setMode] = useState('simple');
  const [decrypted, setDecrypt] = useDecrypt();
  const history = useHistory();
  const location = useLocation();
  const queryStrings = qs.parse(location.search);

  /* DECRYPT START */
  useEffect(() => {
    if (queryStrings.source) {
      setDecrypt(queryStrings.source)
    }
  }, []);
  useEffect(() => {
    if (decrypted !== '') {
      const json = JSON.parse(decrypted);
      setRecipients([{
        ...json,
        id: uuid()
      }]);
    }
  }, [decrypted]);
  /* DECRYPT END */

  useEffect(() => {
    if (queryStrings.employee && employee.length !== 0) {
      const list = queryStrings.employee.split(',');
      setEmployeesById(list);
    }
  }, [employee]);

  return (
    <>
      <Title.Line small>{t('New payout')}</Title.Line>
      <BoxSelector
        value={mode}
        onChange={(mode) => {
          setMode(mode)
        }}
        options={[
          {
            Icon: <Icon name={'create'} />,
            label: t('Simple'),
            value: 'simple',
            Component: (
              <RecipientProvider init={initRecipient(recipients)} >
                <Simple
                  loading={loadingEmployees}
                  onSubmit={d => {
                    setRecipients(d);
                    history.push('/payout/step/pay')
                  }}
                />
              </RecipientProvider>
            )
          },
          {
            Icon: <Icon name={'cloud-upload'} />,
            label: t('From file'),
            value: 'file',
            Component: (
              <RecipientProvider init={[]} >
                <SuspenseWrapper>
                  <File
                    onSubmit={d => {
                      setRecipients(d);
                      history.push('/payout/step/pay')
                    }}
                  />
                </SuspenseWrapper>
              </RecipientProvider>
            )
          }
        ]}
      />
    </>
  )
};

const mapStateToProps = (state) => ({
  recipients: state.payout.recipients,
  loadingEmployees: state.payout.loadingEmployees,
  employee: state.employee.list
});

const mapDispatchToProps = {
  setRecipients,
  setEmployeesById,
};


export default connect(mapStateToProps, mapDispatchToProps)(AddRecipients);