import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom'
import * as qs from 'query-string';
import { Transition } from 'components/'
import { List, Upload, Done, PersonalInformation, SelectWorkPermit } from './containers/'
import { format as formatUrl } from 'utils/url';

import lmaPreview from "./assets/lma.png"
import preview2 from "./assets/resi­dence.png"


const Verify = ({ passport_front, passport_back, work_permit_front, work_permit_back, id, isVerified }) => {
  const history = useHistory();
  const location = useLocation();
  const query = qs.parse(location.search);

  const [home, setHome] = useState('');
  const [identity, setIdentity] = useState(null);
  const [permit, setPermit] = useState(null);
  const { t } = useTranslation('signup');
  useEffect(() => {
    setHome(formatUrl(`/verify`, query));
    if(isVerified){
      history.replace('/dashboard')
    }
  }, []);
  return (
    <div className="content center">
      <Transition
        root={'/verify'}
        defaultPath={`/verify`}
      >
        <Transition.Item path={root => `${root}/`}>
          <List
            identity={identity}
            permit={permit}
            onSubmit={() => {
              history.push('/verify/done')
            }}
          />
        </Transition.Item>
        <Transition.Item path={root => `${root}/personal`}>
          <PersonalInformation
            title={t('Submit an image of your valid passport')}
            description={t('common:Verify identity')}
            onComplete={() => {
              history.push(home)
            }}
          />
        </Transition.Item>
        <Transition.Item path={root => `${root}/id/front`}>
          <Upload
            title={t('Submit an image of the front of your valid passport')}
            description={t('Passport front')}
            fileType={'passport_front'}
            docRef={passport_front}
            onComplete={() => {
              history.push('/verify/id/back/')
            }}
          />
        </Transition.Item>
        <Transition.Item path={root => `${root}/id/back`}>
          <Upload
            title={t('Submit an image of the back of your valid passport')}
            description={t('Passport back')}
            fileType={'passport_back'}
            docRef={passport_back}
            onComplete={() => {
              history.push(home)
            }}
          />
        </Transition.Item>
        <Transition.Item path={root => `${root}/permit/`}>
          <SelectWorkPermit
            onComplete={() => {
              history.push('/verify/permit/back/')
            }}
          />
        </Transition.Item>

        {/* LMA CARD */}
        <Transition.Item path={root => `${root}/permit/LMA/front`}>
          <Upload
            title={t('Submit an image of the front of your valid LMA card')}
            description={t('Working Permit Front')}
            preview={lmaPreview}
            isPermit={true}
            fileType={'work_permit_front'}
            docRef={work_permit_front}
            onComplete={() => {
              history.push('/verify/permit/LMA/back/')
            }}
          />
        </Transition.Item>
        <Transition.Item path={root => `${root}/permit/LMA/back`}>
          <Upload
            title={t('Submit an image of the back of of your valid LMA card')}
            description={t('Working Permit Back')}
            fileType={'work_permit_back'}
            docRef={work_permit_back}
            isPermit={true}
            onComplete={() => {
              history.push(home)
            }}
          />
        </Transition.Item>

        {/* RECIDENT CARD */}
        <Transition.Item path={root => `${root}/permit/residence/front`}>
          <Upload
            title={t('Submit an image of the front of your valid resi­dence permit card')}
            description={t('Working Permit Front')}
            preview={preview2}
            isPermit={true}
            fileType={'work_permit_front'}
            docRef={work_permit_front}
            onComplete={() => {
              history.push('/verify/permit/residence/back')
            }}
          />
        </Transition.Item>
        <Transition.Item path={root => `${root}/permit/residence/back`}>
          <Upload
            title={t('Submit an image of the back of your valid resi­dence permit card')}
            description={t('Working Permit Back')}
            fileType={'work_permit_back'}
            docRef={work_permit_back}
            onComplete={() => {
              history.push(home)
            }}
          />
        </Transition.Item>
        {/* HOLIDAY CARD */}
        <Transition.Item path={root => `${root}/permit/holidayvisa/front`}>
          <Upload
            title={t('Submit an image of the front of your valid holiday visa')}
            description={t('Working Permit Front')}
            fileType={'work_permit_front'}
            isPermit={true}
            docRef={work_permit_front}
            onComplete={() => {
              history.push('/verify/permit/holidayvisa/back')
            }}
          />
        </Transition.Item>
        <Transition.Item path={root => `${root}/permit/holidayvisa/back`}>
          <Upload
            title={t('Submit an image of the back of your valid holiday visa')}
            description={t('Working Permit Back')}
            fileType={'work_permit_back'}
            docRef={work_permit_back}
            isPermit={true}
            onComplete={() => {
              history.push(home)
            }}
          />
        </Transition.Item>

        
        <Transition.Item path={root => `${root}/done`}>
          <Done

          />
        </Transition.Item>

      </Transition>
    </div>
  )
};

const mapStateToProps = (state) => ({
  work_permit_front: state.verify.work_permit_front,
  work_permit_back: state.verify.work_permit_back,
  passport_front: state.verify.passport_front,
  passport_back: state.verify.passport_back,
  id: state.verify.id,
  isVerified: state.auth.isVerified,
});

const mapDispatchToProps = {};


export default connect(mapStateToProps, mapDispatchToProps)(Verify);

