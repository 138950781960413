import isMobileLib from 'is-mobile'

const useDevice = () => {
  const isMobileOrTablet = isMobileLib({ tablet: true, featureDetect: true })
  const isChromeOnAppleDevice = Boolean(navigator.userAgent.match(/CriOS/))
  const isFirefoxOnAppleDevice = Boolean(navigator.userAgent.match(/FxiOS/))
  const isOperaTouchOnAppleDevice = Boolean(navigator.userAgent.match(/OPT/))

  return {
    isMobileOrTablet,
    isChromeOnAppleDevice,
    isFirefoxOnAppleDevice,
    isOperaTouchOnAppleDevice,
  }
}

export default useDevice
