import { types, status } from './action-types';

const defaultState = {
  loginStatus: status.DEFAULT,
};

const bankIdLoginReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.BANKID_LOGIN_BEGIN:
      return {
        ...state,
        loginStatus: status.BEGIN,
      };
    case types.BANKID_LOGIN_SUCCESS:
      return {
        ...state,
        loginStatus: status.SUCCESS,
      };
    case types.BANKID_LOGIN_ERROR:
      return {
        ...state,
        loginStatus: status.ERROR,
      };
    default:
      return state;
  }
};

export default bankIdLoginReducer;