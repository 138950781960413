import React from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { Title, Card, List, Button } from 'gigapay-ui';
import { SuccessAnimation } from 'components/'
import { formatPrice } from 'utils/price';
import { payoutFeePercent } from 'config';

const Complete = ({ net, payoutBackends }) => {
  const { t } = useTranslation(['accept', 'common']);
  const history = useHistory();

  const dateString = moment().format('YYYY-MM-DD');

  return (
    <>
      <SuccessAnimation size={150} />
      <div className="success-message">
        <Title margins={false}>{t('Done!')}</Title>
        <Card>
          <List
            items={[
              {
                label: t('common:Date'),
                value: <span className="success-value">{dateString}</span>,
                key: 'date'
              },
              {
                label: t('Swish number'),
                value: <span className="success-value">{payoutBackends.swish.cellphone_number}</span>,
                key: 'bank-details'
              },
              ...((payoutFeePercent === null) ? [
                {
                  label: t('common:Amount'),
                  value: <span className="success-value">{formatPrice(net)} kr</span>,
                  key: 'amount',
                },] : []
              ),
              ...((payoutFeePercent !== null) ? [
                {
                  label: t('common:Amount'),
                  value: <span className="success-value">{formatPrice(net - payoutFeePercent * net / 100)} kr</span>,
                  key: 'amount',
                },] : []
              ),
            ]}
          />
        </Card>
        <div className="spacing" />
        <Button
          onClick={() => {
            history.push('/dashboard')
          }}
          responsive
        >
          {t('Go to dashboard')}
        </Button>
      </div>
    </>
  );
};

Complete.propTypes = {
  net: PropTypes.number,
};

Complete.defaultProps = {
  net: 0,
};


const mapStateToProps = (state) => ({
  payoutBackends: state.payoutBackends
});

const mapDispatchToProps = {};


export default connect(mapStateToProps, mapDispatchToProps)(Complete);

