import React, { useEffect, useState, useRef } from 'react';
import {
  Subtitle,
  Button,
  Icon,
  Fieldset,
  Input,
  Alert
} from 'gigapay-ui'
import { status } from 'constants/action-types'
import { useTranslation } from 'react-i18next';
import { validPhoneNumber, validEmail } from '../../../../utils/validate'
import { asPhoneNumber } from '../../../../utils/number'


const EditEmployee = ({ onSubmit, name: pName, cellphone_number: pCellphone_number, email: pEmail, updateStatus, updateError }) => {
  const phoneRef = useRef(null);
  const { t } = useTranslation('employees');
  const [phone, setPhone] = useState(pCellphone_number)
  const [name, setName] = useState(pName)
  const [email, setEmail] = useState(pEmail)
  const [error, setError] = useState(null)
  const onSubmitPressed = () => {
    if (email !== null && email !== '' && !validEmail(email)) {
      setError(t('Invalid email'))
      return
    };

    if (phone !== null && !validPhoneNumber(phone) && !validEmail(email)) {
      setError(t('Email or phone number is required'));
      phoneRef.current.focus()
      return
    }

    onSubmit({
      cellphone_number: phone !== null && phone !== '' ? asPhoneNumber(phone) : null,
      email,
      name,
    })

  }
  const hasError = key => updateError && updateError[key] ? updateError[key] : null;
  const errorLabel = key => updateError && updateError[key] ? updateError[key] : '';
  return (
    <div>
      <Subtitle>{t('Edit recipient')}</Subtitle>
      <Fieldset>
        <Input
          label={`${t('common:Name')}*`}
          leftIcon={<Icon name={'person'} />}
          value={name}
          onChange={name => setName(name)}
        />
        <Input
          label={`${t('common:Phone number')} ${errorLabel('cellphone_number')}`}
          inputMode="tel"
          type={"tel"}
          validate={i => i === '' || validPhoneNumber(i)}
          onChange={phone => setPhone(phone)}
          value={phone}
          ref={phoneRef}
          error={errorLabel('cellphone_number') !== ''}
          leftIcon={<Icon name={'phone'} />}
        />
        <Input
          label={`${t('common:Email')} ${errorLabel('email')}`}
          leftIcon={<Icon name={'mail'} />}
          validate={i => i === '' || validEmail(i)}
          value={email}
          error={errorLabel('email') !== ''}
          onChange={email => setEmail(email)}
        />
      </Fieldset>
      {error && (
        <Alert.Error style={{ marginBottom: 12 }}>
          <span>{error}</span>
        </Alert.Error>)
      }
      <Button primary responsive loading={updateStatus === status.BEGIN} onClick={onSubmitPressed}>
        {t('Save')}
      </Button>
    </div>
  )
}
export default EditEmployee;