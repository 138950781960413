import React, { Component } from 'react'
import {Redirect } from "react-router-dom";
import { connect } from 'react-redux'
import { changeLanguage } from '../../actions/user';

export class En extends Component {
  componentDidMount(){
    this.props.changeLanguage('en');
  }
  render() {
    return (
      <Redirect to={'/signup'} />
    )
  }
}

const mapStateToProps = (state) => ({
  
})

const mapDispatchToProps = dispatch => ({
  changeLanguage: lang => dispatch(changeLanguage(lang)),
});

export default connect(mapStateToProps, mapDispatchToProps)(En)
