import React, { useState } from 'react';
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  Title,
  Paragraph,
  Fieldset,
  Input,
  Icon,
  Subtitle,
  Button,
  Alert
} from 'gigapay-ui'
import { PriceBarList } from 'components/'
import { usePricing } from 'hooks/invoicing'
import { useTotalAmount } from 'hooks/payout';
import { validEmail, validEmailorEmpty } from 'utils/validate'
import { clean_number} from "utils/clean";

const Create = ({ onSubmit = () => { }, payout, createResponse }) => {
  const history = useHistory();
  const { t } = useTranslation('invoices');

  const [name, setName] = useState(payout.company.name);
  const [address, setAddress] = useState(payout.company.address_line_1);
  const [zip_code, setZip_code] = useState(payout.company.zip_code);
  const [city, setCity] = useState(payout.company.city);

  const [email, setEmail] = useState(payout.company.email);
  const [input, setInput] = useState(payout.input);
  const [amountType, setAmountType] = useState('cost');
  const [description, setDescription] = useState(payout.description);
  const [error, setError] = useState(null);

  const { recipients, loading, error: priceError } = usePricing(input, amountType);
  const { cost, vat, amount } = useTotalAmount(recipients);
  const submit = () => {

    if (name.length < 2) {
      setError(t('Invalid company name'));
      return;
    }
    if (address.length < 2) {
      setError(t('Invalid address'));
      return;
    }
    if (city.length < 2) {
      setError(t('Invalid city'));
      return;
    }
    if (zip_code.length < 5) {
      setError(t('Invalid zip code'));
      return;
    }
    if (email.length < 3 || !validEmail(email)) {
      setError(t('Invalid company email'));
      return;
    }
    if (description.length < 1) {
      setError(t('Enter a work description'));
      return;
    }

    onSubmit({
      amountType,
      cost,
      vat,
      amount,
      description,
      company: {
        name,
        email,
        address_line_1: address,
        city,
        zip_code: clean_number(zip_code)
      }
    });
    history.push(`/invoices/new/preview/`)
  };
  const errorLabel = key => createResponse && createResponse[key] ? createResponse[key] : '';
  const errorCompanyLabel = key => createResponse && createResponse['company'] && createResponse.company[key] ? createResponse.company[key] : '';

  return (
    <div>
      <Title small>{t('New invoice')}</Title>
      <Paragraph small>{t('Provide some information about the recipient, the amount and a description of the work done and we\'ll generate an Invoice.')}</Paragraph>

      <Subtitle small>{t('Customer')}</Subtitle>
      <Fieldset>
        <Input
          leftIcon={<Icon name={'business'} color={'#A8A8A8'} size={24} />}
          label={`${t('common:Name')} ${errorCompanyLabel('name')}`}
          onChange={(n) => setName(n)}
          value={name}
          error={errorCompanyLabel('name') !== ''}
        />
        <Input
          leftIcon={<Icon name={'business'} color={'#A8A8A8'} size={24} />}
          label={`${t('common:Address')} ${errorCompanyLabel('address_line_1')}`}
          onChange={(n) => setAddress(n)}
          value={address}
          error={errorCompanyLabel('address_line_1') !== ''}
        />
        <Fieldset.Row>
          <Input
            leftIcon={true}
            label={`${t('common:Zip code')} ${errorCompanyLabel('zip_code')}`}
            onChange={(n) => setZip_code(n)}
            value={zip_code}
            error={errorCompanyLabel('zip_code') !== ''}
          />
          <Input
            label={`${t('common:City')} ${errorCompanyLabel('city')}`}
            onChange={(n) => setCity(n)}
            value={city}
            error={errorCompanyLabel('city') !== ''}
          />
        </Fieldset.Row>
        <Input
          leftIcon={<Icon name={'mail'} size={24} />}
          label={`${t('common:Email')} ${errorCompanyLabel('email')}`}
          value={email}
          onChange={e => setEmail(e)}
          validate={validEmailorEmpty}
          error={errorCompanyLabel('email') !== ''}
        />
      </Fieldset>
      <div className="spacing" />
      <Subtitle small>{t('Work performed')}</Subtitle>
      <Fieldset>
        <Input
          leftIcon={<Icon name={'person'} size={24} />}
          label={`${t('common:Description')} ${errorLabel('description')}`}
          onChange={(description) => setDescription(description)}
          value={description}
          error={errorLabel('description') !== ''}
        />
        <Input
          leftIcon={<Icon name={'cash'} size={22} />}
          className="amount-row"
          label={t('common:Amount')}
          inputMode="numeric"
          pattern="[0-9]*"
          onChange={(input) => setInput(input)}
          value={input}
        >
          <Input.Picker
            onChange={v => setAmountType(v.value)}
            options={[
              { label: t('common:Compensation'), description: t('The gross salary you will receive after payroll taxes and Gigapay\'s fee.'), value: 'amount' },
              { label: t('common:Cost'), description: t('The amount the recipient will pay.'), value: 'cost' },
            ]}
            value={amountType}
          />
        </Input>
      </Fieldset>
      <div className="spacing" />
      {error && (
        <Alert.Error style={{ marginBottom: 10 }}>
          {error}
        </Alert.Error>
      )}

      <PriceBarList recipients={recipients} />

      <Button.Primary loading={loading} onClick={submit} responsive>{t('Next')}</Button.Primary>

    </div>
  )
};

const mapStateToProps = (state) => ({
  createResponse: state.invoices.createResponse,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Create);

