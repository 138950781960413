import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux';
import { status } from 'constants/action-types'
import {
  Title,
  Paragraph,
  Subtitle,
  Button,
  List,
  Card
} from 'gigapay-ui'
import { createInvoicingPayout, setInvoicingData } from 'actions/invoicing';
import { numberWithSpaces, formatPrice } from 'utils/price';

const Preview = ({ createInvoicingPayout, setInvoicingData, payout, createStatus, createResponse }) => {
  const { t } = useTranslation('invoices');
  const history = useHistory();
  useEffect(() => {
    
    if (payout.input === '') {
      history.replace(`/invoices/new/`)
    }
    setInvoicingData({ createStatus: status.DEFAULT, createResponse: null})
  }, []);

  useEffect(() => {
    if (createStatus === status.SUCCESS) {
      setInvoicingData({ createStatus: status.DEFAULT, createResponse: null});
      history.push(`/invoices/new/delivered/?id=${createResponse.invoice}`)
    } else if (createStatus === status.ERROR){
      setInvoicingData({ createStatus: status.DEFAULT });
      history.push(`/invoices/new/`)
    }
  }, [createStatus]);

  const send = () => {
    createInvoicingPayout({
      company: {
        ...payout.company
      },
      description: payout.description,
      [payout.amountType]: payout[payout.amountType]
    })
  };
  
  if (payout === null) {
    return null;
  }
  return (
    <div>
      <Title small>{t('Preview')}</Title>
      <Paragraph small>{t('Check and make sure the information you\'ve provided is correct, the click send \'Send invoice by email\'.')}</Paragraph>
      <Card>
        <List
          items={[

            { label: t('Description'), value: <span>{payout.description}</span>, key: 'description' },
            { label: t('Payment terms'), value: t('30 days'), key: 'terms' },
            { label: t('Amount to invoice excl. VAT'), value: <span>{numberWithSpaces(payout.cost)} sek</span>, key: 'cost' },
            { label: <span className={"vat"}> + {t('VAT (25%)')}</span>, value: <span>{formatPrice(payout.vat)} sek</span>, key: 'vat' },
            { label: <span>= {t('Gross salary')}</span>, value: <span>{numberWithSpaces(payout.amount)} sek</span>, key: 'gross' }
          ]}
        />
      </Card>
      <div className={"spacing"} />
      <Subtitle small>{t('Customer')}</Subtitle>
      <Card>
        <List
          items={[

            { label: t('common:Name'), value: payout.company.name, key: 'name' },
            {
              label: t('common:Address'), value: (
                <div>
                  <span>{payout.company.address_line_1}</span><br />
                  <span>{payout.company.zip_code} {payout.company.city}</span>
                </div>
              ), key: 'address'
            },
            { label: t('common:Email'), value: payout.company.email, key: 'email' },
          ]}
        />
      </Card>
      <div className={"spacing"} />
      <Button.Primary loading={createStatus === status.BEGIN} onClick={send} responsive>{t('Send invoice by email')}</Button.Primary>

    </div>
  )
};

const mapStateToProps = (state) => ({
  createStatus: state.invoices.createStatus,
  createResponse: state.invoices.createResponse,
});
const mapDispatchToProps = {
  createInvoicingPayout,
  setInvoicingData
};

export default connect(mapStateToProps, mapDispatchToProps)(Preview);
