import React, { Component, useEffect, useState } from 'react'
import _ from 'lodash';
import {
  Title,
  Button,
  Radio,
  Loader,
  Paragraph,
  Subtitle,
  Alert,
  Fieldset,
  Input,
  Icon,
  Strong,
  Link,
} from 'gigapay-ui';
import { connect } from 'react-redux'
import { withTranslation, Trans, useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { status } from '../../../../constants/action-types';
import { validEmail } from '../../../../utils/validate';
import {
  fetchCompanyEngagements,
  createCompany,
  verifyCompany
} from '../../../../actions/company';
import { updateSignup } from '../../../../actions/signup'

import { fetchUserCompanies, fetchCompanyLeads } from '../../../../actions/user'

const errorToString = serverError => {
  let errorString = '';
  if (serverError !== null) {
    for (var key in serverError) {
      errorString = `${errorString} ${serverError[key]}`;
    }
  }
  return errorString;
}


const LoadingInline = () => {
  return (
    <div style={{ textAlign: 'center', margin: '10px auto' }}><Loader inline /></div>
  )
}
const RadioDescription = ({ company, function: role }) => {
  const { t } = useTranslation(['signup', 'common'])
  return (
    <>
      <span>OrgNr: {company.organisational_number}</span><br />
      <span>{t('Your role')}: {role}</span>
    </>
  )
}

const ChooseCompany = ({
  email: emailProp,
  companies,
  createCompany,
  serverError,
  companyEngagements,
  fetchCompanyEngagements,
  fetchUserCompanies,
  fetchCompanyLeads,
  companyId,
  updateSignup,
  companyEngagementsLoading,
  createCompanyStatus,
  verifyCompanyStatus,
  verifyId,
  verifyCompany
}) => {
  const history = useHistory()
  const { t } = useTranslation(['signup', 'common'])
  const [company, setCompany] = useState(null)
  const [email, setEmail] = useState(emailProp)
  const [error, setError] = useState(null)

  useEffect(() => {
    fetchCompanyEngagements();
  }, [])

  useEffect(() => {
    if (verifyId) {
      verifyCompany(verifyId);
    }
  }, [verifyId])

  useEffect(() => {
    if (verifyCompanyStatus === status.SUCCESS) {
      fetchUserCompanies()
      fetchCompanyLeads()
      updateSignup({
        email: null,
        accountType: 1,
      })
      history.push(`/signup/complete/?id=${companyId}`)
    } else if (verifyCompanyStatus === status.ERROR) {
      fetchUserCompanies()
      fetchCompanyLeads()
      updateSignup({
        email: null,
        accountType: 1,
      })
      history.push(`/signup/waiting`)
    }
  }, [verifyCompanyStatus])


  const submit = (e) => {
    e.preventDefault()

    if (company === null) {
      setError('Choose a company')
      return;
    }

    if (!validEmail(email)) {
      setError(t('Invalid email'))
      return;
    }

    setError(null);
    createCompany({
      organisational_number: company,
      email
    });
  }

  // Open intercom chat box
  const openIntercom = (e) => {
    e.preventDefault();
    window.Intercom('show');
  };

  // organize the company engangemanets
  const existingCompanies = _.values(companies).map(c => c.organisational_number);
  const radioChoices = companyEngagements
    .filter(c => existingCompanies.indexOf(c.company.organisational_number) < 0)
    .map(engagement => ({
      label: engagement.company.name,
      description: (
        <RadioDescription {...engagement} />
      ),
      value: engagement.company.organisational_number,
    }));

  return (
    <>
      <Title.Line small>{
        emailProp ?
          t('Add a company') :
          t('You and your company')
      }</Title.Line>
      <Paragraph>{t('There is a list bellow with your company engagements, choose the company you want to register on Gigapay. If the company that you want to register doesn\'t exist in the list bellow, please contact us at ')}{<span><Link primary onClick={openIntercom}>{t('info@gigapay.co')}</Link></span>}{t('.')}</Paragraph>
      {
        companyEngagementsLoading && <LoadingInline />
      }
      {
        /* USER HAS COMPANIES */
        radioChoices.length === 0 && !companyEngagementsLoading && (

          <Alert.Error>
            <Trans i18nKey="payout:only-company-message">
              {[
                <a key={'link-sub'} href="https://gigapay.us19.list-manage.com/subscribe?u=d727de35fca501b3c8ca87298&id=d68cee59a0"></a>,
              ]}
            </Trans>
          </Alert.Error>
        )
      }
      {
        /* USER HAS COMPANIES */
        radioChoices.length !== 0 && !companyEngagementsLoading && (
          <>

            {emailProp === null && !companyEngagementsLoading && (
              <div>
                <Strong>{t('Your company email')}</Strong>
                <Fieldset>
                  <Input
                    leftIcon={<Icon name={'mail'} />}
                    label={t('common:Email')}
                    value={email || ''}
                    onChange={email => setEmail(email)}
                    validate={validEmail}
                  />
                </Fieldset>
              </div>
            )}

            {!companyEngagementsLoading && companyEngagements.length !== 0 && (
              <div style={{ marginTop: 10, float: 'left', width: '100%' }}>
                {companyEngagements.length > 1 && <Subtitle small>{t('Choose company')}</Subtitle>}
                {companyEngagements.length === 1 && <Subtitle small>{t('Company')}</Subtitle>}
                <Radio
                  options={radioChoices}
                  value={company}
                  onChange={company => {
                    setCompany(company)
                  }}
                />
              </div>
            )}

            {error && <Alert.Error>{error}</Alert.Error>}
            {serverError && <Alert.Error>{errorToString(serverError)}</Alert.Error>}

            {(!companyEngagementsLoading && companyEngagements !== null) && (
              <div style={{ marginTop: 10, display: 'block', width: '100%', float: 'left' }}>
                <Button
                  primary
                  responsive
                  loading={(createCompanyStatus === status.BEGIN || verifyCompanyStatus === status.BEGIN)}
                  onClick={submit}
                >
                  {t('common:Next')}
                </Button>
              </div>
            )}

          </>
        )
      }
    </>
  )
}


const mapStateToProps = (state) => ({
  companies: state.user.companies,
  companyEngagements: state.company.companyEngagements,
  companyEngagementsLoading: state.company.companyEngagementsLoading,
  createCompanyStatus: state.company.createCompanyStatus,
  verifyCompanyStatus: state.company.verifyCompanyStatus,
  companyId: state.company.id,
  verifyId: state.company.verifyId,
  personal_number: state.user.personal_number,
  serverError: state.company.error,
  email: state.signup.email,
})

const mapDispatchToProps = {
  fetchCompanyEngagements,
  fetchCompanyLeads,
  createCompany,
  verifyCompany,
  fetchUserCompanies,
  updateSignup
}


export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(['signup', 'common'])(ChooseCompany))
