/*
  This is a new verision of fetch, used this on all new request
*/
import { createAction } from 'redux-actions';
import { status } from '../constants/action-types';
import * as config from '../config';
import fetch from './customFetch';

export const success = (actionType, params) =>
  createAction(actionType,
    payload => payload,
    _action => ({
      status: status.SUCCESS,
      params,
    })
  );

export const error = (actionType, params) =>
  createAction(actionType,
    payload => payload,
    _payload => ({
      status: status.ERROR,
      params,
    })
  );

export const begin = (actionType, params) =>
  createAction(actionType,
    payload => payload,
    _payload => ({
      status: status.BEGIN,
      params,
    })
  );

export const timedout = (actionType, params) =>
  createAction(actionType,
    payload => payload,
    _payload => ({
      status: status.TIMEOUT,
      params,
    })
  );

const fetchRequest = (url, opts, timeout) => new Promise((resolve, reject) => {
  let statusCode = -1;
  fetch(url, opts, timeout)
    .then((res) => {
      statusCode = res.status;
      return res.text();
    })
    .then((response) => {
      try {
        const data = response
          ? JSON.parse(response)
          : null;

        if (statusCode >= 200 && statusCode < 300) {
          resolve(data);
        } else {
          reject(data);
        }
      } catch (err) {
        reject({ error: 'Unable to communicate with server.' })
      }
    })
    .catch((errorMessage) => {
      reject({ error: errorMessage })
    });
})
export const ajaxRequest = (actionType, path, opts, params = null, transform = (_ => _), timeout) =>
  // dispatch and getState are given to us by the redux-thunk middleware
  (dispatch, getState) => {

    dispatch(begin(actionType, params)(opts.rawBody));

    const state = getState();

    const token = state.auth.token;
    if (token !== null) {
      opts.headers = {
        ...opts.headers,
        'Authorization': `Token ${token}`,
      };
    }
    const language = state.auth.language;
    if (language !== null) {
      opts.headers = {
        ...opts.headers,
        'Accept-Language': `${language}`,
      };
    }
    const currentCompany = state.user.currentCompany;
    if (currentCompany !== null && (state.user.companies && state.user.companies.length !== 0)) {
      opts.headers = {
        ...opts.headers,
        'Integration-ID': `${state.user.companies[currentCompany].webapp_integration_id}`,
      };
    }

    const url = (p) => (typeof p === 'function') ? p(config, state) : p;

    const promiseList = (Array.isArray(path)) ?
      path.map(p => fetchRequest(url(p), opts, timeout)) :
      [fetchRequest(url(path), opts, timeout)];

    Promise.all(promiseList)
      .then(function (response) {
        const reshaped = (!Array.isArray(path)) ? response[0] : response;
        const data = reshaped
          ? transform(reshaped)
          : null;
        dispatch(success(actionType, params)(data, opts.headers));
      })
      .catch((errorMessage) => {
        dispatch(error(actionType, params)({ error: errorMessage }));
      })
  };

const commonHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const commonHeadersRaw = {};

const opts = {
  get: () => ({
    method: 'GET',
    headers: { ...commonHeaders },
  }),
  head: () => ({
    method: 'HEAD',
    headers: { ...commonHeaders },
  }),
  put: data => ({
    method: 'PUT',
    body: data && JSON.stringify(data),
    rawBody: data,
    headers: { ...commonHeaders },
  }),
  patch: data => ({
    method: 'PATCH',
    body: data && JSON.stringify(data),
    rawBody: data,
    headers: { ...commonHeaders },
  }),
  patchRaw: data => ({
    method: 'PATCH',
    body: data,
    rawBody: data,
  }),
  post: data => ({
    method: 'POST',
    body: JSON.stringify(data),
    rawBody: data,
    headers: { ...commonHeaders },
  }),
  delete: () => ({
    method: 'DELETE',
    headers: { ...commonHeaders },
  }),
  postRaw: (data, headers) => ({
    method: 'POST',
    body: data,
    rawBody: data,
    headers: { ...commonHeadersRaw, ...headers },
  }),
};

export const post = (actionType, path, body, params, transform = (_ => _), timeout = 15_000) =>
  ajaxRequest(actionType, path, opts.post(body), params, transform, timeout);

export const patch = (actionType, path, body, params, transform = (_ => _), timeout = 15_000) =>
  ajaxRequest(actionType, path, opts.patch(body), params, transform, timeout);

export const patchRaw = (actionType, path, body, params, transform = (_ => _), timeout = 15_000) =>
  ajaxRequest(actionType, path, opts.patchRaw(body), params, transform, timeout);

export const postRaw = (actionType, path, body, headers, params, transform = (_ => _), timeout = 15_000) =>
  ajaxRequest(actionType, path, opts.postRaw(body, headers), params, transform, timeout);

export const get = (actionType, path, params, transform = (_ => _), timeout = 15_000) =>
  ajaxRequest(actionType, path, opts.get(), params, transform, timeout);

// High timeout because we are making the payments syncronously which takes more time
export const put = (actionType, path, body, params, transform = (_ => _), timeout = 20_000) =>
  ajaxRequest(actionType, path, opts.put(body), params, transform, timeout);

export const delete_ = (actionType, path, params, transform = (_ => _), timeout = 15_000) =>
  ajaxRequest(actionType, path, opts.delete(), params, transform, timeout);
