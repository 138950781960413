export const slugify = (name = '') => {
  if (!name) return '';
  name = name.toLowerCase();
  name = name.replace(/ /g, '-');
  name = name.replace(/å/g, 'a');
  name = name.replace(/ä/g, 'a');
  name = name.replace(/ö/g, 'o');
  return name;
};


export const trim = (name = '') => {
  if (!name) return '';
  if (name.length > 8) {
    name = name.substring(0, 8) + '.'
  }
  return name;
};