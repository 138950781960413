import React from 'react';
import css from './userAvatar.module.css';

const initals = fullName => {
  if (!fullName) return ':)'
  const list = fullName.split(' ');
  if (list.length === 0) return ':)';
  if (list.length === 1) return list[0].substr(0, 1).toUpperCase();
  return `${list[0].substr(0, 1)}${list[list.length - 1].substr(0, 1)}`.toUpperCase();
}
const randomColor = (name = '') => {
  if(!name) return '000';
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }
  const c = (hash & 0x00FFFFFF)
    .toString(16)
    .toUpperCase();
  return "00000".substring(0, 6 - c.length) + c;
}
const Avatar = ({ name = null, img = null }) => {
  if (img) {
    return (
      <div className={css.imgAvatar}>
        <img src={img} className={css.img} />
      </div>
    )
  }

  return (
    <div className={`${css.symbol}`} style={{ backgroundColor: `#${randomColor(name)}` }}>{initals(name)}</div>
  )
}
export default Avatar;
