import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types'
import {
  Input,
  Icon
} from 'gigapay-ui';
import { useTranslation } from 'react-i18next';

const AmountInput = ({ onChange, pricing, inputValue, inputType }) => {
  const { t } = useTranslation('payout')
  const [amountError, setAmountError] = useState('')
  useEffect(() => {
    if (pricing && pricing.error) {
      setAmountError(pricing.error)
    } else {
      setAmountError('')
    }
  }, [pricing])
  return (
    <Input
      leftIcon={<Icon name={'cash'} />}
      className="amount-row"
      label={`${t('common:Amount')} ${amountError}`}
      inputMode="numeric"
      pattern="[0-9]*"
      error={amountError !== ''}
      value={inputValue}
      onChange={v => onChange({ inputValue: v })}
    >
      <Input.Picker
        onChange={v => onChange({ inputType: v.value })}
        options={[
          { label: t('common:GrossSalary'), description: t('common:Taxes and fees will be added'), value: 'gross' },
          { label: t('common:PayrollCost'), description: t('common:Taxes and fees will be included'), value: 'cost' }
        ]}
        value={inputType}
      />
    </Input>
  )
}

AmountInput.propTypes = {
  inputValue: PropTypes.string,
  inputType: PropTypes.string,
  onChange: PropTypes.func,
}
AmountInput.defaultProps = {
  inputValue: '',
  inputType: 'gross',
  onChange: () => { }
}

export default AmountInput;