import React, { Component } from 'react'
import { connect } from 'react-redux'
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import ScrollToTop from '../ScrollToTop';
import NavBar from '../NavBar';
import Footer from '../Footer';
import Invoiceings from 'routes/Invoiceings';
import ReceivedInvoice from 'routes/ReceivedInvoice'
import NewInvoice from 'routes/NewInvoice';
import Employees from 'routes/Employees';
import Employee from 'routes/Employee';
import ImportEmployee from 'routes/ImportEmployee';
import Payout from 'routes/Payout';
import Settings from 'routes/Settings';
import Dashboard from 'routes/Dashoard';
import Login from 'routes/Login';
import Accept from 'routes/Accept';
import Request from 'routes/Request';
import Invoice from 'routes/Invoice';
import Signup from 'routes/Signup';
import En from 'routes/En';
import Verify from 'routes/Verify'
import EmailVerify from 'routes/EmailVerify';
import Payouts from 'routes/Payouts'
import Logout from "routes/Logout/";

const TestRoute = () => {
  return (
    <span>hejsan</span>
  )
};

const routes = [
  {
    path: '/i/:id',
    component: ReceivedInvoice
  },
  {
    path: '/invoices/',
    privateRoute: true,
    exact: true,
    verifiedRoute: true,
    component: Invoiceings
  },
  {
    path: '/invoices/new/',
    privateRoute: true,
    verifiedRoute: true,
    component: NewInvoice
  },
  {
    path: '/invoices/:id/',
    privateRoute: true,
    verifiedRoute: true,
    exact: true,
    component: Invoice
  },
  {
    path: '/invoices/:id/preview/',
    privateRoute: true,
    verifiedRoute: true,
    exact: true,
    component: Invoice
  },
  {
    path: '/verify/',
    privateRoute: true,
    component: Verify
  },
  {
    path: '/en',
    component: En
  },
  {
    path: '/login/',
    component: Login
  },
  {
    path: '/employees',
    exact: true,
    privateRoute: true,
    verifiedRoute: true,
    component: Employees
  },
  {
    path: '/employees/import',
    exact: true,
    privateRoute: true,
    verifiedRoute: true,
    component: ImportEmployee
  },
  {
    path: '/employees/:employee',
    exact: true,
    privateRoute: true,
    verifiedRoute: true,
    component: Employee
  },
  {
    path: '/dashboard',
    exact: true,
    privateRoute: true,
    verifiedRoute: true,
    component: Dashboard
  },
  {
    path: '/settings',
    privateRoute: true,
    verifiedRoute: true,
    component: Settings
  },
  {
    path: '/email/verify',
    privateRoute: false,
    verifiedRoute: false,
    component: EmailVerify
  },
  {
    path: '/ok',
    component: Accept
  },
  {
    path: '/request',
    privateRoute: true,
    verifiedRoute: true,
    component: Request
  },
  {
    path: '/signup',
    component: Signup
  },
  {
    path: '/payout',
    privateRoute: true,
    verifiedRoute: true,
    component: Payout
  },
  {
    path: '/payouts',
    privateRoute: true,
    verifiedRoute: true,
    component: Payouts
  },
  {
    path: '/logout',
    component: Logout
  },
];

export class Routes extends Component {
  render() {
    const { isAuthenticated, isVerified } = this.props;
    return (
      <BrowserRouter>
        <>
          <NavBar />
          <ScrollToTop>
            <Switch >
              {
                routes.map(({ component: Component, path, exact = false, privateRoute = false, verifiedRoute = false }) => {
                  return (
                    <Route
                      key={`p-${path}`}
                      exact={exact}
                      path={path}
                      render={props => {
                        if (!isAuthenticated && privateRoute === true) {
                          return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                        }
                        if (verifiedRoute && !isVerified) {
                          return <Redirect to={{ pathname: '/verify', state: { from: props.location } }} />
                        }
                        return <Component {...props} />
                      }} />
                  )
                })
              }
              <Route path="*" render={() => <Redirect to="/login/" />} />
            </Switch>
          </ScrollToTop>
          <Footer />
        </>
      </BrowserRouter>
    )
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  isVerified: state.auth.isVerified
})

export default connect(mapStateToProps, null)(Routes)
