import gigapayLogo from './assets/images/logo.svg';
import payhourLogo from './assets/images/13350718-0-payhour-logo.png'
let baseUrl, privateUrl, host, invoicingUrl, appUrl, stripePublicKey, sentryDSN, logo, theme, payoutBackends, payoutBackendsSettings, payoutFeePercent, configFile, phoneNumberValidation;
const encryptionKey = "KbPeShVmYq3t6w9z";


const termsLink = 'https://gigapay.se/terms.html';
const dataPolicy = 'https://gigapay.se/integritetspolicy.html';
logo = gigapayLogo;
theme = {}; // default theme
payoutBackends = ['swish'];  //default
payoutBackendsSettings = ['swish', 'bankgiro']
payoutFeePercent = null;  // For Payhour
phoneNumberValidation = 'sweden';
configFile = '';

const PAYOUT_MAX_LIMIT = 500000;
const PAYOUT_MIN_LIMIT_AMOUNT = 5;
const PAYOUT_MIN_LIMIT_PRICE = 7;
const CARD_MAX_LIMIT = 100000;


// Config variables for development
if (process.env.REACT_APP_STAGE === "development") {
  baseUrl = 'http://localhost:8000/v2';
  privateUrl = 'http://localhost:8000/app';
  host = 'http://localhost:8000';
  appUrl = 'http://localhost:3000';
  stripePublicKey = 'pk_test_lMh5V87nHB59zzCSe7smUeAy';
  configFile = "development";
}
// Config variables for quality assurance
else if (process.env.REACT_APP_STAGE === "qa") {
  baseUrl = 'https://api.qa.gigapay.se/v2';
  privateUrl = 'https://api.qa.gigapay.se/app';
  host = 'https://api.qa.gigapay.se';
  appUrl = 'https://app.qa.gigapay.se';
  stripePublicKey = 'pk_test_lMh5V87nHB59zzCSe7smUeAy';
  sentryDSN = 'https://4a0b4e771677451c9a74c154a35877cf@zentry.zerebra.net/21';
  configFile = "qa";
}
// Config variables for demo
else if (process.env.REACT_APP_STAGE === "demo") {
  baseUrl = 'https://api.demo.gigapay.se/v2';
  privateUrl = 'https://api.demo.gigapay.se/app';
  host = 'https://api.demo.gigapay.se';
  appUrl = 'https://app.demo.gigapay.co';
  stripePublicKey = 'pk_test_lMh5V87nHB59zzCSe7smUeAy';
  sentryDSN = 'https://33bb63126da343a59f054766e30de518@zentry.zerebra.net/22';
  configFile = "demo";
}
// Config variables for payhour
else if (process.env.REACT_APP_STAGE === "payhour") {
  baseUrl = 'https://gigapay.payhour.se/v2';
  privateUrl = 'https://gigapay.payhour.se/app';
  host = 'https://gigapay.payhour.se';
  appUrl = 'https://payments.payhour.se';
  stripePublicKey = 'None';
  sentryDSN = 'https://2979ebace6b44b71ad3f60517a4c2fc6@o1100595.ingest.sentry.io/6125837';
  logo = payhourLogo;
  theme = {
    'primary': '#34bb96',
    'primary-fill': '#7DDCC2',
    'primary-fill-light': '#F1FAF6'
  };
  payoutBackends = ['swish'];
  payoutBackendsSettings = ['bankgiro', 'swish']
  payoutFeePercent = 5;
  configFile = "payhour";
}
// Config variables for production
else {
  baseUrl = 'https://api.gigapay.se/v2';
  privateUrl = 'https://api.gigapay.se/app';
  host = 'https://api.gigapay.se';
  appUrl = 'https://app.gigapay.se';
  stripePublicKey = 'pk_live_mApIxypDD2L9KLCyiqVB88Gx';
  sentryDSN = 'https://d55d33b156b64877b6ebcac4ef355120@zentry.zerebra.net/13';
  configFile = "production";
}
invoicingUrl= `${host}/invoicing`;

export {
  baseUrl,
  privateUrl,
  host,
  invoicingUrl,
  stripePublicKey,
  sentryDSN,
  encryptionKey,
  appUrl,
  termsLink,
  dataPolicy,
  logo,
  theme,
  payoutBackends,
  payoutBackendsSettings,
  payoutFeePercent,

  PAYOUT_MAX_LIMIT,
  PAYOUT_MIN_LIMIT_AMOUNT,
  PAYOUT_MIN_LIMIT_PRICE,
  CARD_MAX_LIMIT,

  configFile,

  phoneNumberValidation
};
