import { types } from '../constants/action-types';
import { get, put } from 'utils/fetchV3';


const privateUri = (parms = '') => config =>
  `${config.privateUrl}/${parms}`;

export const listPayoutBackends = () =>
  get(types.LIST_PAYOUT_BACKENDS, privateUri(`payment_methods/`));

export const updatePreferredPayoutBackend = data =>
  put(types.UPDATE_PREFERRED_PAYOUT_BACKEND, privateUri(`payment_methods/`), data);

export const resetPayoutBackends = () =>
  ({ type: types.RESET_PAYOUT_BACKENDS });

export const createTrustlyBackend = data =>
  put(types.CREATE_TRUSTLY_BACKEND,  privateUri(`payment_methods/trustly/`), data);

export const updateTrustlyBackend = data =>
  put(types.UPDATE_TRUSTLY_BACKEND,  privateUri(`payment_methods/trustly/`), data);

export const createBankGiroBackend = data =>
  put(types.CREATE_BANKGIRO_BACKEND,  privateUri(`payment_methods/bankgiro/`), data);

export const updateBankGiroBackend = data =>
  put(types.UPDATE_BANKGIRO_BACKEND,  privateUri(`payment_methods/bankgiro/`), data);

export const createBriteBackend = data =>
  put(types.CREATE_BRITE_BACKEND,  privateUri(`payment_methods/brite/`), data);

export const updateBriteBackend = data =>
  put(types.UPDATE_BRITE_BACKEND,  privateUri(`payment_methods/brite/`), data);

export const updateSwishBackend = data =>
  put(types.UPDATE_SWISH_BACKEND,  privateUri(`payment_methods/swish/`), data);
