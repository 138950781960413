import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import * as qs from 'query-string';
import { status } from 'constants/action-types';
import { acceptPayouts, resetAccept } from 'actions/payout-accept';
import { fetchPayoutsById, resetPayoutsById } from 'actions/payout';
import Summary from './components/Summary/';
import Complete from './components/Complete/';

const Payout = ({
  isAuthenticated,
  fetchPayoutsById,
  payoutsById,
  resetPayoutsById,
  acceptPayouts,
  resetAccept,
  acceptStatus,
  preferred_payment_method,
}) => {
  const [page, setPage] = useState('step1');
  const [error, setError] = useState('');
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation('payout')
  const query = qs.parse(location.search);

  useEffect(() => {
    if (query.id && isAuthenticated) {
      const ids = query.id.split(',');
      fetchPayoutsById(ids);
    } else if(query.id && !isAuthenticated){
      history.push(`/login/?redirect_url=${encodeURIComponent(`/ok/payout/?id=${query.id}`)}`)
    }
  }, []);

  useEffect(() => {
    if (acceptStatus === status.SUCCESS) {
      setPage('step2');
      resetAccept();
    }
    else if (acceptStatus === status.ERROR){
      setError(t('Payment failed'))
    }
  }, [acceptStatus]);

  const { data, loading } = payoutsById;

  const [totalAmount, setTotalAmount] = useState(0);
  useEffect(() => {
    setTotalAmount(data.reduce((a, b) => parseFloat(b.amount) + a, 0));
  }, [data]);

  const [totalTax, setTotalTax] = useState(0);
  useEffect(() => {
    setTotalTax(data.reduce((a, b) => b.tax ? (parseFloat(b.tax) + a): (parseFloat(b.preliminary_tax) + a) , 0));
  }, [data]);

  const [totalFee, setTotalFee] = useState(0);
  useEffect(() => {
    setTotalFee(data.reduce((a, b) => parseFloat(b.fee) + a, 0));
  }, [data]);

  const onAcceptClicked = () => {
    const { data } = payoutsById;
    const ids = data.map(v => ({ id: v.id, payout_backend:  preferred_payment_method }));
    setError('')
    acceptPayouts(ids);
  };

  return (
    <div className="content center">
      {isAuthenticated && (
        <div className="doc">
          <div className={`step-container clearfix ${page}`}>
            <div className="step1-container">
              <Summary
                payouts={data}
                totalAmount={totalAmount}
                totalTax={totalTax}
                totalFee={totalFee}
                loading={loading}
                onAcceptClicked={onAcceptClicked}
                // We only accept payouts for swish
                hasPaymentMethod={preferred_payment_method==='swish'}
                resetPayoutsById={resetPayoutsById}
                loadingButton={acceptStatus === status.BEGIN}
                error={error}
              />
            </div>
            <div className="step2-container">
              {page === 'step2' && (
                <Complete net={(totalAmount - totalTax)} />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  payoutsById: state.payout.payoutsById,
  acceptStatus: state.accept.acceptStatus,
  preferred_payment_method: state.payoutBackends.preferred_payment_method,
});

const mapDispatchToProps = {
  fetchPayoutsById,
  resetPayoutsById,
  acceptPayouts,
  resetAccept
};


export default connect(mapStateToProps, mapDispatchToProps)(Payout);
