import React, { Component, Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { useLocation, useHistory } from "react-router-dom";
import * as qs from 'query-string';
import { Loader, Subtitle } from 'gigapay-ui';
import { status } from '../../constants/action-types';
import { login } from '../../actions/user-login';
import { fetchUserCompanies, fetchCompanyLeads, } from '../../actions/user';


import Bankid from './modules/Bankid'
import Mail from './modules/Mail'

const loginModule = {
  'mail': Mail,
  'bankid': Bankid
}
const Authenticator = ({
  language,
  loginStatus,
  fetchUserCompanies,
  fetchCompanyLeads,
  login,
  baseName,
  redirectUrl,
  email,
  onComplete,
  onError,
  ...rest }) => {

  const location = useLocation();
  const history = useHistory();
  const [processingLogin, setProcessingLogin] = useState(false);
  const [loginMode, setLoginMode] = useState('bankid')
  // Check login
  useEffect(() => {
    if (loginStatus === status.SUCCESS) {
      onComplete()
      fetchUserCompanies();
      fetchCompanyLeads();
    }
    if (loginStatus === status.ERROR) {
      setProcessingLogin(false)
      onError()
    }
  }, [loginStatus]);

  // Check token
  const queryStrings = qs.parse(location.search);
  const token = queryStrings.token;
  useEffect(() => {
    if (typeof token !== 'undefined') {
      setProcessingLogin(true);
      login(token);
    }
  }, []);

  if (processingLogin) {
    return (
      <div style={{ textAlign: 'center' }}>
        <Loader large />
        <Subtitle style={{ marginTop: 20 }} small>Login in..</Subtitle>
      </div>
    )
  }
  const Login = loginModule[loginMode] || null;
  if (!Login) return Login;

  // send navigaton props
  const props = {
    navigate: path => {
      const url = `${baseName}${path}`;
      if (location.pathname !== url) {
        history.push(url)
      }
    },
    back: () => history.goBack(),
    root: baseName,
    redirectUrl: encodeURIComponent(redirectUrl),
    email,
    switchMode: (mode) => setLoginMode(mode)
  }

  return (
    <div>
      <Login {...props} />
    </div >
  );
}

Authenticator.contextTypes = {
  router: PropTypes.object
};
Authenticator.propTypes = {
  onComplete: PropTypes.func,
  onError: PropTypes.func,
  baseName: PropTypes.string,
  email: PropTypes.string,
  redirectUrl: PropTypes.string,
}
Authenticator.defaultProps = {
  onComplete: () => { },
  onError: () => {},
  baseName: "/login",
  email: null,
  redirectUrl: '/dashboard',
}


const mapStateToProps = (state) => ({
  error: state.auth.error,
  loginStatus: state.auth.loginStatus,
  language: state.auth.language,

  isAuthenticated: state.auth.isAuthenticated,
  personal_number: state.user.personal_number,
  onMobile: state.user.onMobile,
});
const mapDispatchToProps = dispatch => ({
  login: token => dispatch(login(token)),
  fetchUserCompanies: () => dispatch(fetchUserCompanies()),
  fetchCompanyLeads: () => dispatch(fetchCompanyLeads()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Authenticator);