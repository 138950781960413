import React, { useState } from 'react';
import { Table, Icon, List, Link, Dialog, Card, Subtitle } from 'gigapay-ui'
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { Avatar as UserAvatar } from 'components/';
import { format } from 'utils/dateTime';
import { numberWithSpaces } from 'utils/price';
import css from '../../payouts.module.css';

const CompanyTable = ({ data }) => {
  const { t } = useTranslation('payouts');
  const history = useHistory();
  const getStatus = props => {
    if (props.accepted_at) {
      return <span style={{ color: 'green' }}>{t('Paid out')}</span>
    } else if (props.notified_at) {
      return <span>{t('Awaiting to be claimed by recipient')}</span>
    } else {
      return <span style={{ color: 'orange' }}>{t('Waiting for payment')}</span>
    }
    return null;
  }
  const [payout, setPayout] = useState({'cost': '', 'amount': ''})
  const [showDialog, setShowDialog] = useState(false)
  return (
    <React.Fragment>
      <Dialog
        style={{ paddingTop: 15 }}
        show={showDialog}
        onBackdropClick={() => {
          setShowDialog(false);
        }}
        onEscapeKeyDown={() => {
          setShowDialog(false)
        }}
      >
        <Subtitle >{t('Payout')}</Subtitle>
        <Card>
          <List
            small
            items={[
              {
                key: 'to',
                value: payout.employee && <Link onClick={() => history.push(`/employees/${payout.employee.id}/`)}>{payout.employee.name}</Link> || '-',
                label: t('To')
              },
              {
                key: 'to',
                value: `${numberWithSpaces(payout.cost)} ${payout.currency}`,
                label: t('Cost')
              },
              {
                key: 'gross',
                value: `${numberWithSpaces(payout.amount)} ${payout.currency}`,
                label: t('Gross')
              }
            ]}
          />
        </Card>
        <Subtitle small>{t('Timeline')}</Subtitle>
        <Card>
          <List
            small
            items={[
              {
                key: 'created',
                value: payout.created_at && format(payout.created_at),
                label: t('Created')
              },
              {
                key: 'paid',
                value: payout.notified_at && format(payout.notified_at) || <Link style={{ color: 'red'}} onClick={() => history.push(`/invoices/${payout.invoice}/`)}>{t('Not paid')}</Link>,
                label: t('Paid')
              },
              {
                key: 'notified',
                value: payout.notified_at && format(payout.notified_at) || '-',
                label: t('Recipient notified')
              },
              {
                key: 'accepted',
                value: payout.accepted_at && format(payout.accepted_at) || '-',
                label: t('Paid out')
              }
            ]}
          />
        </Card>
      </Dialog>

      <Table
        keyExtractor={(item) => item.id}
        data={data}
        onClick={props => {
          setShowDialog(true);
          setPayout(props)
        }}
        //onClick={(props) => history.push("/employees/" + props.id)}
        columns={[{
          Header: t('common:Name'),
          Cell: props => (
            <div className="cell-container">
              <div style={{ display: 'inline-block', float: 'left', marginRight: 8 }}>
                <UserAvatar name={props.employee && props.employee.name} />
              </div>
              <span>
                <Link
                  onClick={() => history.push(`/employees/${props.employee.id}/`)}
                  className="bold-mobile"
                >
                  {props.employee && props.employee.name}
                </Link>
                <span className="tableCell-mobileOnly table-subtitle">{getStatus(props)} - {format(props.created_at, t)}</span>
              </span>
            </div>),
          id: 'name',
          className: 'main-cell',
          headerClassName: 'main-cell',
        }, {
          Header: t('common:Date'),
          Cell: props => <span>{format(props.created_at)}</span>,
          id: 'date',
          className: 'tableCell-desktopOnly',
          headerClassName: 'tableCell-desktopOnly'
        }, {
          Header: t('common:Status'),
          Cell: props => getStatus(props),
          id: 'status',
          className: 'tableCell-desktopOnly',
          headerClassName: 'tableCell-desktopOnly'
        }, {
          Header: <span className="text-align-right">{t('common:GrossSalary')}</span>,
          Cell: props => <span className="text-align-right">{numberWithSpaces(props.amount)} kr</span>,
          id: 'amount',
        }]}
      />
    </React.Fragment>
  )
}
export default CompanyTable;