import {
  types,
} from '../constants/action-types';
import { get, post } from 'utils/fetchV3';


const urlWithQuery = (parms = '', args = {}) => config => {
  let url = `${config.invoicingUrl}/${parms}?`;
  const pairs = [];
  for (let [k, v] of Object.entries(args)) {
    pairs.push(`${k}=${v}`);
  }
  url = `${url}${pairs.join('&')}`
  return url;
}

export const fetchInvoice = id =>
  get(types.FETCH_INVOICE, urlWithQuery(`invoices/${id}/`, { expand: 'company' }));

export const fetchInvoices = (args, reset = true) =>
  get(types.FETCH_INVOICES, urlWithQuery(`invoices/`, args), { reset, args });

export const createInvoicingPayout = data =>
  post(types.CREATE_INVOICING_PAYOUT, urlWithQuery(`payouts/`, { expand: 'company' }), data);

export const setInvoicingData = (info) => ({
    type: types.SET_INVOICING_DATA,
    payload: info,
});
