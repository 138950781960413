import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types'
import {
  Elements,
  StripeProvider,
  injectStripe
} from 'react-stripe-elements';
import { Button, Paragraph, Subtitle, Alert } from 'gigapay-ui'
import CardForm from 'components/CardForm/';
import { PriceTable } from 'components'
import { stripePublicKey } from 'config';
import { useTranslation } from 'react-i18next';

const Card = ({ loading, recipients, onSubmit, stripe, clientIntent, setPaymentResponse }) => {
  const { t } = useTranslation('payout')
  const [cardError, setCardError] = useState('')
  const [inProgress, setInProgress] = useState(false)
  useEffect(() => {
    if (stripe && clientIntent && !inProgress) {
      setPaymentResponse.begin();
      setInProgress(true)
      stripe
        .handleCardPayment(clientIntent)
        .then((payload) => {
          setInProgress(false)
          if (payload.error) {
            // it the intent has expired
            if(payload.error.code === "payment_intent_incompatible_payment_method"){
              setPaymentResponse.reset()
              setCardError('');
              return;
            }
            setPaymentResponse.failure({
              message: payload.error.message
            })
            setCardError(payload.error.message)
          } else {
           setCardError('');
           setPaymentResponse.success();
          }
        });
    }
  }, [clientIntent])


  return (
    <>
      <Paragraph>{t('The salary will be paid out to the recipient immediately')}</Paragraph>
      <Subtitle small>{t('Card details')}</Subtitle>
      <div className="receivers">
        <CardForm />
      </div>
      { cardError !== '' && (
        <Alert.Error style={{ marginBottom: 12}}>
          { cardError }
        </Alert.Error>
      )}
      <PriceTable payouts={recipients} />
      <Button.Primary
        loading={loading}
        onClick={() => onSubmit('card')}
        responsive
      >
        {t('Pay')}
      </Button.Primary>
    </>
  )
}

Card.propTypes = {
  setPaymentResponse: PropTypes.shape({
    failure: PropTypes.func,
    success: PropTypes.func,
    begin: PropTypes.func,
    reset: PropTypes.func,
  }),
  onSubmit: PropTypes.func,
  recipients: PropTypes.array,
}
Card.defaultProps = {
  setPaymentResponse: {
    failure: () => {},
    success: () => {},
    begin: () => {},
    reset: () => {},
  },
  onSubmit: () => {},
  recipients: [],
}

const InjectedCheckoutForm = injectStripe(Card);
const InjectedCardView = (props) => (
  <StripeProvider apiKey={stripePublicKey}>
    <Elements>
      <InjectedCheckoutForm {...props} />
    </Elements>
  </StripeProvider>
);

export default InjectedCardView;