import React, { useState } from 'react';
import { Fieldset, Input, Icon } from 'gigapay-ui';
import { usePayouts } from 'hooks/payoutsV2'
import { useTranslation } from 'react-i18next';
import { validPhoneNumber, validEmail } from 'utils/validate'
import DropDown from './components/DropDown'
import AmountInput from './components/AmountInput';
import { asPhoneNumber } from 'utils/number';


const Recipient = ({ id, employee, inputValue, pricing, description, inputType }) => {

  const { t } = useTranslation('payout');
  const { payouts, remove, update } = usePayouts();
  const [showEmployees, setShowEmployees] = useState(false);
  const deleteable = payouts.length !== 1;
  const [employeeError, setEmployeeError] = useState(false);
  const onChange = v => {
    update({
      id,
      ...v
    })
  };

  return (
    <Fieldset>
      {deleteable && (
        <div
          className="delete-receiver"
          onClick={() => remove(id)}
        >
          <Icon name={'close-circle'} color={'gray'} />
        </div>
      )}
      <Input
        leftIcon={<Icon name={'person'} size={22} />}
        label={t('common:Name')}
        value={employee.name}
        onChange={v => onChange({
          employee: { ...employee, name: v }
        })}
        autoComplete={'off'}
        onKeyDown={(e) => {
          if (e.keyCode === 9) {
            setShowEmployees(false)
          }
        }}
        onFocus={() => setShowEmployees(true)}
      >
        <DropDown
          show={showEmployees}
          onBlur={() => setShowEmployees(false)}
          value={employee.name}
          onClick={e => {
            const d = {
              employee: {
                name: e.name,
                cellphone_number: e.cellphone_number,
                email: e.email || null,
                value: e.cellphone_number || e.email
              }
            };
            onChange(d)
            setShowEmployees(false)
            setEmployeeError(
              !(
                validPhoneNumber(d.employee.cellphone_number) ||
                validEmail(d.employee.email)
              )
            );
          }}
        />
      </Input>
      <Input
        label={t('common:Phone number')}
        leftIcon={(
          validEmail(employee.value) ?
            <Icon name={'mail'} /> :
            <Icon name={'phone'} />
        )}
        error={employeeError}
        type={"text"}
        value={employee.value}
        onBlur={() => {
          setEmployeeError(
            !(
              validPhoneNumber(employee.cellphone_number) ||
              validEmail(employee.email)
            )
          );
        }}
        onChange={v => {
          let uEmployee = {
            name: employee.name || '',
            value: v
          }

          if (validPhoneNumber(v)) {
            uEmployee = {
              ...uEmployee,
              cellphone_number: asPhoneNumber(v)
            }
          } else if (validEmail(v)) {
            uEmployee = {
              ...uEmployee,
              email: v
            }
          } else {
            uEmployee = {
              ...uEmployee,
              email: null,
              cellphone_number: null
            }
          }
          onChange({ employee: uEmployee })
        }}
      />
      { /*
      <Input
        leftIcon={(
          validEmail(employee.value) ?
            <Icon name={'mail'} /> :
            <Icon name={'phone'} />
        )}
        label={t('common:Phone number')}
        inputMode="tel"
        type={"tel"}
        value={employee.value}
        error={employeeError}
        onBlur={() => {
          setEmployeeError(
            !(
              validPhoneNumber(employee.cellphone_number) ||
              validEmail(employee.email)
            )
          );
        }}
        onChange={v => {
          let uEmployee = {
            name: employee.name || '',
            value: v
          }
          
          if (validPhoneNumber(v)) {
            uEmployee = {
              ...uEmployee,
              cellphone_number: asPhoneNumber(v)
            }
          } else if (validEmail(v)) {
            uEmployee = {
              ...uEmployee,
              email: v
            }
          } else {
            uEmployee = {
              ...uEmployee,
              email: null,
              cellphone_number: null
            }
          }
          onChange({ employee: uEmployee })
        }}
        autoComplete={'off'}
        onKeyDown={(e) => {
          if (e.keyCode === 9) {
            setShowEmployees(false)
          }
        }}
        onFocus={() => setShowEmployees(true)}
      >
        <DropDown
          show={showEmployees}
          onBlur={() => setShowEmployees(false)}
          value={employee.value}
          onClick={e => {
            const d = {
              employee: {
                name: e.name,
                cellphone_number: e.cellphone_number,
                email: e.email || null,
                value: e.cellphone_number || e.email
              }
            };
            onChange(d)
            setShowEmployees(false)
            setEmployeeError(
              !(
                validPhoneNumber(d.employee.cellphone_number) ||
                validEmail(d.employee.email)
              )
            );
          }}
        />
      </Input>
      <Input
        label={t('common:Name')}
        leftIcon={true}
        type={"text"}
        value={employee.name}
        onChange={v => onChange({
          employee: { ...employee, name: v }
        })}
      />
      */}
      <Input
        label={t('common:Description')}
        leftIcon={<Icon name={'clipboard'} />}
        type={"text"}
        value={description}
        onChange={v => onChange({
          description: v
        })}
      />
      <AmountInput
        inputValue={inputValue}
        inputType={inputType}
        pricing={pricing}
        onChange={v => onChange(v)}
      />
    </Fieldset >
  )
};
export default Recipient;