import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next';
import { Elements, StripeProvider, injectStripe } from 'react-stripe-elements';
import { connect } from 'react-redux';
import {formatPrice, numberWithSpaces} from 'utils/price';
import {
  Icon,
  Dialog,
  Loader,
  Button,
  Alert,
  Subtitle
} from 'gigapay-ui'
import { CardForm } from 'components';
import { stripePublicKey } from 'config';

import {
  createPaymentIntent,
  paymentBegin,
  paymentSuccess,
  paymentFailure,
  paymentReset
} from 'actions/payout-payment'

const PaymentModal = ({
  paymentReset,
  paymentBegin,
  paymentSuccess,
  paymentFailure,
  paymentStatus,
  createPaymentIntent,
  onCancel,
  onComplete,
  visible,
  clientIntent,
  clientIntentError,
  invoice,
  stripe
}) => {
  const { t } = useTranslation(['invoice'])
  const submit = () => {
    if (invoice && invoice.id) {
      createPaymentIntent(invoice.id);
    }
  }
  const [cardError, setCardError] = useState('')
  const [inProgress, setInProgress] = useState(false)
  useEffect(() => {
    if (stripe && clientIntent && !inProgress) {
      paymentBegin();
      setInProgress(true)
      stripe
        .handleCardPayment(clientIntent)
        .then((payload) => {
          setInProgress(false)
          if (payload.error) {
            // it the intent has expired
            if (payload.error.code === "payment_intent_incompatible_payment_method") {
              paymentReset()
              setCardError('');
              return;
            }
            paymentFailure()
            setCardError(payload.error.message)
          } else {
            setCardError('');
            paymentSuccess();
          }
        });
    }
  }, [clientIntent])

  useEffect(() => {
    if (paymentStatus === 2) {
      onComplete();
      paymentReset()
    }
  }, [paymentStatus]);
  useEffect(() => {
    
    if (clientIntentError && clientIntentError.non_field_errors) {
      setCardError(t(clientIntentError.non_field_errors[0]))
    }
  },[clientIntentError]);

  return (
    <Dialog
      show={visible}
      onBackdropClick={() => {
        // reset payment info
        paymentReset()
        onCancel()
        setCardError(null);
      }}
      onEscapeKeyDown={() => {
        paymentReset()
        onCancel()
        setCardError(null);
      }}
    >
      <div>
        <Subtitle small>{t('Card information')}</Subtitle>
        <CardForm />
        <div className="summary">
          <div className="summary-sum">{numberWithSpaces(invoice.price)} kr</div>
          <div className="summary-subtitle">{t('Total amount including VAT')}</div>
        </div>
        { cardError && <Alert.Error style={{ marginBottom: 10 }}>{cardError}</Alert.Error>}
        <Button loading={paymentStatus === 1} onClick={submit} primary responsive>{t('Pay')}</Button>
      </div>
    </Dialog>
  )
}
PaymentModal.propTypes = {
  visible: PropTypes.bool,
  onComplete: PropTypes.func,
  onCancel: PropTypes.func,
  invoice: PropTypes.shape({
    id: PropTypes.string,
    amount: PropTypes.string,
  }),
}
PaymentModal.defaultProps = {
  visible: false,
  onComplete: () => { },
  onCancel: () => { },
  invoice: null,
}
const mapStateToProps = (state) => ({
  clientIntentError: state.payout.clientIntentError,
  clientIntent: state.payout.clientIntent,
  createPayoutStatus: state.payout.createPayoutStatus,
  createPayoutResponse: state.payout.createPayoutResponse,
  paymentStatus: state.payout.paymentStatus,
});
const mapDispatchToProps = {
  createPaymentIntent,
  paymentReset,
  paymentBegin,
  paymentSuccess,
  paymentFailure,
}

const InjectedPaymentModal = injectStripe(PaymentModal);
const InjectedPaymentModalView = (props) => (
  <StripeProvider apiKey={stripePublicKey}>
    <Elements>
      <InjectedPaymentModal {...props} />
    </Elements>
  </StripeProvider>
);


export default connect(mapStateToProps, mapDispatchToProps)(InjectedPaymentModalView);
