import AES from 'crypto-js/aes';
import CryptoJS from 'crypto-js';

import {
    UPDATE_SIMPLIFIED_INVOICE,
    UPDATE_URL,
    URL_FAILED,
    RESET_URL,
    RESET_AND_ADD,
} from '../constants/action-types'

import { validPersonalNumber } from '../utils/validate';

import { encryptionKey, appUrl } from '../config'


export const updateUrl = (data) => ({
    type: UPDATE_URL,
    payload: data
});
export const resetUrl = () => ({
    type: RESET_URL,
});
export const urlFailed = () => ({
    type: URL_FAILED,
});

export const updateSimplifiedInvoice = (data) => ({
    type: UPDATE_SIMPLIFIED_INVOICE,
    payload: data,
});

const makeid = () => {
    let text = "";
    const possible = "abcdefghijklmnopqrstuvwxyz0123456789";
  
    for (var i = 0; i < 10; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));
  
    return text;
  }

export const resetAndAdd = (recipient) => ({
    type: RESET_AND_ADD,
    payload: { ...recipient, tempId: makeid() },
})

export const generateUrl = () => (dispatch, getState) => {

    const { amount, description } = getState().simplifiedInvoice;
    const { personal_number, latest_cellphone_number } = getState().user;
    if(personal_number !== '' && validPersonalNumber(personal_number)){
        
        const encrypted = AES.encrypt(JSON.stringify({
            personalNumber: `${personal_number}`,
            amount,
            description,
            cellphoneNumber: latest_cellphone_number,
            valid: 1,
        }), encryptionKey).toString();
        const url = `${appUrl}/payout/step/add?ref=${encodeURIComponent(encrypted)}`;
        dispatch(updateUrl({ url }));
    }   

}

export const parseRef = (ref) =>  (dispatch) => {
    const decrypted = AES.decrypt(ref, encryptionKey).toString(CryptoJS.enc.Utf8);
    try{
        const json = JSON.parse(decrypted);
        
        if(json.valid === 1){
            delete json.valid;
            dispatch(resetAndAdd(json));
            //return json;
        }
    }catch(err) {

    }

}

