import React from 'react';
import PropTypes from 'prop-types'
import { Loader } from 'gigapay-ui'
import './NumberBox.css';
import { numberWithSpaces } from 'utils/price';

const NumberBox = ({amount, label, unit, loading}) => (
    <div className="number-box">
    
        <div className="number">
            {(loading || amount === -1) && <Loader inline color={'#fff'} />}
            {(!loading && amount >= 0) && <React.Fragment>{numberWithSpaces(amount)} {unit}</React.Fragment>}
        </div>
        <div className="label">{label}</div>
    </div>
);

NumberBox.propTypes = {
    amount: PropTypes.number,
    label: PropTypes.string,
    unit: PropTypes.string,
    loading: PropTypes.bool,
};

NumberBox.defaultProps = {
    amount: -1,
    label: '',
    unit: 'kr',
    loading: false,
};


export default NumberBox;