import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatPrice, numberWithSpaces } from 'utils/price';
import css from './list.module.css';

const List = ({
  label = '',
  sum = '',
  items = [],
  vatRate = 0.25,
  price = 0,
  currency = 'SEK'
}) => {
  const netSum = items.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.sum), 0);
  const totalPrice = Math.max(netSum, price)
  const vat = totalPrice - netSum;
  const { t } = useTranslation(['invoice', 'invoices'])
  return (
    <>
      <div className={css.thead}>
        <span className={css.label}>{label}</span>
        <span className={css.sum}>{numberWithSpaces(sum)}</span>
      </div>
      <ul className={css.list}>
        {items.map((i) => (
          <li key={i.key}>
            <span className={css.label}>{i.label}</span>
            <span className={css.amount}>{numberWithSpaces(i.sum)} {currency}</span>
          </li>
        ))}
      </ul>
      <div className={css.spec}>
        <ul className={css.specList}>
          <li>
            <span className={css.specLabel}>{t('Net Sum')}</span>
            <span className={css.specSum}>{formatPrice(netSum)} {currency}</span>
          </li>
          <li>
            <span className={css.specLabel}>{t('VAT')} (25 %)</span>
            <span className={css.specSum}>{formatPrice(vat)} {currency}</span>
          </li>
          <li className={css.bold}>
            <span className={css.specLabel}>{t('Gross Total')}</span>
            <span className={css.specSum}>{formatPrice(totalPrice)} {currency}</span>
          </li>
        </ul>
      </div>
    </>
  )
}
export default List;