import moment from 'moment-timezone';
import i18n from '../I18n/'

export const format = (date) => {
    const theDate = moment(date).tz("Europe/Stockholm");
    const today = moment().tz("Europe/Stockholm");
    const yesterday = moment().subtract(1,'day');
    
    
    if(theDate.isSame(yesterday,'day'))
        return `${i18n.t('common:Yesterday')} ${theDate.format('HH:mm')}`;

    if(theDate.isSame(today,'day'))
        return `${i18n.t('common:Today')} ${theDate.format('HH:mm')}`;
    
    if(theDate.diff(today,'year') === 0)
        return theDate.format('D MMM')


    return theDate.format('D MMM Y')
}