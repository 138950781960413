import {
  types,
} from '../constants/action-types';
import { post } from '../utils/fetchV3';


const privateUrl = (parms = '') => config =>
  `${config.privateUrl}/${parms}`;

const baseUrl = (parms = '') => config =>
  `${config.baseUrl}/${parms}`;
  
export const paymentReset = () => 
  ({ type: types.PAYMENT_RESET })
  
export const paymentBegin = () =>
  ({ type: types.PAYMENT_BEGIN })

export const paymentSuccess = () => ({
  type: types.PAYMENT_SUCCESS,
  payload: {
    succeeded: true,
  }
});
export const paymentFailure = message => ({
  type: types.PAYMENT_FAILURE,
  payload: {
    succeeded: false,
    ...message,
  }
});

export const createPayout = (data, paymentMethod) => 
  post(types.CREATE_PAYOUT, baseUrl(`payouts/?expand=employee`), data, { paymentMethod });

export const createPaymentIntent = invoice => 
  post(types.CREATE_PAYMENT_INTENT, privateUrl(`stripe/payment_intent/`), { invoice });

