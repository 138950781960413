import { types, status } from '../../../../constants/action-types';


const defaultState = {
  requestTokenStatus: status.DEFAULT,
}
const mailLoginReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.RESET: 
      return {
        ...state,
        requestTokenStatus: status.DEFAULT,
      }
    case types.REQUEST_TOKEN:
      switch (action.meta.status) {
        case status.BEGIN:
          return {
            ...state,
            requestTokenStatus: status.BEGIN,
            redirect: '',
          };
        case status.SUCCESS:
          return {
            ...state,
            requestTokenStatus: status.SUCCESS,
          };
        case status.ERROR:
          return {
            ...state,
            requestTokenStatus: status.ERROR,
          };
        default:
          return state;
      }
    default:
          return state;
  }
}
export default mailLoginReducer;