import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';

import { Route, NavLink, Switch } from "react-router-dom";
import { Title, Menu, BoxAction, Icon } from 'gigapay-ui';
import css from './settings.module.css';
import General from './components/General/'
import Bank from './components/Bank/'
import PersonalSettings from './components/PersonalSettings/'

import { fetchMe, save, updateUserInfo, verifyEmail } from '../../actions/user';


const Integrations = () => (
  <div className="flex-row-md">
    <div className="flex-col-md-7" style={{ marginTop: 24 }}>
      {
        <BoxAction
          className={css.apibox}
          label={'API-key'}
          description={(
            <div>
              <Trans i18nKey="settings:requestAPIKey">
                {[
                  <a className={css.link} key={'api-link'} href="mailto:info@gigapay.co?subject=API-key"></a>
                ]}
              </Trans><br />
              <a className={css.link} target="_blank" href="https://developer.gigapay.se/">Documentation</a>
            </div>
          )}
          Icon={<Icon name={'person'} size={30} />}
        />
      }
    </div>
    <div className="flex-col-md-4"></div>
  </div>
);



class Settings extends Component {

  logout = (e) => {
    e.preventDefault();
    this.props.logout();
  };

  componentDidMount = () => {};

  loadGeneral = () => {};

  loadBankDetails = () => {
    this.props.fetchMe();
  };

  render() {
    const { loading, save, mode, t } = this.props;

    return (
      <div className="content">

        <div className="flex-row-md">
          <div className="flex-col-md-12">
            <Title.Line>{t('Settings')}</Title.Line>
          </div>
        </div>

        <div className="flex-row-md">
          <div className="flex-col-md-12">
            {mode === 'company' && (
              <Menu.Tab>
                {props => (
                  <>
                    <Menu.Item>
                      <NavLink activeClassName={props.activeClassName} exact to="/settings/">{t('General')}</NavLink>
                    </Menu.Item>
                    <Menu.Item>
                      <NavLink activeClassName={props.activeClassName} to="/settings/integrations/">{t('Integration')}</NavLink>
                    </Menu.Item>
                  </>
                )}
              </Menu.Tab>
            )}
            {mode === 'person' && (
              <Menu.Tab>
                {props => (
                  <>
                    <Menu.Item>
                      <NavLink activeClassName={props.activeClassName} exact to="/settings/">{t('General')}</NavLink>
                    </Menu.Item>
                    <Menu.Item>
                      <NavLink activeClassName={props.activeClassName} exact to="/settings/bank/">{t('Banking information')}</NavLink>
                    </Menu.Item>
                  </>
                )}
              </Menu.Tab>
            )}
          </div>
        </div>

        <div className="flex-row-md">
          <div className="flex-col-md-12">

            <Switch>
              <Route path="/settings/" exact render={() =>
                (mode === 'company') ?
                  <General /> :
                  <PersonalSettings
                    save={this.props.save}
                    saveStatus={this.props.saveStatus}
                    updateUserInfo={this.props.updateUserInfo}
                    email={this.props.email}
                    language={this.props.language}

                    isValid={this.props.bankValid}
                    hasCompany={this.props.hasCompany}
                    /*onSubmit={this.props.saveBankDetails}*/
                    loading={this.props.bankLoading}
                    clearing={this.props.clearing}
                    account={this.props.account}
                    onMount={this.loadBankDetails}
                    status={this.props.bankStatus}
                  />
              } />
              <Route path="/settings/integrations/" exact component={Integrations} />
              <Route path="/settings/bank/" exact render={() => (
                <Bank
                  /*save={this.props.saveBankDetails}*/

                  isValid={this.props.bankValid}
                  hasCompany={this.props.hasCompany}
                  /*onSubmit={this.props.saveBankDetails}*/
                  loading={this.props.bankLoading}
                  clearing={this.props.clearing}
                  account={this.props.account}
                  onMount={this.loadBankDetails}
                  status={this.props.bankStatus}
                />
              )} />
              
            </Switch>

          </div>
        </div>

      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  loading: state.data.loading,
  hasCompany: state.user.hasCompany,
  mode: state.user.mode,
  isAuthenticated: state.auth.isAuthenticated,
  email: state.user.email,
  language: state.auth.language,
  saveStatus: state.user.saveStatus,
  verifyEmailStatus: state.user.verifyEmailStatus,
  verifyEmailError: state.user.verifyEmailError,
});

const mapDispatchToProps = {
  save,
  fetchMe,
  updateUserInfo,
  verifyEmail
};


export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(['settings', 'common'])(Settings));