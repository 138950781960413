import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { Button } from 'gigapay-ui';
import { useTranslation } from 'react-i18next';
import css from './actionbar.module.css';

const ActionBar = ({ itemCount = 0, onClick= () => {} }) => {
  const { t } = useTranslation('employees');
  const [barClass, setBarClass] = useState('');
  useEffect(() => {
    setBarClass(itemCount === 0 ? '' : css.show);
  },[itemCount]);
  return (
    <div className={`${css.actionBarContainer} ${barClass}`}>
      <div className={`content ${css.barContent}`}>
        <div className={css.actionBar}>
          <div className={css.col}>
            <Button.Primary responsive onClick={() => onClick(0)}>
              {t('Create payouts ({{x}})', { x: itemCount })}
            </Button.Primary>
          </div>
          <div className={`${css.col} ${css.colMid}`} />
          <div className={`${css.col} ${css.colRight}`}>
            <Button responsive onClick={() => onClick(1)}>
              {t('New pre-registration ({{x}})', { x: itemCount})}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

ActionBar.propTypes = {
  itemCount: PropTypes.number,
  onClick: PropTypes.func,
}

export default ActionBar
