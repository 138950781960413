import React from 'react';
import css from './section.module.css';

const Section = ({ children = null, title = null }) => {
  return (
    <div className={css.section}>
      {title && <h2 className={css.title}>{title}</h2>}
      {children}
    </div>
  )
}

export default Section;