import {

  FETCH_USER_SUCCESS,
  LOGIN_SUCCESS,

  types
} from '../constants/action-types';

import { status } from '../constants/action-types';

const defaultCompany = {
  name: '',
  organisational_number: '',
  address: '',
  email: '',
  logo: null,
  id: null,
  verifyId: null,

  createCompanyStatus: status.DEFAULT,
  companyEngagements: [],
  companyEngagementsLoading: false,
  companyEngagementsStatus: status.DEFAULT,
  verifyCompanyStatus: status.DEFAULT,

  status: status.DEFAULT,
  loading: false,
  error: null,
};


const company = (state = defaultCompany, action) => {
  switch (action.type) {
    
    case types.SAVE_COMPANY:
      switch (action.meta.status) {
        case status.BEGIN:
          return {
            ...state,
            loading: true,
            status: status.BEGIN,
            error: null,
          };
        case status.SUCCESS:
          return {
            ...state,
            loading: false,
            status: status.SUCCESS,
            error: null,
          };
        case status.ERROR:
          return {
            ...state,
            loading: false,
            status: status.ERROR,
            error: action.payload,
          };
        default:
          return state;
      }

    case types.CREATE_COMPANY:
      switch (action.meta.status) {
        case status.BEGIN:
          return {
            ...state,
            verifyId: null,
            createCompanyStatus: status.BEGIN,
            error: null,
          };
        case status.SUCCESS:
          return {
            ...state,
            createCompanyStatus: status.SUCCESS,
            verifyId: action.payload.id,
            error: null,
          };
        case status.ERROR:
          return {
            ...state,
            verifyId: null,
            createCompanyStatus: status.ERROR,
            error: action.payload
          };
        default:
          return state;
      }

    case types.VERIFY_COMPANY:
      switch (action.meta.status) {
        case status.BEGIN:
          return {
            ...state,
            id: null,
            verifyCompanyStatus: status.BEGIN,
            error: null,
          };
        case status.SUCCESS:
          return {
            ...state,
            id: action.payload.id,
            verifyCompanyStatus: status.SUCCESS,
            error: null,
          };
        case status.ERROR:
          return {
            ...state,
            id: null,
            verifyCompanyStatus: status.ERROR,
            error: action.payload
          };
        default:
          return state;
      }

    case types.FETCH_COMPANY_ENGAGEMENTS:
      switch (action.meta.status) {
        case status.BEGIN:
          return {
            ...state,
            companyEngagementsLoading: true,
            companyEngagementsStatus: status.BEGIN,
          };
        case status.SUCCESS:
          return {
            ...state,
            companyEngagementsLoading: false,
            companyEngagements: action.payload,
            companyEngagementsStatus: status.SUCCESS,
          };
        case status.ERROR:
          return {
            ...state,
            companyEngagementsLoading: false,
            companyEngagementsStatus: status.ERROR
          };
        default:
          return state;
      }

    case LOGIN_SUCCESS:
      return {
        ...state,
        name: action.payload.companyName
      };
      
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        name: action.payload.companyName
      };




 

    default:
      return state;
  }
}
export default company;