import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom'
import * as qs from 'query-string';
import { Loader } from 'gigapay-ui';
import { format as formatUrl } from 'utils/url';
import { status } from 'constants/action-types';
import { login } from 'actions/user-login';

const TokenLogin = ({
  isAuthenticated,
  login,
  loginStatus
}) => {
  const history = useHistory();
  const location = useLocation();
  const query = qs.parse(location.search);

  useEffect(() => {
    if (query.l) {
      login(query.l)
    }
  }, []);
  useEffect(() => {

  }, [isAuthenticated]);
  useEffect(() => {
    if (loginStatus === status.ERROR) {
      history.push(formatUrl(`/ok/login`, { ...query, failed: true }))
    } else if (loginStatus === status.SUCCESS) {
      if (query.id) {
        history.push(formatUrl(`/ok/payout/`, { id: query.id }))
      } else {
        history.push('/dashboard')
      }
    }
  }, [loginStatus]);
  return (
    <div className="loading-container" style={{ backgroundColor: 'white' }}>
      <Loader.Fill large inline />
    </div>
  );
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loginStatus: state.auth.loginStatus,
});
const mapDispatchToProps = {
  login
}
export default connect(mapStateToProps, mapDispatchToProps)(TokenLogin);