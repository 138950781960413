import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { withTranslation, useTranslation } from 'react-i18next';
import { Fieldset, Input, Link, Loader, Button, ToolTip, Icon } from 'gigapay-ui';
import { validate } from 'utils/banktools'
import { status } from 'constants/action-types';
import { createBriteBackend, updateBriteBackend } from "actions/payout-backends";
import { trim, slugify} from "../utils";

import css from './brite.module.css'


const ViewBriteBackend = ({briteBackend, setEditView}) => {
  const {t} = useTranslation('common');
  const result = validate(briteBackend.clearing, briteBackend.account, 'brite');
  return (
    <div className={css.viewBriteDetails}>
      <i className={`${css.bankType} ${css[slugify(result.bank)] || ''}`} title={result.bank}>{trim(result.bank)}</i>
      <span>{result.clearing.format}-{result.account.format}</span>
      <Link onClick={() => setEditView(true)} className={css.edit}>{t('Edit')}</Link>
    </div>
  )
};


const ErrorIcon = ({ message }) => {
  return (
    <ToolTip
      label={(
        <div className={css.errorMessage}>
          <Icon name={'alert'} size={13} color="#b50707"/>
        </div>
      )}
      width="200px"
      text={message}
      style={{position:"absolute", bottom:"3px", margin: 0}}
    />
  )
};

const WarningIcon = ({ message }) => {
  return (
    <ToolTip
      label={(
        <div className={css.errorMessage}>
          <Icon name={'alert'} size={13} color="#E36722"/>
        </div>
      )}
      text={message}
      width="350px"
      style={{position:"absolute", bottom:"28px", margin: 0}}
    />
  )
};


const BriteBackend = ({
  backend: briteBackend,
  createBriteBackend,
  updateBriteBackend,
}) => {

  const {t} = useTranslation('common');
  const clearingRef = useRef(null);
  const accountRef = useRef(null);
  const [editView, setEditView] = useState(false);
  const [clearing, setClearing] = useState('');
  const [account, setAccount] = useState('');
  const [fastTransfer, setFastTransfer] = useState(true);
  const [bank, setBank] = useState({
    className: null,
    name: '',
  });

  // If you have already saved an account, pre-populate the state with it.
  useEffect(() => {
    const result = validate(briteBackend.clearing, briteBackend.account, 'brite');
    setClearing(result.clearing.format);
    setAccount(result.account.format);
    setFastTransfer(result.bank ? result.supportFastTransfer : true);
    setBank({name: result.bank, className: slugify(result.bank)})
  }, [briteBackend.clearing, briteBackend.account]);

  const saveBriteBackend = () => {
    if (!briteBackend['token']) {
      createBriteBackend({clearing, account})
    } else {
      updateBriteBackend({clearing, account})
    }
  };

  // switch away from the edit view when you have saved
  useEffect(() => {
    if (briteBackend.status === status.SUCCESS && !briteBackend.loading && editView) {
      setEditView(false);
    }
  }, [briteBackend]);

  // check format the input on key press
  const _onAccountKeyDown = (e) => {
    if (!accountRef || !clearingRef) return;

    if (!(e.keyCode < 48 || e.keyCode > 57)) {
      const av = accountRef.current.value + e.key;
      const result = validate(clearing, av.trimEnd(), 'brite');
      setBank({name: result.bank, className: slugify(result.bank)});
      setAccount(result.account.format.trim());
      setFastTransfer(result.bank ? result.supportFastTransfer : true);
      e.preventDefault()
    }
    if (accountRef && accountRef.current.value.length === 0 && e.keyCode === 8) {
      if (clearingRef.current) {
        clearingRef.current.focus();
        e.preventDefault()
      }
    }
  };

  // on clearing key up, check bank
  const onClearingKeyUp = (e) => {
    if (!accountRef || !clearingRef) return;

    const cv = clearingRef.current.value;
    const result = validate(cv, account, 'brite');
    setBank({name: result.bank, className: slugify(result.bank)});
    setFastTransfer(result.bank ? result.supportFastTransfer : true);
    if (result.clearing.isValid && e.keyCode !== 8) {
      accountRef.current.focus();
    }
    setClearing(result.clearing.format)
  };

  const clearingError = (briteBackend.error && briteBackend.error.clearing) ? briteBackend.error.clearing: '';
  const accountError = (briteBackend.error && (briteBackend.error.account || briteBackend.error.non_field_errors)) ? (briteBackend.error.account || briteBackend.error.non_field_errors): '';

  return <>
    {editView && (
      <div>
        <Fieldset style={{margin: 0, border:0}}>
          <Fieldset.Row className={css.fieldSetRow}>
            <Input
              className={css.NarrowInput}
              label={<p>{t('Clearing')} {clearingError && <ErrorIcon message={clearingError}/>}</p>}
              inputmode="numeric"
              pattern="[0-9\-]{4,6}"
              ref={clearingRef}
              onKeyUp={onClearingKeyUp}
              onChange={c => setClearing(c.trim())}
              value={clearing}
              error={clearingError}
              warning={!fastTransfer}
              leftIcon={bank.className && (
                <i className={`${css.bankType} ${css[slugify(bank.className)] || ''}`} title={bank.name}>
                {trim(bank.name)} {!fastTransfer && <WarningIcon message={t('accept:This bank does not support fast payouts. The money will be paid out the next business day.')}/>}
                </i>
              )}
              inputStyle={bank.className? {paddingLeft: "76px"}: null}
              labelStyle={bank.className? {left: "76px"}: null}
            />
            <Input
              className={css.wideInput}
              label={<p>{t('Account')} {accountError && <ErrorIcon message={accountError}/>}</p>}
              inputmode="numeric"
              pattern="[0-9\- ]{7,15}"
              ref={accountRef}
              onKeyDown={_onAccountKeyDown}
              onChange={a => setAccount(a.trim())}
              value={account}
              error={accountError}
              rightIcon={
                <Button.Primary
                  onClick={saveBriteBackend}
                  className={css.save}
                  disabled={!(clearing && account)}
                >
                  {briteBackend['loading'] ? <Loader small color={'#fff'}/> : t('Use')}
                </Button.Primary>
              }
              rightIconStyle={{width: "76px", zIndex:2}}
              ficonStyle={{lineHeight: 1, fontSize: "10px", color: "#fff"}}
            />
          </Fieldset.Row>
        </Fieldset>
      </div>
    )}
    {!editView && (
      <ViewBriteBackend
        briteBackend={briteBackend}
        setEditView={setEditView}
      />
    )}
  </>
};

const mapDispatchToProps = {
  createBriteBackend,
  updateBriteBackend,
};

export default connect(null, mapDispatchToProps)(withTranslation('common')(BriteBackend));