
import {
  types,
  status,
} from '../constants/action-types';

const defaultSignup = {
  accountType: 1,
  email: null,
};


const signup = (state = defaultSignup, action) => {
    switch (action.type) {
      case types.REGISTER_EMAIL:
        return {
          ...state,
          email: action.payload.email,
        }
      case types.UPDATE_SIGNUP:
        return {
          ...state,
          ...action.payload
        }
        default: 
        return state;
    }
}
export default signup;