import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Paragraph, Title } from 'gigapay-ui'
import { SuccessAnimation } from 'components';

const Done = ({ onComplete }) => {
  const { t } = useTranslation('signup');
  const onDrop = () => {
    onComplete()
  };
  return (
    <div style={{ textAlign: 'center' }}>
      <SuccessAnimation />
      <Title>{t('You’re all done')}!</Title>
      <Paragraph small margins={false}>{t('We’ll get back to once we’ve verified your documents. Hang tight!')}</Paragraph>
    </div>
  )
}



const mapStateToProps = (state) => ({
  onMobile: state.user.onMobile
});
const mapDispatchToProps = dispatch => ({
});
export default connect(mapStateToProps, mapDispatchToProps)(Done);
