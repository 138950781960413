import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Subtitle, Paragraph, Button, Link, Alert } from 'gigapay-ui';
import { Placeholder as P } from 'components/'
import { useTranslation, Trans } from 'react-i18next';
import PayoutBackends from 'shared/PayoutBackends';
import { Details, Header } from './components/'
import { configFile } from 'config'

const Summary = ({ payouts, resetPayoutsById, totalAmount, totalTax, totalFee, loadingButton,
                   loading, onAcceptClicked, hasPaymentMethod, error }) => {
  const { t } = useTranslation(['accept', 'common']);

  const [isReady, setIsReady] = useState(false);
  const [canBeAccepted, setCanBeAccepted] = useState(false);

  useEffect(() => {
    resetPayoutsById()
  }, []);

  useEffect(() => {
    setCanBeAccepted(!payouts.every((i) => i.accepted_at !== null));
    setIsReady(payouts.length !== 0)
  }, [payouts]);
  const showReportModal = (e) => {
    e.preventDefault();
    const id = payouts[0].id;
    const message = t('Hey! My payout #{{id}} has the following error:', { id });
    window.Intercom('showNewMessage', message);
  };
  const isLoading = (loading || payouts.length === 0) || !isReady;

  return (
    <>
      <div className="title">
        <Header
          loading={isLoading}
          canBeAccepted={canBeAccepted}
          payouts={payouts}
        />

        <Details
          loading={isLoading}
          payouts={payouts}
          totalAmount={totalAmount}
          totalTax={totalTax}
          totalFee={totalFee}
          canBeAccepted={canBeAccepted}
        />

        {!isLoading && canBeAccepted && (
          <div>
            <Subtitle small>{t('Payment method')}</Subtitle>
            <PayoutBackends/>
          </div>
        )}

        <div className="spacing" />

        <Subtitle small>
          <P.Title ready={!isLoading}>{t('Terms')}</P.Title>
        </Subtitle>
        <Paragraph xsmall margins={false} className="terms">
          <Trans i18nKey="common:agrement">
            {[
              <a key={'terms-link'} rel="noopener noreferrer" target="_blank" href={t('common:termsLink')}></a>,
              <a key={'data-link'} rel="noopener noreferrer" target="_blank" href={t('common:dataLink')}></a>
            ]}
          </Trans>
        </Paragraph>
          
        {error && <Alert.Error> {error} </Alert.Error>}

        {!loading && canBeAccepted && <div className="">
          <Button
            onClick={onAcceptClicked}
            disabledPress={() => { }}
            disabled={!hasPaymentMethod}
            responsive
            loading={loadingButton}
            icon={'bankId'}
            primary>{t('Accept')}
          </Button>
          {configFile !== 'payhour' && (
            <div className="report-payout">
              <Link onClick={showReportModal}>{t('Report errors')}</Link>
            </div>
          )}

        </div>}

      </div>
    </>
  );
};

Summary.propTypes = {
  payouts: PropTypes.array,
  loadingButton: PropTypes.bool,
  loading: PropTypes.bool,
  totalAmount: PropTypes.number,
  net: PropTypes.number,
  totalTax: PropTypes.number,
  resetPayoutsById: PropTypes.func,
  onAcceptClicked: PropTypes.func,
  hasPaymentMethod: PropTypes.bool,
};

Summary.defaultProps = {
  payouts: [],
  loadingButton: false,
  loading: false,
  totalAmount: 0,
  totalTax: 0,
  resetPayoutsById: () => { },
  onAcceptClicked: () => { },
  hasPaymentMethod: false,
};


export default Summary;