import {
  LOGIN_BEGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_CANCEL,
  LOGOUT,
  RESET_ERROR,
  FETCH_USER_SUCCESS,

  RESET_SIGN_STATUS,
  SIGN_CANCEL,
  status,
  types
} from '../constants/action-types';

const defaultAuth = {
  isAuthenticated: false,
  isVerified: false,
  token: null,
  language: 'sv',

  loginStatus: 'default',
  error: null,


  signStatus: status.DEFAULT,

  loading: false,

};

/*
    loginStatus: default, inProgress, completed,
*/




const auth = (state = defaultAuth, action) => {
  switch (action.type) {

    case types.SET_LANG:
      return {
        ...state,
        language: action.payload
      };
    
    case types.VERIFY_EMAIL:
      switch (action.meta.status) {
        case status.SUCCESS:
          return {
            ...state,
            token: action.payload.token,
            language: action.payload.language || 'sv',
            isAuthenticated: action.payload.user_authenticated,
            isVerified: action.payload.user_verified,
            loginStatus: status.SUCCESS
          };
        default:
          return state;
      }


    case types.FETCH_ME:
    case types.SAVE_USER:
    case types.LOGIN:
      switch (action.meta.status) {
        case status.BEGIN:
          return {
            ...state,
            error: null,
            loginStatus: status.BEGIN
          };
        case status.SUCCESS:
          return {
            ...state,
            token: action.payload.token,
            language: action.payload.language || 'sv',
            isAuthenticated: action.payload.user_authenticated,
            isVerified: action.payload.user_verified,
            loginStatus: status.SUCCESS
          };
        case status.ERROR:
          return {
            ...state,
            error: action.payload.token,
            isAuthenticated: false,
            loginStatus: status.ERROR
          };
        default:
          return state;
      }


    case SIGN_CANCEL:
      return {
        ...state,
        signStatus: status.DEFAULT,
      }
    case RESET_SIGN_STATUS:
      return {
        ...state,
        signStatus: status.DEFAULT,
      }


    case FETCH_USER_SUCCESS:
      return {
        ...state,
        personalNumber: action.payload.personalNumber,
      }



    case LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        hasCompany: false,
        token: null,
        loginStatus: 'default',
      };

    case RESET_ERROR:
      return {
        ...state,
        error: null,
      };
    case LOGIN_BEGIN:
      return {
        ...state,
        loginStatus: 'inProgress',
        error: null,
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        token: action.payload.token,
        loginStatus: 'completed',
        error: null,
      };

    case LOGIN_FAILURE:
      return {
        ...state,
        loginStatus: 'default',
        error: action.payload,
      };

    case LOGIN_CANCEL:
      return {
        ...state,
        loginStatus: 'default',
        error: null,
      };
    default:
      return state;
  }
}
export default auth;