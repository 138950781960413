import React from 'react';
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next';
import {
  ToolTip,
  List,
  Loader
} from 'gigapay-ui'
import { useTotalAmount } from 'hooks/payout';
import { formatPrice, numberWithSpaces } from 'utils/price'
import css from './pricebarlist.module.css';

const Loading = () => (
  <div className={css.loading}>
    <Loader small inline className={css.loadingIndicator} />
  </div>
);

const PriceBarList = ({ recipients }) => {
  const { t } = useTranslation('common')
  //const { VAT, net, gross, tax, social, fee, cost } = useTotalAmount(recipients);

  const {
    amount,
    currency,
    payroll,
    fee,
    vat,
    cost,
    loading
  } = useTotalAmount(recipients);

  const summaryClass = (parseInt(cost) !== 0) ? css.show : '';
  return (
    <div className={`${css.sum} ${summaryClass}`}>
      <div className={css.totalSum}>
        {t('Total amount')} <small>({t('Excl. VAT')})</small>
        <span id="total-price" className={css.totalPrice}>
          { loading && <Loading />}
          {formatPrice(cost)} {currency}
        </span>
      </div>
      <div className={css.totalSumSubrow}>
        <div className={css.linkContainer}>

        </div>
        <div className={css.taxContainer}>
          <span> + {formatPrice(vat)} kr ({t('VAT')}) kr</span>
        </div>
      </div>
      <div className={css.details}>
        <div className={css.detailsBar}>
          <div className={css.green} title="50%" style={{ width: '50%' }}></div>
          <div className={css.orange} title="21.4%" style={{ width: '21.4%' }}></div>
          <div className={css.red} title="22.5%" style={{ width: '22.5%' }}></div>
          <div className={css.blue} title="5%" style={{ width: '6%' }}></div>
        </div>
        <List
          small
          items={[
            {
              key: 'net',
              label: (
                <div className={"label-container"}>
                  <div className={`${css.circle} ${css.green}`} />
                  {t('Net Pay')} <ToolTip width={150} text={t('Including 12% vacation pay')} />
                </div>),
              value: <span id="net">{formatPrice(amount * 0.7)} {currency}</span>
            },
            {
              key: 'tax',
              label: (
                <div className={"label-container"}>
                  <div className={`${css.circle} ${css.orange}`} />
                  {t('Tax')} (30%)
                  </div>),
              value: <span id="tax">{`${formatPrice(amount * 0.3)} ${currency}`}</span>
            },
            {
              key: 'social',
              label: (
                <div className={"label-container"}>
                  <div className={`${css.circle} ${css.red}`} />
                  {t('PayrollTax')} <ToolTip width={150} text={t('Paid by law to the tax authorities by Gigapay')} />
                </div>),
              value: <span id="social">{`${formatPrice(payroll)} ${currency}`}</span>
            },
            {
              key: 'fee',
              label: (
                <div className={"label-container"}>
                  <div className={`${css.circle} ${css.blue}`} />
                  {t('GigapayFee')} <ToolTip width={150} text={t('This helps us run our platform.')} />
                </div>),
              value: <span id="fee">{`${formatPrice(fee)} ${currency}`}</span>
            },
            {
              key: 'salestax',
              label: (
                <div className={"label-container"}>
                  <div className={`${css.circle} ${css.gray}`} />
                  {t('VAT')} (25%)
                  </div>),
              value: <span id="fee">{formatPrice(vat)} kr</span>
            },
          ]}
        />
      </div>
    </div>
  )
}

export default PriceBarList;