import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next';
import { Loader, Icon } from 'gigapay-ui';

const loadingStyle = {
  float: 'left',
  width: '100%',
  position: 'relative',
  display: 'block',
  height: 50,
  boxSizing: 'borderBox',
}
const loadMoreContainer = {
    float: 'left',
    width: '100%',
    height: 50,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
}

class TableFooter extends Component {

  renderLoading(){
    return (
      <div style={loadingStyle}>
        <Loader />
      </div>
    )
  }
  render() {
    
    const { loading, hasNext, onClick, t, paging } = this.props;

    if(loading){
      return this.renderLoading()
    }

    if(hasNext && paging){
      return (
        <div style={loadMoreContainer}>
          <span className="action" onClick={onClick}>{t('Next')} <Icon size={14} name={'arrow-forward'} /></span>
        </div>
      )
    }
    
    if(hasNext){
      return (
        <div style={loadMoreContainer}>
          <span className="action" onClick={onClick}>{t('Show more')} <Icon size={14} name={'arrow-down'} /></span>
        </div>
      )
    }
    return null;
  }
}

TableFooter.propTypes = {
  hasNext: PropTypes.bool,
  loading: PropTypes.bool,
  paging: PropTypes.bool,
  onClick: PropTypes.func,
}
TableFooter.defaultProps = {
  hasNext: false,
  loading: false,
  paging: false,
  onClick: () => {}
}

export default withTranslation(['common'])(TableFooter);
