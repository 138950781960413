import React, { Component, Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import CompanyDashboard from './components/CompanyDashbard/';
import PersonDashboard from './components/PersonDashboard/';

const Dashboard = ({
  mode,
  ...rest
}) => {

  if (mode === 'company') {
    return <CompanyDashboard />
  } 
  return <PersonDashboard />
}


const mapStateToProps = (state) => ({
  mode: state.user.mode,
});
const mapDispatchToProps = dispatch => ({
});


export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);