import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import {
  Loader,
  BoxSelector,
  Title,
  Icon,
} from 'gigapay-ui';
import { Card, Invoice } from './components';
import { CARD_MAX_LIMIT } from 'config/';
import {
  createPaymentIntent,
  createPayout,
  paymentBegin,
  paymentSuccess,
  paymentFailure,
  paymentReset
} from 'actions/payout-payment'
import { useTotalAmount } from 'hooks/payout';
import { transformPayout } from 'utils/requestHelpers';

const Pay = ({
  loading,
  invoiceId,
  clientIntent,
  createPayoutStatus,
  createPayoutResponse,
  createPaymentIntent,
  createPayout,
  paymentStatus,
  recipients,
  paymentReset,
  paymentBegin,
  paymentSuccess,
  paymentFailure,
}) => {
  const history = useHistory()
  const { t } = useTranslation('payout');
  const { cost, vat } = useTotalAmount(recipients);
  const totalAmount = parseFloat(cost) + vat;
  const canPayWithCard = totalAmount < CARD_MAX_LIMIT;
  const [paymentMethod, setPaymentMethod] = useState('card');

  useEffect(() => {
    paymentReset();
    if (!canPayWithCard) {
      setPaymentMethod('invoice')
    }
  }, [canPayWithCard]);

  useEffect(() => {
    if (paymentStatus === 2 && createPayoutResponse) {
      history.push(`/payout/step/done?id=${createPayoutResponse}`)
    }
  }, [paymentStatus]);

  useEffect(() => {
    if (createPayoutStatus === 2) {
      if (createPayoutResponse && paymentMethod === 'card') {
        createPaymentIntent(createPayoutResponse);
      } else if (createPayoutResponse && paymentMethod === 'invoice') {
        history.push(`/payout/step/invoices/${createPayoutResponse}/`)
      }
    }
  }, [createPayoutStatus]);

  const submit = method => {
    if (invoiceId && method === 'card') {
      createPaymentIntent(invoiceId);
    } else {
      const body = transformPayout(recipients);
      createPayout(body, method);
    }
  }

  return (
    <>
      {loading && <Loader.Fill />}
      <Title.Line small>{t('How would you like to pay?')}</Title.Line>
      <BoxSelector
        value={paymentMethod}
        onChange={method => setPaymentMethod(method)}
        options={[
          {
            Icon: <Icon name={'credit-card'} />,
            label: t('Card'),
            disabled: !canPayWithCard,
            title: !canPayWithCard ? t('Right now we only allow card payments for amounts under {{amount}} sek.', { amount: CARD_MAX_LIMIT }) : null,
            value: 'card',
            Component: (
              <Card
                recipients={recipients}
                onSubmit={submit}
                loading={loading}
                setPaymentResponse={{
                  begin: paymentBegin,
                  success: paymentSuccess,
                  failure: paymentFailure,
                  reset: paymentReset
                }}
                clientIntent={clientIntent}
              />)
          },
          {
            Icon: <Icon name={'document'} />,
            label: t('Invoice'),
            value: 'invoice',
            Component: (
              <Invoice
                recipients={recipients}
                onSubmit={submit}
                loading={loading}
              />
            )
          }
        ]}
      />
    </>
  )
}


const mapStateToProps = (state) => ({
  recipients: state.payout.recipients,
  clientIntent: state.payout.clientIntent,
  createPayoutStatus: state.payout.createPayoutStatus,
  createPayoutResponse: state.payout.createPayoutResponse,
  paymentStatus: state.payout.paymentStatus,
  loading: state.payout.loading,
  invoiceId: state.payout.invoiceId,
});
const mapDispatchToProps = {
  createPaymentIntent,
  createPayout,

  paymentReset,
  paymentBegin,
  paymentSuccess,
  paymentFailure,
};


export default connect(mapStateToProps, mapDispatchToProps)(Pay);