import React  from 'react';

export const useTotalAmount = (payouts) => {
  if (!payouts) {
    return {
      cost: 0,
      payroll: 0,
      amount: 0,
      fee: 0,
      feeUnit: '5%',
      vat: 0,
      tax: 0,
      currency: 'sek',
    }
  }
  const total = payouts
    .filter(f => f.pricing && f.pricing.cost)
    .reduce((acc, cur) => ({
      cost: acc.cost + parseFloat(cur.pricing.cost),
      payroll: acc.payroll + parseFloat(cur.pricing.payroll),
      amount: acc.amount + parseFloat(cur.pricing.amount),
      fee: acc.fee + parseFloat(cur.pricing.fee)
    }), {
      amount: 0,
      payroll: 0,
      cost: 0,
      fee: 0,
    });

  const vat = (total.cost * 1.25 - total.cost);
  return {
    cost: total.cost,
    payroll: total.payroll,
    amount: total.amount,
    fee: total.fee,
    vat: vat,
    tax: (total.amount * 0.3),
    currency: 'sek',
  }
};

