import React from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types'

import { BriteComplete, SwishComplete } from './components';

const Complete = ({ net, payoutBackends }) => {

  return (
    <>
      {payoutBackends.preferred_payment_method === 'brite' && 
        <BriteComplete 
          net={net} 
          payoutBackends={payoutBackends}
        />
      }
      {payoutBackends.preferred_payment_method === 'swish' &&
        <SwishComplete
          net={net}
          payoutBackends={payoutBackends}
        />
      }
    </>
  );
};

Complete.propTypes = {
  net: PropTypes.number,
};

Complete.defaultProps = {
  net: 0,
};


const mapStateToProps = (state) => ({
  payoutBackends: state.payoutBackends
});

const mapDispatchToProps = {};


export default connect(mapStateToProps, mapDispatchToProps)(Complete);

