import React from 'react';

const format = (parms = '', args = {}) => {
  let url = `${parms}?`;
  const pairs = [];
  for (let [k, v] of Object.entries(args)) {
    if (v !== null) {
      pairs.push(`${k}=${v}`);
    }
  }
  url = `${url}${pairs.join('&')}`
  return url;
}
export { format }

