import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom'
import { SuccessAnimation } from 'components/'
import { useLocationQuery } from 'hooks/utils';
import {
  Button,
  Icon,
  Title,
  Paragraph
} from 'gigapay-ui'
import { fetchInvoices } from 'actions/invoicing';

const Delivered = ({ fetchInvoices }) => {
  const { t } = useTranslation(['invoices']);
  const history = useHistory();
  const { id } = useLocationQuery({
    id: null
  });
  useEffect(() => {
    fetchInvoices({ expand: 'company' })
  }, [])
  return (
    <div style={{ textAlign: 'center' }}>
      <SuccessAnimation />
      <Title>{t('Done')}</Title>
      <div style={{ marginTop: 10 }}>
        <Paragraph>{t('Your invoice has been sent. The salary is paid out after the invoice has been paid by the customer.')}</Paragraph>
        <Button.Primary onClick={() => history.push(`/invoices/${id}/`)}>{t('Follow the progress')}</Button.Primary>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
});
const mapDispatchToProps = {
  fetchInvoices
};

export default connect(mapStateToProps, mapDispatchToProps)(Delivered);
