import React, { Component, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom'
import ReceivedInvoice from './ReceivedInvoice';
import InvoiceOverview from './InvoiceOverview';

const Invoice = ({ mode }) => {
  let { pathname } = useLocation();

  if (mode === 'company' || pathname.includes('preview')) {
    return (
      <ReceivedInvoice />
    )
  }
  return (
    <InvoiceOverview />
  )
}


const mapStateToProps = (state) => ({
  mode: state.user.mode,
});
const mapDispatchToProps = {

}


export default connect(mapStateToProps, mapDispatchToProps)(Invoice);
